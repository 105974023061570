import { Injectable } from '@angular/core';
import { MainService } from '../../public/services/main.service';
import { AlertService } from '../alertService/alert-service.service';
import { Router } from '@angular/router';

interface Permissions {
  accces: boolean;
  reading: boolean;
  writing: boolean;
}

const globalPermiisons: Permissions = {
  accces: false,
  reading: false,
  writing: false,
};

enum PermissionOptions {
  ACCESS = 'NINGUNO',
  READING = 'LECTURA',
  WRITING = 'ESCRITURA',
}

@Injectable({
  providedIn: 'root',
})
export class RolService {
  private currentUser: any = JSON.parse(localStorage.getItem('usuario'));
  private moduleToCheck: string;

  private permissions: Permissions = {
    accces: false,
    reading: false,
    writing: false,
  };

  private messagesAlert = {
    accces: 'No se tiene permisos de acceso al módulo',
    reading: 'No tienes permiso de lectura',
    writing: 'No tienes permiso de escritura',
  };

  constructor(private mainService: MainService, private alertService: AlertService, private router: Router) {}

  public async checkRolAccess(moduleToCheck: string): Promise<Permissions> {
    try {
      const rol = await this.mainService.get(`api/rol/${this.currentUser.tipo}`).toPromise();
      this.moduleToCheck = rol[moduleToCheck];
      this.setPermissonsModule();

      return this.permissions;
    } catch (e) {
      return this.permissions;
    }
  }

  private setPermissonsModule() {
    this.permissions.accces = this.moduleToCheck !== PermissionOptions.ACCESS;
    this.permissions.reading =
      this.moduleToCheck === PermissionOptions.READING || this.moduleToCheck === PermissionOptions.WRITING;
    this.permissions.writing = this.moduleToCheck === PermissionOptions.WRITING;
  }

  // keyof typeof this.permissions
  public checkByPermissions(permisionToCheck: keyof typeof globalPermiisons) {
    if (this.permissions[permisionToCheck]) return this.permissions[permisionToCheck];
    if (permisionToCheck === 'accces') this.router.navigate(['home/dashboard']);

    this.alertService.simpleAlertWarning(this.messagesAlert[permisionToCheck]);
    return this.permissions[permisionToCheck];
  }
}
