import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import * as _ from 'lodash';
import { multiSelectsIns } from '../../../../instructions/multipleSelect.ins';

@Component({
  selector: 'area-empresa-detalle',
  templateUrl: './area-empresa-detalle.component.html',
  styleUrls: ['./area-empresa-detalle.component.scss'],
})
export class AreaEmpresaDetalleComponent implements OnInit {
  /** Referencia al permiso de edición */
  @Input() available: boolean;
  /** Referencia al dialogo del detalle */
  @Input() dialogRef: any;
  /** Modo de la accion del detalle ('CREAR', 'EDITAR' o 'VER') */
  @Input() modo: string;
  /** Datos del area de la empresa seleccionada */
  @Input() areaEmpresa: any;
  /** Empresa Pre seleccionada */
  @Input() empresa: any;
  @Input() empresaSQL: any;
  /** Evento que indica que se realizo algun cambio en la tabla areaEmpresas de la BD */
  @Output() cambioEnAreaEmpresa: EventEmitter<any> = new EventEmitter<any>();

  /** Formulario reactivo con los datos del area de una empresa */
  public areaEmpresaFormulario: FormGroup;
  /** Datos de empresas de la BD */
  public empresas: any = [];
  /** Poibles modos de acciones para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Indica si las empresas se estan cargando */
  public cargandoEmpresas = true;
  /** Indica que le modulo se esta cargando */
  public cargandoModulo = false;

  public instructions = {
    areaCompanyType: multiSelectsIns.areaCompanyType,
  };

  constructor(private mainService: MainService) {}
  public idEmpresa = undefined;

  public disabledInputSearch = false;

  ngOnInit() {
    if (this.empresaSQL) {
      this.disabledInputSearch = true;
      this.idEmpresa = this.empresaSQL.EMP_ID;
    } else if (this.areaEmpresa) {
      console.log(this.areaEmpresa);
      this.idEmpresa = this.areaEmpresa.empresa;
    }

    this.obtenerEmpresas();
    this.inicializarFormulairoSegunModo();
  }

  /**
   * Inicializa el formulario reactivo segun el modo del detalle
   */
  inicializarFormulairoSegunModo() {
    switch (this.modo) {
      case this.modos[0]:
        this.inicializarFormularioModoCrear();
        break;
      case this.modos[1]:
        this.inicializarFormularioModoEditar();
        break;
      case this.modos[2]:
        this.inicializarFormualrioModoVer();
        break;
    }
  }

  /**
   * Carga los datos de las empresas de la BD
   */
  obtenerEmpresas() {
    let queries = '?activo=true';

    if (this.empresaSQL) {
      queries = `${queries}&EMP_ID=${this.empresaSQL.EMP_ID}`;
    }

    this.cargandoEmpresas = true;

    this.mainService.get(`api/empresa_sql${queries}`).subscribe((res) => {
      this.empresas = res;
      this.empresas = _.orderBy(this.empresas, ['EMP_NOMBRE']);
      this.setDataCollectionEmpresa();
      this.cargandoEmpresas = false;
    });
  }

  /**
   * Inincializa los datos del formulario reactivo en modo crear
   */
  inicializarFormularioModoCrear() {
    let empresa = undefined;

    if (this.empresaSQL) {
      empresa = this.empresaSQL.EMP_ID;
    } else {
      empresa = this.empresa;
    }

    this.areaEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: 'PRINCIPAL', disabled: false }, Validators.required),
      empresa: new FormControl({ value: empresa, disabled: false }, Validators.required),
      type: new FormControl({ value: '', disabled: false }, Validators.required),
    });
  }

  /**
   * Inincializa los datos del formulario reactivo en modo editar
   */
  inicializarFormularioModoEditar() {
    this.instructions.areaCompanyType.defaults.value = this.areaEmpresa.type;

    this.areaEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: this.areaEmpresa.nombre, disabled: false }, Validators.required),
      empresa: new FormControl({ value: this.areaEmpresa.empresa, disabled: false }, Validators.required),
      type: new FormControl({ value: this.areaEmpresa.type, disabled: false }, Validators.required),
    });
  }

  /**
   * Inincializa los datos del formulario reactivo en modo ver
   */
  inicializarFormualrioModoVer() {
    this.areaEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: this.areaEmpresa.nombre, disabled: true }, Validators.required),
      empresa: new FormControl({ value: this.areaEmpresa.empresa, disabled: true }, Validators.required),
      type: new FormControl({ value: this.areaEmpresa.type, disabled: true }, Validators.required),
    });
  }

  /**
   * Se asegura que los datos ingresados al formulario sean correctos y envia los datos a la BD
   */
  onEnviarFormulario() {
    this.cargandoModulo = true;
    if (this.areaEmpresaFormulario.invalid) {
      Swal.fire({
        title: `¡No se puede ${this.modo.toLocaleLowerCase()} el área!`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargandoModulo = false;
    } else {
      // Contenido del formulario
      const contenidoFormulario = this.areaEmpresaFormulario.value;
      // Contenido del formulario en formato JSON
      const areaEmpresa = JSON.parse(JSON.stringify(contenidoFormulario));

      if (this.modo == this.modos[0]) {
        this.mainService.get('api/global_variable/AEM_ID').subscribe((res) => {
          // Cuando la variable global no ha sido creada llega un mensaje
          if (res.message) {
            areaEmpresa.AEM_ID = 90100; //Primer valor del ID
            // Crea el objeto que sera guardado en la BD
            const areaEmpresaIdGlobalVariable = {
              key: 'AEM_ID',
              value: areaEmpresa.AEM_ID,
            };
            this.mainService.post('api/global_variable', areaEmpresaIdGlobalVariable).subscribe((result) => {});
          } else {
            areaEmpresa.AEM_ID = res.value;
          }

          this.mainService.put('api/global_variable/AEM_ID', {}).subscribe((result) => {});
          this.mainService.post('api/area_empresa', areaEmpresa).subscribe((result) => {
            if (result) {
              this.cambioEnAreaEmpresa.emit(result);

              Swal.fire('Éxito', 'Se agregó el área exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.cerrarDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo agregar el área, verifique la información', 'warning');
            }

            this.cargandoModulo = false;
          });
        });
      } else {
        this.mainService.put('api/area_empresa/' + this.areaEmpresa._id, areaEmpresa).subscribe((result) => {
          if (result) {
            this.cambioEnAreaEmpresa.emit(result);

            Swal.fire('Éxito', 'Se actualizó el área exitosamente', 'success').then((result) => {
              if (result.value) {
                this.cerrarDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo actualizar el área, verifique la información', 'warning');
          }
        });
      }
    }
  }

  /**
   * Cierra la ventana de dialogo del detalle
   */
  cerrarDialog() {
    this.dialogRef.close();
  }

  /**
   * Recarga las empresas trayendolas de la BD
   */
  onRecargarEmpresas() {
    this.obtenerEmpresas();
  }

  setEmpresaAreaEmpresa(data: string) {
    if (data === undefined) data = null;
    this.areaEmpresaFormulario.patchValue({
      empresa: data,
    });
  }

  public dataCollectionToFindAreaEmpresa = [];
  setDataCollectionEmpresa() {
    this.dataCollectionToFindAreaEmpresa = [];
    this.empresas.forEach((empresa) => {
      if (!empresa.EMP_NOMBRE) return;
      this.dataCollectionToFindAreaEmpresa.push({
        key: empresa.EMP_ID,
        value: empresa.EMP_NOMBRE,
      });
    });
  }
}
