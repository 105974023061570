import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../../../../services/main.service';
import { Router } from '@angular/router';
import { ReportesService } from '../../../../reportes.service';

@Component({
  selector: 'vista-previa-reporte-zarpe',
  templateUrl: './vista-previa-reporte-zarpe.component.html',
  styleUrls: ['./vista-previa-reporte-zarpe.component.scss'],
})
export class VistaPreviaReporteZarpeComponent implements OnInit {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  public companyName: string;
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Arreglo con los parrafos del remark*/
  public parrafosRemarks: string[] = [];
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = true;
  /** Indica si se incluyen las tablas o no */
  public incluirTablas: boolean = true;
  /** Indica los posibles reportes del modeulo */
  public posiblesReportesModulo: string[] = ['Fin Operaciones', 'Zarpe'];
  /** Indica si se esta en el reporte de zarpe o en el reporte de fin de operaciones*/
  public reporteActual: string = 'Cargando ...';
  /** Indica si puede ver el cuadro de arrival y sailing conditions */
  public verCuadros: boolean;
  /** sofId */
  public sofConfirmadas: any[];
  public sofEstimadas: any[];
  /** Imagen del buque */
  public imagenBuque: string;
  public iconBuque: string;

  /** Lista de correos con copia y copia oculta */
  public correosCopia: string[];
  public correosCopiaOculta: string[];

  public asunto: string;
  public attachments: any[];

  constructor(private mainService: MainService, public router: Router, private reportesService: ReportesService) {}

  ngOnInit() {
    this.obtenerViaje();
  }

  public showSOFActivities: boolean;
  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }
  /**
   * A traves de la URL obtiene el viaje del reporte de prospectos e inicializa el viaje en el formato requerido para
   * el componente de reporte de prospectos
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.reporteActual =
      partesURL[2] == 'reporte-zarpe' ? this.posiblesReportesModulo[1] : this.posiblesReportesModulo[0];

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      this.viaje = res;
      this.obtenerNombresAsociacionesRelevantesViaje();
      // this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
      this.parrafosRemarks = this.viaje.remark ? this.viaje.remark.split('\n') : [];
      this.cargando = false;
    });
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de prospectos
   */
  obtenerNombresAsociacionesRelevantesViaje(): void {}

  /**
   * Obtiene las actividades confirmadas y estimadas ordenadas cronologicamente
   */
  obtenerActividadesEstimadasYConfirmadasOrdenadas() {
    if (Object.keys(this.viaje.portLog).length > 0) {
      let activdadesOrdenadas = [];
      for (const property in this.viaje.portLog) {
        if (this.viaje.portLog[property].fecha) {
          let actividad = {
            nombre: this.viaje.portLog[property].nombre,
            fecha: this.viaje.portLog[property].fecha,
            status: this.viaje.portLog[property].status,
            fechaVisual: '',
          };

          activdadesOrdenadas.push(actividad);
        }
      }

      activdadesOrdenadas.forEach((actividad) => {
        actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
      });

      activdadesOrdenadas.sort(function (a, b) {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

      this.arregloOrdenadoActividadesConfirmadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'CONFIRMED'
      );
      this.arregloOrdenadoActividadesEstimadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'ESTIMATED'
      );
    } else {
      this.arregloOrdenadoActividadesConfirmadas = [];
      this.arregloOrdenadoActividadesEstimadas = [];
    }
  }

  /**
   * Cambia el estado de la inclusión de actividades
   */
  public onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;

    const allActivites = [...this.arregloOrdenadoActividadesConfirmadas, ...this.arregloOrdenadoActividadesEstimadas];

    if (this.incluirActividades) {
      allActivites.push(...this.sofConfirmadas, ...this.sofEstimadas);
    }

    const { confirmedActivities, estimatedActivites } = this.reportesService.combinePortLogsAndSOFActivities(
      allActivites,
      this.incluirActividades
    );

    this.arregloOrdenadoActividadesConfirmadas = [...confirmedActivities];
    this.arregloOrdenadoActividadesEstimadas = [...estimatedActivites];
  }

  /**
   * Cambia el estado de la inclusión de tablas
   */
  onCambioIncluirTablas() {
    this.incluirTablas = !this.incluirTablas;
  }
}
