import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { PqrsService } from '../../pqrs.service';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { e } from 'mathjs';
import * as moment from 'moment';
import { TimeService } from '../../../../services/time/time.service';
@Component({
  selector: 'todos-los-pqrs',
  templateUrl: './todos-los-pqrs.component.html',
  styleUrls: ['./todos-los-pqrs.component.scss'],
})
export class TodosLosPqrsComponent implements OnInit {
  /** Referencia al permiso de edición */
  @Input() available: boolean;
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Tipos de modos para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle */
  public modo: String = 'CREAR';
  /** Dato seleccionado por el usuario*/
  public pqr: any;
  /** PQRs en la BD */
  public pqrs: any;
  /** Subscripcion a los pqrs del servicio */
  private pqrsSubscripcion: Subscription;
  /** Datos de las PQRS */
  public datosPqrs: any;
  /** Subscripcion a los datos de las pqrs del servicio */
  private datosPqrsSubscripcion: Subscription;
  /** Indica si se estan cargando las PQRs */
  public pqrsCargandose: boolean;
  /** Subscripcion a los pqrs del servicio */
  private pqrsCargandoseSubscripcion: Subscription;
  /** Configuracion de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      idPqr: {
        title: 'ID',
      },
      estadoHtml: {
        type: 'html',
        title: 'Estado',
      },
      nombreBuque: {
        title: 'Buque',
      },
      nombreNominador: {
        title: 'Nominador',
      },
      nombrePuerto: {
        title: 'Puerto/Oficina',
      },
      tipoSolicitud: {
        title: 'Tipo de solicitud',
      },
      nombreResponsable: {
        title: 'Responsable',
      },
      nombreQuienReporta: {
        title: 'Quien reporta',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Informacion del usuario actual */
  public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));

  /** Referencia al detalle*/
  @ViewChild('pqrDetalle', { static: true }) pqrDetalle: ElementRef;

  constructor(
    private pqrsService: PqrsService,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private dateAndTimeService: DateAndTimeService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.inicializarSubscripciones();
  }

  ngOnDestroy(): void {
    this.detenerSubscripciones();
  }

  /**
   * Inicializa los datos del modulo
   */
  recargarDatos(): void {
    this.pqrs = [];
    this.pqrsService.obtenerPqrs();
  }

  /**
   * Subscribrse a los datos necesarios del servicio
   */
  inicializarSubscripciones(): void {
    this.pqrsCargandoseSubscripcion = this.pqrsService.cargando.subscribe((cargandose) => {
      this.pqrsCargandose = cargandose;
    });
    this.pqrsSubscripcion = this.pqrsService.pqrsCambiadas.subscribe((pqrs) => {
      this.pqrs = pqrs;
    });
    this.datosPqrsSubscripcion = this.pqrsService.datosPqrsCambiados.subscribe((datos) => {
      this.datosPqrs = datos;
    });
  }

  /**
   * Detener Subscripciones del servicio
   */
  detenerSubscripciones(): void {
    this.pqrsSubscripcion.unsubscribe();
    this.pqrsCargandoseSubscripcion.unsubscribe();
    this.datosPqrsSubscripcion.unsubscribe();
  }

  /**
   * Abre el detalle de una PQR
   * @param dialog Referencia al detalle
   */
  abrirPqrDetalleDialog(dialog): void {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear, Ver o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarPqr(event);
        break;
      case 'editar':
        this.editarPQR(event);
        break;
    }
  }

  /**
   * Borra el PQR de la BD
   * @param event Evento con los datos de la PQR a eliminar
   */
  borrarPqr(event): void {
    if (
      this.usuarioActual._id === event.data.responsable._id ||
      this.usuarioActual._id !== event.data.quienReporta._id
    ) {
      Swal.fire({
        title: 'No se tiene permisos para eliminar PQRs asignadas',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el PQR?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/pqr/' + event.data._id).subscribe((res) => {
          if (res.exito) {
            Swal.fire('Éxito', 'PQR eliminada exitosamente', 'success');

            this.recargarDatos();
          } else {
            Swal.fire('Error', 'Se generó un error al intentar eliminar la PQR', 'error');
          }
        });
      }
    });
  }

  /**
   * Edita una PQR en la BD
   * @param event Evento con los datos de la PQR a editar
   */
  editarPQR(event) {
    this.pqr = event.data;
    this.modo = this.modos[1];

    if (this.usuarioActual._id === this.pqr.responsable._id || this.usuarioActual._id === this.pqr.quienReporta._id) {
      this.available = false;
    } else {
      this.available = true;
    }

    this.abrirPqrDetalleDialog(this.pqrDetalle);
  }

  /**
   * Descarga el excel con todas las PQRs
   */
  onDescargarExcel() {
    let pqrsExcel = [];

    this.pqrs.forEach((element) => {
      let pqr = {
        id: element.idPqr,
        estado: element.estado,
        tipo: element.tipoSolicitud,
        responsable: element.tipoSolicitud,
        quienReporta: element.nombreQuienReporta,
        clientes: element.nombresClientes,
        fechaEvento: element.fechaEvento
          ? `${this.dateAndTimeService.convertirFechaAFormatoFormulario(
              element.fechaEvento
            )} ${this.dateAndTimeService.convertirHoraAFormatoFormulario(
              element.fechaEvento
            )}:${this.dateAndTimeService.convertirMinutoAFormatoFormulario(element.fechaEvento)}`
          : '',
        area: element.area,
        buque: element.nombreBuque,
        nv: element.viaje.nv,
        nominador: element.nombreNominador,
        puerto: element.nombrePuerto,
        descripcionCaso: element.descripcionCaso,
        respuestaCaso: element.respuestaCaso,
        seguimientoCierre: element.seguimientoCierre,
        fechaSeguimientoCierre: element.fechaSeguimientoCierre
          ? `${this.dateAndTimeService.convertirFechaAFormatoFormulario(
              element.fechaSeguimientoCierre
            )} ${this.dateAndTimeService.convertirHoraAFormatoFormulario(
              element.fechaSeguimientoCierre
            )}:${this.dateAndTimeService.convertirMinutoAFormatoFormulario(element.fechaSeguimientoCierre)}`
          : '',
      };

      pqrsExcel.push(pqr);
    });

    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: 'PQRs',
    };
    workbook.SheetNames.push('PQRs');

    let worksheet = XLSX.utils.json_to_sheet(pqrsExcel);
    worksheet.A1.v = 'ID';
    worksheet.B1.v = 'Estado';
    worksheet.C1.v = 'Tipo';
    worksheet.D1.v = 'Responsable';
    worksheet.E1.v = 'Quien reporta';
    worksheet.F1.v = 'Clientes';
    worksheet.G1.v = 'Fecha evento';
    worksheet.H1.v = 'Area';
    worksheet.I1.v = 'Buque';
    worksheet.J1.v = 'NV';
    worksheet.K1.v = 'Nominador';
    worksheet.L1.v = 'Puerto';
    worksheet.M1.v = 'Descripción';
    worksheet.N1.v = 'Respuesta';
    worksheet.O1.v = 'Seguimiento/Cierre';
    worksheet.P1.v = 'Fecha seguimiento/cierre';

    workbook.Sheets['PQRs'] = worksheet;

    let wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), 'pqrs.xlsx');
  }

  /**
   * Convierte el libro de calculo de binario a octado
   * @param s Libro de calculo en binario
   * @returns Buffer con el libro de calculo en octado
   */
  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }
}
