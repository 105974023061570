import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MainService } from '../services/main.service';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.scss'],
})
export class ContactosComponent implements OnInit {
  /** Configuracion de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
        { name: 'ver', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      nombre: {
        title: 'Nombre',
      },
      correo: {
        title: 'Correo',
      },
      referenciaEmpresa: {
        title: 'Empresa',
        valuePrepareFunction: (empresa) => {
          return empresa.EMP_NOMBRE;
        },
        filterFunction: (empresa, search: string) => {
          if (empresa.EMP_NOMBRE && search) {
            return empresa.EMP_NOMBRE.toLowerCase().includes(search.toLowerCase());
          }
          return false;
        },
      },
      referenciaArea: {
        title: 'Área',
        valuePrepareFunction: (area) => {
          return area.nombre;
        },
        filterFunction: (area, search: string) => {
          if (area.nombre && search) {
            return area.nombre.toLowerCase().includes(search.toLowerCase());
          }
          return false;
        },
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Contactos de empresas en la BD */
  public contactosEmpresa: any = [];
  /** Posibles modos para el detalle del contacto */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle de contacto */
  public modo: String = 'CREAR';
  /** Contacto de empresa actualmente seleccionado */
  public contactoEmpresa: any;
  /** Indica si algo se esta cargando en el modulo */
  public cargando: boolean = false;
  /** Empresas en la BD */
  public empresas: any = [];
  /** Areas de empresas en la BD*/
  public areasEmpresas: any = [];

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  /** Detalle de contacto de empresa */
  @ViewChild('contactoEmpresaDetalle', { static: true }) contactoEmpresaDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private router: Router, private timeService: TimeService) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.contactosEmpresas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.inicializarDatos();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  inicializarDatos() {
    this.cargando = true;
    this.contactosEmpresa = [];
    this.mainService.get('api/contacto_empresa').subscribe((res) => {
      this.contactosEmpresa = res;
      this.cargando = false;
    });
  }

  /**
   * Inincializa la creacion de un contacto de una empresa
   */
  onCrearContactoEmpresa() {
    this.contactoEmpresa = null;
    this.modo = this.modos[0];
    this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
  }

  /**
   * Abre el detalle del contacto de la empresa
   * @param dialog Referencia al dialogo del detalle
   */
  abrirContactoEmpresaDetalleDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las posibles acciones
   * @param event Evento con la información de la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarContactoEmpresa(event);
        break;
      case 'editar':
        this.editarContactoEmpresa(event);
        break;
      case 'ver':
        this.verContactoEmpresa(event);
        break;
    }
  }

  /**
   * Borra un contacto de la BD
   * @param event Evento con los datos del contacto a borrar
   */
  borrarContactoEmpresa(event) {
    if (this.permisosUsuario.contactosEmpresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el área?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.inicializarDatos();
        this.mainService.delete('api/contacto_empresa/' + event.data._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'Contacto eliminado con éxito', 'success');
            this.contactosEmpresa = [];
            this.inicializarDatos();
          }
        });
      }
    });
  }

  /**
   * Inicializa la edicion de un contacto
   * @param event Evento con los datos del contacto a editar
   */
  editarContactoEmpresa(event) {
    if (this.permisosUsuario.contactosEmpresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.contactoEmpresa = event.data;
    this.modo = this.modos[1];
    this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
  }
  /**
   * Inicializa la visualizacion de un contacto
   * @param event Evento con los datos del contacto a ver
   */
  verContactoEmpresa(event) {
    this.contactoEmpresa = event.data;
    this.modo = this.modos[2];
    this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
  }
}
