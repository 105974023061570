import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
})
export class ServiciosComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  datosEdit = {
    _id: '',
    nv: '',
    service: '',
    account: '',
    amount: '',
  };

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        /* {name: 'detalle', title: '<i class="nb-search"></i>'} */
      ],
    },
    columns: {
      nv: {
        title: 'Nave',
      },
      service: {
        title: 'Service',
      },
      account: {
        title: 'Account',
      },
      amount: {
        title: 'Amount',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };
  enviado: boolean = false;
  nv: any;
  service: any;
  account: any;
  amount: any;
  public user: any;
  public data: any;
  public source: LocalDataSource;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.data = [];
    this.onCargar();
  }

  /** Para modal **/

  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    this.datosEdit.nv = event.data.nv;
    this.datosEdit.amount = event.data.amount;
    this.datosEdit.account = event.data.account;
    this.datosEdit.service = event.data.service;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearServicio() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (this.nv != '' && this.service != '') {
      let servicio = {
        nv: this.nv,
        service: this.service,
        account: this.account,
        amount: this.amount,
      };
      console.log(servicio);
      this.mainService.post('api/servicio', servicio).subscribe((result) => {
        console.log(result);
        if (result) {
          Swal.fire('Éxito', 'Se agregó el servicio exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar el servicio, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el servicio, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/servicio-ver/' + event.data._id]);
  }

  onActualizar() {
    if (this.datosEdit.nv != '' && this.datosEdit.service != '') {
      let servicio = {
        _id: this.datosEdit._id,
        nv: this.datosEdit.nv,
        service: this.datosEdit.service,
        account: this.datosEdit.account,
        amount: this.datosEdit.amount,
      };
      console.log(servicio);
      console.log(servicio._id);
      let data = servicio;
      console.log(data);
      this.mainService.put('api/servicio/' + data._id, data).subscribe((result) => {
        console.log(result);
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el servicio exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo actualizar el servicio, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el servicio, verifique la información', 'warning');
    }
  }

  onCargar() {
    let x = this;
    this.data = [];
    console.log('Cargando');
    this.mainService.get('api/servicio').subscribe((res) => {
      x.data = res;
      console.log(x.data);
      x.data.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
      this.data = new LocalDataSource(this.data);
    });
  }

  onEliminar(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el servicio?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let servicio = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/servicio/' + servicio._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'servicio eliminado con éxito', 'success');
            this.onCargar();
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando el servicio', 'warning');
      }
    });
  }
}
