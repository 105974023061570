import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
/* Services */
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-category-sof',
  templateUrl: './category-sof.component.html',
  styleUrls: ['./category-sof.component.scss'],
})
export class CategorySofComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  settings = {
    edit: { confirmEdit: true, editButtonContent: '<i class="nb-compose"></i> ' },
    delete: { confirmDelete: true, deleteButtonContent: '<i class="nb-trash"></i> ' },
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      description: { title: 'Descripcion' },
      priority: { title: 'Prioridad' },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  public source: LocalDataSource;
  public dataCategorias: any = [];
  public datos: any = [];
  public data: any = [];
  public categoria: any = {};
  public usuario: any = {};
  public enviado: boolean = false;

  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.categoriasSof === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.categoriasSof !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.categoria = {};
    this.categoria = Object.assign({}, event.data);
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.categoria = {};
      });
  }

  crearCategoria() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (
      this.categoria.description != '' &&
      this.categoria.description != undefined &&
      this.categoria.priority != '' &&
      this.categoria.priority != undefined
    ) {
      this.enviado = false;
      this.mainService.post('api/category_sof', this.categoria).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó la categoria exitosamente', 'success');
          this.categoria = {};
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar la categoria, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la categoria, verifique la información', 'warning');
    }
  }

  onActualizar() {
    if (
      this.categoria.description != '' &&
      this.categoria.description != undefined &&
      this.categoria.priority != '' &&
      this.categoria.priority != undefined
    ) {
      let data = this.categoria;
      this.mainService.put('api/category_sof/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la categoria exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.categoria = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la categoria, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la categoria, verifique la información', 'warning');
    }
  }

  onCargar() {
    this.data = [];
    this.mainService.get('api/category_sof?activo=true').subscribe((res) => {
      this.data = res;
      this.dataCategorias = JSON.parse(JSON.stringify(res));
      this.data = _.orderBy(this.data, ['createdAt'], ['asc']);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.categoriasSof !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la categoria?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let categoriaEliminar = event.data;
        this.spinerGuardar = true;
        this.mainService.delete(`api/category_sof/${categoriaEliminar._id}`).subscribe((res) => {
          if (res) {
            if (res.message) {
              Swal.fire({
                title: 'Error',
                text: res.message,
                type: 'error',
              });
              this.spinerGuardar = false;
              return;
            }

            Swal.fire('Éxito', 'categoria eliminada con éxito', 'success');
            this.onCargar();
            this.spinerGuardar = false;
          }
        });
      }
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          TER_ID: this.datos[i][0],
        };
        this.mainService.post('api/category_sof', excel).subscribe((res) => {});
      }
      this.onCargar();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
