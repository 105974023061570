import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss'],
})
export class NotificacionesComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  datosEdit = {
    _id: '',
    titulo: '',
    usuario: '',
    texto: '',
  };

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      titulo: {
        title: 'Título',
      },
      texto: {
        title: 'Texto',
      },
      nombre: {
        title: 'Enviado',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  enviado: boolean = false;
  titulo: any;
  texto: any;
  public user: any;
  public data: any;
  public source: LocalDataSource;

  public usuarios: any[];

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
    this.obtenerUsuarios();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.notificaciones === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  obtenerUsuarios() {
    this.mainService.get('api/user?activo=true').subscribe((response) => {
      this.usuarios = response;
    });
  }

  /** Para modal **/

  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.notificaciones !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.datosEdit = Object.assign({}, event.data);
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearNotificacion() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    if (this.titulo && this.texto) {
      this.enviado = false;
      const notificacion = {
        titulo: this.titulo,
        texto: this.texto,
        usuario: this.usuario,
      };
      this.mainService.post('api/notificacion', notificacion).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó la notificacion exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar la notificacion, verifique la información', 'warning');
        }
      });
    } else {
      this.enviado = true;
      Swal.fire('Error', 'No se pudo agregar la notificacion, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/notificacion-ver/' + event.data._id]);
  }

  onActualizar() {
    if (this.datosEdit.titulo != '' && this.datosEdit.texto != '') {
      let notificacion = {
        _id: this.datosEdit._id,
        titulo: this.datosEdit.titulo,
        usuario: this.datosEdit.usuario,
        texto: this.datosEdit.texto,
      };
      let data = notificacion;
      this.mainService.put('api/notificacion/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la notificacion exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo actualizar la notificacion, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la notificacion, verifique la información', 'warning');
    }
  }

  onCargar() {
    let x = this;
    this.data = [];
    this.mainService.get('api/notificacion').subscribe((res) => {
      res.forEach((element) => {
        if (element.usuario) {
          this.mainService.get('api/user/' + element.usuario).subscribe((name) => {
            element.nombre = name.nombre + ' ' + name.apellido;
          });
        } else {
          element.nombre = 'general';
        }
      });
      this.data = _.orderBy(res, ['createdAt'], ['desc']);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.notificaciones !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la notificacion?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let noticia = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/notificacion/' + noticia._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'notificacion eliminada con éxito', 'success');
            this.onCargar();
          }
        });
      }
    });
  }

  /**
   * Inicializa los datos al cerrar la ventana de dialogo
   */
  onCerrarDialogo() {
    this.titulo = '';
    this.texto = '';
    this.usuario = '';
  }
}
