import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-observations-list',
  templateUrl: './observations-list.component.html',
  styleUrls: ['./observations-list.component.scss'],
})
export class ObservationsListComponent implements OnInit, OnChanges {
  @Input() private instructions = {
    observationsList: [],
  };

  public observationsList = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instructions) this.observationsList = this.instructions.observationsList;
  }
}
