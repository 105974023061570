import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {evaluate} from 'mathjs';
import Swal from 'sweetalert2'

@Component({
  selector: 'liquidacion-servicio',
  templateUrl: './liquidacion-servicio.component.html',
  styleUrls: ['./liquidacion-servicio.component.scss']
})
export class LiquidacionServicioComponent implements OnInit {
	/** Permiso de edición */
	@Input() available: boolean;
	/** Permiso de cambiar estado de factura */
	@Input() bill: boolean;
	/** Permiso de agregar o eliminar servicios */
	@Input() services: boolean;
	/** Servicio del componente*/
  @Input() servicio: any;
	/** Id en el arreglo de servicios de la liquidacion*/
  @Input() servicioId: String;
	/** Terminales en la BD */
	@Input() terminals: any;
	/** Buque de la nominacion */
	@Input() buque: any;
	/** Operador del servicio  */
	@Input() operadores: any;
	/** Parametros del sistema traidos de la BD*/
	@Input() parametrosSistema: any;
	/** Valores de factores de arqueo traidos de la BD */
	@Input() factoresArqueo: any;
	/** Evento para indicar que se eliminara este servicio */
  @Output() onDeleteServicio: EventEmitter<any>=new EventEmitter<any>();
	/** Evento que indica que este servicio fue alterado */
	@Output() servicioChanged: EventEmitter<any>=new EventEmitter<any>();

	/** Valor del servicio en formato separado por commas y con dos decimales */
	public valorFormateado: string;

	/** Referencia al elemento de la cantidad del servicio */
	@ViewChild('cantidadElement', {static: true}) cantidadElement: ElementRef;
	/** Referencia al elemento de factuara recibida del servicio*/
	@ViewChild('facturaRecibidaCheckBox', { static: true }) facturaRecibidaCheckBox: ElementRef;

  ngOnInit() {
		this.actualizarValorFormateado(this.servicio.valor);
  }

	/**
	 * Elimina este servicio de la liquidacion
	 */
	deleteServicio(){
		if(!this.services){
			Swal.fire({
				title: 'No se tiene permisos de escritura en el modulo',
				type: 'error',
				showCancelButton: false,
				confirmButtonText: 'Continuar',
			});
			return;
		}
    this.onDeleteServicio.emit(this.servicioId)
  }

	/**
	 * Hace los cambios en el valor del servicio segun la cantidad ingresada
	 */
	onChangeCantidad(valorIngresado){
		if(!valorIngresado){
			valorIngresado = 0;
			// Elemento natvo de la cantidad del servicio
			const cantidadElementInput = this.cantidadElement.nativeElement;
			cantidadElementInput.value = 0;
		}
		
		this.servicio.cantidad = Number(valorIngresado);

		let valorTarifaUSD = this.obtenerValorTarifaUSD();
		this.servicio.valor = evaluate(this.reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD));
		this.actualizarValorFormateado(this.servicio.valor);

		this.servicioChanged.emit({
			servicioId: this.servicioId,
			servicio: this.servicio
		});
	}

	onChangeTotalValue(event) {
    if (event.target.value === '') {
      event.target.value = 0;
    }

    const value = event.target.value;
    const stringWithoutCommas = value.replace(/,/g, '');

    if (!this.onlyNumbersAndDots(stringWithoutCommas)) {
      event.target.value = this.values.valorFormateado;
      return;
    }

    this.servicio.valor = parseFloat(stringWithoutCommas);
    this.actualizarValorFormateado(stringWithoutCommas);

    event.target.value = this.values.valorFormateado;

    this.servicioChanged.emit({
      servicioId: this.servicioId,
      servicio: this.servicio,
    });
  }

	onlyNumbersAndDots(input) {
    const regex = /^[0-9.]+$/;
    return regex.test(input);
  }

	values = {
    valorFormateado: undefined,
  };


	/**
	 * Actualiza el valor en formato separado por commas
	 */
	actualizarValorFormateado(valor){
		this.values.valorFormateado = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(valor);
	}

	/**
	 * Calcula el valor de la tarifa en dolares
	 * @returns Valor de la tarifa en dolares
	 */
	obtenerValorTarifaUSD(){
		let valorTarifaUSD = 0;
		
		if (this.servicio.tarifa.tipoTarifa == 'DINAMICA'){
			let formulaTarifaUSD = this.servicio.tarifa.tarifaAplicarUSD.slice();
			
			this.parametrosSistema.forEach(element => {
				formulaTarifaUSD = formulaTarifaUSD.replace(element.nombre, element.valor);
			})
			formulaTarifaUSD = formulaTarifaUSD.replace('COP', this.servicio.tarifa.tarifaCOP);

			valorTarifaUSD = evaluate(formulaTarifaUSD);
		} else {
			valorTarifaUSD = evaluate(this.servicio.tarifa.tarifaAplicarUSD);
		}

		return valorTarifaUSD;
	}

	/**
	 * Reemplaza las variables por sus valores en la formula de la tarifa
	 * @param valorIngresado Cantidad del servicio
	 * @param valorTarifaUSD Valor en dolares de la tarifa
	 * @returns String con la formula en terminos númericos
	 */
	reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD){
		let formulaServicio = this.servicio.tarifa.formula.slice();

		//Variables de tarifa y del buque
		formulaServicio = formulaServicio.replace('CANT', valorIngresado);
		formulaServicio = formulaServicio.replace('USD', valorTarifaUSD);
		formulaServicio = formulaServicio.replace('DWT', this.buque.BUQ_DWT);
		formulaServicio = formulaServicio.replace('GRT', this.buque.BUQ_GT);
		formulaServicio = formulaServicio.replace('LOA', this.buque.BUQ_ESLORA);

		//Variable factor de arqueo
		let i = 0;
		let encontroFactorArqueo = false;
	
		while (i < this.factoresArqueo.length && !encontroFactorArqueo) {

			if(this.factoresArqueo[i].rangoGRTMin < this.buque.BUQ_GT && this.factoresArqueo[i].rangoGRTMax > this.buque.BUQ_GT){
				formulaServicio = formulaServicio.replace('FA', this.factoresArqueo[i].factorArqueo);
				encontroFactorArqueo = true;
			}

			i ++;
		}

		// Variables de parametros de sistema
		this.parametrosSistema.forEach(element => {
			formulaServicio = formulaServicio.replace(element.nombre, element.valor);
		})

		return formulaServicio;
	}

	/**
	 * Cambia el estado de la factura recibida
	 */
	onChangeFacturaRecibida(){
		if(!this.bill){
      Swal.fire({
				title: 'No se tiene permisos de escritura en el modulo',
				type: 'error',
				showCancelButton: false,
				confirmButtonText: 'Continuar',
			})
			return;
		} 
		if(this.servicio.facturaRecibida){
			this.servicio.facturaRecibida = false;
		} else {
			this.servicio.facturaRecibida = true;
		}

		this.servicioChanged.emit({
			servicioId: this.servicioId,
			servicio: this.servicio
		});
	}

}
