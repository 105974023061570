import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {
  NbThemeModule,
  NbLayoutModule,
  NbCardModule,
  NbSidebarModule,
  NbSidebarService,
  NbMenuModule,
  NbMenuService,
  NbActionsModule,
  NbInputModule,
  NbButtonModule,
  NbCalendarModule,
  NbDatepickerModule,
  NbPopoverModule,
  NbToastrModule,
  NbDialogModule,
  NbSpinnerModule,
  NbUserModule,
  NbSelectModule,
  NbTabsetModule,
  NbCalendarRangeModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartModule } from 'angular2-chartjs';
import { NbMomentDateModule } from '@nebular/moment';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';

import { AuthGuard } from './public/guards/auth.guard';
import { LoggedInGuard } from './public/guards/logged-in.guard';
import { AdminGuard } from './public/guards/admin.guard';
import { MainService } from './public/services/main.service';
import { AuthService } from './public/services/auth.service';
import { HomeComponent } from './public/home/home.component';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { LoginComponent } from './public/login/login.component';
import { UsuariosComponent } from './public/usuarios/usuarios.component';
import { AgentsComponent } from './public/agents/agents.component';
import { ViajesComponent } from './public/viajes/viajes.component';
import { NoticiasComponent } from './public/noticias/noticias.component';
import { NotificacionesComponent } from './public/notificaciones/notificaciones.component';
import { ConfiguracionesComponent } from './public/configuraciones/configuraciones.component';
import { UsuarioVerComponent } from './public/usuarios/usuario-ver/usuario-ver.component';
import { AgentVerComponent } from './public/agents/agents-ver/agent-ver.component';
import { NoticiaVerComponent } from './public/noticias/noticia-ver/noticia-ver.component';
import { NotificacionVerComponent } from './public/notificaciones/notificacion-ver/notificacion-ver.component';
import { BarcoVerComponent } from './public/viajes/components/barco-ver/barco-ver.component';
import { ServiciosComponent } from './public/servicios/servicios.component';
import { BuquesComponent } from './public/buques/buques.component';
import { BuqueVerComponent } from './public/buques/buque-ver/buque-ver.component';
import { BussinesLineComponent } from './public/bussines-line/bussines-line.component';
import { BussinesLineVerComponent } from './public/bussines-line/bussines-line-ver/bussines-line-ver.component';
import { CommodityComponent } from './public/commodity/commodity.component';
import { CommodityVerComponent } from './public/commodity/commodity-ver/commodity-ver.component';
import { StatementComponent } from './public/statement/statement.component';
import { StatementVerComponent } from './public/statement/statement-ver/statement-ver.component';
import { DocumentosComponent } from './public/documentos/documentos.component';
import { QuillModule } from 'ngx-quill';
import { KimComponent } from './public/kim/kim.component';
import { KimVerComponent } from './public/kim/kim-ver/kim-ver.component';
import { OperacionVerComponent } from './public/operacion/operacion-ver/operacion-ver.component';
import { OperacionComponent } from './public/operacion/operacion.component';
import { TerminalVerComponent } from './public/terminal/terminal-ver/terminal-ver.component';
import { TerminalComponent } from './public/terminal/terminal.component';
import { PuertoVerComponent } from './public/puerto/puerto-ver/puerto-ver.component';
import { PuertoComponent } from './public/puerto/puerto.component';
import { EmpresaSqlComponent } from './public/empresa-sql/empresa-sql.component';
import { EmpresaSqlVerComponent } from './public/empresa-sql/empresa-sql-ver/empresa-sql-ver.component';
import { HusbandryComponent } from './public/husbandry/husbandry.component';
import { ProductoComponent } from './public/producto/producto.component';
import { MedidaComponent } from './public/medida/medida.component';
import { ProductoViajeComponent } from './public/producto-viaje/producto-viaje.component';
import { ProductoVerComponent } from './public/producto-viaje/producto-ver/producto-ver.component';
import { CategorySofComponent } from './public/category-sof/category-sof.component';
import { TypeRemarksComponent } from './public/type-remarks/type-remarks.component';
import { SofVerComponent } from './public/viajes/components/sof-ver/sof-ver.component';
import { ViajeDetalleComponent } from './public/viajes/components/viaje-detalle/viaje-detalle.component';
import { RsoComponent } from './public/viajes/components/viaje-detalle/components/rso/rso.component';
import { CargarDocumentosCardViajeComponent } from './public/viajes/components/viaje-detalle/components/cargar-documentos-card-viaje/cargar-documentos-card-viaje.component';
import { CrearProformaCardViajeComponent } from './public/viajes/components/viaje-detalle/components/crear-proforma-card-viaje/crear-proforma-card-viaje.component';
import { ListadoNovedadesViajeComponent } from './public/viajes/components/viaje-detalle/components/listado-novedades-viaje/listado-novedades-viaje.component';
import { ListadoProductosViajeComponent } from './public/viajes/components/viaje-detalle/components/listado-productos-viaje/listado-productos-viaje.component';
import { StatementOfFactsCargarViajeComponent } from './public/viajes/components/viaje-detalle/components/statement-of-facts-cargar-viaje/statement-of-facts-cargar-viaje.component';
import { FactorArqueoComponent } from './public/parametros/components/factor-arqueo/factor-arqueo.component';
import { ParametrosComponent } from './public/parametros/parametros.component';
import { ParametroComponent } from './public/parametros/components/parametro/parametro.component';
import { ProformasComponent } from './public/proformas/proformas.component';
import { ProformasSinNominacionComponent } from './public/proformas/components/proformas-sin-nominacion/proformas-sin-nominacion.component';
import { ProformasConNominacionComponent } from './public/proformas/components/proformas-con-nominacion/proformas-con-nominacion.component';
import { ProformaDetalleComponent } from './public/proformas/components/proforma-detalle/proforma-detalle.component';
import { SofCardViajeComponent } from './public/viajes/components/viaje-detalle/components/sof-card-viaje/sof-card-viaje.component';
import { LiquidacionCardViajeComponent } from './public/viajes/components/viaje-detalle/components/liquidacion-card-viaje/liquidacion-card-viaje.component';
import { TarifasComponent } from './public/tarifas/tarifas.component';
import { TarifaDetalleComponent } from './public/tarifas/tarifa-detalle/tarifa-detalle.component';
import { ProformaReporteComponent } from './public/proformas/components/proforma-reporte/proforma-reporte.component';
import { ProformaCartaComponent } from './public/proformas/components/proforma-carta/proforma-carta.component';
import { ProformaServicioComponent } from './public/proformas/components/proforma-detalle/components/proforma-servicio/proforma-servicio.component';
import { LiquidacionesComponent } from './public/liquidaciones/liquidaciones.component';
import { LiquidacionDetalleComponent } from './public/liquidaciones/componentes/liquidacion-detalle/liquidacion-detalle.component';
import { LiquidacionServicioComponent } from './public/liquidaciones/componentes/liquidacion-detalle/components/liquidacion-servicio/liquidacion-servicio.component';
import { AreasEmpresaComponent } from './public/areas-empresa/areas-empresa.component';
import { AreaEmpresaDetalleComponent } from './public/areas-empresa/componentes/area-empresa-detalle/area-empresa-detalle.component';
import { ContactosComponent } from './public/contactos/contactos.component';
import { ContactoDetalleComponent } from './public/contactos/componentes/contacto-detalle/contacto-detalle.component';
import { AnalisisOperativoComponent } from './public/analisis-operativo/analisis-operativo.component';
import { FiltroAnalisisOperativoComponent } from './public/analisis-operativo/componentes/filtro-analisis-operativo/filtro-analisis-operativo.component';
import { TablaViajesAnalisisOperativoComponent } from './public/analisis-operativo/componentes/tabla-viajes-analisis-operativo/tabla-viajes-analisis-operativo.component';
import { ListaDeChequeoComponent } from './public/analisis-operativo/componentes/lista-de-chequeo/lista-de-chequeo.component';
import { ReportesComponent } from './public/analisis-operativo/componentes/reportes/reportes.component';
import { ReporteArriboComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-arribo/reporte-arribo.component';
import { ReporteProspectosComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-prospectos/reporte-prospectos.component';
import { ReporteProductividadComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-productividad/reporte-productividad.component';
import { ReporteZarpeComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-zarpe/reporte-zarpe.component';
import { VistaPreviaReporeProspectosComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-prospectos/components/vista-previa-repore-prospectos/vista-previa-repore-prospectos.component';
import { VistaPreviaReporeArriboComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-arribo/componentes/vista-previa-repore-arribo/vista-previa-repore-arribo.component';
import { ProductoViajeDetalleComponent } from './public/producto-viaje/componentes/producto-viaje-detalle/producto-viaje-detalle.component';
import { VistaPreviaReporteZarpeComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-zarpe/componentes/vista-previa-reporte-zarpe/vista-previa-reporte-zarpe.component';
import { PqrsComponent } from './public/pqrs/pqrs.component';
import { MisPqrsReportadosComponent } from './public/pqrs/componentes/mis-pqrs-reportados/mis-pqrs-reportados.component';
import { MisPqrsAsignadosComponent } from './public/pqrs/componentes/mis-pqrs-asignados/mis-pqrs-asignados.component';
import { TodosLosPqrsComponent } from './public/pqrs/componentes/todos-los-pqrs/todos-los-pqrs.component';
import { PqrDetalleComponent } from './public/pqrs/componentes/pqr-detalle/pqr-detalle.component';
import { VistaPreviaReporteProductividadComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-productividad/componentes/vista-previa-reporte-productividad/vista-previa-reporte-productividad.component';
import { RolesComponent } from './public/roles/roles.component';
import { RolDetalleComponent } from './public/roles/componentes/rol-detalle/rol-detalle.component';
import { PreventDefaultDirective } from './public/directives/prevent-default.directive';
import { RecuperarContrasenaComponent } from './public/recuperar-contrasena/recuperar-contrasena.component';
import { ReestablecerComponent } from './public/reestablecer/reestablecer.component';
import { CargaComponent } from './public/carga/carga.component';
import { CargaVerComponent } from './public/carga/carga-ver/carga-ver.component';

import { SingleSelectComponent } from './shared/inputs/single-select/single-select.component';
import { NgxEditorModule } from 'ngx-editor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MultipleSelectComponent } from './shared/inputs/multiple-select/multiple-select.component';
import { SofFormatComponent } from './pages/sof-format/sof-format.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObservationsListComponent } from './components/observationsList/observations-list/observations-list.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSearchComponent } from './components/input-search/input-search.component';

import { CaptianInterviewModule } from './captian-interview/captian-interview.module';
import { CaptianInterviewComponent } from './pages/captian-interview/captian-interview.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    CaptianInterviewComponent,
    AppComponent,
    HomeComponent,
    DashboardComponent,
    LoginComponent,
    UsuariosComponent,
    AgentsComponent,
    ViajesComponent,
    NoticiasComponent,
    CargaComponent,
    CargaVerComponent,
    NotificacionesComponent,
    ConfiguracionesComponent,
    UsuarioVerComponent,
    AgentVerComponent,
    NoticiaVerComponent,
    NotificacionVerComponent,
    BarcoVerComponent,
    ServiciosComponent,
    BuquesComponent,
    BuqueVerComponent,
    BussinesLineComponent,
    BussinesLineVerComponent,
    CommodityComponent,
    CommodityVerComponent,
    StatementComponent,
    StatementVerComponent,
    DocumentosComponent,
    KimComponent,
    KimVerComponent,
    OperacionVerComponent,
    OperacionComponent,
    TerminalVerComponent,
    TerminalComponent,
    PuertoVerComponent,
    PuertoComponent,
    EmpresaSqlComponent,
    EmpresaSqlVerComponent,
    HusbandryComponent,
    ProductoComponent,
    MedidaComponent,
    ProductoViajeComponent,
    ProductoVerComponent,
    CategorySofComponent,
    TypeRemarksComponent,
    SofVerComponent,
    ViajeDetalleComponent,
    RsoComponent,
    CargarDocumentosCardViajeComponent,
    CrearProformaCardViajeComponent,
    ListadoNovedadesViajeComponent,
    ListadoProductosViajeComponent,
    StatementOfFactsCargarViajeComponent,
    FactorArqueoComponent,
    ParametrosComponent,
    ParametroComponent,
    ProformasComponent,
    ProformasSinNominacionComponent,
    ProformasConNominacionComponent,
    ProformaDetalleComponent,
    SofCardViajeComponent,
    LiquidacionCardViajeComponent,
    TarifasComponent,
    TarifaDetalleComponent,
    ProformaReporteComponent,
    ProformaCartaComponent,
    ProformaServicioComponent,
    LiquidacionesComponent,
    LiquidacionDetalleComponent,
    LiquidacionServicioComponent,
    AreasEmpresaComponent,
    AreaEmpresaDetalleComponent,
    ContactosComponent,
    ContactoDetalleComponent,
    AnalisisOperativoComponent,
    FiltroAnalisisOperativoComponent,
    TablaViajesAnalisisOperativoComponent,
    ListaDeChequeoComponent,
    ReportesComponent,
    ReporteArriboComponent,
    ReporteProspectosComponent,
    ReporteProductividadComponent,
    ReporteZarpeComponent,
    VistaPreviaReporeProspectosComponent,
    VistaPreviaReporeArriboComponent,
    ProductoViajeDetalleComponent,
    VistaPreviaReporteZarpeComponent,
    PqrsComponent,
    MisPqrsReportadosComponent,
    MisPqrsAsignadosComponent,
    TodosLosPqrsComponent,
    PqrDetalleComponent,
    VistaPreviaReporteProductividadComponent,
    RolesComponent,
    RolDetalleComponent,
    PreventDefaultDirective,
    RecuperarContrasenaComponent,
    ReestablecerComponent,
    SingleSelectComponent,
    MultipleSelectComponent,
    SofFormatComponent,
    ObservationsListComponent,
    InputSearchComponent
  ],
  imports: [
    CommonModule,
    CaptianInterviewModule,
    AutoCompleteModule,
    DragDropModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbSidebarModule,
    NbActionsModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    NbTabsetModule,
    NbCalendarModule,
    Ng2SmartTableModule,
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    ReactiveFormsModule,
    NbToastrModule.forRoot(),
    NbDialogModule.forChild(),
    NbSpinnerModule,
    NbCalendarRangeModule,
    NbUserModule,
    ChartModule,
    MyDatePickerModule,
    NbMomentDateModule,
    NbDateFnsDateModule.forRoot({ format: 'dd.MM.yyyy' }),
    NbDateFnsDateModule.forChild({ format: 'dd.MM.yyyy' }),
    NbDatepickerModule.forRoot(),
    HttpModule,
    HttpClientModule,
    MyDateRangePickerModule,
    QuillModule.forRoot({
      modules: { syntax: true },
    }),
    NgxEditorModule,
    AngularEditorModule,
    NgxPaginationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NbSidebarService,
    NbMenuService,
    FormsModule,
    MainService,
    AuthService,
    AuthGuard,
    LoggedInGuard,
    AdminGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
