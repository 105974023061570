import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MainService } from '../../../../../services/main.service';
import { DateAndTimeService } from '../../../,./../../../services/date-and-time.service';
import * as moment from 'moment';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'listado-productos-viaje',
  templateUrl: './listado-productos-viaje.component.html',
  styleUrls: ['./listado-productos-viaje.component.scss'],
})
export class ListadoProductosViajeComponent implements OnInit, OnChanges {
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      fromVisual: { title: 'Time from' },
      toVisual: { title: 'Time to' },
      nombreProducto: { title: 'Product' },
      acumuladoVisual: { title: 'Accumulated' },
      productividadVisual: { title: 'Productivity' },
      planificadoVisual: { title: 'Planified' },
      robVisual: { title: 'ROB' },
      nombreMedida: { title: 'Measure' },
      nombreTipoOperacion: { title: 'Operation type' },
      nombreTerminal: { title: 'Terminal' },
      holdInfo: { title: 'Hold info' },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'asc',
      },
    },
  };
  /** Boolean para abrir el modal de crear producto */
  public crearProducto: boolean = false;
  /** Referencia al dialogo del detalle un producto-viaje*/
  public dialogRefProducto: any;
  /** Modo actual del detalle del producto-viaje */
  public modoProducto: string = 'CREAR';
  /** Arreglo con los posibles modos de un detalle */
  public modosPosiblesDetalles: string[] = ['CREAR', 'EDITAR'];
  /** Referencia al producto */
  public productoViaje: any;
  /** Array de productos asociados al viaje */
  public productosDelViaje: any[] = [];
  /** Información general del viaje */
  @Input() viaje: any;
  /** ID del viaje */
  @Input() VIA_ID: string | number;
  /** Permiso de escritura */
  @Input() available: boolean;
  /** Dialog para nuevos productos */
  @ViewChild('productoDetalle', { static: true }) productoDetalle: ElementRef;

  public usuarioActual = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  constructor(
    private mainService: MainService,
    private dateAndTimeService: DateAndTimeService,
    private dialogService: NbDialogService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.VIA_ID) {
      this.VIA_ID = changes.VIA_ID.currentValue;
      this.obtenerPermisos();
    }
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.viajes === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.inicializarDatos();
      }
    });
  }

  public holdsUsed = [];

  inicializarDatos() {
    this.holdsUsed = [];
    const timesHold = {};

    this.mainService.get(`api/producto_completo/recalada/${this.viaje.recaladas[0]._id}`).subscribe((res) => {
      this.productosDelViaje = res;
      this.productosDelViaje.forEach((producto) => {
        timesHold[producto.HOLD_NUMBER] = timesHold[producto.HOLD_NUMBER] ? timesHold[producto.HOLD_NUMBER] + 1 : 1;
        if (timesHold[producto.HOLD_NUMBER] >= 5) this.holdsUsed.push(producto.HOLD_NUMBER);
        this.productoAFromatoVisual(producto);
      });
    });
  }

  productoAFromatoVisual(producto) {
    producto.createdAt = moment(producto.createdAt).format('DD-MM-YYYY HH:mm');

    producto.fromVisual = `${this.dateAndTimeService.convertirFechaAFormatoFormulario(producto.PRV__TIME_FROM)} `;
    producto.fromVisual += `${this.dateAndTimeService.convertirHoraAFormatoFormulario(producto.PRV__TIME_FROM)}:`;
    producto.fromVisual += `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(producto.PRV__TIME_FROM)}`;

    producto.toVisual = `${this.dateAndTimeService.convertirFechaAFormatoFormulario(producto.PRV__TIME_TO)} `;
    producto.toVisual += `${this.dateAndTimeService.convertirHoraAFormatoFormulario(producto.PRV__TIME_TO)}:`;
    producto.toVisual += `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(producto.PRV__TIME_TO)}`;

    producto.nombreProducto = 'Cargando ...';
    this.mainService
      .get(`api/producto?PRD_ID=${producto.PRD_ID}&COMODITY_ID=${producto.COMMODITY_ID}`)
      .subscribe((res) => {
        const proudctoAsociado = res.filter((element) => element.PRD_ID == producto.PRD_ID);
        producto.nombreProducto =
          proudctoAsociado && proudctoAsociado[0] && proudctoAsociado[0].PRD_NOMBRE
            ? proudctoAsociado[0].PRD_NOMBRE
            : 'Producto no encontrado';
        this.productosDelViaje = this.productosDelViaje.slice();
      });

    producto.acumuladoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_ACUMULADO);
    producto.productividadVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_PROD_12H);
    producto.planificadoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_CANTIDAD);
    producto.robVisual = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      producto.PRV_ROB
    );

    producto.nombreMedida = 'Cargando ...';
    this.mainService.get(`api/medida?MED_ID=${producto.MED_ID}`).subscribe((res) => {
      producto.nombreMedida =
        res && res[0] && res[0].MED_NOMBRE
          ? res.filter((element) => element.MED_ID == producto.MED_ID)[0].MED_NOMBRE
          : 'Medida no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.nombreTipoOperacion = 'Cargando ...';
    this.mainService.get(`api/operacion?TOP_ID=${producto.TOP_ID}`).subscribe((res) => {
      producto.nombreTipoOperacion =
        res && res[0] && res[0].TOP_NOMBRE ? res[0].TOP_NOMBRE : 'Tipo de operación no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.nombreTerminal = 'Cargando ...';
    this.mainService.get(`api/terminal?TER_ID=${producto.TERMINAL}`).subscribe((res) => {
      producto.nombreTerminal = res && res[0] && res[0].TER_NOMBRE ? res[0].TER_NOMBRE : 'Terminal no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.holdInfo = `Hold #${producto.HOLD_NUMBER}`;
  }

  /**
   * Abre el formulario para agregar un producto
   */
  onAgregarProducto() {
    this.productoViaje = null;
    this.modoProducto = this.modosPosiblesDetalles[0];
    this.crearProducto = true;
    this.abrirDetalleProductoViaje(this.productoDetalle);
  }

  /**
   * Abre el detalle del producto viaje
   * @param dialog Referencia al dialogo del detalle
   */
  abrirDetalleProductoViaje(dialog) {
    this.dialogRefProducto = this.dialogService.open(dialog, { context: '' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarProducto(event);
        break;
      case 'editar':
        this.editarProducto(event);
        break;
    }
  }

  /**
   * Borra un producto de la BD
   */
  borrarProducto(event) {
    if (!this.available) {
      Swal.fire({
        title: '¡Error!',
        text: 'No se puede eliminar un producto.',
        type: 'error',
      });
      return;
    }
    if (this.permisosUsuario.viajes !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el producto?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete('api/producto_completo/' + event.data._id).subscribe((res) => {
            if (res) {
              Swal.fire('Éxito', 'Producto eliminado con éxito', 'success');
              this.inicializarDatos();
            }
            this.viaje.productos = this.viaje.productos.filter((producto) => producto != event.data._id);

            this.mainService.put(`api/nuevo-viaje/${this.viaje._id}`, this.viaje).subscribe((result) => {});
          });
        }
      });
    }
  }

  /**
   * Inicializa la edicion de un producto
   * @param event Seleccion del producto que se quiere editar
   */
  editarProducto(event) {
    if (!this.available) {
      Swal.fire({
        title: '¡Error!',
        text: 'No se puede editar un producto.',
        type: 'error',
      });
      return;
    }
    if (this.permisosUsuario.viajes !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.productoViaje = event.data;
      this.modoProducto = this.modosPosiblesDetalles[1];
      this.abrirDetalleProductoViaje(this.productoDetalle);
    }
  }
}
