import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { normalize } from 'normalize-diacritics';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Terminal actualmente seleccionada */
  terminal: any = {};
  /** Puertos en la BD */
  puertos: any = [];
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = true;
  /** Indica si se estan cargando los puertos */
  public puertosCargandose: boolean = false;
  /** Terminales en la BD */
  public dataTerminales: any = [];
  public terminalExistente: any = {};
  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      TER_ID: {
        title: 'ID',
      },
      TER_NOMBRE: {
        title: 'Nombre',
      },
      PUE_NOMBRE: {
        title: 'Puerto',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any = [];
  /** Insica si los datos fueron enviados */
  enviado: boolean = false;

  /** Usuario actual */
  public usuario: any;
  public source: LocalDataSource;

  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  /**
   * Inicializa el usuario actual y carga los datos
   */
  ngOnInit() {
    this.dataTerminales = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.terminales === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
        this.obtenerPuertosBD();
      }
    });
  }

  /**
   * Reacciona a la accion de la tabla que se quiere ejecutar
   * @param event Evento con los datos de la terminal a editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de una terminales
   * @param dialogo Referencia al dialogo del detalle
   * @param event Evento con los datos de la terminal a editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.terminales !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.terminal = {};
      this.terminal = Object.assign({}, event.data);
      this.dialogService
        .open(dialog, { context: 'this is some additional data passed to dialog' })
        .onClose.subscribe(() => {
          this.terminal = {};
        });
    }
  }

  /**
   * Abre el dialogo para la edicion de una terminal
   * @param edit Referencia al dialogo
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de una terminal
   */
  crearTerminal() {
    this.terminal.PUE_ID = '';
    this.terminal.TER_PRIVADO = 0;
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo de creacion de una terminal
   * @param dialog Referencia al dialogo del detalle
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Envia los datos a la BD
   */
  public crear() {
    this.enviado = false;
    if (
      this.terminal.TER_NOMBRE &&
      this.terminal.PUE_ID &&
      (this.terminal.TER_PRIVADO == 0 || this.terminal.TER_PRIVADO == 1) &&
      this.terminal.TER_ABREVIATURA
    ) {
      this.mainService.get('api/global_variable/TER_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.terminal.TER_ID = 123; //Primer valor del ID
          const terminalIdGlobalVariable = {
            key: 'TER_ID',
            value: this.terminal.TER_ID,
          };
          this.mainService.post('api/global_variable', terminalIdGlobalVariable).subscribe((result) => {});
        } else {
          this.terminal.TER_ID = res.value;
        }

        this.mainService.put('api/global_variable/TER_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/terminal', this.terminal).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó la terminal exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar la terminal, verifique la información', 'warning');
          }
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la terminal, verifique la información', 'warning');
    }
  }

  /**
   * Redirigue a la pagina de visualizacion de una terminal
   * @event Evento con los datos de la terminal a editar
   */
  onVer(event) {
    this.router.navigate(['home/terminal-ver/' + event.data._id]);
  }

  /**
   * Actualiza una terminal
   */
  onActualizar() {
    if (
      this.terminal.TER_NOMBRE &&
      this.terminal.PUE_ID &&
      (this.terminal.TER_PRIVADO == 0 || this.terminal.TER_PRIVADO == 1) &&
      this.terminal.TER_ABREVIATURA
    ) {
      let terminalActualizar = this.terminal;
      let data = terminalActualizar;

      this.mainService.put('api/terminal/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el terminal exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.terminal = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la terminal, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la terminal, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos de la BD
   */
  onCargar() {
    this.cargando = true;

    let x = this;
    this.dataTerminales = [];
    this.mainService.get('api/terminal?activo=true').subscribe((res) => {
      x.dataTerminales = res;
      //Hay que quitar la terminal ALL de las opciones, esta tiene el ID 0
      let i = 0;
      let encontroTerminal = false;
      while (i < x.dataTerminales.length && !encontroTerminal) {
        if (x.dataTerminales[i].TER_ID == 0) {
          x.dataTerminales.splice(i, 1);
          encontroTerminal = true;
        }

        i++;
      }
      this.dataTerminales = JSON.parse(JSON.stringify(res));
      x.dataTerminales.forEach((element) => {
        // Puerto asociado a la terminal
        element.PUE_NOMBRE = 'Cargando ...';
        this.mainService.get(`api/puerto?PUE_ID=${element.PUE_ID}`).subscribe(async (resul) => {
          element.PUE_NOMBRE = resul.length > 0 ? await normalize(resul[0].PUE_NOMBRE) : 'No encontrado';
          this.dataTerminales = this.dataTerminales.slice();
        });
      });

      this.cargando = false;
    });
  }

  /**
   * Elimina una terminal de la BD
   * @event Evento con los datos de la terminal que se quiere eliminar
   */
  onEliminar(event) {
    if (this.permisosUsuario.usuarios !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar la terminal?</strong>',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          let terminalEliminar = event.data;
          this.cargando = true;
          this.mainService
            .delete(`api/terminal/${terminalEliminar._id}?terminalId=${terminalEliminar.TER_ID}`)
            .subscribe(
              (res) => {
                if (res) {
                  this.cargando = false;
                  if (res.message) {
                    Swal.fire({
                      title: 'Error',
                      text: res.message,
                      type: 'error',
                    });
                    return;
                  }
                  Swal.fire('Éxito', 'Terminal eliminada con éxito', 'success');
                  this.onCargar();
                }
              },
              (err) => {
                this.cargando = false;
                Swal.fire({
                  title: 'Error',
                  text: err.error.message,
                  type: 'error',
                });
              }
            );
        }
      });
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          TER_ID: this.datos[i][0],
          PUE_ID: this.datos[i][1],
          TER_NOMBRE: this.datos[i][2],
          TER_PRIVADO: this.datos[i][3],
          TER_TEXTO: this.datos[i][4],
          TER_ABREVIATURA: this.datos[i][5],
        };
        this.terminalExistente = {};
        this.terminalExistente = this.getTerminalByID(excel.TER_ID);
        if (excel.TER_ID > 1) {
          if (this.terminalExistente == null) {
            this.mainService.post('api/terminal', excel).subscribe(
              (res) => {},
              (err) => {}
            );
          }
        }
      }
    };
    this.onCargar();
    reader.readAsBinaryString(target.files[0]);
  }

  public getTerminalByID(evt) {
    let x = _.find(this.dataTerminales, { TER_ID: evt });
    if (x) return x;
    else return null;
  }

  /**
   * Maneja el evento cuando se hace click en el boton para recargar los puertos
   */
  onRecargarPuertos() {
    this.obtenerPuertosBD();
  }

  /**
   * Trae los puertos de la BD
   */
  obtenerPuertosBD(): void {
    this.puertosCargandose = true;

    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.puertos = res;
      //Hay que quitar el puerto ALL de las opciones, este tiene el ID 0
      let i = 0;
      let encontroPuerto = false;
      while (i < this.puertos.length && !encontroPuerto) {
        if (this.puertos[i].PUE_ID == 0) {
          this.puertos.splice(i, 1);
          encontroPuerto = true;
        }

        i++;
      }
      this.puertos = _.orderBy(this.puertos, ['PUE_NOMBRE']);
      this.puertosCargandose = false;
    });
  }

  /**
   * Maneja el evento de cerrar dialogo inicializando la terminal acutal
   */
  onCerrarDialogo() {
    this.terminal = {};
  }
}
