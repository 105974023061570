import { Component, Input, OnInit } from '@angular/core';

export interface Instruction {
  title: string;
  textButton: string;
}

@Component({
  selector: 'shared-nb-card-module',
  templateUrl: './nb-card-module.component.html',
  styleUrls: ['./nb-card-module.component.scss'],
})
export class NbCardModuleComponent implements OnInit {
  @Input('instruction') instruction: Instruction;

  constructor() {}

  ngOnInit() {}
}
