import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
@Component({
  selector: 'app-operacion',
  templateUrl: './operacion.component.html',
  styleUrls: ['./operacion.component.scss'],
})
export class OperacionComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Operacion actualmente seleccionada */
  operacion: any = {};
  /** Indica si hay algo cargandose en el modulo */
  cargando: boolean = true;

  /** Configuraciones de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      TOP_ID: {
        title: 'ID',
      },
      TOP_NOMBRE: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any = [];
  /** Indica si los datos han sido enviados a la BD */
  enviado: boolean = false;

  /** Usuario actual */
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  /**
   * Inicializa el usario y carga los datos
   */
  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.tiposDeOperaciones === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las diferenctes acciones
   * @param event Evento con la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de una operacion
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.tiposDeOperaciones !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.operacion = {};
    this.operacion = Object.assign({}, event.data);
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Abre la ventana para editar una operacion
   * @param edit Edita la operacion
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de una operacion
   */
  crearOperacion() {
    this.operacion = {};
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo para crear una operacion
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Envia los datos a la BD
   */
  public crear() {
    this.enviado = false;
    if (this.operacion.TOP_NOMBRE) {
      this.mainService.get('api/global_variable/TOP_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.operacion.TOP_ID = 12; //Primer valor del ID
          const operacionIdGlobalVariable = {
            key: 'TOP_ID',
            value: this.operacion.TOP_ID,
          };
          this.mainService.post('api/global_variable', operacionIdGlobalVariable).subscribe((result) => {});
        } else {
          this.operacion.TOP_ID = res.value;
        }

        this.mainService.put('api/global_variable/TOP_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/operacion', this.operacion).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó la operación exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar la operación, verifique la información', 'warning');
          }
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la operación, verifique la información', 'warning');
    }
  }

  /**
   * Redirigue a la ventana para ver una operacion
   * @param event Evento con la operacion a ver
   */
  onVer(event) {
    this.router.navigate(['home/operacion-ver/' + event.data._id]);
  }

  /**
   * Actualiza una operacion
   */
  onActualizar() {
    if (this.operacion.TOP_NOMBRE != '') {
      let operacionActualizar = this.operacion;
      let data = operacionActualizar;

      this.mainService.put('api/operacion/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la operación exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.operacion = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la operación, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la operación, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos de la BD
   */
  onCargar() {
    this.cargando = true;

    let x = this;
    this.data = [];
    this.mainService.get('api/operacion?activo=true').subscribe((res) => {
      x.data = res;
      this.cargando = false;
    });
  }

  /**
   * Elimina una operacion
   * @param event Evento con los datos de la operacion a eliminar
   */
  onEliminar(event) {
    if (this.permisosUsuario.tiposDeOperaciones !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Desea eliminar la operación?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let operacionEliminar = event.data;
        this.cargando = true;
        this.mainService
          .delete(`api/operacion/${operacionEliminar._id}?operacionId=${operacionEliminar.TOP_ID}`)
          .subscribe((res) => {
            if (res) {
              this.cargando = false;
              if (res.message) {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'Operación eliminada con éxito', 'success');
              this.onCargar();
            }
          });
      }
    });
  }
}
