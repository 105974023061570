import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../services/main.service';
import { AnalisisOperativoService } from './analisis-operativo.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FiltroAnalisisOperativoComponent } from './componentes/filtro-analisis-operativo/filtro-analisis-operativo.component';
import { TablaViajesAnalisisOperativoComponent } from './componentes/tabla-viajes-analisis-operativo/tabla-viajes-analisis-operativo.component';
@Component({
  selector: 'analisis-operativo',
  templateUrl: './analisis-operativo.component.html',
  styleUrls: ['./analisis-operativo.component.scss']
})
export class AnalisisOperativoComponent implements OnInit{

	/** Indica  si el filtro se encuentra actualmente visible*/
	@ViewChild(TablaViajesAnalisisOperativoComponent, { static: false }) tablaComponent: TablaViajesAnalisisOperativoComponent;
	@ViewChild(FiltroAnalisisOperativoComponent, { static: false }) FiltroComponent: FiltroAnalisisOperativoComponent;
	public filtroVisible : boolean = false;

	public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));
	public permisosUsuario: any;

  constructor(
		private analisisOperativoService: AnalisisOperativoService,
		private mainService: MainService,
		private router: Router
		) { }

  ngOnInit() : void {
		this.obtenerPermisos();
		const objetoFormularioString = sessionStorage.getItem('filter');
		this.validateFilter(objetoFormularioString);
		this.viajesCargandoseSub();
	}

	viajesCargandoseSub() {
		if (this.tablaComponent) {
			this.tablaComponent.viajesCargandoseSubject.subscribe((nuevoValor) => {
				if(!nuevoValor) {
					this.FiltroComponent.onFiltrar();
				}
			});
		}
	}

	/**
	 * Obtiene los permisos del módulo de roles y bloquea o deja pasar
	 */
	obtenerPermisos(){
		this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe(res => {
			this.permisosUsuario = res;
			if(this.permisosUsuario.analisisOperativo === 'NINGUNO'){
				Swal.fire({
					title: 'No se tiene permisos de acceso al módulo',
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Continuar'
				});
				this.router.navigate(['home/dashboard']);
			} else {
				this.analisisOperativoService.inicializarDatos();
			}
		})
	}

	validateFilter(filter: string) {
		const form = JSON.parse(filter);
		if (form.boardingAgent || form.buque || form.commodity || form.estado || form.nominador || form.puerto) {
			this.onCambioVisibilidadFiltro();
		}
	}

	/**
	 * Cambia la visibildad del filtro
	 */
	onCambioVisibilidadFiltro(){
		this.filtroVisible = !this.filtroVisible;
	}
}
