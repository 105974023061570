import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
/* Services */
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-producto-ver',
  templateUrl: './producto-ver.component.html',
  styleUrls: ['./producto-ver.component.scss'],
})
export class ProductoVerComponent implements OnInit {
  public dataTypeProducts: any = [];
  public dataMeasures: any = [];
  public dataOperationType: any = [];
  public history: any = [];
  public product: any = {};
  public holdHistory: any = {};
  public user: any = {};
  public openEdit: boolean = false;

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      holdNumber: { title: 'Numero de compuerta' },
      operationType: { title: 'Tipo de operacion' },
      // charge: { title: 'Carga' },
      product: { title: 'Producto' },
      accumulated: { title: 'Acumulado' },
      quantity: { title: 'Cantidad' },
      rob: { title: 'ROB' },
      productivity: { title: 'Productividad' },
      estimated: { title: 'Estimado' },
      measure: { title: 'Medida' },
      from: { title: 'From' },
      to: { title: 'To' },
      date: { title: 'Fecha de creación' },
    },
  };
  id: string = '';

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  constructor(
    public router: Router,
    private mainService: MainService,
    public activatedRoute: ActivatedRoute,
    public _location: Location
  ) {}

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.viajes === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.loadProduct(this.id);
      }
    });
  }

  public loadProduct(id) {
    this.mainService.get(`api/producto_completo/${id}`).subscribe((product) => {
      this.product = product;
      this.product.nvBuque = `${this.product.nv} - ${this.product.viaje.vessel.nombre}`;
      this.history = this.product.record ? this.product.record : [];
      this.history = _.orderBy(this.history, ['date'], ['asc']);
      this.history.forEach((element) => (element.date = moment(element.date).format('DD-MMM-YYYY')));
    });
    this.mainService.get('api/producto').subscribe((res) => (this.dataTypeProducts = res));
    this.mainService.get('api/medida').subscribe((res) => (this.dataMeasures = res));
    this.mainService.get('api/operacion').subscribe((res) => (this.dataOperationType = res));
  }

  public onGoBack() {
    this._location.back();
  }

  public onSetDate(event) {
    this.holdHistory.date = moment(event.target.value);
  }

  public onCreateQuantity() {
    if (this.holdHistory.accumulated && this.holdHistory.rob) {
      this.holdHistory.quantity = this.holdHistory.accumulated - this.holdHistory.rob;
    }
  }

  public onCustomActions(event) {
    switch (event.action) {
      case 'edit':
        this.loadHoldInfo(event.data);
        break;
      case 'delete':
        this.deleteHoldInfo(event.data);
        break;
    }
  }

  public async deleteHoldInfo(holdInfo) {
    if (this.permisosUsuario.productosViaje !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    await this.createSwalFire('¿Deseas eliminar el contenido?').then((respond) => {
      if (respond) {
        let index = this.history.findIndex((holdRecord) => holdRecord._id == holdInfo._id);
        this.history.splice(index, 1);
        this.mainService.put(`api/producto_completo/${this.product._id}`, { record: this.history }).subscribe((res) => {
          this.loadProduct(this.product._id);
          this.simpleSwalFire('Éxito', 'Se actualizó el statements of facts', 'success');
        });
      } else this.simpleSwalFire('Error', 'Problema eliminando la observacion', 'warning');
    });
  }

  public createSwalFire(title) {
    return new Promise((success) => {
      Swal.fire({
        title: `<strong>${title}</strong>`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => success(result.value));
    });
  }

  public simpleSwalFire(title, description, type) {
    Swal.fire(title, description, type);
  }

  public loadHoldInfo(holdInfo) {
    if (this.permisosUsuario.productosViaje !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.holdHistory = holdInfo;
    this.openEdit = true;
  }

  public onAddHistoryHold() {
    this.holdHistory.date = this.holdHistory.date ? this.holdHistory.date : moment();
    this.history.push(this.holdHistory);
    this.mainService.put(`api/producto_completo/${this.product._id}`, { record: this.history }).subscribe((res) => {
      this.loadProduct(this.product._id);
      this.simpleSwalFire('Éxito', 'Se actualizó el historial de compuertas', 'success');
      this.history = res.record ? res.record : [];
      this.history = _.orderBy(this.history, ['date'], ['asc']);
      this.history.forEach((element) => (element.date = moment(element.date).format('DD-MMM-YYYY')));
      this.holdHistory = {};
    });
  }

  public onCancelEdit() {
    this.openEdit = false;
    this.holdHistory = {};
  }

  public onEditHistoryHold() {
    let index = this.history.findIndex((holdRecord) => holdRecord._id == this.holdHistory._id);
    this.history.splice(index, 1);
    this.history.push(this.holdHistory);
    this.mainService.put(`api/producto_completo/${this.product._id}`, { record: this.history }).subscribe((res) => {
      this.loadProduct(this.product._id);
      this.openEdit = false;
      this.simpleSwalFire('Éxito', 'Se actualizó el historial de compuertas', 'success');
      this.history = res.record ? res.record : [];
      this.history = _.orderBy(this.history, ['date'], ['asc']);
      this.history.forEach((element) => (element.date = moment(element.date).format('DD-MMM-YYYY')));
      this.holdHistory = {};
    });
  }
}
