import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { singleSelectsIns } from '../../../../instructions/singleSelect.ins';
import { AlertService } from '../../../../services/alertService/alert-service.service';

@Component({
  selector: 'proforma-reporte',
  templateUrl: './proforma-reporte.component.html',
  styleUrls: ['./proforma-reporte.component.scss'],
})
export class ProformaReporteComponent implements OnInit {
  /** Formuloario reactivo del filtro */
  public filtro: FormGroup;
  /** Buques en la BD */
  public buques: any = [];
  /**Empresas en la BD */
  public empresas: any = [];
  /** Peuerto sen la BD */
  public puertos: any = [];
  /** Commodities en la BD */
  public commodities: any = [];
  /** Kims en la BD */
  public kims: any = [];
  /** Viajes en la BD */
  public viajes: any = [];
  /** Proformas en la BD */
  public todasLasProformas: any = [];
  /** Proformas con nominacion en la BD */
  public proformasConNominacion: any = [];
  /** Liquidaciones en la BD */
  public liquidaciones: any = [];
  /** Posibles errores en el filtro */
  public erroresFiltro: string[] = ['NINGUNO', 'FILTRO_VACIO', 'RANGO'];
  /** Indica si algun elemento se esta cargando en el modulo */
  public cargando: boolean = false;
  /** Proformas filtradas por los valores ingresados por el usaurio en el filitro */
  public proformasFiltradas: any = [];

  public proformaReporteType = singleSelectsIns.proformaReporteType;

  public downloadingExcel = false;

  @Input('permisosUsuario') permisosUsuario: any;

  constructor(private mainService: MainService, private alertService: AlertService) {}

  ngOnInit() {
    this.initializeData();
    this.initializeFiltro();
  }

  /**
   * Se inicializan los datos traidos de la BD
   */
  initializeData() {
    this.cargando = true;

    this.mainService.get('api/buque?activo=true').subscribe((res) => {
      this.buques = res;
      this.buques = _.orderBy(this.buques, ['BUQ_NOMBRE']);

      this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
        this.empresas = res;

        this.mainService.get('api/proforma?activo=true').subscribe((res) => {
          this.todasLasProformas = res;

          this.todasLasProformas.forEach((element) => {
            if (element.nv) {
              element.etaString = moment(element.eta).format('DD-MM-YYYY HH:mm');
              this.proformasConNominacion.push(element);
            }
          });
          this.proformasConNominacion = _.orderBy(this.proformasConNominacion, ['etaString'], ['asc']);

          this.mainService.get('api/liquidacion?activo=true').subscribe((res) => {
            this.liquidaciones = res;

            this.mainService.get('api/nuevo-viaje?activo=true').subscribe((res) => {
              this.viajes = res;

              this.setDataCollectionBuque();

              this.mainService.get('api/puerto?activo=true').subscribe((res) => {
                this.puertos = res;

                this.mainService.get('api/commodity?activo=true').subscribe((res) => {
                  this.commodities = res;

                  this.mainService.get('api/kim?activo=true').subscribe((res) => {
                    this.kims = res;

                    this.cargando = false;
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  /**
   * Se inicializa el formulario reactivo del filtro
   */
  initializeFiltro() {
    this.filtro = new FormGroup({
      buque: new FormControl({ value: '', disabled: false }),
      type: new FormControl({ value: this.proformaReporteType.defaults.value, disabled: false }),
      fechaMin: new FormControl({ value: '', disabled: false }),
      fechaMax: new FormControl({ value: '', disabled: false }),
    });
  }

  /**
   * Reacciona a la aplicacion del filtro
   */
  async onSubmit() {
    switch (this.encontrarErroresFiltro()) {
      case this.erroresFiltro[1]:
        Swal.fire({
          title: 'No se puede aplicar el filtro',
          text: 'No se ha definido ningún campo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        break;
      case this.erroresFiltro[2]:
        Swal.fire({
          title: 'No se puede aplicar el filtro',
          text: 'El rango de fechas esta mal definido',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        break;
      default:
        const formContent = this.filtro.value;
        const filteredReports = await this.mainService
          .get(
            `api/proformas/reports?vessel=${formContent.buque}&fechaMin=${formContent.fechaMin}&fechaMax=${formContent.fechaMax}`
          )
          .toPromise();

        // const filtro = JSON.parse(JSON.stringify(formContent));

        const proformasFiltradas = filteredReports;
        console.log(this.proformasFiltradas);
        this.proformasFiltradas = this.proformasAFormatoTabla(proformasFiltradas);
        break;
    }
  }

  public diffDateForExcel(fromDate: string, toDate: string): string {
    if (!fromDate || !toDate) return 'No definido';
    if (fromDate === 'No definido' || toDate === 'No definido') return 'No definido';

    const startMoment = moment(fromDate);
    const endMoment = moment(toDate);

    return Math.abs(endMoment.diff(startMoment, 'days')).toString();
  }

  /**
   * Pone las proformas fiiltradas en el formato de la tabla para visualizar
   */
  proformasAFormatoTabla(proformas) {
    let proformasFormatoTabla = [];

    proformas.forEach((proforma) => {
      const viajeAsociado = this.viajes.find((viaje) => viaje.nv == proforma.nv);

      if (viajeAsociado) {
        let recaladaAsociada = viajeAsociado.recaladas.find((recalada) => recalada._id == proforma.recaladaId);

        if (!recaladaAsociada) {
          recaladaAsociada = viajeAsociado.recaladas[0];
        }

        const liquidacionAsociada = this.liquidaciones.find((liquidacion) => liquidacion.nv == proforma.nv);

        let commodityAsociado = undefined;
        if (viajeAsociado.commodity)
          commodityAsociado = this.commodities.find((commodity) => commodity.ID == viajeAsociado.commodity.id);

        const kimAsociado = this.kims.find((kim) => kim.KIM_ID == viajeAsociado.kim.id);

        const { TOTAL: totalProformado } = this.obtenerTotalProformadoOLiquidado(proforma);
        const { TOTAL: totalLiquidado } = this.obtenerTotalProformadoOLiquidado(liquidacionAsociada);

        const closeToReal = totalLiquidado - totalProformado;
        const anticipo = viajeAsociado.anticipo ? viajeAsociado.anticipo : 0;

        const port = this.puertos.find((puerto) => proforma.port == puerto.PUE_ID);
        const buque = this.buques.find((buque) => proforma.vessel == buque.BUQ_ID);

        let saldo = 0;
        if (recaladaAsociada.informacionExtra.anticipo) {
          saldo = totalProformado - recaladaAsociada.informacionExtra.anticipo;
        } else {
          saldo = totalProformado;
        }

        const proformaFormatoTabla = {
          nv: proforma.nv,
          index: `${proforma.nv}(${recaladaAsociada.index || 1})`,
          eta: proforma.eta ? moment.utc(proforma.eta).format('DD-MM-YYYY HH:mm') : 'No definido',
          load: proforma.load,
          puerto: port.PUE_NOMBRE,
          buque: buque.BUQ_NOMBRE,
          diasCierrePuerto: '',
          diasFB: '',
          diasFact: '',
          observacionesInfoExtra: recaladaAsociada.informacionExtra.observaciones,
          facturaRecobro: recaladaAsociada.informacionExtra.facturaRecobro,
          facturaIngreso: recaladaAsociada.informacionExtra.facturaIngreso,
          dateReport: moment(new Date()).format('DD/MM/YYYY HH:MM'),
          terminal: recaladaAsociada.terminal.nombre,
          status: viajeAsociado.status,
          operationType: recaladaAsociada.operationType.nombre,
          proforma: totalProformado,
          liquidacion: totalLiquidado,
          anticipo: recaladaAsociada.informacionExtra.anticipo
            ? recaladaAsociada.informacionExtra.anticipo.toFixed(2)
            : 0,
          fechaAnticipo: recaladaAsociada.informacionExtra.fechaAnticipo
            ? this.convertirAFechaFormatoTabla(recaladaAsociada.informacionExtra.fechaAnticipo)
            : 'No definido',
          closeToReal: closeToReal,
          saldo: saldo,
          porcentajeCTR: totalLiquidado > 0 ? `${(totalProformado / totalLiquidado) * 100}%` : '0%',
          porcentajeFondos: totalProformado ? `${(anticipo / totalProformado) * 100}%` : '0%',
          observaciones: viajeAsociado.observaciones ? viajeAsociado.observaciones : '',
          fechaCierrePuerto: viajeAsociado.fechaCierrePuerto
            ? this.convertirAFechaFormatoTabla(viajeAsociado.fechaCierrePuerto)
            : '',
          commodity: commodityAsociado ? commodityAsociado.COMODITY : 'No definido',
          kim: kimAsociado ? kimAsociado.KIM_NOMBRE : 'No definido',
          servicios: '',
        };

        // calculate dates

        proformaFormatoTabla.diasCierrePuerto = this.diffDateForExcel(
          recaladaAsociada.informacionExtra.fechaPuertoCierre,
          liquidacionAsociada ? liquidacionAsociada.atd : ''
        );

        proformaFormatoTabla.diasFB = this.diffDateForExcel(
          recaladaAsociada.informacionExtra.fechaRecepcionBogota,
          recaladaAsociada.informacionExtra.fechaFacturacion
        );

        proformaFormatoTabla.diasFact = this.diffDateForExcel(
          new Date().toString(),
          liquidacionAsociada ? liquidacionAsociada.atd : ''
        );

        proforma.serviciosProforma.forEach(
          (service) =>
            (proformaFormatoTabla.servicios = `${proformaFormatoTabla.servicios} ${service.tarifa.nombreServicio}`)
        );

        proformaFormatoTabla.servicios;
        proformasFormatoTabla.push(proformaFormatoTabla);
      }
    });

    return proformasFormatoTabla;
  }

  /**
   * Obtiene el costo total de una proforma o  de una liquidacion
   * @param proformaOLiquidacion Proforma o liquidacion a la que se quiere obtener su costo total
   * @return Valor del total proformado o liquidado
   */
  obtenerTotalProformadoOLiquidado(proformaOLiquidacion) {
    const valueInfo = {
      TOTAL: 0,
      INGRESO: 0,
      COSTO: 0,
    };

    if (!proformaOLiquidacion) return valueInfo;

    if (proformaOLiquidacion.serviciosProforma) {
      proformaOLiquidacion.serviciosProforma.forEach((servicio) => {
        valueInfo[servicio.tarifa.clasificacionServicio] += servicio.valor;
        valueInfo.TOTAL += servicio.valor;
      });
    } else {
      proformaOLiquidacion.serviciosLiquidacion.forEach((servicio) => {
        valueInfo[servicio.tarifa.clasificacionServicio] += servicio.valor;
        valueInfo.TOTAL += servicio.valor;
      });
    }

    return valueInfo;
  }

  /**
   * Convierte una fecha al formato de la fecha Excel
   * @fecha Fecha la que se quiere obtener su formato en excel
   * @return Fecha en formato de excel
   */
  convertirAFechaFormatoTabla(fecha) {
    const fechaEnFormaDate = new Date(fecha);

    const dia = this.convertirANumeroDeDosDigitos(fechaEnFormaDate.getDate());
    const mes = this.obtenerMesAbreviatura(fechaEnFormaDate.getMonth());
    const anio = fechaEnFormaDate.getFullYear();
    const hora = this.convertirANumeroDeDosDigitos(fechaEnFormaDate.getHours());
    const minuto = this.convertirANumeroDeDosDigitos(fechaEnFormaDate.getMinutes());

    return `${dia}/${mes}/${anio} ${hora}${minuto} Hrs`;
  }

  /**
   * Convierte un numero a un string del numero con minimo 2 digitos
   * @param numero Numero que se quiere volver en un string de minimo 2 digitos
   * @return String del numero ingresado con minimo 2 digidos
   */
  convertirANumeroDeDosDigitos(numero) {
    if (numero < 10) {
      return `0${numero}`;
    } else {
      return `${numero}`;
    }
  }

  /**
   * Da la abreviacion de un mes segun su numero
   * @param mesNumero Numero del mes
   * @return Abreviatura del mes
   */
  obtenerMesAbreviatura(mesNumerico) {
    let mesAbreviatura = '';

    switch (mesNumerico) {
      case 0:
        mesAbreviatura = 'Ene';
        break;
      case 1:
        mesAbreviatura = 'Feb';
        break;
      case 2:
        mesAbreviatura = 'Mar';
        break;
      case 3:
        mesAbreviatura = 'Abr';
        break;
      case 4:
        mesAbreviatura = 'May';
        break;
      case 5:
        mesAbreviatura = 'Jun';
        break;
      case 6:
        mesAbreviatura = 'Jul';
        break;
      case 7:
        mesAbreviatura = 'Ago';
        break;
      case 8:
        mesAbreviatura = 'Sep';
        break;
      case 9:
        mesAbreviatura = 'Oct';
        break;
      case 10:
        mesAbreviatura = 'Nov';
        break;
      case 11:
        mesAbreviatura = 'Dic';
        break;
    }

    return mesAbreviatura;
  }

  /**
   * Filtra las proformas segun los valores ingresados en el filtro
   * @param filtro Valores ingresados en el filtro
   * @return Proformas filtradas
   */
  obtenerProformasPorFiltro(filtro) {
    let proformasFiltradas = this.proformasConNominacion.slice();

    if (filtro.buque) {
      proformasFiltradas = proformasFiltradas.filter((proforma) => proforma.vessel == filtro.buque);
    }
    if (filtro.fechaMin && filtro.fechaMax) {
      const fechaMinUTC = this.convertirAFechaUTC(filtro.fechaMin);
      const fechaMaxUTC = this.convertirAFechaUTC(filtro.fechaMax);
      proformasFiltradas = proformasFiltradas.filter((proforma) => {
        const etaProforma = new Date(proforma.eta).getTime();
        return etaProforma >= fechaMinUTC && etaProforma <= fechaMaxUTC;
      });
    }

    return proformasFiltradas;
  }

  /**
   * Obtiene el valor de una fecha en UTC
   * @param fecha que se quiere obtener su valor UTC
   * @return Fecha UTC de la fecha ingresada
   */
  convertirAFechaUTC(fecha) {
    const fechaPartes = fecha.split('-');

    return Date.UTC(fechaPartes[0], fechaPartes[1] - 1, fechaPartes[2]);
  }

  /**
   * Busca los errores en los valores ingresados al filtro
   */
  encontrarErroresFiltro() {
    const fechaMinPartes = this.filtro.get('fechaMin').value ? this.filtro.get('fechaMin').value.split('-') : '';
    const fechaMaxPartes = this.filtro.get('fechaMax').value ? this.filtro.get('fechaMax').value.split('-') : '';

    const fechaMin = fechaMinPartes ? Date.UTC(fechaMinPartes[0], fechaMinPartes[1], fechaMinPartes[2]) : '';
    const fechaMax = fechaMaxPartes ? Date.UTC(fechaMaxPartes[0], fechaMaxPartes[1], fechaMaxPartes[2]) : '';

    if (!this.filtro.get('buque').value && !fechaMin && !fechaMax) return this.erroresFiltro[1];
    else if (fechaMin && fechaMax && fechaMin > fechaMax) return this.erroresFiltro[2];
    else if ((fechaMin && !fechaMax) || (!fechaMin && fechaMax)) return this.erroresFiltro[2];
    else return this.erroresFiltro[0];
  }

  onLimpiarFiltro() {
    this.filtro.controls['buque'].setValue('');
    this.filtro.controls['fechaMin'].setValue('');
    this.filtro.controls['fechaMax'].setValue('');
    this.proformasFiltradas = [];
    this.componet.dataToFind = '';
  }

  /**
   * Descarga un excel
   */
  onDescargarExcel() {
    this.downloadingExcel = true;
    const formContent = this.filtro.value;

    const reportType = formContent.type === 'CloseToReal' ? 'reports-for-real' : 'reports-proforma';

    this.mainService
      .download(
        `api/proformas/${reportType}/download?vessel=${formContent.buque}&fechaMin=${formContent.fechaMin}&fechaMax=${formContent.fechaMax}`
      )
      .subscribe((blob) => {
        this.downloadingExcel = false;
        saveAs(blob, `${reportType}.xlsx`);
        this.alertService.simpleAlertConfirmTitle(
          'Descarga Exitosa',
          'Tener en cuenta que en el documento se mostrarán los servicios asociados a la proforma'
        );
      });
  }

  /**
   * Convierte el libro de calculo de binario a octado
   * @param s Libro de calculo en binario
   * @returns Buffer con el libro de calculo en octado
   */
  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  public dataCollectionToFind = [];

  public setVessel(data: string) {
    this.filtro.patchValue({
      buque: data,
    });
  }

  public componet;
  public hadleComponent(component) {
    this.componet = component;
  }

  public setDataCollectionBuque() {
    this.dataCollectionToFind = [];
    this.viajes.forEach((viaje) => {
      this.dataCollectionToFind.push({
        key: viaje.vessel.id,
        value: `${viaje.nv} - ${viaje.vessel.nombre} - ${viaje.voyageNumber || ''} - ${viaje.port.nombre}
              `,
      });
    });
  }
}
