import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  public simpleAlertConfirm(message: string) {
    return Swal.fire({
      title: message,
      type: 'success',
      confirmButtonText: 'Continuar',
    });
  }

  public simpleAlertConfirmTitle(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      type: 'success',
      confirmButtonText: 'Continuar',
    });
  }

  public simpleAlertError(message: string) {
    Swal.fire({
      title: message,
      type: 'error',
      confirmButtonText: 'Continuar',
    });
  }

  public simpleAlertWarning(message: string) {
    Swal.fire({
      title: message,
      type: 'warning',
      confirmButtonText: 'Continuar',
    });
  }

  public optionsAlertWarning(message: string) {
    return Swal.fire({
      title: message,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      focusCancel: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    });
  }
}
