import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalisisOperativoService } from '../../analisis-operativo.service';
import { FormGroup, FormControl } from '@angular/forms';
import { InputSearchComponent } from '../../../../components/input-search/input-search.component';

@Component({
  selector: 'filtro-analisis-operativo',
  templateUrl: './filtro-analisis-operativo.component.html',
  styleUrls: ['./filtro-analisis-operativo.component.scss'],
})
export class FiltroAnalisisOperativoComponent implements OnInit, OnDestroy {
  /** Buques en la BD */
  public buques: any;
  /** Subscripcion a los buques del servicio */
  private buquesSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de los buques */
  public buquesCargandose: boolean = true;
  /** Subscripcion al indicativo de carga de buques del sevicio*/
  public buquesCargandoseSubscripcion: Subscription;
  /** Puertos en la BD */
  public puertos: any;
  /** Subscripcion a los puertos del servicio */
  private puertosSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de los puertos */
  public puertosCargandose: boolean = true;
  /** Subscripcion al indicativo de carga de puertos del sevicio*/
  public puertosCargandoseSubscripcion: Subscription;
  /** Empresas en la BD */
  public empresas: any;
  /** Subscripcion a las empresas del servicio */
  private empresasSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de las empresas */
  public empresasCargandose: boolean = true;
  /** Subscripcion al indicativo de carga de empresas del sevicio*/
  public empresasCargandoseSubscripcion: Subscription;

  /** Empresas en la BD */
  public commodities: any;
  /** Subscripcion a las empresas del servicio */
  private commoditiesSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de las empresas */
  public commoditiesCargandose: boolean = true;
  /** Subscripcion al indicativo de carga de empresas del sevicio*/
  public commoditiesCargandoseSubscripcion: Subscription;

  /** Empresas en la BD */
  public boardingAgents: any;
  /** Subscripcion a las empresas del servicio */
  private boardingAgentsSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de las empresas */
  public boardingAgentsCargandose: boolean = true;
  /** Subscripcion al indicativo de carga de empresas del sevicio*/
  public boardingAgentsCargandoseSubscripcion: Subscription;
  /** Viajes en la BD */
  public viajes: any;
  /** Subscripcion a las viajes del servicio */
  private viajesSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de los viajes */
  public viajesCargandose: boolean = true;
 /** Subscripcion al indicativo de carga de viajes del sevicio*/
  public viajesCargandoseSubscripcion: Subscription;

  /** Formulario reactivo del filtro */
  public formularioFiltro: FormGroup;

  /** Posibles estados para filtrar un viaje */
  public estadosViaje: string[] = ['ANNOUNCED', 'BERTHED', 'COMPLETED', 'SHIPPED', 'LIQUIDADO', 'FACTURAS RECIBIDAS'];

  @ViewChild('inputSearch', {static: false}) inputSearch :InputSearchComponent;

  constructor(private analisisOperativoService: AnalisisOperativoService) {}

  ngOnInit(): void {
    this.inicializarSubscripciones();
    this.inicializarFormualrio();
    this.initFilter();
  }

  ngOnDestroy(): void {
    this.detenerSubscripciones();
  }

  /**
   * Subscribrse a los datos necesarios del servicio
   */
  inicializarSubscripciones(): void {
    // Buques
    this.buquesCargandoseSubscripcion = this.analisisOperativoService.buquesCargandose.subscribe((cargandose) => {
      this.buquesCargandose = cargandose;
    });
    this.buquesSubscripcion = this.analisisOperativoService.buquesCambiados.subscribe((buques) => {
      this.buques = buques;
    });

    // Puertos
    this.puertosCargandoseSubscripcion = this.analisisOperativoService.puertosCargandose.subscribe((cargandose) => {
      this.puertosCargandose = cargandose;
    });
    this.puertosSubscripcion = this.analisisOperativoService.puertosCambiados.subscribe((puertos) => {
      this.puertos = puertos;
    });

    // Empresas
    this.empresasCargandoseSubscripcion = this.analisisOperativoService.empresasCargandose.subscribe((cargandose) => {
      this.empresasCargandose = cargandose;
    });
    this.empresasSubscripcion = this.analisisOperativoService.empresasCambiadas.subscribe((empresas) => {
      this.empresas = empresas;
    });

    // Commodity
    this.commoditiesCargandoseSubscripcion = this.analisisOperativoService.commoditiesCargandose.subscribe(
      (cargandose) => {
        this.commoditiesCargandose = cargandose;
      }
    );
    this.commoditiesSubscripcion = this.analisisOperativoService.commoditiesCambiadas.subscribe((empresas) => {
      this.commodities = empresas;
    });

    // Boarding Agent
    this.boardingAgentsCargandoseSubscripcion = this.analisisOperativoService.boardingAgentsCargandose.subscribe(
      (cargandose) => {
        this.boardingAgentsCargandose = cargandose;
      }
    );
    this.boardingAgentsSubscripcion = this.analisisOperativoService.boardingAgentsCambiadas.subscribe((empresas) => {
      this.boardingAgents = empresas;
    });

    // Viajes y buques
    this.viajesCargandoseSubscripcion = this.analisisOperativoService.viajesCargandose.subscribe((cargandose) => {
      this.viajesCargandose = cargandose;
    });
    this.viajesSubscripcion = this.analisisOperativoService.viajesFiltradosCambiados.subscribe((viajes) => {
      if (!this.viajes || this.viajes.length == 0) {
        this.viajes = this.filtrarViajes(viajes);//viajes.sort((a, b) => b.nv - a.nv);
      }
    });
  }

  filtrarViajes(array: any) {
    let viajes = array.sort((a, b) => b.nv - a.nv);
    let seenIds = [];
    let uniqueViajes = [];

    for (let viaje of viajes) {
      if (seenIds.indexOf(viaje._id) == -1) {
        uniqueViajes.push({
          key: viaje._id,
          value: `${viaje.nv} - ${viaje.nombreBuque}`,
        });
        seenIds.push(viaje._id);
      }
    }
    return uniqueViajes;
  }

  /**
   * Detener Subscripciones del servicio
   */
  detenerSubscripciones(): void {
    this.viajesSubscripcion.unsubscribe();
    this.viajesCargandoseSubscripcion.unsubscribe();
    this.buquesSubscripcion.unsubscribe();
    this.buquesCargandoseSubscripcion.unsubscribe();
    this.puertosSubscripcion.unsubscribe();
    this.puertosCargandoseSubscripcion.unsubscribe();
    this.empresasSubscripcion.unsubscribe();
    this.empresasCargandoseSubscripcion.unsubscribe();

    this.commoditiesCargandoseSubscripcion.unsubscribe();
    this.boardingAgentsCargandoseSubscripcion.unsubscribe();
  }

  /**
   * Inicializa el formulariio reactivo del filtro
   */
  inicializarFormualrio() {
    this.formularioFiltro = new FormGroup({
      viaje: new FormControl({ value: '', disabled: false }),
      buque: new FormControl({ value: '', disabled: false }),
      puerto: new FormControl({ value: '', disabled: false }),
      nominador: new FormControl({ value: '', disabled: false }),
      estado: new FormControl({ value: '', disabled: false }),
      commodity: new FormControl({ value: '', disabled: false }),
      boardingAgent: new FormControl({ value: '', disabled: false }),
    });
  }

  /**
   * Envia al servicio de analisis operativo los valores del filtro
   */
  onFiltrar() {
    /** Contenido del formulario en formato JSON */
    const objetoFormulario = JSON.parse(JSON.stringify(this.formularioFiltro.value));

    sessionStorage.setItem('filter', JSON.stringify(objetoFormulario))
    this.analisisOperativoService.filtrarViajes(objetoFormulario);
  }

  initFilter() {
    const filterObject = sessionStorage.getItem('filter')
    this.formularioFiltro.patchValue(JSON.parse(filterObject))
  }

  /**
   * Reeinicia el formulario y limpia el filtro aplicado previamente
   */
  onLimpiarFiltro() {
    sessionStorage.setItem('filter', null);
    this.analisisOperativoService.filtrarViajes({});

    // Inicializar los valores en el formulario
    this.inputSearch.clearData();
    this.formularioFiltro.controls['viaje'].setValue('');
    this.formularioFiltro.controls['buque'].setValue('');
    this.formularioFiltro.controls['puerto'].setValue('');
    this.formularioFiltro.controls['nominador'].setValue('');
    this.formularioFiltro.controls['estado'].setValue('');
    this.formularioFiltro.controls['commodity'].setValue('');
    this.formularioFiltro.controls['boardingAgent'].setValue('');
  }

  setViaje(event: any) {
    this.formularioFiltro.controls['viaje'].setValue(event ? event : '');
  }
}
