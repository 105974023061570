import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss'],
})
export class ReportesComponent implements OnInit {
  /** Viaje de los reportes */
  public viaje: any;
  /** Indica si hay algo en el modulo cargandose */
  public cargando = true;

  constructor(
    public router: Router,
    private mainService: MainService,
    private _location: Location,
    private dateAndTimeService: DateAndTimeService
  ) {}

  ngOnInit() {
    this.obtenerViaje();
  }

  /**
   * A traves de la URL obtiene el viaje de los reportes actual y inicializa los datos acrodemente
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      if (res.message) {
        Swal.fire({
          title: '¡Error!',
          text: res.message,
          type: 'error',
        });
        return this.router.navigate(['home/dashboard']);
      }
      this.viaje = res;
      this.viaje.etaVisual = moment.utc(this.viaje.eta).format('DD-MM-YYYY HH:mm');
      this.viaje.etbVisual = moment.utc(this.viaje.etb).format('DD-MM-YYYY HH:mm');
      this.viaje.etdVisual = moment.utc(this.viaje.etd).format('DD-MM-YYYY HH:mm');

      if (!this.viaje.eta) this.viaje.etaVisual = 'Fecha no definida';
      if (!this.viaje.etb) this.viaje.etbVisual = 'Fecha no definida';
      if (!this.viaje.etd) this.viaje.etdVisual = 'Fecha no definida';

      this.cargando = false;
    });
  }

  /**
   * Convierte una fecha a un String para ser visualizada
   * @param fecha Fecha de la cual se quiere obtener el formato visual
   * @returns String con la fecha en el formaot visual
   */
  fechaAFormatoVisual(fecha: Date): string {
    if (!fecha) {
      return 'No definido';
    }
    return this.dateAndTimeService.convertirFechaAFormatoFormulario(fecha);
  }

  /**
   * Direcciona a la pagina del viaje
   */
  onIrAViaje() {
    this.router.navigate([`home/viaje/view-and-edit/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Direcciona a la pagina del reporte de arribo del viaje
   */
  onIrAReporteArribo() {
    this.router.navigate([`home/reporte-arribo/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Direcciona a la pagina del reporte de productividad del viaje
   */
  onIrAReporteProductividad() {
    this.router.navigate([`home/reporte-productividad/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Direcciona a la pagina del reporte de prospectos del viaje
   */
  onIrAReporteProspectos() {
    this.router.navigate([`home/reporte-prospectos/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Direcciona a la pagina del reporte de fin de operaciones
   */
  onIrAReporteFinOperaciones() {
    this.router.navigate([`home/reporte-fin-operaciones/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Direcciona a la pagina del reporte de zarpe del viaje
   */
  onIrAReporteZarpe() {
    this.router.navigate([`home/reporte-zarpe/${this.viaje._id}/${this.viaje.recalada._id}`]);
  }

  /**
   * Crea el sof asociado al viaje
   */
  onCrearSof(): void {
    this.cargando = true;

    this.cargando = true;
    let nuevoSof = {
      voyage: this.viaje.voyageNumber,
      terminal: this.viaje.recalada.terminal.id.toString(),
      nv: this.viaje.nv,
      via_id: this.viaje._id,
      history: [],
      idRecalada: this.viaje.recalada._id,
    };

    this.mainService.post('api/sof', nuevoSof).subscribe(
      (res) => {
        this.mainService.put(`api/recalada/${this.viaje.recalada._id}`, { sof: [res._id] }).subscribe(
          (res) => {
            Swal.fire('Éxito', 'Se creó el SOF exitosamente', 'success');
            this.cargando = false;
            this.obtenerViaje();
          },
          (err) => {
            Swal.fire('Error', 'No se pudo asociar el SOF a la recalada', 'error');
            this.cargando = false;
          }
        );
      },
      (err) => {
        Swal.fire('Error', 'No se pudo crear el SOF', 'error');
        this.cargando = false;
      }
    );
  }

  /**
   * Redirigue al detalle del sof
   */
  onVerSof(): void {
    this.router.navigate([`home/reportes/ver-sof/${this.viaje.recalada.sof[0]}/${this.viaje.recalada._id}`]);
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    this.router.navigate(['home/analisis-operativo']);
  }
}
