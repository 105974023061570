import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
/* Servicios */
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-producto-viaje',
  templateUrl: './producto-viaje.component.html',
  styleUrls: ['./producto-viaje.component.scss'],
})
export class ProductoViajeComponent implements OnInit {
  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: 'detail', title: '<i class="nb-search"></i>' }],
    },
    columns: {
      nv: { title: 'NV' },
      nombreBuque: { title: 'Buque' },
      nombrePuerto: { title: 'Puerto' },
      nombreTerminal: { title: 'Terminal' },
      PRV_ID: { title: 'ID' },
      productName: { title: 'Producto' },
      PRV_ACUMULADO: { title: 'Acumulado' },
      PRV_PROD_12H: { title: 'Prod_12H' },
      PRV_CANTIDAD: { title: 'Cantidad' },
      PRV_ROB_: { title: 'ROB' },
      nombreMedida: { title: 'Medida' },
      createdAt: {
        title: 'Fecha de creación',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /*  Modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  public dataProductosCompleto: any = [];
  public dataProductos: any = [];
  public dataMedidas: any = [];
  public dataViajes: any = [];
  public datos: any = [];
  public data: any = [];
  public productoExistente: any = {};
  public medidaExistente: any = {};
  public viajeExistente: any = {};
  public producto: any = {};
  public medida: any = {};
  public viaje: any = {};
  public enviado: boolean = false;

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.productosViaje === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.load();
      }
    });
  }

  getAllTrips(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.get(`api/viajes`).subscribe(() => {
        return;
      });
    });
  }

  /**
   * Function to choose action in smart-table
   * @param event
   */
  public onCustom(event) {
    if (event.action === 'detail') {
      this.view(event.data);
    }
  }

  /**
   * Function to view all information associate a product of ship
   * @param product
   */
  public view(product) {
    if (product._id != 'N/A') {
      this.router.navigate([`home/producto-ver/${product._id}`]);
    } else {
      Swal.fire('Error', 'Este producto no tiene ningun viaje asociado', 'warning');
    }
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Function to close present modal
   */
  public closeModal() {
    const element: HTMLElement = document.getElementById('btn-close');
    element.click();
  }

  /**
   * Function to load all important information of module
   */
  public load() {
    this.data = [];
    this.mainService.get('api/producto_completo/via_vessels?activo=true').subscribe((res) => {
      this.data = res;
      console.log(res);
      this.dataProductosCompleto = JSON.parse(JSON.stringify(res));
      this.data.forEach((element) => {
        element.productName = element.productData.PRD_NOMBRE;
        element.PRV_ID = Number(element.PRV_ID);
        element.nv = `${element.nv}(${element.indexRecalada || 1})`;
      });
      this.data = _.orderBy(this.data, ['PRV_ID'], ['asc']);
    });
    this.mainService.get('api/producto').subscribe((res) => (this.dataProductos = JSON.parse(JSON.stringify(res))));
    this.mainService.get('api/medida').subscribe((res) => (this.dataMedidas = JSON.parse(JSON.stringify(res))));
    this.mainService.get('api/barco').subscribe((res) => (this.dataViajes = JSON.parse(JSON.stringify(res))));
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        this.viajeExistente = {};
        this.productoExistente = {};
        this.medidaExistente = {};
        let idProducto = this.datos[i][1];
        let idMedida = this.datos[i][7];
        let idViaje = this.datos[i][3];
        this.viajeExistente = await this.getViajeByID(idViaje);
        this.productoExistente = await this.getProductoByID(idProducto);
        if (this.productoExistente == null) {
          this.productoExistente = {
            PRD_NOMBRE: '',
          };
          this.productoExistente.PRD_NOMBRE = this.datos[i][1];
        }
        this.medidaExistente = await this.getMedidaByID(idMedida);
        if (this.medidaExistente == null) {
          this.medidaExistente = {
            MED_NOMBRE: '',
          };
          this.medidaExistente.MED_NOMBRE = this.datos[i][7];
        }
        let excel = {
          nv: 'N/A',
          PRV_ID: this.datos[i][0],
          PRD_ID: this.productoExistente.PRD_NOMBRE,
          PRV_ACUMULADO: this.datos[i][2],
          VIA_ID: this.datos[i][3],
          PRV_PROD_12H: this.datos[i][4],
          PRV_CANTIDAD: this.datos[i][5],
          PRV_ROB: this.datos[i][6],
          MED_ID: this.medidaExistente.MED_NOMBRE,
          TOC_ID: this.datos[i][8],
        };
        this.producto = {};
        if (excel.PRV_ID > 1) {
          this.producto = await this.getProductoCompletoByID(excel.PRV_ID);
          if (this.producto == null) {
            this.producto = {};
            this.producto = await this.crearProductoViajeDesdeExcel(excel);
            if (this.viajeExistente != null) {
              excel.nv = this.viajeExistente.nv;
              await this.actualizarProductoViajeDesdeExcel(this.producto._id, excel);
              let validation = this.viajeExistente.productos.findIndex((producto) => producto._id == this.producto._id);
              if (validation < 0) {
                this.viajeExistente.productos.push(this.producto._id);
                let x = await this.actualizarViajeDesdeExcel(this.viajeExistente._id, this.viajeExistente);
              }
            }
          } else {
            await this.actualizarProductoViajeDesdeExcel(this.producto._id, excel);
            if (this.viajeExistente != null) {
              excel.nv = this.viajeExistente.nv;
              await this.actualizarProductoViajeDesdeExcel(this.producto._id, excel);
              let validation = this.viajeExistente.productos.findIndex((producto) => producto._id == this.producto._id);
              if (validation < 0) {
                this.viajeExistente.productos.push(this.producto._id);
                let x = await this.actualizarViajeDesdeExcel(this.viajeExistente._id, this.viajeExistente);
              }
            }
          }
        }
      }
      this.load();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  public getProductoCompletoByID(evt) {
    return new Promise((result, error) => {
      let x = _.find(this.dataProductosCompleto, { PRV_ID: evt });
      if (x) result(x);
      else result(null);
    });
  }
  public getProductoByID(evt) {
    return new Promise((result, error) => {
      let x = _.find(this.dataProductos, { PRD_ID: evt });
      if (x) result(x);
      else result(null);
    });
  }
  public getViajeByID(evt) {
    return new Promise((result, error) => {
      let x = _.find(this.dataViajes, { via_id: evt });
      if (x) result(x);
      else result(null);
    });
  }
  public getMedidaByID(evt) {
    return new Promise((result, error) => {
      let x = _.find(this.dataMedidas, { MED_ID: evt });
      if (x) result(x);
      else result(null);
    });
  }

  public crearProductoViajeDesdeExcel(producto) {
    return new Promise((result, error) => {
      this.mainService.post('api/producto_completo', producto).subscribe(
        (res) => {
          return result(res);
        },
        (err) => {
          return error(err);
        }
      );
    });
  }

  public actualizarProductoViajeDesdeExcel(id, producto) {
    return new Promise((result, error) => {
      this.mainService.put('api/producto_completo/' + id, producto).subscribe(
        (res) => {
          return result(res);
        },
        (err) => {
          return error(err);
        }
      );
    });
  }

  public crearViajeDesdeExcel(viaje) {
    return new Promise((result, error) => {
      this.mainService.post('api/barco', viaje).subscribe(
        (res) => {
          return result(res);
        },
        (err) => {
          return error(err);
        }
      );
    });
  }

  public actualizarViajeDesdeExcel(id, viaje) {
    return new Promise((result, error) => {
      this.mainService.put('api/barco/' + id, viaje).subscribe(
        (res) => {
          return result(res);
        },
        (err) => {
          return error(err);
        }
      );
    });
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
