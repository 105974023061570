import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import { DateAndTimeService } from '../../../../../services/date-and-time.service';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import Chart from 'chart.js';
import * as _ from 'lodash';
const jsPDF = require('jspdf');
import { Location } from '@angular/common';
import { ReportesService } from '../../reportes.service';
import { VistaPreviaReporteProductividadComponent } from './componentes/vista-previa-reporte-productividad/vista-previa-reporte-productividad.component';
import { S3Service } from '../../../../../services/s3.service';
import { S3 } from 'aws-sdk';

import { TimeService } from '../../../../../../services/time/time.service';
import html2canvas from 'html2canvas';
import { Buffer } from 'buffer';

import { AlertService } from '../../../../../../services/alertService/alert-service.service';
import { Sumatoria, Medida } from './componentes/vista-previa-reporte-productividad/sumatoria';

@Component({
  selector: 'reporte-productividad',
  templateUrl: './reporte-productividad.component.html',
  styleUrls: ['./reporte-productividad.component.scss'],
})
export class ReporteProductividadComponent implements OnInit {
  /** Configuracion de la tabla */
  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i>' },
      ],
    },
    columns: {
      fromVisual: { title: 'Time from' },
      toVisual: { title: 'Time to' },
      nombreProducto: { title: 'Product' },
      acumuladoVisual: { title: 'Accumulated' },
      productividadVisual: { title: 'Productivity' },
      planificadoVisual: { title: 'Planified' },
      robVisual: { title: 'ROB' },
      nombreMedida: { title: 'Measure' },
      nombreTipoOperacion: { title: 'Operation type' },
      nombreTerminal: { title: 'Terminal' },
      holdInfo: { title: 'Hold info' },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'asc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Viaje del reporte de prospectos */
  public viaje: any;
  /** Arreglo con los correos de los contactos asociados al viaje */
  public emailsContactosViaje: any[] = [];
  /** Arreglo con todas las áreas con sus correos */
  public areasConCorreos: any[] = [];
  /** Arreglo con todas las áreas de las empresas */
  public areasEmpresas: any[] = [];
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Indica si los productos del viaje se estan cargando */
  public cargandoProductos: boolean = true;
  /** Referencia al dialogo del detalle un producto-viaje*/
  dialogRefProducto: any;
  /** Modo actual del detalle del producto-viaje */
  public modoProducto: string = 'CREAR';
  /** Arreglo con los posibles modos de un detalle */
  public modosPosiblesDetalles: string[] = ['CREAR', 'EDITAR'];
  /** Productos del viaje en la BD */
  public productosDelViaje: any = [];
  /** Producto-Viaje actualmente seleccionado */
  public productoViaje: any;
  /**Productos del viaje para tabla Email*/
  public productosDelViajeForTable: any = [];
  /** Categorias del SOF */
  public categoriasSof: any;
  /** Obtener SOF viaje*/
  public sofViaje: any;

  /** Indica si se está enviando correo */
  public enviandoCorreo: boolean = false;
  /** Indica si desea enviar correo */
  public enviarCorreo: boolean = false;

  /** Productividad total */
  public productividadTotal: number = 0;

  /** Lista de correos con copia */
  public ccs: any[] = [];
  /** Nuevo correo para agregar a la copia */
  public nuevoCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Nuevo correo para agregar a la copia */
  public nuevoBCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Lista de correos con copia oculta */
  public bccs: any[] = [];

  /** Objeto con informacion de la grafica de horas paradas */
  public infoGraficaHorasParadas: { datosCargados: boolean; graficaTieneDatos: boolean } = {
    datosCargados: false,
    graficaTieneDatos: false,
  };
  /** Objeto con informacion de la grafica de horas trabajadas contra horas paradas */
  public infoGraficaHorasTrabajadasVsParadas: { datosCargados: boolean; graficaTieneDatos: boolean } = {
    datosCargados: false,
    graficaTieneDatos: false,
  };

  /** Arreglo con todas las áreas con sus correos */
  public empresasAreasConCorreos: any[] = [];
  /** Objeto con informacion de la grafica de productividad por producto */
  public infoGraficaProductividadPorProducto: { datosCargados: boolean; graficaTieneDatos: boolean } = {
    datosCargados: false,
    graficaTieneDatos: false,
  };
  /** Objeto con informacion de la grafica de las tasas de productividad */
  public infoGraficaTasasProductividad: { datosCargados: boolean; graficaTieneDatos: boolean } = {
    datosCargados: false,
    graficaTieneDatos: false,
  };
  /** Formulario reactivo del reporte de productividad */
  public formulario: FormGroup;
  /** Archivos adjuntos en el reporte de productividad */
  public archivosAdjuntos: any[] = [];
  /** Indica que los archivos adjuntos se estan cargando */
  public archivosAdjuntosCargandose: boolean = false;
  /** Arreglo con los posibles errores al intentar enviar un correo */
  public erroresEnvioCorreo: string[] = ['NINGUNO', 'SIN_ASUNTO', 'SIN_CONTACTOS'];
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = false;
  /** Email del puerto asociado */
  public emailPuerto: string = '';
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */

  nombeEmpresa: string = '';
  commodityNombre: string = '';
  puertoNombre: string = '';
  terminalNombre: string = '';
  buqueNombre: string = '';

  /** Detalle de contacto de empresa */
  @ViewChild('productoDetalle', { static: true }) productoDetalle: ElementRef;
  /** Referencia al grafico de las horas paradas */
  @ViewChild('graficaHorasParadas', { static: false }) graficaHorasParadas: ElementRef;
  /** Referencia al grafico de las horas trabajadas contra las horas paradas por día */
  @ViewChild('graficaHorasTrabajadasVsParadas', { static: false }) graficaHorasTrabajadasVsParadas: ElementRef;
  /** Referencia al grafico de la productividad por producto */
  @ViewChild('graficaProductividadPorProducto', { static: false }) graficaProductividadPorProducto: ElementRef;
  /** Referencia al grafico de las tasas de productividad */
  @ViewChild('graficaTasasProductividad', { static: false }) graficaTasasProductividad: ElementRef;
  /** Referencia al archivo que se va a adjuntar */
  @ViewChild('archjivoAAdjuntar', { static: true }) archjivoAAdjuntar: ElementRef;
  @ViewChild('guardarVistaPrevia', { static: false }) guardarVistaPrevia: TemplateRef<any>;

  private openDialog: any;

  private vistaPrevia: boolean;

  public usuarioActual = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  /** Imágenes del buque */
  public fotoBuque: string;
  public iconoBuque: string;
  public buqueCorreo: string;
  public sumatoria: Sumatoria;

  /** Bucket de s3 */
  private bucket: S3;
  /** url del documento cargado */
  private documentURL: string;

  horasProductividad: number = 0;

  private totalStoppedHours;

  constructor(
    public router: Router,
    private mainService: MainService,
    private dialogService: NbDialogService,
    private alertService: AlertService,
    public dateAndTimeService: DateAndTimeService,
    private _location: Location,
    private reportesService: ReportesService,
    private s3Service: S3Service,
    private timeService: TimeService
  ) {
    this.bucket = this.s3Service.getBucket();
    this.sumatoria = new Sumatoria();
  }

  ngOnInit() {
    this.inicializarFormualrioVacio();
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe(async (res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.productividadTotal = 0;
        await this.inicializarDatos();
        await this.obtenerCorreosYEmpresasAsociados();
      }
    });
  }

  public arregloOrdenadoActividadesSofConfirmadas = [];
  public arregloOrdenadoActividadesSofEstimadas = [];
  public activitiesStoppedOperations = [];

  obtenerActividadesSof(idSof): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (idSof) {
        this.mainService.get(`api/sof/${idSof}/${this.viaje.recaladaId}`).subscribe((res) => {
          const actividadesSof = res.history || [];
          const actividadesOrdenadasSof = [];

          for (let actividad of actividadesSof) {
            const formattedDate = this.timeService.combineDatesAndHours(actividad.date, actividad.from)
            actividadesOrdenadasSof.push({
              category: actividad.category,
              type: actividad.type,
              status: actividad.status,
              fecha: formattedDate,
              description: actividad.description,
              to: actividad.to,
              from: actividad.from,
            });
          }
          actividadesOrdenadasSof.forEach((actividad) => {
            actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
            // actividad.fechaVisual = moment.utc(actividad.fecha).format('MM/DD/YYYY HHmm');
          });
          actividadesOrdenadasSof.sort(function (a, b) {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
          });

          this.activitiesStoppedOperations = [];
          actividadesOrdenadasSof.forEach((activity) => {
            if (activity.category !== 'STOPPED OPERATIONS' && activity.category !== 'STOPPED HOURS') return;
            this.activitiesStoppedOperations.push(activity);
          });

          this.arregloOrdenadoActividadesSofConfirmadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Confirmed'
          );

          this.arregloOrdenadoActividadesSofEstimadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Estimated'
          );

          resolve(true);
        });
      }
    });
  }

  /**
   * Inicializa el formulario con los datos vacios
   * (Esta fucnion tiene como error prevebnir el error de que el formulario se renderice antes de obtener los datos del viaje)
   */
  inicializarFormualrioVacio(): void {
    this.formulario = new FormGroup({
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: '', disabled: false }),
    });
  }

  public showSOFActivities = false;
  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de productividad e inicializa el viaje en el formato requerido para
   * el componente de reporte de productividad
   */
  inicializarDatos() {
    return new Promise((resolve, reject) => {
      this.cargando = true;

      // Partes de la URL separadas por '/'
      const partesURL = this.router.url.split('/');

      this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
        if (res.message) {
          Swal.fire({
            title: '¡Error!',
            text: res.message,
            type: 'error',
          });
          return this.router.navigate(['home/dashboard']);
        }
        this.viaje = res;
        this.viaje.recalada.productos.reverse();

        if (this.viaje.sof.length > 0) {
          await this.obtenerActividadesSof(this.viaje.sof[0]);
          this.totalStoppedHours = await this.mainService
            .get(`api/sof/all-stopped-hours/${this.viaje.sof[0]}`)
            .toPromise();
          this.generateStoppedHoursReport();
        }

        this.emailPuerto = this.viaje.puertoCorreo;
        this.viaje.recaladas = [this.viaje.recalada];

        this.viaje.commenceOpsVisual =
          this.viaje &&
          this.viaje.portLog &&
          this.viaje.portLog.commenceOPS &&
          this.viaje.portLog.commenceOPS.fecha &&
          this.viaje.portLog.commenceOPS.status == 'CONFIRMED'
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.portLog.commenceOPS.fecha)
            : 'Not registered';
        this.viaje.etcVisual =
          this.viaje && this.viaje.etc
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
            : 'Not registered';
        this.viaje.etdVisual =
          this.viaje && this.viaje.etd
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
            : 'Not registered';

        if (this.viaje.portLog.commenceOPS.status !== 'CONFIRMED') {
          Swal.fire({
            title: 'Para acceder a esta funcionalidad debe registrar en arribo el inicio de las operaciones',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });
          this.router.navigate([`home/reportes/${partesURL[3]}/${partesURL[4]}`]);
          return;
        }
        const savedSubject = this.formulario.value.asuntoReporte;
        const savedFiles = this.archivosAdjuntos;
        this.inicializarFormualrioConDatosViaje();
        await this.inicializarViajeEnFormatoRequerido();
        this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
        await this.obtenerProductosViaje();
        await this.obtenerCategoriasSof();
        this.archivosAdjuntos = savedFiles;
        this.formulario.patchValue({ asuntoReporte: savedSubject });
        this.cargando = false;
        this.mixPortogAndSofActivities();
        this.setDefaultSubjects();

        this.calculateProductivityDetails();
        this.setConfirmedAndEstimatedActivities();
        resolve(true);
      });
    });
  }

  public setConfirmedAndEstimatedActivities() {
    const allActivites = [...this.arregloOrdenadoActividadesConfirmadas, ...this.arregloOrdenadoActividadesEstimadas];

    if (this.incluirActividades) {
      allActivites.push(
        ...this.arregloOrdenadoActividadesSofConfirmadas,
        ...this.arregloOrdenadoActividadesSofEstimadas
      );
    }

    const { confirmedActivities, estimatedActivites } = this.reportesService.combinePortLogsAndSOFActivities(
      allActivites,
      this.incluirActividades
    );

    this.arregloOrdenadoActividadesConfirmadas = confirmedActivities;
    this.arregloOrdenadoActividadesEstimadas = estimatedActivites;
  }

  public onAddSOFActivities() {}

  public mixPortogAndSofActivities() {
    let newArr = this.arregloOrdenadoActividadesConfirmadas
      .concat(this.arregloOrdenadoActividadesSofConfirmadas || [])
      .sort((a: any, b: any) => {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

    this.arregloOrdenadoActividadesConfirmadas = newArr;
  }

  /**
   * Inicializa el formulario con los datos del viaje
   */
  inicializarFormualrioConDatosViaje(): void {
    this.formulario = new FormGroup({
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: this.viaje.remark ? this.viaje.remark : '', disabled: false }),
    });
  }

  /**
   * Obtiene las categorias del SOF de la BD
   */
  obtenerCategoriasSof() {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/category_sof').subscribe(async (res) => {
        this.categoriasSof = res;
        await this.obtenerSofViaje();
        resolve(true);
      });
    });
  }

  /**
   * Obtiene el SOF asociado al viaje
   */
  obtenerSofViaje(): void {
    if (this.viaje && this.viaje.sof && this.viaje.sof[0]) {
      this.mainService.get(`api/sof/${this.viaje.sof[0]}/${this.viaje.recaladaId}`).subscribe((res) => {
        this.sofViaje = res;
        if (this.viaje.sof[0].history && this.viaje.sof[0].history.length > 0) {
          this.sofViaje.forEach((fact) => {
            fact.categoria = this.categoriasSof.find((categoria) => categoria._id == fact.categoryId).description;
          });
        }

        this.inicializarGraficaHorasTrabajadasVsParadas();
        this.inicializarGraficaHorasParadas();
      });
    }
  }

  public holdsUsed = [];

  /**
   * Obtiene los productos del viaje
   */
  obtenerProductosViaje() {
    this.productsByHolds = {
      H1: {
        hold: 'H1',
        products: [],
      },
      H2: {
        hold: 'H2',
        products: [],
      },
      H3: {
        hold: 'H3',
        products: [],
      },
      H4: {
        hold: 'H4',
        products: [],
      },
      H5: {
        hold: 'H5',
        products: [],
      },
      H6: {
        hold: 'H6',
        products: [],
      },
    };

    this.holdsUsed = [];
    this.cargandoProductos = true;
    this.productosDelViaje = [];

    this.mainService.get(`api/producto_completo/recalada/${this.viaje.recalada._id}`).subscribe((res) => {
      let diff = 0;
      for (const item of res) {
        diff += moment(item.PRV__TIME_TO).diff(moment(item.PRV__TIME_FROM), 'hours');
      }

      res.forEach((product) => {
        if (!this.productsByHolds[product.HOLD_NUMBER]) return;
        this.productoAFromatoVisual(product);
        this.productsByHolds[product.HOLD_NUMBER].products.push(product);
      });

      this.productsForView = Object.values(this.productsByHolds).reverse();

      const timesHold = {};

      this.productividadTotal = diff;
      this.productosDelViaje = res.filter((item: any) => (item.activo = true));
      this.productosDelViaje.forEach((producto) => {
        timesHold[producto.HOLD_NUMBER] = timesHold[producto.HOLD_NUMBER] ? timesHold[producto.HOLD_NUMBER] + 1 : 1;
        if (timesHold[producto.HOLD_NUMBER] >= 5) this.holdsUsed.push(producto.HOLD_NUMBER);
        this.productoAFromatoVisual(producto);
      });
      this.cargandoProductos = false;
      this.productosDelViajeForTable = this.productosDelViaje

      // Graficas de productividad
      this.mainService.get('api/producto').subscribe((res) => {
        this.inicializarGraficaProductividadPorProducto(res);
        this.inicializarGraficaTasasDeProductividad(res);
      });
    });
  }

  /**
   * Coge un producto y lo pasa al formato para visualizar en la tabla
   */
  async productoAFromatoVisual(producto: any): Promise<void> {
    const productividadParcial = moment(producto.PRV__TIME_TO).diff(moment(producto.PRV__TIME_FROM), 'hours');
    // this.productividadTotal += productividadParcial;
    producto.fromVisual = `${this.dateAndTimeService.convertirFechaAFormatoFormulario(producto.PRV__TIME_FROM)} `;
    producto.fromVisual += `${this.dateAndTimeService.convertirHoraAFormatoFormulario(producto.PRV__TIME_FROM)}:`;
    producto.fromVisual += `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(producto.PRV__TIME_FROM)}`;

    producto.toVisual = `${this.dateAndTimeService.convertirFechaAFormatoFormulario(producto.PRV__TIME_TO)} `;
    producto.toVisual += `${this.dateAndTimeService.convertirHoraAFormatoFormulario(producto.PRV__TIME_TO)}:`;
    producto.toVisual += `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(producto.PRV__TIME_TO)}`;

    producto.fromVisual = moment.utc(producto.PRV__TIME_FROM).format('DD/MM/YYYY HH:mm');
    producto.toVisual = moment.utc(producto.PRV__TIME_TO).format('DD/MM/YYYY HH:mm');

    await this.mainService.get(`api/producto?PRD_ID=${producto.PRD_ID}`).subscribe((res) => {
      const proudctoAsociado = res.filter((element) => element.PRD_ID == producto.PRD_ID);
      producto.nombreProducto =
        proudctoAsociado && proudctoAsociado[0] && proudctoAsociado[0].PRD_NOMBRE
          ? proudctoAsociado[0].PRD_NOMBRE
          : 'Producto no encontrado';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.acumuladoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_ACUMULADO);
    producto.productividadVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_PROD_12H);
    producto.planificadoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_CANTIDAD);
    producto.robVisual = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      producto.PRV_ROB
    );

    try {
      producto.nombreMedida = 'Cargando ...';
      const res = await this.mainService.get(`api/medida?MED_ID=${producto.MED_ID}`).toPromise();

      producto.nombreMedida =
        res && res[0] && res[0].MED_NOMBRE
          ? res.filter((element) => element.MED_ID == producto.MED_ID)[0].MED_NOMBRE
          : 'Medida no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    } catch (err) {
      console.log(err);
    }

    producto.nombreTipoOperacion = 'Cargando ...';
    this.mainService.get(`api/operacion?TOP_ID=${producto.TOP_ID}`).subscribe((res) => {
      producto.nombreTipoOperacion =
        res && res[0] && res[0].TOP_NOMBRE ? res[0].TOP_NOMBRE : 'Tipo de operación no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.nombreTerminal = 'Cargando ...';
    this.mainService.get(`api/terminal?TER_ID=${producto.TERMINAL}`).subscribe((res) => {
      producto.nombreTerminal = res && res[0] && res[0].TER_NOMBRE ? res[0].TER_NOMBRE : 'Terminal no encontrada';
      this.productosDelViaje = this.productosDelViaje.slice();
    });

    producto.holdInfo = `Hold #${producto.HOLD_NUMBER}`;
  }

  /**
   * Inicializa el viaje en el formato requerido para el componente de reporte de prospectos
   */
  async inicializarViajeEnFormatoRequerido() {
    this.viaje.buqueNombre = 'Cargando ...';
    await this.obtenerNombresAsociacionesRelevantesViaje();
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de productividad, también trae los iconos e imagenes del buque
   */
  async obtenerNombresAsociacionesRelevantesViaje() {
    // Nombre del buque e imágenes
    if (this.viaje.vessel) {
      const res = await this.mainService.get(`api/buque?BUQ_ID=${this.viaje.vessel}`).toPromise();

      this.buqueCorreo = res.length > 0 ? res[0].BUQ_CORREO : undefined;
      this.viaje.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
      this.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
      this.iconoBuque = res.length > 0 ? res[0].BUQ_FOTO_ICONO : undefined;
      this.fotoBuque = res.length > 0 ? res[0].BUQ_FOTO_REAL : undefined;
    } else {
      this.viaje.buqueNombre = 'Not registered';
      this.buqueNombre = 'Not registered';
    }
  }

  /**
   * Trae los correos de los contactos asociados al viaje
   */
  obtenerCorreosYEmpresasAsociados() {
    this.emailsContactosViaje = [];
    return new Promise((resolve, reject) => {
      this.mainService.get(`api/empresa`).subscribe(async (areasEmpresas) => {
        this.areasEmpresas = areasEmpresas;
        await this.getContacts();
        resolve(true);
      });
    });
  }

  getContacts() {
    const arrayFinalSendgrid = [];
    return new Promise((resolve, reject) => {
      this.viaje.recieversShippersOthers.forEach((contacto) => {
        this.mainService.get(`api/contacto_empresa/areaEmpresa/${contacto.areaId}`).subscribe((contactosEmpresas) => {
          if (contactosEmpresas && contactosEmpresas[0]) {
            const posiblesEmpresas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreEmpresa).flat();
            const areasEmpresasFiltradas = posiblesEmpresas.filter((area, index) => {
              return posiblesEmpresas.indexOf(area) === index;
            });
            const posiblesAreas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreArea).flat();
            const areasAreasFiltradas = posiblesAreas.filter((area, index) => {
              return posiblesAreas.indexOf(area) === index;
            });
            for (let empresa of areasEmpresasFiltradas) {
              for (let area of areasAreasFiltradas) {
                const correos = contactosEmpresas
                  .filter((conEmpresa) => {
                    return conEmpresa.nombreEmpresa[0] === empresa && conEmpresa.nombreArea[0] === area;
                  })
                  .map((contacto) => {
                    return { email: contacto.correo, nombre: contacto.nombre };
                  });
                const empresaAreaContactos = {
                  empresa,
                  area,
                  correos,
                };
                arrayFinalSendgrid.push(empresaAreaContactos);
                this.empresasAreasConCorreos = arrayFinalSendgrid;
              }
            }
            resolve(true);
          }
        });
      });
    });
  }

  /**
   * Agrega los emails de un área a la lista de emails a mandar por sendgrid
   */
  addEmails(area, event) {
    if (event.target.checked) {
      this.emailsContactosViaje = this.emailsContactosViaje.includes(area.correos)
        ? this.emailsContactosViaje
        : this.emailsContactosViaje.concat(area);
    } else {
      this.emailsContactosViaje = this.emailsContactosViaje.filter((areaAgregada) => {
        return !area.correos.includes(...areaAgregada.correos);
      });
    }
  }

  /**
   * Añade o elimina de la lista de destinatarios el correo del buque
   */
  public addedVesselEmail = false;
  addBuqueCorreo(event) {
    const contactoBuque = {
      area: undefined,
      empresa: this.viaje.buqueNombre,
      correos: [
        {
          email: this.buqueCorreo,
          nombre: this.viaje.buqueNombre,
        },
      ],
    };
    if (event.target.checked) {
      this.addedVesselEmail = true;
      const existeContacto = this.emailsContactosViaje.find((contactoAgregado) => {
        return contactoAgregado.empresa === this.viaje.buqueNombre;
      });
      if (!existeContacto) {
        this.emailsContactosViaje.push(contactoBuque);
      }
    } else {
      this.addedVesselEmail = true;
      this.emailsContactosViaje = this.emailsContactosViaje.filter((contactoAgregado) => {
        return contactoAgregado.empresa != this.viaje.buqueNombre;
      });
    }
  }

  /**
   * Abre el formulario para agregar un producto
   */
  onAgregarProducto(): void {
    this.productoViaje = null;
    this.modoProducto = this.modosPosiblesDetalles[0];
    this.abrirDetalleProductoViaje(this.productoDetalle);
  }

  onShowModalInformationOPSTime() {
    Swal.fire({
      title: 'Completion OPS time establecido, no puede eliminar información',
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
    });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarProducto(event);
        break;
      case 'editar':
        this.editarProducto(event);
        break;
    }
  }

  /**
   * Borra un producto de la BD
   */
  borrarProducto(event) {
    if (this.viaje.portLog.completionOPSTime.fecha) {
      this.onShowModalInformationOPSTime();
      return;
    }
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el producto?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete('api/producto_completo/' + event.data._id).subscribe((res) => {
            if (res) {
              Swal.fire('Éxito', 'Producto eliminado con éxito', 'success');
              this.inicializarDatos();
            }
            this.viaje.productos = this.viaje.productos.filter((producto) => producto != event.data._id);

            this.mainService.put(`api/viaje/${this.viaje._id}`, this.viaje).subscribe((result) => {});
          });
        }
      });
    }
  }

  /**
   * Inicializa la edicion de un producto
   * @param event Seleccion del producto que se quiere editar
   */
  editarProducto(event) {
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.productoViaje = event.data;
      this.modoProducto = this.modosPosiblesDetalles[1];
      this.abrirDetalleProductoViaje(this.productoDetalle);
    }
  }

  /**
   * Abre el detalle del producto viaje
   * @param dialog Referencia al dialogo del detalle
   */
  abrirDetalleProductoViaje(dialog) {
    this.dialogRefProducto = this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    });
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia
   */
  eliminarContactoCopia(index: number): void {
    this.ccs.splice(index, 1);
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia oculta
   */
  eliminarContactoCopiaOculta(index: number): void {
    this.bccs.splice(index, 1);
  }

  /**
   * Inicializa la gráfica de las tasas de productividad
   * @param productos Productos en la BD
   */
  inicializarGraficaTasasDeProductividad(productos: any): void {
    const datosTasasDeProductividadPorProductoPorDia = this.contarTasasDeProductividadPorDia(productos);

    if (datosTasasDeProductividadPorProductoPorDia) {
      const grafica = this.graficaTasasProductividad.nativeElement.getContext('2d');

      new Chart(grafica, {
        type: 'bar',
        data: {
          labels: datosTasasDeProductividadPorProductoPorDia.labels,
          datasets: datosTasasDeProductividadPorProductoPorDia.datasets,
        },
        options: {
          title: {
            display: true,
            text: 'Rates by Product',
            fontSize: 18,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    this.infoGraficaTasasProductividad.datosCargados = true;
  }

  /**
   * Inicializa la gráfica de productividad por produco
   * @param productos Productos en la BD
   */
  inicializarGraficaProductividadPorProducto(productos: any): void {
    const datosProductividadPorProductoPorBodega = this.contarProductividadPorProductoPorBodega(productos);

    if (datosProductividadPorProductoPorBodega) {
      const grafica = this.graficaProductividadPorProducto.nativeElement.getContext('2d');

      new Chart(grafica, {
        type: 'bar',
        data: {
          labels: datosProductividadPorProductoPorBodega.labels,
          datasets: datosProductividadPorProductoPorBodega.datasets,
        },
        options: {
          title: {
            display: true,
            text: 'Productivity by Product',
            fontSize: 18,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    this.infoGraficaProductividadPorProducto.datosCargados = true;
  }

  /**
   * Cuenta las horas de productividad por producto por bodega
   * @param productos Productos en la BD
   * @return Objeto que tiene las horas de productividad por producto por bodega en el formato de Chart JS
   */
  contarProductividadPorProductoPorBodega(productos: any): any {
    if (this.productosDelViaje.length > 0) {
      this.infoGraficaProductividadPorProducto.graficaTieneDatos = true;

      // Ordenar productividad por fecha
      const productividadesOrdenadas: any = this.productosDelViaje.sort((a, b) => {
        Number(b.HOLD_NUMBER) - Number(a.HOLD_NUMBER);
      });

      // Obtener los productos
      const cantidadPorProducto = _.countBy(productividadesOrdenadas, 'PRD_ID');
      let idProductos = [];
      for (var key in cantidadPorProducto) {
        idProductos.push(key);
      }

      let nombresDataSets = [];
      idProductos.forEach((idProducto) => {
        const proudctoAsociado = productos.find((producto) => producto.PRD_ID == idProducto);
        if (proudctoAsociado !== undefined) {
          nombresDataSets.push(`${proudctoAsociado.PRD_NOMBRE} - Productivity`);
          nombresDataSets.push(`${proudctoAsociado.PRD_NOMBRE} - Planified`);
        }
      });

      // Separar productividades por bodegas
      const productividadesPorBodega: any = _.groupBy(productividadesOrdenadas, 'holdInfo');
      productividadesPorBodega;

      // Separar y contar productividades por producto y bodega
      let labels = [];
      let dataSets = [];

      idProductos.forEach((idProducto, index) => {
        let productividadProducto = [];
        let planificadoProducto = [];
        labels = [];

        for (const infoBodega in productividadesPorBodega) {
          labels.push(infoBodega);

          let productividadProductoBodega = productividadesPorBodega[infoBodega].filter(
            (productividad) => productividad.PRD_ID == idProducto
          );
          productividadProducto.push(this.sumarProductividades(productividadProductoBodega));
          planificadoProducto.push(this.sumarProductividadesPlanificadas(productividadProductoBodega));
        }

        dataSets.push({
          label: nombresDataSets[index * 2],
          data: productividadProducto,
          backgroundColor: this.obtenerColorUnico((index+1) * 2),
        });
        dataSets.push({
          label: nombresDataSets[index * 2 + 1],
          data: planificadoProducto,
          backgroundColor: this.obtenerColorUnico((index+1) * 2 + 1),
        });
      });

      return {
        labels: labels,
        datasets: dataSets,
      };
    } else return null;
  }

  /**
   * Cuenta las tasas de productividad por dia
   * @param productos Productos en la BD
   * @return Objeto que tiene las tasas de productividad por dia por bodega en el formato de Chart JS
   */
  contarTasasDeProductividadPorDia(productos: any): any {
    if (this.productosDelViaje.length > 0) {
      this.infoGraficaTasasProductividad.graficaTieneDatos = true;

      // Ordenar productividad por fecha
      let productividadesOrdenadas: any = this.productosDelViaje.sort((a, b) => {
        return new Date(b.PRV__TIME_TO).getTime() - new Date(a.PRV__TIME_TO).getTime();
      });

      // Poner fecha visual a productividades y tiempo entre productividad
      productividadesOrdenadas.forEach((productividad) => {
        const partesFechaFormatoFormulario: string[] = this.dateAndTimeService
          .convertirFechaAFormatoFormulario(productividad.PRV__TIME_TO)
          .split('-');
        const mesVisual: string = this.dateAndTimeService.obtenerAbreviacionMesEnInglesPorNumero(
          Number(partesFechaFormatoFormulario[1])
        );
        productividad.fechaVisual = `${partesFechaFormatoFormulario[2]}-${mesVisual}`;

        productividad.horas =
          (new Date(productividad.PRV__TIME_TO).getTime() - new Date(productividad.PRV__TIME_FROM).getTime()) / 3600000;
      });
      productividadesOrdenadas = _.orderBy(productividadesOrdenadas, 'fechaVisual');

      // Obtener los productos
      const cantidadPorProducto = _.countBy(productividadesOrdenadas, 'PRD_ID');
      let idProductos = [];
      for (var key in cantidadPorProducto) {
        idProductos.push(key);
      }

      let nombresDataSets = [];
      idProductos.forEach((idProducto) => {
        const proudctoAsociado = productos.find((producto) => producto.PRD_ID == idProducto);
        if (proudctoAsociado !== undefined) {
          nombresDataSets.push(proudctoAsociado.PRD_NOMBRE);
        }
      });

      // Separar productividades por fechas
      let productividadesPorBodega: any = _.groupBy(productividadesOrdenadas, 'fechaVisual');

      // Separar y contar tasas de productividades por producto y fecha
      let labels = [];
      let dataSets = [];

      idProductos.forEach((idProducto, index) => {
        let tasasProductividad: number[] = [];
        labels = [];

        for (const fecha in productividadesPorBodega) {
          labels.push(fecha);

          let productividadProductoFecha = productividadesPorBodega[fecha].filter(
            (productividad) => productividad.PRD_ID == idProducto
          );
          tasasProductividad.push(this.sumarTasasProductividades(productividadProductoFecha));
        }

        dataSets.push({
          label: nombresDataSets[index],
          data: tasasProductividad,
          backgroundColor: this.obtenerColorUnico((index+1) * 3),
        });
      });

      return {
        labels: labels,
        datasets: dataSets,
      };
    } else return null;
  }

  /**
   * Da un color unico dependiendo del numero ingresado
   * @param n Numero del cual se quiere obtener el color unico
   * @returns String con el valor hexadecimal del color unico
   */
  obtenerColorUnico(n: number) {
    if (n === 0) n = 1
    const pastelRgb = [0, 0, 0];

    for (let i = 0; i < 3; i++) {
        pastelRgb[i] = Math.random() * 0.5 + 0.5; // Genera un valor aleatorio entre 0.5 y 1
    }

    const clampedPastelRgb = pastelRgb.map(value => Math.min(Math.round(value * 255), 255));
    return '#' + clampedPastelRgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '');
  }

  /**
   * Suma las tasas de productividades de un grupo de productos de viaje
   * @param productos Grupo de productos de un viaje
   * @return Suma de las tasas productividades de los productos entrados por parametro
   */
  sumarTasasProductividades(productos: any): number {
    let suma = 0;

    productos.forEach((producto) => (suma += Number((producto.PRV_PROD_12H / producto.horas).toFixed(2))));

    return suma;
  }

  /**
   * Suma las productividades de un grupo de productos de viaje
   * @param productos Grupo de productos de un viaje
   * @return Suma de las productividades de los productos entrados por parametro
   */
  sumarProductividades(productos: any): number {
    let suma = 0;

    productos.forEach((producto) => (suma += producto.PRV_PROD_12H));

    return suma;
  }

  /**
   * Suma las productividades planificadas de un grupo de productos de viaje
   * @param productos Grupo de productos de un viaje
   * @return Suma de las productividades planificadas de los productos entrados por parametro
   */
  sumarProductividadesPlanificadas(productos: any): number {
    let suma = 0;

    productos.forEach((producto) => (suma += producto.PRV_CANTIDAD));

    return suma;
  }

  /**
   * Inicializa la gráfica de horas paradas
   */
  inicializarGraficaHorasParadas(): void {
    const datosHorasParadas = this.contarHorasParadasPorRazones();
    if (datosHorasParadas) {
      let labels = [];
      let dataSets = [];
      for (var key in datosHorasParadas) {
        let variables = datosHorasParadas[key];
        labels.push(key);
        dataSets.push(variables);
      }

      const grafica = this.graficaHorasParadas.nativeElement.getContext('2d');
      const horasParadas = {
        label: 'STOPPED HOURS',
        data: dataSets,
        backgroundColor: this.obtenerColorUnico(46),
      };

      new Chart(grafica, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [horasParadas],
        },
        options: {
          title: {
            display: true,
            text: 'Stopped Hours',
            fontSize: 18,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    this.infoGraficaHorasParadas.datosCargados = true;
  }

  /**
   * Inicializa la gráfica de horas trabajadas contra las horas paradas por día
   */
  inicializarGraficaHorasTrabajadasVsParadas(): void {
    const datosHorasTrabajadasVsParadas = this.contarHorasTrabajadasVsParadasPorDias();
    if (datosHorasTrabajadasVsParadas) {
      const grafica = this.graficaHorasTrabajadasVsParadas.nativeElement.getContext('2d');

      new Chart(grafica, {
        type: 'bar',
        data: {
          labels: datosHorasTrabajadasVsParadas.labels,
          datasets: datosHorasTrabajadasVsParadas.datasets,
        },
        options: {
          title: {
            display: true,
            text: 'Worked & Stopped Hours',
            fontSize: 18,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    this.infoGraficaHorasTrabajadasVsParadas.datosCargados = true;
  }

  /**
   * Cuenta las horas paradas por razones
   * @return Objeto que tiene las horas paradas por razones
   */
  contarHorasParadasPorRazones(): any {
    if (this.sofViaje && this.sofViaje.history && this.sofViaje.history.length > 0) {
      this.infoGraficaHorasParadas.graficaTieneDatos = true;

      const todasLasActividadesDeHorasParadasConfirmadas = this.sofViaje.history.filter((actividad) => {
        return (
          actividad.status == 'Confirmed' &&
          (actividad.category == 'STOPPED OPERATIONS' || actividad.category == 'STOPPED HOURS')
        );
      });
      const actividadesParadasPorLluvia = todasLasActividadesDeHorasParadasConfirmadas.filter(
        (actividad) => actividad.type.includes('DUE TO RAIN') || actividad.type.includes('STOPPED BY RAINS')
      );
      const actividadesParadasPorOtrasRazones = todasLasActividadesDeHorasParadasConfirmadas.filter(
        (actividad) => !actividad.type.includes('DUE TO RAIN') && !actividad.type.includes('STOPPED BY RAINS')
      );

      return {
        'STOPPED BY RAINS': this.contarHorasGrupoDeActividades(actividadesParadasPorLluvia),
        'STOPPED BY OTHER REASONS': this.contarHorasGrupoDeActividades(actividadesParadasPorOtrasRazones),
      };
    } else {
      return null;
    }
  }

  /**
   * Cuenta las horas paradas y las horas trabajadas por día
   * @return Objeto que tiene las horas trabajadas y paradas en el formato de Chart JS
   */
  contarHorasTrabajadasVsParadasPorDias(): any {
    if (this.sofViaje && this.sofViaje.history && this.sofViaje.history.length > 0) {
      this.infoGraficaHorasTrabajadasVsParadas.graficaTieneDatos = true;

      let labels = [];
      let horasTrabajadasPorFecha: any = [];
      let horasParadasPorFecha: any = [];

      // Ordenar eventos por fecha de menor a mayor
      const todasLasActividadesConfirmadas = this.sofViaje.history.filter(
        (actividad) => actividad.status == 'Confirmed'
      );
      const todasLasActividadesOrdenadas: any = todasLasActividadesConfirmadas.sort((a, b) => {
        new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      // Poner fecha visual a eventos
      todasLasActividadesOrdenadas.forEach((actividad) => {
        const partesFechaFormatoFormulario: string[] = this.dateAndTimeService
          .convertirFechaAFormatoFormulario(actividad.date)
          .split('-');
        const mesVisual: string = this.dateAndTimeService.obtenerAbreviacionMesEnInglesPorNumero(
          Number(partesFechaFormatoFormulario[1])
        );
        actividad.fechaVisual = `${partesFechaFormatoFormulario[2]}-${mesVisual}`;
      });

      // Separar actividades por fechas
      const actividadesPorFechas: any = _.groupBy(todasLasActividadesOrdenadas, 'fechaVisual');

      // Separar y contar horas trabajadas y horas paradas
      for (const fechaActividades in actividadesPorFechas) {
        labels.push(fechaActividades);

        const actividadesTrabajadasFecha = actividadesPorFechas[fechaActividades].filter(
          (actividad) => actividad.hoursType == 'Worked'
        );
        const actividadesParadasFecha = actividadesPorFechas[fechaActividades].filter(
          (actividad) => actividad.hoursType == 'Stopped'
        );

        horasTrabajadasPorFecha.push(this.contarHorasGrupoDeActividades(actividadesTrabajadasFecha));
        horasParadasPorFecha.push(this.contarHorasGrupoDeActividades(actividadesParadasFecha));
      }

      return {
        labels: labels,
        datasets: [
          {
            label: 'WORKED HOURS',
            data: horasTrabajadasPorFecha,
            backgroundColor: this.obtenerColorUnico(25),
          },
          {
            label: 'STOPPED HOURS',
            data: horasParadasPorFecha,
            backgroundColor: this.obtenerColorUnico(36),
          },
        ],
      };
    } else return null;
  }

  /**
   * Cuenta las horas de un grupo de actividades
   * @param actividades Grupo de actividades de las que se quiere contar sus horas
   * @return Total de horas entre las actividades ingresadas por parametro
   */
  contarHorasGrupoDeActividades(actividades): number {
    let totalHoras: number = 0;

    actividades.forEach((actividad) => {
      const partesHoraComienzo: string[] = actividad.from.split(':');
      const partesHoraFin: string[] = actividad.to.split(':');

      const comienzo: number = Number(partesHoraComienzo[0]) + Number(partesHoraComienzo[1]) / 60;
      const fin: number = Number(partesHoraFin[0]) + Number(partesHoraFin[1]) / 60;

      totalHoras += fin - comienzo;
    });

    return totalHoras;
  }

  /**
   * Descarga las graficas de productividad en formato PDF
   */
  onDescargarGraficas() {
    const graficaProductividadPorProducto = this.graficaProductividadPorProducto.nativeElement;
    const graficaTasasProductividad = this.graficaTasasProductividad.nativeElement;
    const graficaHorasTrabajadasVsParadas = this.graficaHorasTrabajadasVsParadas.nativeElement;
    const graficaHorasParadas = this.graficaHorasParadas.nativeElement;

    const imgGraficaProductividadPorProducto = graficaProductividadPorProducto.toDataURL('image/png', 1.0);
    const imgGraficaTasasProductividad = graficaTasasProductividad.toDataURL('image/png', 1.0);
    const imgGraficaHorasTrabajadasVsParadas = graficaHorasTrabajadasVsParadas.toDataURL('image/png', 1.0);
    const imgGraficaHorasParadas = graficaHorasParadas.toDataURL('image/png', 1.0);

    let pdf = new jsPDF('landscape');
    pdf.addImage(imgGraficaProductividadPorProducto, 'JPEG', 10, 20, 280, 150);
    pdf.addPage();
    pdf.addImage(imgGraficaTasasProductividad, 'JPEG', 10, 20, 280, 150);
    pdf.addPage();
    pdf.addImage(imgGraficaHorasTrabajadasVsParadas, 'JPEG', 10, 20, 280, 150);
    pdf.addPage();
    pdf.addImage(imgGraficaHorasParadas, 'JPEG', 10, 20, 280, 150);
    pdf.save('productivity-graphs.pdf');
  }

  public selectedSubject: string;
  public setSubject(subjectSelected?: string) {
    this.formulario.patchValue({
      asuntoReporte: subjectSelected || this.selectedSubject,
    });

    this.selectedSubject = subjectSelected || this.selectedSubject;
  }

  public deaultSubjects: string[] = [];
  public setDefaultSubjects() {
    const nv = this.viaje.nv;
    const vesselName = this.viaje.buqueNombre;
    const portName = this.viaje.puertoNombre;
    const sentReports = this.viaje.recalada.reportesEnviados.length;

    const voyageNumber = this.viaje.voyageNumber ? `${this.viaje.voyageNumber + ' -'}` : '';

    this.deaultSubjects.push(`MV ${vesselName} ${nv} - ${portName} - PRODUCTIVITY REPORT No. ${sentReports}`);
    this.selectedSubject = `MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - PRODUCTIVITY REPORT No. ${sentReports}`;
    this.setSubject(this.formulario.value.asuntoReporte);
  }

  /**
   * Reacciona al evento de guardar cambios
   */
  onGuardarCambios() {
    Swal.fire({
      title: 'Se guardaron los datos exitosamente',
      type: 'success',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
    });
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.router.navigate([`home/reportes/${this.viaje._id}/${this.viaje.recalada._id}`]);
      }
    });
  }

  /**
   * Alista los datos para enviarlos a la BD
   */
  onGuardarReporte({ vistaPrev = false, enviarCorreo = false }): void {
    this.vistaPrevia = vistaPrev;
    this.enviarCorreo = enviarCorreo;
    this.cargando = true;

    /** Objeto con los datos de salida para guardar en la BD */
    let datosSalida = {
      remark: null,
    };

    // Remark
    if (this.formulario.value.remark) {
      datosSalida.remark = this.formulario.value.remark;
    }

    this.enviarDatosABD(datosSalida);
  }

  /**
   * Envia los datos ingresados por el usaurio a la BD
   * @param datosSalida Datos que van a persistir
   */
  enviarDatosABD(datosSalida): void {
    this.mainService
      .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, datosSalida)
      .subscribe(async () => {
        await this.inicializarDatos();

        this.viaje.commenceOpsVisual =
          this.viaje &&
          this.viaje.portLog &&
          this.viaje.portLog.commenceOPS &&
          this.viaje.portLog.commenceOPS.fecha &&
          this.viaje.portLog.commenceOPS.status == 'CONFIRMED'
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.portLog.commenceOPS.fecha)
            : 'Not registered';
        this.viaje.etcVisual =
          this.viaje && this.viaje.etc
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
            : 'Not registered';
        this.viaje.etdVisual =
          this.viaje && this.viaje.etd
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
            : 'Not registered';

        this.cargando = false;

        if (!this.enviarCorreo && !this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente');
        }

        if (this.enviarCorreo) {
          this.onEnviarCorreoReporte();
          this.enviarCorreo = false;
          return;
        }

        if (this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente').then((result) => {
            this.dialogService
              .open(VistaPreviaReporteProductividadComponent, {
                context: {
                  companyName: this.empresasAreasConCorreos.length !== 0 && this.empresasAreasConCorreos[0].empresa,
                  asunto: this.formulario.value.asuntoReporte,
                  attachments: this.archivosAdjuntos,
                  parrafosRemarks: this.formulario.value.remark,
                  imagenBuque: this.fotoBuque,
                  iconBuque: this.iconoBuque,
                  correosCopia: this.ccs.map((cc) => cc.email),
                  correosCopiaOculta: this.bccs.map((bcc) => bcc.email),
                  showIncludeProductivityDetails: this.showIncludeProductivityDetails,
                  showIncludeStoppedHours: this.showIncludeStoppedHours,
                  showSOFActivities: this.showSOFActivities,
                  sofConfirmadas: this.arregloOrdenadoActividadesSofConfirmadas,
                  sofEstimadas: this.arregloOrdenadoActividadesSofEstimadas,
                  arregloOrdenadoActividadesEstimadas: this.arregloOrdenadoActividadesEstimadas,
                  arregloOrdenadoActividadesConfirmadas: this.arregloOrdenadoActividadesConfirmadas,
                  stoppedHoursReport: this.stoppedHoursReport,
                  showVessel: this.showVessel,
                  incluirActividades: this.incluirActividades,
                  calculatedProductivityDetials: this.calculatedProductivityDetials,
                },
              })
              .onClose.subscribe((act) => {
                this.vistaPrevia = false;
                this.inicializarDatos();
              });
          });

          return;
        }

        this.vistaPrevia = false;
        this.enviarCorreo = false;

        return;
      });
  }

  /**
   * Agrega un nuevo correo a la lista de correos con copia o con copia oculta
   */
  agregarExtraCorreo(extra, lista) {
    if (extra.valid) {
      if (
        this.ccs.find((cc) => cc.email.toLowerCase() === extra.value.toLowerCase()) ||
        this.bccs.find((bcc) => bcc.email.toLowerCase() === extra.value.toLowerCase())
      ) {
        Swal.fire({
          title: '¡Error!',
          text: 'No se puede agregar un correo que ya se encuentre en la lista.',
          type: 'error',
        });
        return;
      }
      if (lista === 'cc') {
        this.ccs.push({ email: extra.value });
        this.nuevoCC.setValue('');
      } else {
        this.bccs.push({ email: extra.value });
        this.nuevoBCC.setValue('');
      }
    }
  }

  /**
   * Maneja la seleccion de archivos a adjuntar
   */
  onSeleccionarArchivoAAdjuntar(event) {
    const maxSizeInMb = 15;
    this.archivosAdjuntosCargandose = true;
    const archivoSeleccionado = event.target.files[0];
    if (archivoSeleccionado) {
      const fileSizeInMb = archivoSeleccionado.size / (1024 * 1024);
      // Obtener la información necesaria
      if (fileSizeInMb > maxSizeInMb) {
        Swal.fire({
          title: 'El archivo excede el tamaño máximo permitido de 15 MB.',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.archivosAdjuntosCargandose = false;
      } else {
        const { params, options } = this.s3Service.getInfoForUpload('adjuntoCorreos', archivoSeleccionado);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if (err) {
            this.s3Service.showErrorUploading();
          } else {
            this.documentURL = data.Location.toString();
            this.reportesService
              .archivoABase64(event.target.files[0])
              .then((result) => {
                const nuevoArchivo = {
                  content: result,
                  filename: event.target.files[0].name,
                  type: 'application/pdf',
                  disposition: 'attachment',
                  path: this.documentURL,
                };
                this.archivosAdjuntos.push(nuevoArchivo);
                this.archivosAdjuntosCargandose = false;
                this.archjivoAAdjuntar.nativeElement.value = '';
              })
              .catch(() => {
                Swal.fire({
                  title: 'Se generó un problema al adjuntar el archivo',
                  type: 'error',
                  showCancelButton: false,
                  confirmButtonText: 'Continuar',
                });
              });
          }
        });
      }
    }
  }

  /**
   * Borra un arhivo de los archivos adjuntos
   * @param indiceArchivo Indice del archivo en los archivos adjuntos
   */
  onBorrarArchivoAdjunto(indiceArchivo: number): void {
    this.archivosAdjuntos.splice(indiceArchivo, 1);
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;
  }

  public showIncludeProductivityDetails = false;
  public includeProductivityDetails() {
    this.showIncludeProductivityDetails = !this.showIncludeProductivityDetails;
  }

  public showIncludeStoppedHours = false;
  public includeStoppedHours() {
    this.showIncludeStoppedHours = !this.showIncludeStoppedHours;
    this.generateStoppedHoursReport();
  }

  public showVessel = false;
  public includeVesselGraphic() {
    this.showVessel = !this.showVessel;
  }

  private stoppedHoursReport = [];
  public async generateStoppedHoursReport() {
    this.stoppedHoursReport = [];

    const lastActiveProduct = this.viaje.recalada.productos.find((product) => product.activo);
    if (!lastActiveProduct) return;
    const productFromTime = lastActiveProduct.PRV__TIME_FROM;
    const productToTime = lastActiveProduct.PRV__TIME_TO;

    this.activitiesStoppedOperations.forEach((activity) => {
      const fromDate = this.timeService.combineDatesAndHours(activity.fecha, activity.from);
      const toDate = this.timeService.combineDatesAndHours(activity.fecha, activity.to);

      const fromWithinRange = this.filterRecordsByDateRange(fromDate, productFromTime, productToTime);
      const toWithinRange = this.filterRecordsByDateRange(toDate, productFromTime, productToTime);

      const totalStoppedActivity = this.totalStoppedHours.totalHoursByType[activity.type];
      if (!totalStoppedActivity) return;

      if (fromWithinRange && toWithinRange) {
        let time = this.timeService.diffDateInHoursMinutes(fromDate, toDate);

        let restHours = this.timeService.diffDateInHoursMinutes(fromDate, productFromTime);
        if (restHours.hours > 0) time = this.timeService.minusHoursAndMinutes(time, restHours);

        restHours = this.timeService.diffDateInHoursMinutes(productToTime, toDate);
        if (restHours.hours > 0) time = this.timeService.minusHoursAndMinutes(time, restHours);

        totalStoppedActivity.report = this.timeService.formatHoursMinutesToString(time.hours, time.minutes);
      }

      totalStoppedActivity.diffHours = this.timeService.formatHoursMinutesToString(
        totalStoppedActivity.hours,
        totalStoppedActivity.minutes
      );

      if (this.stoppedHoursReport.some((activity) => activity.type === totalStoppedActivity.type)) return;
      this.stoppedHoursReport.push(totalStoppedActivity);
    });
  }

  filterRecordsByDateRange(recordDate, startDateRange, endDateRange) {
    const dateToCheck = moment.utc(recordDate).startOf('day');
    const startDate = moment.utc(startDateRange).startOf('day');
    const endDate = moment.utc(endDateRange).endOf('day');

    return dateToCheck.isBetween(startDate, endDate, null, '[]');
  }

  /**
   * Activa el modal para guardar cambios antes de ir a la vista previa
   */
  activarModalVistaPrevia() {
    this.generateStoppedHoursReport();
    this.openDialog = this.dialogService.open(this.guardarVistaPrevia, { context: '' });
  }

  /**
   * Redirigue a pagina con la visa previa del correo
   */
  onIrVistaPreviaCorreo() {
    this.openDialog.close();
    this.onGuardarReporte({ vistaPrev: true });
  }

  /**
   * Procede a guardar y posteriormente a enviar el correo
   */
  onGuardarInfoEnviarCorreo() {
    this.onGuardarReporte({ enviarCorreo: true });
  }

  /**
   * Obtiene las actividades confirmadas y estimadas ordenadas cronologicamente
   */
  obtenerActividadesEstimadasYConfirmadasOrdenadas() {
    if (Object.keys(this.viaje.portLog).length > 0) {
      let activdadesOrdenadas = [];
      for (const property in this.viaje.portLog) {
        if (this.viaje.portLog[property].fecha) {
          let actividad = {
            nombre: this.viaje.portLog[property].nombre,
            fecha: this.viaje.portLog[property].fecha,
            status: this.viaje.portLog[property].status,
            fechaVisual: '',
          };

          activdadesOrdenadas.push(actividad);
        }
      }

      activdadesOrdenadas.forEach((actividad) => {
        actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
      });

      activdadesOrdenadas.sort(function (a, b) {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

      this.arregloOrdenadoActividadesConfirmadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'CONFIRMED'
      );
      this.arregloOrdenadoActividadesEstimadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'ESTIMATED'
      );
    } else {
      this.arregloOrdenadoActividadesConfirmadas = [];
      this.arregloOrdenadoActividadesEstimadas = [];
    }
  }

  /**
   * Envia el correo con el repore
   */
  async onEnviarCorreoReporte() {
    this.cargando = true;

    if (this.encontrarErroresEnvioCorreo() == this.erroresEnvioCorreo[0]) {
      this.enviandoCorreo = true;
      const emailsToSend = [];

      const emails = [];

      for (let destinatario of this.emailsContactosViaje) {
        delete destinatario.nombre;

        const html = await this.generarHTMLCorreo(destinatario.empresa);

        const msg = {
          to: destinatario.correos,
          cc: this.ccs,
          bcc: this.bccs,
          html: html,
          attachments: this.archivosAdjuntos,
          fromEmail: this.emailPuerto,
          subject: this.formulario.value.asuntoReporte,
        };

        emails.push(msg);
      }

      this.mainService.post(`api/sendEmail`, { emails, puerto: this.viaje.puertoNombre }).subscribe(async (result) => {
        this.emailsContactosViaje = [];
        this.enviandoCorreo = false;
        this.addedVesselEmail = false;
        const reporteEnviado = {
          tipoReporte: 'PROSPECTOS',
          fechaDeEnvio: Date.now(),
        };

        if (result.success) {
          this.viaje.reportesEnviados
            ? this.viaje.reportesEnviados.push(reporteEnviado) // updateding problem
            : (this.viaje.reportesEnviados = [reporteEnviado]);

          this.mainService
            .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, {
              reporteEnviado,
              statusExtra: this.viaje.statusExtra,
            })
            .subscribe((res) => {
              this.alertService.simpleAlertConfirm('Reporte enviado y datos guardados exitosamente');
              this.inicializarDatos();
            });
        } else {
          Swal.fire({
            title: 'Se generó un problema al enviar el correo',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });
          this.cargando = false;
        }

        await this.obtenerCorreosYEmpresasAsociados();
      });
    }
  }

  public productsByHolds = {
    H1: {
      hold: 'H1',
      products: [],
    },
    H2: {
      hold: 'H2',
      products: [],
    },
    H3: {
      hold: 'H3',
      products: [],
    },
    H4: {
      hold: 'H4',
      products: [],
    },
    H5: {
      hold: 'H5',
      products: [],
    },
    H6: {
      hold: 'H6',
      products: [],
    },
  };
  public productsForView = Object.values(this.productsByHolds).reverse();

  async generarHTMLCorreo(companyName: string) {
    console.log('Lista Final', this.productosDelViajeForTable);
    const spacerItems = '<br><br>';
    const spacerItem = '<br>';
    const spacerHeader = '<br><br><br>';

    for (let archivoAdjunto of this.archivosAdjuntos) {
      delete archivoAdjunto.path;
    }

    let imagenBuque: string;
    if ((this.fotoBuque && this.fotoBuque != 'NULL') || (this.iconoBuque && this.iconoBuque != 'NULL')) {
      imagenBuque = this.fotoBuque || this.iconoBuque;
    }

    let html = `<!DOCTYPE html>
		<html lang="en">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
		</head>
		<body style="color:black !important;">`;

    html += '<table style="border-collapse: collapse;">';

    // header
    if (companyName) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TO:</td><td>${companyName}</td></tr>`;
    }

    if (this.viaje.commodityNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">CARGO:</td><td>${this.viaje.commodityNombre}</td></tr>`;
    }

    if (this.viaje.buqueNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">VESSEL:</td><td>${
        this.viaje.buqueNombre
      } ${this.viaje.voyageNumber? this.viaje.voyageNumber: '' && '- ' + this.viaje.voyageNumber} - ${this.viaje.nv}</td></tr>`;
    }

    if (this.viaje.terminalNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TERMINAL:</td><td>${this.viaje.terminalNombre}</td></tr>`;
    }

    if (this.viaje.puertoNombre) {
      html += `<tr><td style="padding-right: 0.5rem">PORT:</td><td>${this.viaje.puertoNombre}</td></tr>`;
    }

    html += '</table>';
    html += spacerHeader;

    // image

    if (this.iconoBuque && this.iconoBuque != 'NULL') {
      html += `<img src=${this.iconoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    if (this.fotoBuque && this.fotoBuque != 'NULL') {
      html += `<img src=${this.fotoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    html += '<p style="color:black!important; margin: 0 !important;">Please find bellow the productivity report</p>';
    html += spacerItem;
    html +=
      '<table style="border-collapse: collapse;">' +
      `<tr><th style="text-align: center; border: 1px solid; padding: 10px" colspan="4">PERIOD OF PRODUCTIVITY</th><th style="text-align: center; border: 1px solid; padding: 10px" colspan="3">${
        this.productividadTotal + ' Hrs'
      }</th></tr>` +
      '<tr>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">PRODUCT</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">AMOUNT</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">HATCH</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">MEASURE</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">PRODUCTIVITY</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">ACUMULATED</th>' +
      '<th style="text-align: center; border: 1px solid; padding: 10px">ROB</th>' +
      '</tr>';
    
    this.productosDelViajeForTable.forEach((producto: any) => {
      html +=
        '<tr>' +
        `<td style="padding: 10px; border: 1px solid">${producto.nombreProducto}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.planificadoVisual}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.holdInfo}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.nombreMedida}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.productividadVisual}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.acumuladoVisual}</td>` +
        `<td style="padding: 10px; border: 1px solid">${producto.robVisual}</td>` +
        '</tr>';
        this.sumatoria.agregarMedida({ tipo: producto.nombreMedida, totalAmount: producto.PRV_CANTIDAD, totalProductivity: producto.PRV_PROD_12H, 
          totalAcumulated: producto.PRV_ACUMULADO, totalROB: producto.PRV_ROB })
    });
    const totalesxmedida = this.sumatoria.obtenerMedidas();
    for (let [key, item] of Object.entries(totalesxmedida)) {
      html +=
      '<tr>' +
      `<td style="padding: 10px; border: 1px solid"></td>` +
      `<td style="padding: 10px; border: 1px solid">${item.totalAmount || 0}</td>` +
      `<td style="padding: 10px; border: 1px solid"></td>` +
      `<td style="padding: 10px; border: 1px solid">${key}</td>` +
      `<td style="padding: 10px; border: 1px solid">${item.totalProductivity || 0}</td>` +
      `<td style="padding: 10px; border: 1px solid">${item.totalAcumulated || 0}</td>` +
      `<td style="padding: 10px; border: 1px solid">${item.totalROB || 0}</td>` +
      '</tr>';
    }

    html += '</table>';
    html += spacerItems;


    if (this.showVessel) {
      const imageBase64 = await this.generateVesselImage();
      const urlImage = await this.uploadBase64Image(imageBase64);
      html += `<img src="${urlImage}" alt="vessel with information about load" >`;
      html += spacerItems;
    }

    html += '<table style="table-layout:fixed;max-width:400px!important;"><tbody>';
    if (this.viaje && this.viaje.commenceOpsVisual)
      html += `<tr><td style="text-align:left!important;font-weight:400!important;">COMMENCED OPS</td><td style="text-align:left!important;font-weight:400!important;">${this.viaje.commenceOpsVisual}</td></tr>`;
    if (this.viaje && this.viaje.etcVisual)
      html += `<tr><td style="text-align:left!important;font-weight:400!important;">ETC</td><td style="text-align:left!important;font-weight:400!important;">${this.viaje.etcVisual}</td></tr>`;
    if (this.viaje && this.viaje.etdVisual)
      html += `<tr><td style="text-align:left!important;font-weight:400!important;">ETD</td><td style="text-align:left!important;font-weight:400!important;">${this.viaje.etdVisual}</td></tr>`;
    html += '</tbody></table>';

    html += spacerItems;

    if (this.showIncludeProductivityDetails && this.calculatedProductivityDetials.length !== 0) {
      html += '<table style="max-width: 700px;"><tr><th colspan="2" style="text-align: left">PRODUCTIVITY DETAILS</tr>';

      html += `<tr><td style="text-align: left; vertical-align: top; padding-right: 8px">GROSS TIME</td><td>`;
      this.calculatedProductivityDetials.forEach((product) => {
        html += `<p style="margin: 0;">${product.grossTime}</p>`;
      });
      html += `</td></tr>`;

      html += `<tr><td style="text-align: left; vertical-align: top; padding-right: 8px">GROSS RATE</td><td>`;
      this.calculatedProductivityDetials.forEach((product) => {
        html += `<p style="margin: 0;">${product.grossRate}</p>`;
      });
      html += `</td></tr>`;

      html += `<tr><td style="text-align: left; vertical-align: top; padding-right: 8px">NET TIME</td><td>`;
      this.calculatedProductivityDetials.forEach((product) => {
        html += `<p style="margin: 0;">${product.netTime}</p>`;
      });
      html += `</td></tr>`;

      html += `<tr><td style="text-align: left; vertical-align: top; padding-right: 8px">NET RATE</td><td>`;
      this.calculatedProductivityDetials.forEach((product) => {
        html += `<p style="margin: 0;">${product.netRate}</p>`;
      });
      html += `</td></tr>`;

      html += '</table>';
      html += spacerItems;
    }

    if (this.showIncludeStoppedHours && this.stoppedHoursReport.length !== 0) {
      html +=
        '<table style="max-width: 700px;"><tr><th style="text-align: left">STOPPED HOURS</th><th style="text-align: left">ACUM</th><th style="text-align: left" >ON REPORT</th></tr>';

      this.stoppedHoursReport.forEach((activity) => {
        html += `<tr><td style="text-align: left; text-wrap: nowrap">${
          activity.type
        }</td><td style="text-align: left">${
          activity.diffHours || ''
        }</td><td style="text-align: left; text-wrap: nowrap">${activity.report || ''}</td></tr>`;
      });

      html += '</table>';
      html += spacerItems;
    }

    if (this.incluirActividades) {
      html +=
        '<table style="table-layout:fixed;"><tbody><tr><td colspan="2" style="text-align:left!important; font-weight:bold;">Activities</td></tr>';
      this.arregloOrdenadoActividadesConfirmadas.forEach((actividadConfirmada) => {
        html += `<tr><td colspan="1"style="text-align:left!important;font-weight:400!important;">${
          actividadConfirmada.nombre || actividadConfirmada.type
        }</td><td colspan="1"style="text-align:left!important;font-weight:400!important;text-decoration:none!important;color:black!important; text-wrap: nowrap !importatn;">${
          actividadConfirmada.fechaVisual
        }</td></tr>`;
      });
      html += '</tbody></table>';
      html += spacerItems;

      if (this.arregloOrdenadoActividadesEstimadas && this.arregloOrdenadoActividadesEstimadas.length > 0) {
        html +=
          '<table style="table-layout:fixed;><tbody><tr><td colspan="2">ESTIMATED FOLLOWING SCHEDULE FOR THIS VESSEL</td></tr>';
        this.arregloOrdenadoActividadesEstimadas.forEach((actividadEstimada) => {
          html += `<tr><td colspan="1"style="text-align:left!important;font-weight:normal !important;">Estimated - ${actividadEstimada.nombre}</td><td colspan="1"style="text-align:left!important;font-weight:normal !important;text-decoration:none!important;color:black!important;">${actividadEstimada.fechaVisual}</td></tr>`;
        });
        html += '</tbody></table>';
        html += spacerItems;
      }
    }

    html += '<p style="margin: 0; font-weight: bold">Summary</p>';
    html += spacerItem;
    html += this.reportesService.obtenerTextoHTML(this.viaje.remark); // pending
    html += spacerItems;

    html += '<p style="margin: 0;">We will keep you posted</p>';
    html += spacerItems;
    html += '<p style="margin: 0;">Best regards,</p>';
    html += spacerItem;

    if (this.viaje.boardingAgentNombre) {
      html += `<p style="margin: 0;">${this.viaje.boardingAgentNombre}</p>`;
      html += `<p style="margin: 0;">BOARDING AGENT</p>`;
    }

    html += `<p style="margin: 0;">${this.viaje.port.direccion}</p>`;
    html += `<p style="margin: 0;">${this.viaje.port.nombre}</p>`;

    html += `<p style="margin: 0;">Mobile: ${
      this.viaje.boardingAgent && this.viaje.boardingAgent.telefono ? this.viaje.boardingAgent.telefono : 'Not defined'
    }</p>`;

    html += `<p style="margin: 0;">
        Email:
        <a href=${this.viaje.port ? 'mailto:' + this.viaje.port.email : 'Not defined'}>
        ${this.viaje.port ? this.viaje.port.email : 'Not defined'};
        </a>
      </p>`;

    html += `<p style="margin: 0;">
      Web Site:
      <a href="www.navescolombia.com">www.navescolombia.com</a>
    </p>`;

    html += `<p style="text-align: justify;">The information transmitted by this email is confidential and intended solely for use by their (s) recipient (s). His play, read or use is prohibited to any person or entity other, without prior written permission. If you received this in error, please notify the sender immediately and delete it from your system. You may not copy, print or distribute this email or its attachments, or use for any purpose nor disclose its contents to anyone. The opinions, conclusions and other information contained in this email, not related to official business NAVES SAS shall be construed as personal and in no way endorsed by the company. Although Naves SAS has done its best to ensure that this message and any attachments are free from viruses and defects that can potentially affect computers or systems that receive, not responsible for the possible transmission of viruses or malicious programs via this channel, and therefore the recipient's responsibility to confirm the existence of such elements when received and open it. Naves Colombia Neither nor any of its divisions or departments are responsible for possible damages or changes arising from the receipt or use of this message.</p>`;

    html += spacerItem;

    html += `<p style="text-align: justify;">This message has been sent by an automated system, please do not respond to this mail and will not be reviewed
    by any staff because it is an automatic process of sending mail.</p>`;

    html += '</tbody></table></body></html>';

    return html;
  }

  async generateVesselImage() {
    let html = `<table style="">`;

    html += `<tr style="display: flex;">`;

    html += `<td style="border: 1px solid #000; border-radius: 0 0 0 90px; width: 150px">`;
    html += `<div style="height: 100%;">`;
    html += `<div style="height: 30px; border-bottom: 1px solid #000;"></div>`;
    html += `<p style="border: none !important; text-align: center; margin: 1rem 0.5rem;">${this.viaje.buqueNombre}</p><p style="border: none !important; text-align: center; margin: 0 0.5rem">${this.viaje.nv}</p>`;
    html += `</div>`;
    html += `</td>`;

    html += `<td style="width: 8px">`;

    for (const hold of this.productsForView) {
      html += `<td style="padding-top: 80px; min-width: 120px;">`;
      html += `<div style="border: 1px solid #000; border-radius: 4px; height: 100%; border-radius: 4px;">`;
      html += `<p style="margin: 0; text-align: center; border-bottom: 1px solid #000;">${hold.hold}</p>`;
      html += '<div style="padding: 1.5rem 1rem;">';

      if (hold.products.length > 0) {
        hold.products.forEach((product, index) => {
          html += `<div style="${index != hold.products.length - 1 && 'margin-bottom: 0.5rem'}">
                          <p style="margin: 0; text-align: center">${product.PRV_ABV}</p>
                          <p style="margin: 0; text-align: center">${product.PRV_CANTIDAD} ${product.nombreMedida}</p>
                          <p style="margin: 0; text-align: center">ROB: ${product.PRV_ROB}</p>
                       </div>`;
        });
      } else {
        html += `<p style="margin: 0; text-align: center">* EMPTY *</p>`;
      }

      html += `</div>`;
      html += '</div>';
      html += `</td>`;

      html += `<td style="width: 8px">`;
    }

    html += `<td style="padding-top: 30px">`;
    html += `<div style="height: 100%; width: 100px; border-radius: 0 0 70px 0; border: 1px solid #000;"></div>`;
    html += `</td>`;
    html += `</tr>`;
    html += `</table>`;

    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = html;

    // Apply inline styles to the temporary container to avoid cross-origin issues
    // tempContainer.style.display = 'inline';
    tempContainer.style.visibility = 'visible';
    tempContainer.style.width = 'fit-content';

    document.body.appendChild(tempContainer);

    try {
      const canvas = await html2canvas(tempContainer, {
        allowTaint: true, // Allow loading cross-origin images
        useCORS: true, // Use cross-origin (if applicable)
      });

      const img = new Image();

      // Set the image source to the data URL representation of the canvas
      img.src = canvas.toDataURL();

      // Cleanup: remove temporary container
      document.body.removeChild(tempContainer);

      return canvas.toDataURL();
    } catch (err) {}
  }

  private async uploadBase64Image(base64Image: string, typeFiles3 = 'reports') {
    try {
      const base64Data = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ''), 'base64');

      const { params, options } = this.s3Service.getInfoForUpload(typeFiles3, base64Data);
      const type = base64Image.split(';')[0].split('/')[1];

      params['ContentEncoding'] = 'base64';
      params.ContentType = `image/${type}`;

      const data = await this.bucket.upload(params, options).promise();
      return data.Location.toString();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Encuentra si hay algun error al intentar enviar un correo
   */
  encontrarErroresEnvioCorreo(): string {
    if (!this.formulario.value.asuntoReporte) {
      Swal.fire({
        title: 'El reporte no tiene asunto',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[1];
    }

    if (this.emailsContactosViaje.length == 0) {
      Swal.fire({
        title: 'El viaje no tiene Receivers, Shippers o Others',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[2];
    }

    return this.erroresEnvioCorreo[0];
  }

  private calculatedProductivityDetials = [];

  public async calculateProductivityDetails() {
    const productsByMeasure = {};

    for (const product of this.viaje.recalada.productos) {
      await this.productoAFromatoVisual(product);
      if (!product.activo) continue;

      if (productsByMeasure[`${product.nombreMedida}`]) {
        productsByMeasure[`${product.nombreMedida}`].PRV_ACUMULADO += product.PRV_ACUMULADO;
        continue;
      }

      productsByMeasure[`${product.nombreMedida}`] = product;
    }

    const producitivityDetailsByMeasure = {};
    const tableHoursMinutes = {
      hours: 0,
      minutes: 0,
    };
    const workspaceMath = {
      totalGrossTime: 0,
    };

    Object.values(productsByMeasure).forEach((producto: any) => {
      producitivityDetailsByMeasure[`${producto.nombreMedida}`] = {};
      producitivityDetailsByMeasure[`${producto.nombreMedida}`]['grossTime'] = Object.assign({}, tableHoursMinutes);
      producitivityDetailsByMeasure[`${producto.nombreMedida}`]['grossRate'] = Object.assign({}, tableHoursMinutes);
      producitivityDetailsByMeasure[`${producto.nombreMedida}`]['netTime'] = Object.assign({}, tableHoursMinutes);
      producitivityDetailsByMeasure[`${producto.nombreMedida}`]['netRate'] = Object.assign({}, tableHoursMinutes);

      // gross time
      const grossTime = this.timeService.diffDateInHoursMinutes(
        producto.PRV__TIME_FROM,
        producto.PRV__TIME_TO
      );

      workspaceMath.totalGrossTime += this.timeService.diffTimeInHours(
        producto.PRV__TIME_FROM,
        producto.PRV__TIME_TO
      );

      producitivityDetailsByMeasure[`${producto.nombreMedida}`][
        'grossTime'
      ] = `${this.timeService.formatHoursMinutesToString(grossTime.hours, grossTime.minutes)} ${producto.nombreMedida}`;

      // grass rate
      const grossRate = (
        producto.PRV_ACUMULADO /
        this.timeService.diffTimeInHours(producto.PRV__TIME_FROM, producto.PRV__TIME_TO)
      ).toFixed(2);

      producitivityDetailsByMeasure[`${producto.nombreMedida}`][
        'grossRate'
      ] = `${grossRate} ${producto.nombreMedida} / HRS`;
    });

    Object.values(productsByMeasure).forEach((producto: any) => {
      // net time
      let grossTime = this.timeService.diffDateInHoursMinutes(
        producto.PRV__TIME_FROM,
        producto.PRV__TIME_TO
      );

      let netTimeHours: number = this.timeService.sumIndividualHoursAndMinutes(grossTime);

      const totalStoppedHours: number = this.timeService.sumIndividualHoursAndMinutes(
        this.totalStoppedHours.totalHoursByType.TOTAL
      );

      const porcentaje =
        this.timeService.diffTimeInHours(producto.PRV__TIME_FROM, producto.PRV__TIME_TO) /
        workspaceMath.totalGrossTime;

      netTimeHours = netTimeHours - totalStoppedHours * porcentaje;

      const hoursAndMinutesNetTime = this.timeService.convertMinutesToHoursMinutes(netTimeHours);

      producitivityDetailsByMeasure[`${producto.nombreMedida}`][
        'netTime'
      ] = `${this.timeService.formatHoursMinutesToString(
        hoursAndMinutesNetTime.hours,
        hoursAndMinutesNetTime.minutes
      )} ${producto.nombreMedida}`;

      producitivityDetailsByMeasure[`${producto.nombreMedida}`]['netRate'] = `${(
        producto.PRV_ACUMULADO / netTimeHours
      ).toFixed(2)} ${producto.nombreMedida} / HRS`;
    });

    this.calculatedProductivityDetials = Object.values(producitivityDetailsByMeasure);
  }

  onCrearSof(): void {
    this.cargando = true;

    this.cargando = true;
    let nuevoSof = {
      voyage: this.viaje.voyageNumber,
      terminal: this.viaje.recalada.terminal.id.toString(),
      nv: this.viaje.nv,
      via_id: this.viaje._id,
      history: [],
      idRecalada: this.viaje.recalada._id,
    };

    this.mainService.post('api/sof', nuevoSof).subscribe(
      (res) => {
        this.mainService.put(`api/recalada/${this.viaje.recalada._id}`, { sof: [res._id] }).subscribe(
          (res) => {
            Swal.fire('Éxito', 'Se creó el SOF exitosamente', 'success');
            this.viaje = res;
            this.cargando = false;
          },
          (err) => {
            Swal.fire('Error', 'No se pudo asociar el SOF a la recalada', 'error');
            this.cargando = false;
          }
        );
      },
      (err) => {
        Swal.fire('Error', 'No se pudo crear el SOF', 'error');
        this.cargando = false;
      }
    );
  }

  /**
   * Redirigue al detalle del sof
   */
  onVerSof(): void {
    this.router.navigate([`home/reportes/ver-sof/${this.viaje.recalada.sof[0]}/${this.viaje.recalada._id}`]);
  }
}
