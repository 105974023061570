
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reestablecer',
  templateUrl: './reestablecer.component.html',
  styleUrls: ['./reestablecer.component.scss']
})
export class ReestablecerComponent implements OnInit {

  public contrasenaNueva: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  public repetirContrasena: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  private token: string;

  public visibleFirst: boolean = false;

  public visibleSecond: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.params['id'];
  }

  resetPassword(){
    if(this.contrasenaNueva.valid && this.repetirContrasena.valid && this.contrasenaNueva.value === this.repetirContrasena.value){
      this.authService.reset(this.contrasenaNueva.value, this.token || undefined).subscribe(response => {
        if(response.success){
          Swal.fire({
            title: 'Éxito',
            text: 'Contraseña actualizada exitosamente',
            type: 'success'
          });
          this.router.navigate(['login']);
        } else {
          Swal.fire({
            title: 'Error',
            text: response.error,
            type: 'error'
          })
        }
      })
    }
  }

}
