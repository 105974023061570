import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../services/main.service';
import * as _ from 'lodash';
import { evaluate } from 'mathjs';
import { singleSelectsIns } from '../../../instructions/singleSelect.ins';

@Component({
  selector: 'tarifa-detalle',
  templateUrl: './tarifa-detalle.component.html',
  styleUrls: ['./tarifa-detalle.component.scss'],
})
export class TarifaDetalleComponent implements OnInit {
  /** Referencia de poder editar o no */
  @Input() available: boolean;
  /** Referenica al dialogo del detalle */
  @Input() dialogRef: any;
  /** Indica si se encuentra en modo de creacion */
  @Input() createMode: any;
  /** Tarifa actualmente seleccionada */
  @Input() tarifa: any;
  /** Indica que hubo un cambio con referencia a las tarifas en la BD */
  @Output() onSubmitTarifa: EventEmitter<any> = new EventEmitter<any>();

  /** Formularuio reactivo de tarifas */
  public tarifaForm: FormGroup;
  /** Modo actual */
  public mode: string = '';
  /** Puertos en la BD */
  public ports: any = [];
  /** Terminales en la BD */
  public terminals: any = [];
  /** Terminal que funciona como comodin de todas las terminales */
  public allTerminals: any;
  /** Terminales filtradas por el puerto escogido */
  public terminalsByPort = [];
  /** Empresas de tipo proveedor en la BD */
  public operadores: any = [];
  /** Variables aceptadas en el valor den dolares de una tarifa dinamica */
  public variablesAceptadasTarifaDinamica: string[] = ['SMLVD', 'SMLVM', 'COP', 'TRM', 'UVT', 'FA'];
  /** Todas la variables aceptadas en la formula de una tarifa */
  public variablesAceptadasFormula: string[] = [
    'SMLVD',
    'SMLVM',
    'DWT',
    'GRT',
    'LOA',
    'TRM',
    'UVT',
    'FA',
    'USD',
    'CANT',
    // 'IVA',
    // '4x1000',
  ];
  /** Posibles errores en el formulario */
  public erroresFormulario: string[] = ['NINGUNO', 'CAMPOS_REQUERIDOS', 'TARIFA_DINAMICA', 'RANGO', 'FORMULA'];
  public instructions = {
    clientCompany: singleSelectsIns.clientCompany,
  };
  /** Indica si fue enviado el formulario */
  public submitted: boolean = false;

  /** Referencia al boton de cerrar el detalle*/
  @ViewChild('closeButton', { static: true }) closeButton: ElementRef;
  /** Referencia al input de la tarifa en dolares */
  @ViewChild('tarifaUSDInput', { static: true }) tarifaUSDInput: ElementRef;

  constructor(private mainService: MainService) {}

  /**
   * Inicializa los datos y los formularios segun el modo actual
   */
  ngOnInit() {
    this.initializeData();
    if (this.createMode) {
      this.mode = 'Crear';
      this.initializeFormCreateMode();
    } else {
      this.mode = 'Editar';
      this.initializeFormEditarMode();
    }
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  initializeData() {
    //Ports
    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.ports = res;
      //Hay que quitar el puerto ALL de las opciones, este tiene el ID 0
      let i = 0;
      let encontroPuerto = false;
      let todosLosPuertos;
      while (i < this.ports.length && !encontroPuerto) {
        if (this.ports[i].PUE_ID == 0) {
          todosLosPuertos = this.ports.splice(i, 1)[0];
          encontroPuerto = true;
        }

        i++;
      }
      this.ports = _.orderBy(this.ports, ['PUE_NOMBRE']);
      if (todosLosPuertos) {
        this.ports.unshift(todosLosPuertos);
      }
    });

    //Operadores
    this.mainService.get('api/empresa_sql?&tipo=PROVEEDOR&activo=true').subscribe((res) => {
      this.operadores = res;
      this.operadores = _.orderBy(this.operadores, ['EMP_NOMBRE']);
      this.setDataOperador();
    });

    //Clientes
    this.mainService.get('api/empresa_sql?&tipo=CLIENTE&activo=true').subscribe((res) => {
      this.instructions.clientCompany.options = res.map((company) => {
        return {
          value: company.EMP_ID,
          content: company.EMP_NOMBRE,
        };
      });

      this.instructions.clientCompany.options.unshift({
        value: '0',
        content: 'TODOS',
      });
    });
  }

  /**
   * Inicializa el formulario en el modo de crear
   */
  initializeFormCreateMode() {
    this.tarifaForm = new FormGroup({
      nombreServicio: new FormControl({ value: '', disabled: false }, Validators.required),
      puerto: new FormControl({ value: '', disabled: false }, Validators.required),
      terminal: new FormControl({ value: '', disabled: false }, Validators.required),
      operador: new FormControl({ value: '', disabled: false }, Validators.required),
      tipoTarifa: new FormControl({ value: '', disabled: false }, Validators.required),
      tarifaCOP: new FormControl({ value: '', disabled: false }, Validators.required),
      tarifaAplicarUSD: new FormControl({ value: '', disabled: false }, Validators.required),
      formula: new FormControl({ value: '', disabled: false }, Validators.required),
      clasificacionServicio: new FormControl({ value: '', disabled: false }, Validators.required),
      baseRango: new FormControl({ value: '', disabled: false }, Validators.required),
      rangoMin: new FormControl({ value: '', disabled: false }, Validators.required),
      rangoMax: new FormControl({ value: '', disabled: false }, Validators.required),
      baseCantidad: new FormControl({ value: '', disabled: false }, Validators.required),
      valorCantidad: new FormControl({ value: '', disabled: false }, Validators.required),
      cliente: new FormControl({ value: '', disabled: false }, Validators.required),
    });

    this.tarifaForm.get('puerto').valueChanges.subscribe((value) => {
      //Terminals
      this.mainService.get(`api/terminal/port/${value}?activo=true`).subscribe((res) => {
        this.terminals = res;
        let i = 0;
        let encontroTerminal = false;
        while (i < this.terminals.length && !encontroTerminal) {
          if (this.terminals[i].TER_ID == 0) {
            this.allTerminals = this.terminals.splice(i, 1)[0];
            encontroTerminal = true;
          }

          i++;
        }
        this.terminals = _.orderBy(this.terminals, ['TER_NOMBRE']);
        if (this.allTerminals) {
          this.terminals.unshift(this.allTerminals);
        }
        this.terminalsByPort = this.terminals;
      });
    });
  }

  /**
   * Inicializa el formulario en el modo de editar
   */
  initializeFormEditarMode() {
    this.tarifaForm = new FormGroup({
      nombreServicio: new FormControl(
        { value: this.tarifa.nombreServicio ? this.tarifa.nombreServicio : '', disabled: !this.available },
        Validators.required
      ),
      puerto: new FormControl(
        { value: this.tarifa.puerto ? this.tarifa.puerto : '', disabled: !this.available },
        Validators.required
      ),
      terminal: new FormControl(
        { value: this.tarifa.terminal ? this.tarifa.terminal : '', disabled: !this.available },
        Validators.required
      ),
      operador: new FormControl(
        { value: this.tarifa.operador ? this.tarifa.operador : '', disabled: !this.available },
        Validators.required
      ),
      tipoTarifa: new FormControl(
        { value: this.tarifa.tipoTarifa ? this.tarifa.tipoTarifa : '', disabled: !this.available },
        Validators.required
      ),
      tarifaCOP: new FormControl(
        {
          value: this.tarifa.tarifaCOP ? this.tarifa.tarifaCOP : 0,
          disabled:
            (this.tarifaForm &&
              (this.tarifaForm.get('tipoTarifa').value == '' ||
                this.tarifaForm.get('tipoTarifa').value == 'ESTATICA')) ||
            !this.available,
        },
        Validators.required
      ),
      tarifaAplicarUSD: new FormControl(
        {
          value: this.tarifa.tarifaAplicarUSD ? this.tarifa.tarifaAplicarUSD : '',
          disabled: (this.tarifaForm && this.tarifaForm.get('tipoTarifa').value == '') || !this.available,
        },
        Validators.required
      ),
      formula: new FormControl(
        { value: this.tarifa.formula ? this.tarifa.formula : '', disabled: this.tarifaForm && !this.available },
        Validators.required
      ),
      clasificacionServicio: new FormControl(
        {
          value: this.tarifa.clasificacionServicio ? this.tarifa.clasificacionServicio : '',
          disabled: this.tarifaForm && !this.available,
        },
        Validators.required
      ),
      baseRango: new FormControl(
        { value: this.tarifa.baseRango ? this.tarifa.baseRango : '', disabled: this.tarifaForm && !this.available },
        Validators.required
      ),
      rangoMin: new FormControl(
        {
          value: this.tarifa.rangoMin ? this.tarifa.rangoMin : 0,
          disabled:
            (this.tarifaForm &&
              (this.tarifaForm.get('baseRango').value == '' || this.tarifaForm.get('baseRango').value == 'NA')) ||
            !this.available,
        },
        Validators.required
      ),
      rangoMax: new FormControl(
        {
          value: this.tarifa.rangoMax ? this.tarifa.rangoMax : 0,
          disabled:
            (this.tarifaForm &&
              (this.tarifaForm.get('baseRango').value == '' || this.tarifaForm.get('baseRango').value == 'NA')) ||
            !this.available,
        },
        Validators.required
      ),
      baseCantidad: new FormControl(
        { value: this.tarifa.baseCantidad ? this.tarifa.baseCantidad : '', disabled: !this.available },
        Validators.required
      ),
      valorCantidad: new FormControl(
        {
          value: this.tarifa.valorCantidad ? this.tarifa.valorCantidad : 0,
          disabled: (this.tarifaForm && this.tarifaForm.get('baseCantidad').value == 'FIJA') || !this.available,
        },
        Validators.required
      ),
      cliente: new FormControl(
        { value: this.tarifa.cliente && this.tarifa.cliente !== 'null' ? this.tarifa.cliente : '', disabled: false },
        Validators.required
      ),
    });

    this.onChangePort();
  }

  /**
   * Maneja el evento del cambio de puerto seleccionado haciendo el filtro del las terminales de ese puerto
   */
  onChangePort() {
    const userInput = this.tarifaForm.get('puerto').value;
    const selectedPort = this.ports.find((p) => {
      return p.PUE_ID == userInput;
    });
    this.terminalsByPort = this.terminals.filter((t) => {
      return t.PUE_ID == selectedPort.PUE_ID;
    });

    if (userInput != 0) {
      this.terminalsByPort.unshift(this.allTerminals);
    }

    this.mainService.get(`api/terminal/port/${userInput}?activo=true`).subscribe((res) => {
      this.terminals = res;
      let i = 0;
      let encontroTerminal = false;
      while (i < this.terminals.length && !encontroTerminal) {
        if (this.terminals[i].TER_ID == 0) {
          this.allTerminals = this.terminals.splice(i, 1)[0];
          encontroTerminal = true;
        }

        i++;
      }
      this.terminals = _.orderBy(this.terminals, ['TER_NOMBRE']);
      if (this.allTerminals) {
        this.terminals.unshift(this.allTerminals);
      }
      this.terminalsByPort = this.terminals;
    });
  }

  /**
   * Maneja el evento del cambio de tipo de tarifa
   */
  onChangeTipoTarifa() {
    if (this.tarifaForm.get('tipoTarifa').value == 'ESTATICA') {
      this.tarifaForm.controls['tarifaCOP'].setValue('0');
      this.tarifaForm.controls['tarifaAplicarUSD'].setValue('');
    } else {
      this.tarifaForm.controls['tarifaCOP'].setValue('');
      this.tarifaForm.controls['tarifaAplicarUSD'].setValue('');
    }
  }

  /**
   * Maneja el evento del cambio de la base del rango
   */
  onChangeBaseRango() {
    if (this.tarifaForm.get('baseRango').value == 'NA') {
      this.tarifaForm.controls['rangoMin'].setValue('0');
      this.tarifaForm.controls['rangoMax'].setValue('0');
    } else {
      this.tarifaForm.controls['rangoMin'].setValue('');
      this.tarifaForm.controls['rangoMax'].setValue('');
    }
  }

  /**
   * Maneja el evento del cambio de la base de la cantidad
   */
  onChangeBaseCantidad() {
    if (this.tarifaForm.get('baseCantidad').value == 'FIJA') this.tarifaForm.controls['valorCantidad'].setValue('');
    else this.tarifaForm.controls['valorCantidad'].setValue('0');
  }

  /**
   * Cierra el dialogo del detalle
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Envia los datos ingresados a la BD
   */
  onSubmit() {
    const currentModeMin = this.mode.toLocaleLowerCase();

    switch (this.verificarDatosFormulario()) {
      case this.erroresFormulario[1]:
        Swal.fire({
          title: `No se puede ${currentModeMin} la tarifa`,
          text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.submitted = true;
        break;
      case this.erroresFormulario[2]:
        Swal.fire({
          title: `No se puede ${currentModeMin} la tarifa`,
          text: 'La tarifa dinámica no está correctamente formulada',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        break;
      case this.erroresFormulario[3]:
        Swal.fire({
          title: `No se puede ${currentModeMin} la tarifa`,
          text: 'El rango está mal definido',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        break;
      case this.erroresFormulario[4]:
        Swal.fire({
          title: `No se puede ${currentModeMin} la tarifa`,
          text: 'La fórmula no está correctamente formulada',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        break;
      default:
        const formContent = this.tarifaForm.value;
        const tarifa = JSON.parse(JSON.stringify(formContent));
        this.submitted = false;
        if (this.createMode) {
          this.mainService.post('api/tarifa', tarifa).subscribe((result) => {
            Swal.fire({
              title: 'Se creó exitosamente la tarifa',
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Continuar',
            }).then((result) => {
              if (result.value) {
                this.onSubmitTarifa.emit();
                this.closeDialog();
              }
            });
          });
        } else {
          this.mainService.put('api/tarifa/' + this.tarifa._id, tarifa).subscribe(
            (_) => {
              Swal.fire({
                title: 'Se actualizó exitosamente la tarifa',
                type: 'success',
                showCancelButton: false,
                confirmButtonText: 'Continuar',
              }).then((result) => {
                if (result.value) {
                  this.onSubmitTarifa.emit();
                  this.closeDialog();
                }
              });
            },
            (err) => {
              console.error(err);
              Swal.fire('Error', err.error.message, 'error');
            }
          );
        }
    }
  }

  get f() {
    return this.tarifaForm.controls;
  }

  /**
   * Busca errores en el formulario de la tarifa
   */
  verificarDatosFormulario() {
    let error = '';

    let formula = this.tarifaForm.get('formula').value;
    let tarifaDinamica = this.tarifaForm.get('tarifaAplicarUSD').value;

    this.variablesAceptadasFormula.forEach((element) => {
      formula = formula.replace(element, '2');
    });
    if (this.tarifaForm.get('tipoTarifa').value == 'DINAMICA') {
      this.variablesAceptadasTarifaDinamica.forEach((element) => {
        tarifaDinamica = tarifaDinamica.replace(element, '2');
      });
    }

    if (this.tarifaForm.invalid) error = this.erroresFormulario[1];

    if (!error) {
      try {
        evaluate(tarifaDinamica);
      } catch (err) {
        error = this.erroresFormulario[2];
      }
    }

    if (
      !error &&
      this.tarifaForm.get('baseRango').value !== 'NA' &&
      this.tarifaForm.get('rangoMin').value >= this.tarifaForm.get('rangoMax').value
    )
      error = this.erroresFormulario[3];

    if (!error) {
      try {
        evaluate(formula);
      } catch (err) {
        error = this.erroresFormulario[4];
      }
    }

    if (!error) return this.erroresFormulario[0];
    else return error;
  }

  /**
   * Reacciona al ingreso de caracteres espaciales a una tarifa
   */
  onEvitarCaracteresEspecialesTarifaEstatica() {
    const tarifaUSDInput = this.tarifaUSDInput.nativeElement;

    if (this.tarifaForm.get('tipoTarifa').value == 'ESTATICA') {
      tarifaUSDInput.value = Math.abs(tarifaUSDInput.value);
    }
  }

  setOperadorForFee(data: string) {
    this.tarifaForm.patchValue({
      operador: data,
    });
  }

  public dataCollectionToFindOperador = [];
  setDataOperador() {
    this.dataCollectionToFindOperador = [];
    this.operadores.forEach((operador) => {
      this.dataCollectionToFindOperador.push({
        key: operador.EMP_ID,
        value: operador.EMP_NOMBRE,
      });
    });
  }
}
