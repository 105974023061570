import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../services/main.service';
import Swal from "sweetalert2";
const axios = require('axios').default;

@Component({
	selector: 'proformas',
	templateUrl: './proformas.component.html',
	styleUrls: ['./proformas.component.scss']
})
export class ProformasComponent implements OnInit {

	/** Pestañas en el modulo de proformas */
	public pestanias: string[] = ['SIN_NOMINACION', 'CON_NOMINACION', 'REPORTE', 'CARTA'];
	/** Dindica la pestania actualmente seleccionada */
	public pestaniaSeleccionada: string = this.pestanias[0];
	/** Todas las proformas de la BD */
	public todasLasProformas: any = [];
	/** Todas las proformas con nominacion en la BD */
	public proformasConNominacion: any = [];
	/** TOdas las proformas sin nominacion en la BD */
	public proformasSinNominacion: any = [];

	public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));
	public permisosUsuario: any;

	/** Referencia a la pestania de proformas isn nominacion */
	@ViewChild('sinNominacionOption', { static: true }) sinNominacionOption: ElementRef;
	/** Referencia a la pestania de proformas con nominacion */
	@ViewChild('conNominacionOption', { static: true }) conNominacionOption: ElementRef;
	/** Referencia a la pestania de reporte */
	@ViewChild('reporteOption', { static: true }) reporteOption: ElementRef;
	/** Referencia a la pestania de carta */
	@ViewChild('cartaOption', { static: true }) cartaOption: ElementRef;

	constructor(
		private router: Router,
		private mainService: MainService
	) { }

	ngOnInit() { 
		this.obtenerPermisos();
	}

	obtenerPermisos(){
		this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe(res => {
			this.permisosUsuario = res;
			if(this.permisosUsuario.proformas === 'NINGUNO'){
				Swal.fire({
					title: 'No se tiene permisos de acceso al módulo',
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Continuar'
				});
				this.router.navigate(['home/dashboard']);
			}
		})
	}

	/**
	 * Ejecuta los cambios necesarios al escoger la pestania sin nominacion
	 */
	onSelectSinNominacion() {
		if (this.pestaniaSeleccionada == this.pestanias[1]) {
			this.conNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.conNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[2]) {
			this.reporteOption.nativeElement.classList.remove('proforma-option-selected');
			this.reporteOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[3]) {
			this.cartaOption.nativeElement.classList.remove('proforma-option-selected');
			this.cartaOption.nativeElement.classList.add('proforma-option-unselected');
		}

		this.sinNominacionOption.nativeElement.classList.remove('proforma-option-unselected');
		this.sinNominacionOption.nativeElement.classList.add('proforma-option-selected');

		this.pestaniaSeleccionada = this.pestanias[0];
	}

	/**
	 * Ejecuta los cambios necesarios al escoger la pestania con nominacion
	 */
	onSelectConNominacion() {
		if (this.pestaniaSeleccionada == this.pestanias[0]) {
			this.sinNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.sinNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[2]) {
			this.reporteOption.nativeElement.classList.remove('proforma-option-selected');
			this.reporteOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[3]) {
			this.cartaOption.nativeElement.classList.remove('proforma-option-selected');
			this.cartaOption.nativeElement.classList.add('proforma-option-unselected');
		}

		this.conNominacionOption.nativeElement.classList.remove('proforma-option-unselected');
		this.conNominacionOption.nativeElement.classList.add('proforma-option-selected');

		this.pestaniaSeleccionada = this.pestanias[1];
	}

	/**
	 * Ejecuta los cambios necesarios al escoger la pestania del reporte
	 */
	onSelectReporte() {
		if (this.pestaniaSeleccionada == this.pestanias[0]) {
			this.sinNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.sinNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[1]) {
			this.conNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.conNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[3]) {
			this.cartaOption.nativeElement.classList.remove('proforma-option-selected');
			this.cartaOption.nativeElement.classList.add('proforma-option-unselected');
		}

		this.reporteOption.nativeElement.classList.remove('proforma-option-unselected');
		this.reporteOption.nativeElement.classList.add('proforma-option-selected');

		this.pestaniaSeleccionada = this.pestanias[2];
	}

	/**
	 * Ejecuta los cambios necesarios al escoger la pestania de carta
	 */
	onSelectCarta() {
		if (this.pestaniaSeleccionada == this.pestanias[0]) {
			this.sinNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.sinNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[1]) {
			this.conNominacionOption.nativeElement.classList.remove('proforma-option-selected');
			this.conNominacionOption.nativeElement.classList.add('proforma-option-unselected');
		} else if (this.pestaniaSeleccionada == this.pestanias[2]) {
			this.reporteOption.nativeElement.classList.remove('proforma-option-selected');
			this.reporteOption.nativeElement.classList.add('proforma-option-unselected');
		}

		this.cartaOption.nativeElement.classList.remove('proforma-option-unselected');
		this.cartaOption.nativeElement.classList.add('proforma-option-selected');

		this.pestaniaSeleccionada = this.pestanias[3];
	}

}
