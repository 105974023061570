import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NbDialogService, NbToastrService} from '@nebular/theme';

import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { create } from 'domain';

@Component({
  selector: 'app-statement-ver',
  templateUrl: './statement-ver.component.html',
  styleUrls: ['./statement-ver.component.scss']
})
export class StatementVerComponent implements OnInit {

  borrador:any;
  statement:any;
  id:any;
  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private _location: Location,) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.onCargar()
  }

    /** Toas **/

    showToast(position, status, titulo, mensaje) {
      this.toastrService.show(
        mensaje,
        titulo,
        { position, status });
    }

  onCargar() {
    this.mainService.get('api/barco/' + this.id).subscribe(res => {
      
      if (res.statementOfFactsBorrador) {
        this.borrador = res.statementOfFactsBorrador
      } else {
        this.borrador = ''
      }
      if (res.statementOfFacts) {
        this.statement = res.statementOfFacts
      } else {
        this.statement = ''
      }
    });
  }

  guardarStatements() {
    let barco = {
      statementOfFacts: this.statement
    }
    this.mainService.put('api/barco/' + this.id, barco).subscribe(res => {
      
      Swal.fire(
        'Éxito',
        'Se actualizó el statements of facts',
        'success' 
      );
    });
  }

  guardarBorrador() {
    let barco = {
      statementOfFactsBorrador: this.borrador
    }
    this.mainService.put('api/barco/' + this.id, barco).subscribe(res => {
      
      Swal.fire(
        'Éxito',
        'Se actualizó el borrador',
        'success' 
      );
    });
  }

  back(){
    this._location.back();
  }
}
