import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
})
export class UsuariosComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/
  nuevoEmail: string;
  datosEdit = {
    _id: '',
    nombre: '',
    apellido: '',
    email: '',
    password: '',
    empresa: '',
    telefono: '',
    cargo: '',
    tipo: '',
    estado: '',
  };

  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      nombre: {
        title: 'Nombre',
      },
      apellido: {
        title: 'Apellido',
      },
      tipo: {
        title: 'Rol de usuario',
        valuePrepareFunction: (value) => {
          return value && value.nombre ? value.nombre : 'Sin asignar';
        },
        filterFunction(rol?: any, search?: string): boolean {
          if (rol.nombre) {
            const pos = rol.nombre.toLowerCase().search(search.toLowerCase());
            if (pos !== -1) return true;
            return false;
          }
          return false;
        },
      },
      cargo: {
        title: 'Cargo',
      },
      estado: {
        title: 'Estado',
        sort: 'true',
      },
    },
  };

  empresas: any = [];
  empresa: any;
  estado: any;
  datos: any;
  enviado: boolean = false;
  nombre: any;
  apellido: any;
  telefono: any;
  email: any;
  tipo: any;
  cargo: any;
  password: any;

  public vessels: any = [];
  public vesselType: any = [];
  public user: any;
  public data: any;
  public source: LocalDataSource;
  public roles: any;
  public rolesCargandose: boolean = true;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.usuarios === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    const usuarioActual = JSON.parse(localStorage.getItem('usuario'));
    if (event.data._id === usuarioActual._id && usuarioActual.tipo !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede editar tu propio usuario',
        type: 'error',
      });
      return;
    }
    if (event.data.tipo._id === '62a0f1d3fea5ec8088ebf094' && this.permisosUsuario._id !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede editar un administrador',
        type: 'error',
      });
      return;
    }
    if (this.permisosUsuario.usuarios !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.enviado = false;
      this.datosEdit.nombre = event.data.nombre;
      this.datosEdit.apellido = event.data.apellido;
      this.datosEdit.telefono = event.data.telefono;
      this.datosEdit.email = event.data.email;
      this.datosEdit.password = event.data.password;
      this.datosEdit.empresa = event.data.empresa && event.data.empresa._id ? event.data.empresa._id : '';
      this.datosEdit.tipo = event.data.tipo._id;
      this.datosEdit.cargo = event.data.cargo;
      this.datosEdit._id = event.data._id;
      this.datosEdit.estado = event.data.estado;
      this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearUsuario() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.borrarCamposCreacion();
      });
  }

  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  setFalseEnviado() {
    this.enviado = false;
  }

  /** CRUD **/

  private borrarCamposCreacion() {
    (this.nombre = ''),
      (this.apellido = ''),
      (this.email = ''),
      (this.cargo = ''),
      (this.tipo = ''),
      (this.password = ''),
      (this.telefono = ''),
      (this.estado = ''),
      (this.empresa = '');
  }

  public crear() {
    this.enviado = false;

    if (
      this.nombre != '' &&
      this.nombre != undefined &&
      this.apellido != '' &&
      this.apellido != undefined &&
      this.utilsService.validateEmail(this.email) &&
      this.cargo != '' &&
      this.cargo != undefined &&
      this.tipo != '' &&
      this.tipo != undefined &&
      this.password.length >= 6 &&
      this.telefono != '' &&
      this.telefono != undefined &&
      this.empresa != '' &&
      this.empresa != undefined
    ) {
      let user = {
        nombre: this.nombre,
        apellido: this.apellido,
        telefono: this.telefono,
        email: this.email,
        password: this.password,
        cargo: this.cargo,
        empresa: this.empresa,
        tipo: this.tipo,
      };
      this.mainService.post('api/user', user).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó el usuario exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            this.enviado = true;
            Swal.fire('Error', 'No se pudo agregar el usuario, verifique la información', 'warning');
          }
        },
        (response) => {
          Swal.fire('Error', `${response.error.message}`, 'warning');
        }
      );
    } else {
      this.enviado = true;

      Swal.fire('Error', 'No se pudo agregar el usuario, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/usuario-ver/' + event.data._id]);
  }

  onActualizar() {
    this.enviado = false;
    if (
      this.datosEdit.nombre != '' &&
      this.datosEdit.nombre != undefined &&
      this.datosEdit.apellido != '' &&
      this.datosEdit.apellido != undefined &&
      this.utilsService.validateEmail(this.datosEdit.email) &&
      this.datosEdit.cargo != '' &&
      this.datosEdit.cargo != undefined &&
      this.datosEdit.tipo != '' &&
      this.datosEdit.tipo != undefined &&
      this.datosEdit.password.length >= 6 &&
      this.datosEdit.telefono != '' &&
      this.datosEdit.telefono != undefined &&
      this.datosEdit.estado != '' &&
      this.datosEdit.estado != undefined &&
      this.datosEdit.empresa != '' &&
      this.datosEdit.empresa != undefined
    ) {
      let data = {
        _id: this.datosEdit._id,
        nombre: this.datosEdit.nombre,
        apellido: this.datosEdit.apellido,
        telefono: this.datosEdit.telefono,
        cargo: this.datosEdit.cargo,
        tipo: this.datosEdit.tipo,
        estado: this.datosEdit.estado,
        empresa: this.datosEdit.empresa,
        email: this.datosEdit.email,
      };
      this.mainService.put('api/user/' + data._id, data).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se actualizó el usuario exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo actualizar el usuario, verifique la información', 'warning');
          }
        },
        (response) => {
          Swal.fire('Error', `${response.error.message}`, 'warning');
        }
      );
    } else {
      this.enviado = true;
      Swal.fire('Error', 'No se pudo actualizar el usuario, verifique la información', 'warning');
    }
  }

  onCargar() {
    this.data = [];
    this.onCargarRoles();
    this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
      this.empresas = res;
      this.empresas = _.orderBy(this.empresas, ['EMP_NOMBRE'], ['asc']);
      this.setDataCollectionEmpresa();
    });
    this.mainService.get('api/user').subscribe((res) => {
      this.data = res;
      this.data.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
      this.data = new LocalDataSource(this.data);
    });
  }

  onEliminar(event) {
    const usuarioActual = JSON.parse(localStorage.getItem('usuario'));
    if (event.data._id === usuarioActual._id && usuarioActual.tipo !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar tu propio usuario',
        type: 'error',
      });
      return;
    }
    if (event.data.tipo.nombre === 'Administrador' && this.permisosUsuario._id !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar un administrador',
        type: 'error',
      });
      return;
    }
    if (this.permisosUsuario.usuarios !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas deshabilitar el usuario?</strong>',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          let user = event.data;
          this.spinerGuardar = true;
          this.mainService.delete('api/user/' + user._id).subscribe((res) => {
            if (res) {
              this.spinerGuardar = false;
              if (res.message) {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'Usuario deshabilitado con éxito', 'success');
              this.onCargar();
            }
          });
        }
      });
    }
  }

  /**
   * Metodo que trae los roles en la BD
   */
  onCargarRoles(): void {
    this.rolesCargandose = true;
    this.mainService.get('api/rol?activo=true').subscribe((res) => {
      this.roles = res;
      this.roles = _.orderBy(this.roles, ['nombre']);
      this.rolesCargandose = false;
    });
  }

  setEmpresaUsuario(data: string) {
    this.empresa = data;
    this.datosEdit.empresa = data;
  }

  public dataCollectionToFindEmpresa = [];
  setDataCollectionEmpresa() {
    this.dataCollectionToFindEmpresa = [];
    this.empresas.forEach((empresa) => {
      if (!empresa.EMP_NOMBRE) return;
      this.dataCollectionToFindEmpresa.push({
        key: empresa._id,
        value: empresa.EMP_NOMBRE,
      });
    });
  }
}
