import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-kim',
  templateUrl: './kim.component.html',
  styleUrls: ['./kim.component.scss'],
})
export class KimComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Indica si algo en el componente se esta guardando */
  public cargando = true;

  /** Kim actualmente seleccionado */
  kim: any = {};

  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      KIM_ID: {
        title: 'ID',
      },
      KIM_NOMBRE: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any = [];
  /** Indica si los datos del detalle fueron enviados */
  enviado: boolean = false;

  /** Usuario actualmente seleccionado */
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.kims === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las diferentes acciones
   * @param event Evento con informacion de la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de un KIM
   * @param dialog Referencia al dialogo del detalle de KIM
   * @param event Evento con los datos del KIM a editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.kims !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.kim = {};
    this.kim = event.data;
    this.kim._id = event.data._id;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Abre el dialogo para editar un KIM
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de un KIM
   */
  crearKim() {
    this.kim = {};
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo para la creacion de un kim
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Crea em la BD el KIM
   */
  public crear() {
    this.enviado = false;
    if (this.kim.KIM_NOMBRE) {
      this.mainService.get('api/global_variable/KIM_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.kim.KIM_ID = 4; //Primer valor del ID
          const kimIdGlobalVariable = {
            key: 'KIM_ID',
            value: this.kim.KIM_ID,
          };
          this.mainService.post('api/global_variable', kimIdGlobalVariable).subscribe((result) => {});
        } else {
          this.kim.KIM_ID = res.value;
        }

        this.mainService.put('api/global_variable/KIM_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/kim', this.kim).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó el KIM exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar la linea de negocio, verifique la información', 'warning');
          }
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el KIM, verifique la información', 'warning');
    }
  }

  /**
   * Redorogue a la pagina para visualizar el KIM
   * @param event Evento con los datos del KIM a ver
   */
  onVer(event) {
    this.router.navigate(['home/kim-ver/' + event.data._id]);
  }

  /**
   * Inicializa la actualizacion de un KIM
   */
  onActualizar() {
    if (this.kim.KIM_NOMBRE != '') {
      let kimActualizar = this.kim;
      let data = kimActualizar;

      this.mainService.put('api/kim/' + data._id, data).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se actualizó el KIM exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
            this.kim = {};
          }
        },
        (err) => {
          console.error(err);
          Swal.fire('Error', err.error.message, 'error');
        }
      );
    } else {
      Swal.fire('Error', 'No se pudo actualizar el KIM, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos traidos de la BD
   */
  onCargar() {
    this.cargando = true;

    let x = this;
    this.data = [];
    this.mainService.get('api/kim?activo=true').subscribe((res) => {
      x.data = res;
      this.cargando = false;
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.kims !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el KIM?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let kimEliminar = event.data;
        this.cargando = true;
        this.mainService.delete(`api/kim/${kimEliminar._id}?kimId=${kimEliminar.KIM_ID}`).subscribe(
          (res) => {
            if (res) {
              Swal.fire('Éxito', 'KIM eliminado con éxito', 'success');
              this.onCargar();
            }
          },
          (err) => {
            console.error(err);
            Swal.fire('Error', err.error.message, 'error');
          }
        );
      }
    });
  }
}
