import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MainService } from '../../../services/main.service';
import { singleSelectsIns } from '../../../../instructions/singleSelect.ins';

import Swal from 'sweetalert2';
import { evaluate } from 'mathjs';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';

const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'proforma-detalle',
  templateUrl: './proforma-detalle.component.html',
  styleUrls: ['./proforma-detalle.component.scss'],
})
export class ProformaDetalleComponent implements OnInit {
  /** Referencia del permiso de poder editar o solo ver */
  @Input() available: boolean;
  /** Referencia al dialogo del detalle de la proforma */
  @Input() dialogRef: any;
  /** Indica si se esta creando una proforma */
  @Input() createMode: boolean; //false implica que esta en modo editar
  /** Indica si la proforma tiene nominacion */
  @Input() conNominacion: boolean;
  /** Indica el viaje asociado a la proforma */
  @Input() viaje: any;
  /** Indica la proforma actual*/
  @Input() proforma: any;
  /** Evento que indica que hubo un cambio en las proformas en la BD */
  @Output() cambioEnProformas: EventEmitter<any> = new EventEmitter<any>();

  public instructionClient = singleSelectsIns.clientProformaSinNomicacion;
  /** Formulario reactivo para la proforma*/
  public proformaForm: FormGroup;
  /** Indica el modo actual del detalle */
  public mode: string = '';
  /** Puertos traidos de la BD */
  public ports: any = [];
  /** Terminales traidas de la BD */
  public terminals: any = [];
  /** Temrinales filtradas por puerto */
  public terminalsByPort = [];
  /** Buques traidos de la BD */
  public vessels: any = [];
  /** Datos de la carta proforma traidos de la BD */
  public datosCartaProforma: any = [];
  /** Parametros del sistema traidos de la BD */
  public parametrosSistema: any = [];
  /** Factores de arqueo traidos de la BD */
  public factoresArqueo: any = [];
  /** Servicios de la proforma */
  public serviciosProforma: any = [];
  /** Servicios traidos de la BD */
  public servicios: any = [];
  /** Servicios filtrados por el puerto actualmente seleccionado */
  public serviciosPuerto: any = [];
  /** Servicios filtrados por la terminal escogida */
  public serviciosAgregar: any = [];
  /** Datos del buque seleccionado actualmente */
  public selectedVessel: any;
  /** Empresas traidas de la BD */
  public businesses: any = [];
  /** Indica si el usuario ya busco servicios relevantes*/
  public buscoServicios: boolean = false;
  /** Valor total de la proforma en formato separado por commas */
  public valorTotal: string = '';
  /** Operador del servicio que se quiere agreagar */
  public operadorServicioAgregar: string = '';
  /** Datos del servicio que se quiere agreagar */
  public servicioAAgregar: any = {
    cantidad: 0,
    valor: 0,
    tarifa: '',
    valorFormateado: '0',
  };
  public cargando = false;

  /** Referencia al select del servicio que se quiere agreagar */
  @ViewChild('servicioAgregar', { static: true }) servicioAgregar: ElementRef;
  /** Refgerencia al input de la cantidad del servicio que se quiere agreagar */
  @ViewChild('cantidadAgregarServicio', { static: true }) cantidadAgregarServicio: ElementRef;
  /** Referencia al input con el valor del servicio que se quiere agreagar */
  @ViewChild('valorAgregarServicio', { static: true }) valorAgregarServicio: ElementRef;
  /** Referencia al select de la terminal del servicio que se quiere agreagar */
  @ViewChild('terminalAgregarServicio', { static: true }) terminalAgregarServicio: ElementRef;

  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.cargando = true;
    this.configurarModoEInizializarFormulario();
  }

  /**
   * Inicializa el detalle segun el modo actual
   */
  configurarModoEInizializarFormulario() {
    if (this.createMode) {
      this.mode = 'Crear';
      if (!this.conNominacion) {
        this.initializeFormCreateSinNominacion();
      } else {
        this.initializeFormCreateConNominacion();
      }
    } else {
      this.mode = 'Editar';
      this.initializeFormEditar();
    }

    this.initializeData();
  }

  /**
   * Inicializa los datos de la BD
   */
  initializeData() {
    const vesselObservable = this.mainService.get('api/buque?activo=true');
    const businessesObservable = this.mainService.get('api/empresa_sql?activo=true');
    const paramsSystemObservable = this.mainService.get('api/parametro_sistema');
    const arqueoObservable = this.mainService.get(`api/factor_arqueo`);
    const serviciosObservable = this.mainService.get('api/tarifa?activo=true');
    const cartaProformaObservable = this.mainService.get('api/carta_proforma');
    const portObservable = this.mainService.get('api/puerto?activo=true');
    const terminalObservable = this.mainService.get('api/terminal?activo=true');

    forkJoin([
      vesselObservable,
      businessesObservable,
      paramsSystemObservable,
      arqueoObservable,
      serviciosObservable,
      cartaProformaObservable,
      portObservable,
      terminalObservable,
    ]).subscribe(([vessels, businesses, params, arqueo, servicios, cartaProforma, ports, terminals]) => {
      // vessels
      this.vessels = _.orderBy(vessels, ['BUQ_NOMBRE']);
      if (this.viaje && this.createMode)
        this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == this.viaje.vessel.id);
      if (this.proforma && !this.createMode)
        this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == this.proforma.vessel);

      // businesses
      this.businesses = _.orderBy(businesses, ['EMP_NOMBRE']);
      // populate instructions
      this.instructionClient.options = this.businesses
        .filter((company) => company.tipo.includes('CLIENTE'))
        .map((company) => ({
          value: company.EMP_ID,
          content: company.EMP_NOMBRE,
        }));

      this.parametrosSistema = params;
      this.factoresArqueo = _.orderBy(arqueo, ['rangoGRTMax'], ['asc']);
      this.servicios = _.orderBy(servicios, ['nombreServicio']);
      this.datosCartaProforma = cartaProforma;

      this.setServiciosPuerto();

      this.ports = ports.filter((port) => port.PUE_ID != 0);

      // terminals

      this.terminals = _.orderBy(terminals, ['TER_NOMBRE']);
      this.terminals = this.terminals.filter((terminal) => terminal.TER_ID != 0);
      this.terminalsByPort = this.terminals;
      this.onChangePort();

      this.cargando = false;

      if (this.viaje) {
        const puerto = ports.find((port) => port.PUE_ID == this.viaje.port.id);
        this.viaje.puertoNombre = puerto.PUE_NOMBRE;

        const terminal = this.terminals.find((terminal) => terminal.TER_ID == this.viaje.recaladas[0].terminal.id);
        this.viaje.terminalNombre = terminal.TER_NOMBRE;
      }

      this.setDataVessel();

      if (this.conNominacion) {
        try {
          this.setConnectedInformation();
          this.calculateHoursAnchorage();
          this.calculateHoursDockage();
          if (this.createMode) {
            this.setTerminalAndPort();
            this.onBotonServicios();
          }
        } catch { }
      } else if (!this.createMode) {
        // this.onBotonServicios();
      }
    });
  }

  // format information of prospectos for liquidation: ata, atb, atc, atd
  setConnectedInformation() {
    const prospectosToForm = ['eta', 'etb', 'etc', 'etd'];
    const prospectos = this.viaje.recaladas[0].prospectos;

    prospectosToForm.forEach((prospectoToForm) => {
      if (!prospectos[prospectoToForm]) return;
      const date = new Date(prospectos[prospectoToForm]);

      const dia = date.getUTCDate().toString().padStart(2, '0');
      const mes = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const anio = date.getUTCFullYear();

      const hora = date.getUTCHours().toString().padStart(2, '0');
      const minutos = date.getUTCMinutes().toString().padStart(2, '0');

      this.proformaForm.patchValue({
        [`${prospectoToForm}Date`]: `${anio}-${mes}-${dia}`,
        [`${prospectoToForm}Time`]: `${hora}:${minutos}`,
      });
    });
  }

  setTerminalAndPort() {
    this.proformaForm.patchValue({
      port: this.viaje.port.id || '',
      terminal: this.viaje.recaladas[0].terminal.id || '',
      vessel: this.viaje.vessel.id || '',
      load: this.viaje.recaladas[0].informacionExtra.load,
    });
  }

  /**
   * Inicializa el formulario para crear una proforma sin nominacion
   */
  initializeFormCreateSinNominacion() {
    this.proformaForm = new FormGroup({
      nv: new FormControl({ value: '', disabled: false }),
      etaDate: new FormControl({ value: '', disabled: false }, Validators.required),
      etaTime: new FormControl({ value: '', disabled: false }, Validators.required),
      etbDate: new FormControl({ value: '', disabled: false }, Validators.required),
      etbTime: new FormControl({ value: '', disabled: false }, Validators.required),
      etcDate: new FormControl({ value: '', disabled: false }, Validators.required),
      etcTime: new FormControl({ value: '', disabled: false }, Validators.required),
      etdDate: new FormControl({ value: '', disabled: false }, Validators.required),
      etdTime: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursAnchorage: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursDockage: new FormControl({ value: '', disabled: false }, Validators.required),
      port: new FormControl({ value: '', disabled: false }, Validators.required),
      terminal: new FormControl({ value: '', disabled: false }, Validators.required),
      vessel: new FormControl({ value: '', disabled: false }, Validators.required),
      load: new FormControl({ value: '', disabled: false }, Validators.pattern('[0-9]*[.]?[0-9]*')),
      client: new FormControl({ value: '', disabled: false }, Validators.required),
    });
  }

  /**
   * Inicializa el formulario para crear una proforma con nominacion
   */
  initializeFormCreateConNominacion() {
    this.formatViajeInput();
    this.proformaForm = new FormGroup({
      nv: new FormControl({ value: this.viaje.nv ? this.viaje.nv : '', disabled: false }),
      etaDate: new FormControl(
        { value: this.viaje.etaDate ? this.viaje.etaDate : '', disabled: false },
        Validators.required
      ),
      etaTime: new FormControl(
        { value: this.viaje.etaTime ? this.viaje.etaTime : '', disabled: false },
        Validators.required
      ),
      etbDate: new FormControl(
        { value: this.viaje.etbDate ? this.viaje.etbDate : '', disabled: false },
        Validators.required
      ),
      etbTime: new FormControl(
        { value: this.viaje.etbTime ? this.viaje.etbTime : '', disabled: false },
        Validators.required
      ),
      etcDate: new FormControl(
        { value: this.viaje.etcDate ? this.viaje.etcDate : '', disabled: false },
        Validators.required
      ),
      etcTime: new FormControl(
        { value: this.viaje.etcTime ? this.viaje.etcTime : '', disabled: false },
        Validators.required
      ),
      etdDate: new FormControl(
        { value: this.viaje.etdDate ? this.viaje.etdDate : '', disabled: false },
        Validators.required
      ),
      etdTime: new FormControl(
        { value: this.viaje.etdTime ? this.viaje.etdTime : '', disabled: false },
        Validators.required
      ),
      hoursAnchorage: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursDockage: new FormControl({ value: '', disabled: false }, Validators.required),
      port: new FormControl({ value: this.viaje.port ? this.viaje.port : '', disabled: false }, Validators.required),
      terminal: new FormControl(
        { value: this.viaje.terminal ? this.viaje.terminal : '', disabled: false },
        Validators.required
      ),
      vessel: new FormControl(
        { value: this.viaje.vessel ? this.viaje.vessel : '', disabled: false },
        Validators.required
      ),
      load: new FormControl({ value: this.viaje.load ? this.viaje.load : '', disabled: false }),
      recaladaId: new FormControl({ value: this.viaje.recaladas[0]._id, disabled: false }, Validators.required),
      client: new FormControl({ value: '', disabled: false }, Validators.required),
    });
  }

  /**
   * Inicializa el formulario para una profoma que se quiere editar
   */
  initializeFormEditar() {
    this.formatProformaInput();
    this.proformaForm = new FormGroup({
      nv: new FormControl({ value: this.proforma.nv ? this.proforma.nv : '', disabled: false }),
      etaDate: new FormControl(
        { value: this.proforma.etaDate ? this.proforma.etaDate : '', disabled: false },
        Validators.required
      ),
      etaTime: new FormControl(
        { value: this.proforma.etaTime ? this.proforma.etaTime : '', disabled: false },
        Validators.required
      ),
      etbDate: new FormControl(
        { value: this.proforma.etbDate ? this.proforma.etbDate : '', disabled: false },
        Validators.required
      ),
      etbTime: new FormControl(
        { value: this.proforma.etbTime ? this.proforma.etbTime : '', disabled: false },
        Validators.required
      ),
      etcDate: new FormControl(
        { value: this.proforma.etcDate ? this.proforma.etcDate : '', disabled: false },
        Validators.required
      ),
      etcTime: new FormControl(
        { value: this.proforma.etcTime ? this.proforma.etcTime : '', disabled: false },
        Validators.required
      ),
      etdDate: new FormControl(
        { value: this.proforma.etdDate ? this.proforma.etdDate : '', disabled: false },
        Validators.required
      ),
      etdTime: new FormControl(
        { value: this.proforma.etdTime ? this.proforma.etdTime : '', disabled: false },
        Validators.required
      ),
      hoursAnchorage: new FormControl(
        { value: this.proforma.hoursAnchorage ? this.proforma.hoursAnchorage : '', disabled: false },
        Validators.required
      ),
      hoursDockage: new FormControl(
        { value: this.proforma.hoursDockage ? this.proforma.hoursDockage : '', disabled: false },
        Validators.required
      ),
      port: new FormControl(
        { value: this.proforma.port ? this.proforma.port : '', disabled: false },
        Validators.required
      ),
      terminal: new FormControl(
        { value: this.proforma.terminal ? this.proforma.terminal : '', disabled: false },
        Validators.required
      ),
      vessel: new FormControl(
        { value: this.proforma.vessel ? this.proforma.vessel : '', disabled: false },
        Validators.required
      ),
      load: new FormControl(
        { value: this.proforma.load ? this.proforma.load : '', disabled: false },
        Validators.pattern('[0-9]*[.]?[0-9]*')
      ),
      client: new FormControl({ value: this.proforma.client || '', disabled: false }),
    });

    this.serviciosProforma = this.proforma.serviciosProforma;
    this.buscoServicios = true;
    this.calcularTotal();
  }

  /**
   * Pone la proforma en el formato del formulario de proforma
   */
  formatProformaInput() {
    if (this.proforma.eta) {
      this.proforma.eta = new Date(this.proforma.eta);
      this.proforma.etaDate = `${this.proforma.eta.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.proforma.eta.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.proforma.eta.getUTCDate())}`;
      this.proforma.etaTime = `${this.toMinimumTwoDigitNumber(
        this.proforma.eta.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.proforma.eta.getUTCMinutes())}`;
    }

    if (this.proforma.etb) {
      this.proforma.etb = new Date(this.proforma.etb);
      this.proforma.etbDate = `${this.proforma.etb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.proforma.etb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.proforma.etb.getUTCDate())}`;
      this.proforma.etbTime = `${this.toMinimumTwoDigitNumber(
        this.proforma.etb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.proforma.etb.getUTCMinutes())}`;
    }

    if (this.proforma.etc) {
      this.proforma.etc = new Date(this.proforma.etc);
      this.proforma.etcDate = `${this.proforma.etc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.proforma.etc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.proforma.etc.getUTCDate())}`;
      this.proforma.etcTime = `${this.toMinimumTwoDigitNumber(
        this.proforma.etc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.proforma.etc.getUTCMinutes())}`;
    }

    if (this.proforma.etd) {
      this.proforma.etd = new Date(this.proforma.etd);
      this.proforma.etdDate = `${this.proforma.etd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.proforma.etd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.proforma.etd.getUTCDate())}`;
      this.proforma.etdTime = `${this.toMinimumTwoDigitNumber(
        this.proforma.etd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.proforma.etd.getUTCMinutes())}`;
    }
  }

  /**
   * Maneja el evento de cambio de puerto filtrando las terminaels por ese puerto
   */
  onChangePort() {
    this.ports = _.orderBy(this.ports, ['PUE_NOMBRE']);
    const userInput = this.proformaForm.get('port').value;
    const selectedPort = this.ports.find((p) => p.PUE_ID == userInput);

    if (selectedPort) {
      this.terminalsByPort = this.terminals.filter((t) => t.PUE_ID == selectedPort.PUE_ID);
    }
  }

  /**
   * Cierra el detalle de la proforma
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Envia los datos de la proforma a la BD
   */
  onSubmit() {
    let eta;
    let etb;
    let etc;
    let etd;

    if (
      this.proformaForm.value.etaDate &&
      this.proformaForm.value.etaTime &&
      this.proformaForm.value.etbDate &&
      this.proformaForm.value.etbTime &&
      this.proformaForm.value.etcDate &&
      this.proformaForm.value.etcTime &&
      this.proformaForm.value.etdDate &&
      this.proformaForm.value.etdTime
    ) {
      function parseDateTime(dateString, timeString) {
        const [year, month, day] = dateString.split('-').map(Number);
        const [hours, minutes] = timeString.split(':').map(Number);
        return new Date(Date.UTC(year, month - 1, day, hours, minutes));
      }

      eta = parseDateTime(this.proformaForm.value.etaDate, this.proformaForm.value.etaTime);
      etb = parseDateTime(this.proformaForm.value.etbDate, this.proformaForm.value.etbTime);
      etc = parseDateTime(this.proformaForm.value.etcDate, this.proformaForm.value.etcTime);
      etd = parseDateTime(this.proformaForm.value.etdDate, this.proformaForm.value.etdTime);
    }

    const currentModeMin = this.mode.toLocaleLowerCase();

    if (this.proformaForm.invalid) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la proforma`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (!(eta <= etb && etb <= etc && etc <= etd)) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la proforma`,
        text: 'Se debe cumplir lo siguiente: (ETA < ETB < ETC < ETD)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (this.serviciosProforma.length == 0 && this.createMode) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la proforma`,
        text: 'Debe incluirse por lo menos un servicio',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      const formContent = this.proformaForm.value;
      const proforma = JSON.parse(JSON.stringify(formContent));
      proforma.eta = eta;
      proforma.etb = etb;
      proforma.etc = etc;
      proforma.etd = etd;
      proforma.serviciosProforma = [];
      this.serviciosProforma.forEach((element) => {
        const servicioAAgregar = {
          cantidad: element.cantidad,
          valor: element.valor,
          tarifa: element.tarifa._id,
        };
        proforma.serviciosProforma.push(servicioAAgregar);
      });

      if (this.createMode) {
        this.mainService.post('api/proforma', proforma).subscribe(
          (result) => {
            if (result) {
              this.cambioEnProformas.emit(result);
              Swal.fire('Éxito', 'Se agregó la proforma exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.closeDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo agregar el proforma, verifique la información', 'warning');
            }
          },
          (err) => {
            console.error(err);
            Swal.fire('Error', err.error.message, 'error');
          }
        );
      } else {
        this.mainService.put(`api/proforma/${this.proforma._id}/${this.proforma.recaladaId}`, proforma).subscribe(
          (result) => {
            if (result) {
              Swal.fire('Éxito', 'Se actualizó la proforma exitosamente', 'success').then((msg) => {
                if (msg.value) {
                  this.cambioEnProformas.emit(result);
                  this.closeDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo actualizar la proforma, verifique la información', 'warning');
            }
          },
          (err) => {
            console.error(err);
            Swal.fire('Error', err.error.message, 'error');
          }
        );
      }
    }
  }

  /**
   * Calcula las horas de fondeo segun el ETA y el ETB
   */
  calculateHoursAnchorage() {
    if (
      this.proformaForm.value.etaDate &&
      this.proformaForm.value.etaTime &&
      this.proformaForm.value.etbDate &&
      this.proformaForm.value.etbTime
    ) {
      const etaDate = this.proformaForm.value.etaDate.split('-');
      const etaTime = this.proformaForm.value.etaTime.split(':');
      const eta = Date.UTC(etaDate[0], etaDate[1] - 1, etaDate[2], etaTime[0], etaTime[1]);

      const etbDate = this.proformaForm.value.etbDate.split('-');
      const etbTime = this.proformaForm.value.etbTime.split(':');
      const etb = Date.UTC(etbDate[0], etbDate[1] - 1, etbDate[2], etbTime[0], etbTime[1]);

      const hoursAnchorage = Math.ceil((etb - eta) / 3600000);
      this.proformaForm.controls['hoursAnchorage'].setValue(hoursAnchorage);
    }
  }

  /**
   * Calcula las horas de atraque segun el ETA y el ETD
   */
  calculateHoursDockage() {
    if (
      this.proformaForm.value.etaDate &&
      this.proformaForm.value.etaTime &&
      this.proformaForm.value.etdDate &&
      this.proformaForm.value.etdTime
    ) {
      const etbDate = this.proformaForm.value.etbDate.split('-');
      const etbTime = this.proformaForm.value.etbTime.split(':');
      const etb = Date.UTC(etbDate[0], etbDate[1] - 1, etbDate[2], etbTime[0], etbTime[1]);

      const etdDate = this.proformaForm.value.etdDate.split('-');
      const etdTime = this.proformaForm.value.etdTime.split(':');
      const etd = Date.UTC(etdDate[0], etdDate[1] - 1, etdDate[2], etdTime[0], etdTime[1]);

      const hoursDockage = Math.ceil((etd - etb) / 3600000);
      this.proformaForm.controls['hoursDockage'].setValue(hoursDockage);
    }
  }

  /**
   * Formatea el objeto del viajde asocaido de la BD al formato del formulario
   */
  formatViajeInput() {
    if (this.viaje.arrivalTime) {
      this.viaje.arrivalTime = new Date(this.viaje.arrivalTime);
      this.viaje.arrivalTimeDate = `${this.viaje.arrivalTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.arrivalTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.arrivalTime.getUTCDate())}`;
      this.viaje.arrivalTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.arrivalTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.arrivalTime.getUTCMinutes())}`;
    }

    if (this.viaje.berthingTime) {
      this.viaje.berthingTime = new Date(this.viaje.berthingTime);
      this.viaje.berthingTimeDate = `${this.viaje.berthingTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.berthingTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.berthingTime.getUTCDate())}`;
      this.viaje.berthingTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.berthingTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.berthingTime.getUTCMinutes())}`;
    }

    if (this.viaje.completionOpsTime) {
      this.viaje.completionOpsTime = new Date(this.viaje.completionOpsTime);
      this.viaje.completionOpsTimeDate = `${this.viaje.completionOpsTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.completionOpsTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.completionOpsTime.getUTCDate())}`;
      this.viaje.completionOpsTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.completionOpsTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.completionOpsTime.getUTCMinutes())}`;
    }

    if (this.viaje.sailingTime) {
      this.viaje.sailingTime = new Date(this.viaje.sailingTime);
      this.viaje.sailingTimeDate = `${this.viaje.sailingTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.sailingTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.sailingTime.getUTCDate())}`;
      this.viaje.sailingTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.sailingTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.sailingTime.getUTCMinutes())}`;
    }

    if (this.viaje.eta) {
      this.viaje.eta = new Date(this.viaje.eta);
      this.viaje.etaDate = `${this.viaje.eta.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.eta.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.eta.getUTCDate())}`;
      this.viaje.etaTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.eta.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.eta.getUTCMinutes())}`;
    }

    if (this.viaje.etb) {
      this.viaje.etb = new Date(this.viaje.etb);
      this.viaje.etbDate = `${this.viaje.etb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.etb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.etb.getUTCDate())}`;
      this.viaje.etbTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.etb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.etb.getUTCMinutes())}`;
    }

    if (this.viaje.etc) {
      this.viaje.etc = new Date(this.viaje.etc);
      this.viaje.etcDate = `${this.viaje.etc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.etc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.etc.getUTCDate())}`;
      this.viaje.etcTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.etc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.etc.getUTCMinutes())}`;
    }

    if (this.viaje.etd) {
      this.viaje.etd = new Date(this.viaje.etd);
      this.viaje.etdDate = `${this.viaje.etd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.etd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.etd.getUTCDate())}`;
      this.viaje.etdTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.etd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.etd.getUTCMinutes())}`;
    }

    if (this.viaje.ata) {
      this.viaje.ata = new Date(this.viaje.ata);
      this.viaje.ataDate = `${this.viaje.ata.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCDate())}`;
      this.viaje.ataTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCMinutes())}`;
    }

    if (this.viaje.atb) {
      this.viaje.atb = new Date(this.viaje.atb);
      this.viaje.atbDate = `${this.viaje.atb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCDate())}`;
      this.viaje.atbTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCMinutes())}`;
    }

    if (this.viaje.atc) {
      this.viaje.atc = new Date(this.viaje.atc);
      this.viaje.atcDate = `${this.viaje.atc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCDate())}`;
      this.viaje.atcTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCMinutes())}`;
    }

    if (this.viaje.atd) {
      this.viaje.atd = new Date(this.viaje.atd);
      this.viaje.atdDate = `${this.viaje.atd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCDate())}`;
      this.viaje.atdTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCMinutes())}`;
    }

    if (this.viaje.fechaAnticipo) {
      this.viaje.fechaAnticipo = new Date(this.viaje.fechaAnticipo);
      this.viaje.fechaAnticipo = `${this.viaje.fechaAnticipo.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaAnticipo.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaAnticipo.getUTCDate())}`;
    }

    if (this.viaje.fechaCierrePuerto) {
      this.viaje.fechaCierrePuerto = new Date(this.viaje.fechaCierrePuerto);
      this.viaje.fechaCierrePuerto = `${this.viaje.fechaCierrePuerto.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaCierrePuerto.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaCierrePuerto.getUTCDate())}`;
    }

    if (this.viaje.fechaFacturacion) {
      this.viaje.fechaFacturacion = new Date(this.viaje.fechaFacturacion);
      this.viaje.fechaFacturacion = `${this.viaje.fechaFacturacion.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaFacturacion.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaFacturacion.getUTCDate())}`;
    }
  }

  /**
   * Maneja la accion de click del boton servicios segun el modo del detalle
   */
  onBotonServicios() {
    this.cargando = true;
    this.buscoServicios = true;

    if (this.proformaForm.invalid) {
      Swal.fire({
        title: `No se puede buscar los servicios`,
        text: 'Deben llenarse todos los campos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return;
    }

    this.serviciosProforma = [];
    const servicesSpecific = {};

    this.serviciosPuerto = this.servicios.filter((servicio) => {
      const serviceName = servicio.nombreServicio.trim().toLowerCase();

      const filterPort = servicio.puerto == 0 || servicio.puerto == this.proformaForm.get('port').value;
      const filterTerminal = servicio.terminal == 0 || servicio.terminal == this.proformaForm.get('terminal').value;
      const filterCliente =
        !servicio.cliente || servicio.cliente == 0 || servicio.cliente == this.proformaForm.get('client').value;

      const canFilter = filterPort && filterTerminal && filterCliente;

      if (!canFilter) return canFilter;

      // specific
      if (servicesSpecific[serviceName] == undefined) {
        servicesSpecific[serviceName] = [];
      }

      if (servicio.cliente == this.proformaForm.get('client').value) {
        servicesSpecific[serviceName].push(servicio._id);
      }

      return canFilter;
    });

    this.serviciosPuerto = this.serviciosPuerto.filter((servicio) => {
      const serviceName = servicio.nombreServicio.trim().toLowerCase();
      if (servicesSpecific[serviceName].length == 0) return true;
      if (servicesSpecific[serviceName].includes(servicio._id)) return true;
      return false;
    });

    this.serviciosPuerto = _.orderBy(this.serviciosPuerto, ['nombreServicio']);
    this.buscarServiciosQueApliquen();
    this.calcularTotal();

    this.cargando = false;
  }

  private setServiciosPuerto() {
    const serviciosTodosLosPuertos = this.servicios.filter((servicio) => servicio.puerto == '0');
    const serviciosPuertoEscogido = this.servicios.filter(
      (servicio) =>
        servicio.puerto == this.proformaForm.get('port').value ||
        servicio.cliente == this.proformaForm.get('client').value
    );

    this.serviciosPuerto = serviciosTodosLosPuertos.concat(serviciosPuertoEscogido);
    this.serviciosPuerto = _.orderBy(this.serviciosPuerto, ['nombreServicio']);
  }

  /**
   * Busca los servicios que apliquen a la terminal escogida por el usuario
   */
  buscarServiciosQueApliquen() {
    this.serviciosPuerto.forEach((element) => {
      if (
        (element.baseRango == 'HRS_DOCKAGE' && this.proformaForm.get('hoursDockage').value >= element.rangoMax) ||
        (element.baseRango == 'HRS_DOCKAGE' &&
          this.proformaForm.get('hoursDockage').value <= element.rangoMax &&
          this.proformaForm.get('hoursDockage').value > element.rangoMin) ||
        (element.baseRango == 'GRT' &&
          this.selectedVessel.BUQ_GT <= element.rangoMax &&
          this.selectedVessel.BUQ_GT > element.rangoMin) ||
        (element.baseRango == 'DWT' &&
          this.selectedVessel.BUQ_DWT < element.rangoMax &&
          this.selectedVessel.BUQ_DWT >= element.rangoMin) ||
        element.baseRango == 'NA'
      ) {
        if (element.baseRango === 'DWT' && element.valorCantidad === 0) element.valorCantidad = 1;
        const servicioProforma = this.crearServicioProformaPorTarifa(element);
        if (servicioProforma.cantidad) this.serviciosProforma.push(servicioProforma);
      }
    });

    this.calcularTotal();
  }

  /**
   * Adiciona un servicio en la proforma
   */
  crearServicioProformaPorTarifa(tarifa) {
    let servicioProforma = {
      cantidad: 0,
      valor: 0,
      tarifa: tarifa,
    };

    const valorTarifaUSD = this.obtenerValorTarifaUSD(servicioProforma);

    if (tarifa.baseCantidad == 'FIJA') servicioProforma.cantidad = tarifa.valorCantidad;
    else if (tarifa.baseCantidad == 'HRS_ANCHORAGE')
      servicioProforma.cantidad = this.proformaForm.get('hoursAnchorage').value;
    // Caso donde tarifa.baseCantidad == 'HRS_DOCKAGE'
    else {
      if (this.proformaForm.get('hoursDockage').value <= tarifa.rangoMax) {
        servicioProforma.cantidad = this.proformaForm.get('hoursDockage').value - tarifa.rangoMin;
      } else servicioProforma.cantidad = tarifa.rangoMax - tarifa.rangoMin;
    }
    try {
      servicioProforma.valor = evaluate(
        this.reemplazarVariablesFormulaTarifa(servicioProforma.cantidad, valorTarifaUSD, tarifa.formula)
      );
    } catch (err) {
      console.log(err);
    }

    return servicioProforma;
  }

  /**
   * Inicializa los servicios de una proforma
   */
  initializeServiciosProforma() {
    this.proforma.serviciosProforma.forEach((servicioProforma) => {
      if (!servicioProforma.tarifa.nombreServicio) {
        servicioProforma.tarifa = this.servicios.find((servicio) => servicio._id == servicioProforma.tarifa);
      }

      const servicio = {
        cantidad: servicioProforma.cantidad,
        valor: servicioProforma.valor,
        tarifa: servicioProforma.tarifa,
      };

      this.serviciosProforma.push(servicio);
    });
  }

  /**
   * Convierte un numero a un string del numero en 2 digitos minimo
   * @param number Numero que se quiere convertir
   * @return String del numero ingresado en minimo 2 digitos
   */
  toMinimumTwoDigitNumber(number) {
    if (number < 10) return `0${number}`;
    else return `${number}`;
  }

  /**
   * Elimina un servicio de la proforma
   * @servicioId Indice del servicio en el arreglo de servicios
   */
  deleteServicio(servicioId) {
    this.serviciosProforma.splice(servicioId, 1);
    this.calcularTotal();
  }

  /**
   * Reacciona al cambio de un servicio en la proforma
   * @param servicioDetails Datos del servicio que se cambio
   */
  servicioChanged(servicioDetails) {
    this.serviciosProforma[servicioDetails.servicioId].cantidad = servicioDetails.servicio.cantidad;
    this.serviciosProforma[servicioDetails.servicioId].valor = servicioDetails.servicio.valor;

    this.calcularTotal();
  }

  /**
   * Reacciona al cambio del buque escogido
   */
  onChangeVessel(idVessel) {
    this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == idVessel);
  }

  /**
   * Calcula el tatal de la proforma y hace los cambios necesarios en el modulo
   */
  calcularTotal() {
    let total = 0;

    this.serviciosProforma.forEach((element) => {
      total += element.valor;
    });

    this.valorTotal = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      total
    );
  }

  /**
   * Reacciona al cambio de la terminal del servicio a agregar
   * @idTerminal ID de la terminal escogida por el usuario
   */
  onChangeTerminalAgregarServicio(idTerminal) {
    this.serviciosAgregar = this.serviciosPuerto.filter((servicio) => servicio.terminal == idTerminal);
  }

  /**
   * Reacciona al cambio del servicio a agreagar
   * @idServicio Servicio escogido por el usuario
   */
  onChangeServicioAgregarServicio(idServicio) {
    this.servicioAAgregar.tarifa = this.serviciosAgregar.find((element) => element._id == idServicio);
    this.operadorServicioAgregar = this.servicioAAgregar.tarifa.operador;
  }

  /**
   * Agreaga un servicio al arreglo de servicio sde la proforma
   */
  onAgregarServicio() {
    const servicioAgregarInput = this.servicioAgregar.nativeElement;
    const cantidadAgregarInput = this.cantidadAgregarServicio.nativeElement;
    const valorAgregarServicioInput = this.valorAgregarServicio.nativeElement;
    const terminalAgregarServicioInput = this.terminalAgregarServicio.nativeElement;

    if (!servicioAgregarInput.value || !cantidadAgregarInput.value) {
      Swal.fire({
        title: `No se puede agregar el servicio`,
        text: 'Se debe escoger un servicio e ingresar una cantidad',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      const servicioProforma = {
        cantidad: this.servicioAAgregar.cantidad,
        valor: this.servicioAAgregar.valor,
        tarifa: this.servicioAAgregar.tarifa,
      };

      this.serviciosProforma.push(servicioProforma);
      servicioAgregarInput.value = '';
      cantidadAgregarInput.value = '';
      valorAgregarServicioInput.value = '';
      this.operadorServicioAgregar = '';
      terminalAgregarServicioInput.value = '';
      this.servicioAAgregar = {
        cantidad: 0,
        valor: 0,
        tarifa: '',
        valorFormateado: '0',
      };

      this.calcularTotal();
    }
  }

  /**
   * Reacciona al cambio de cantidad del servicio a agregar
   * @valorIngresado Valor ingresado por el usuario
   */
  onChangeCantidad(valorIngresado) {
    let valorTarifaUSD = this.obtenerValorTarifaUSD(this.servicioAAgregar);

    this.servicioAAgregar.cantidad = valorIngresado;
    this.servicioAAgregar.valor = evaluate(
      this.reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD, this.servicioAAgregar.tarifa.formula)
    );
    this.servicioAAgregar.valorFormateado = this.formatearNumero(this.servicioAAgregar.valor);

    this.calcularTotal();
  }

  /**
   * Transforma un numero a un string en formato separado por comas
   * @param valor Valor del numero que se quiere transformar
   * @return Retorna un string con el numero en formato separado por comas
   */
  formatearNumero(valor) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valor);
  }

  /**
   * Calcula el valor de una tarifa en dolares
   * @servicio Servicio con la tarifa a calcular
   * @return Valor de la tarifa en dolares
   */
  obtenerValorTarifaUSD(servicio) {
    let valorTarifaUSD = 0;

    if (servicio.tarifa.tipoTarifa == 'DINAMICA') {
      let formulaTarifaUSD = servicio.tarifa.tarifaAplicarUSD.slice();

      this.parametrosSistema.forEach((element) => {
        formulaTarifaUSD = formulaTarifaUSD.replace(element.nombre, element.valor);
      });
      formulaTarifaUSD = formulaTarifaUSD.replace('COP', servicio.tarifa.tarifaCOP);

      valorTarifaUSD = evaluate(formulaTarifaUSD);
    } else {
      valorTarifaUSD = evaluate(servicio.tarifa.tarifaAplicarUSD);
    }

    return valorTarifaUSD;
  }

  /**
   * Remplaza las variables de la formula con sus valores numericos
   * @param cantidad Cantidad del servicio
   * @param valorTarifaUSD Valor de la tarifa en dolares
   * @param formulaTarifa Formula de la Tarifa
   * @return String de la formula de la tarifa con los valores numericos de sus variables
   */
  reemplazarVariablesFormulaTarifa(cantidad, valorTarifaUSD, formulaTarifa) {
    let formulaServicio = formulaTarifa.slice();

    //Variables de tarifa y del buque
    formulaServicio = formulaServicio.replace('CANT', cantidad);
    formulaServicio = formulaServicio.replace('USD', valorTarifaUSD);
    formulaServicio = formulaServicio.replace('IVA', 1.19);
    formulaServicio = formulaServicio.replace('4x1000', 1.004);
    formulaServicio = formulaServicio.replace('DWT', this.selectedVessel.BUQ_DWT);
    formulaServicio = formulaServicio.replace('GRT', this.selectedVessel.BUQ_GT);
    formulaServicio = formulaServicio.replace('LOA', this.selectedVessel.BUQ_ESLORA);

    //Variable factor de arqueo
    let i = 0;
    let encontroFactorArqueo = false;
    while (i < this.factoresArqueo.length && !encontroFactorArqueo) {
      if (
        this.factoresArqueo[i].rangoGRTMin <= this.selectedVessel.BUQ_GT &&
        this.factoresArqueo[i].rangoGRTMax >= this.selectedVessel.BUQ_GT
      ) {
        formulaServicio = formulaServicio.replace('FA', this.factoresArqueo[i].factorArqueo);
        encontroFactorArqueo = true;
      }

      i++;
    }

    // Variables de parametros de sistema
    this.parametrosSistema.forEach((element) => {
      formulaServicio = formulaServicio.replace(element.nombre, element.valor);
    });

    return formulaServicio;
  }

  /**
   * Genera la carta de la proforma
   */
  onBotonCarta() {
    if (this.createMode) this.onBotonServicios();
    this.cargando = true;
    let doc = new jsPDF();

    //Logo
    let imgData = new Image();
    imgData.src = 'assets/img/logo-naves-dark.png';
    doc.addImage(imgData, 'png', 14, 14, 35, 11);
    let posicionVerticalActual = 40;

    // Datos de destinatario y remitente
    doc.setFontSize(12);
    doc.setFontStyle('bold');
    if (this.conNominacion) {
      const empresaAsociada = this.businesses.find((empresa) => empresa.EMP_ID == this.viaje.disponentOwner.id);
      doc.text(14, posicionVerticalActual, `To: ${empresaAsociada.EMP_NOMBRE}`, { maxWidth: 180 });
      posicionVerticalActual += 5;
      doc.text(22, posicionVerticalActual, `${empresaAsociada.EMP_DIRECCION}`, { maxWidth: 180 });
      posicionVerticalActual += 5;
      doc.text(22, posicionVerticalActual, `${empresaAsociada.EMP_CIUDAD}, ${empresaAsociada.EMP_PAIS}`, {
        maxWidth: 180,
      });
      posicionVerticalActual += 7;
    }
    doc.text(14, posicionVerticalActual, 'From: NAVES S.A.S', { maxWidth: 180 });
    posicionVerticalActual += 13;

    // Saludo y primeras palabras de la carta
    doc.setFontStyle('normal');
    doc.text(14, posicionVerticalActual, 'Good day dear Srs,', { maxWidth: 180 });
    posicionVerticalActual += 5;
    doc.text(14, posicionVerticalActual, this.datosCartaProforma.texto1, { maxWidth: 180 });
    posicionVerticalActual += 15;

    //Datos Buque
    doc.rect(14, posicionVerticalActual, 100, 21);
    doc.rect(114, posicionVerticalActual, 70, 7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(120, posicionVerticalActual, 'LOA (MTRS):');
    doc.setFontStyle('normal');
    doc.text(
      147,
      posicionVerticalActual,
      `${this.selectedVessel.BUQ_ESLORA ? this.formatearNumero(this.selectedVessel.BUQ_ESLORA) : '-'}`
    );
    posicionVerticalActual += 2;
    doc.rect(114, posicionVerticalActual, 70, 7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(20, posicionVerticalActual, this.selectedVessel.BUQ_NOMBRE);
    doc.text(120, posicionVerticalActual, 'GRT (MTNS):');
    doc.setFontStyle('normal');
    doc.text(
      147,
      posicionVerticalActual,
      `${this.selectedVessel.BUQ_GT ? this.formatearNumero(this.selectedVessel.BUQ_GT) : '-'}`
    );
    posicionVerticalActual += 2;
    doc.rect(114, posicionVerticalActual, 70, 7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(120, posicionVerticalActual, 'DWT (MTNS):');
    doc.setFontStyle('normal');
    doc.text(
      148,
      posicionVerticalActual,
      `${this.selectedVessel.BUQ_DWT ? this.formatearNumero(this.selectedVessel.BUQ_DWT) : '-'}`
    );
    posicionVerticalActual += 10;

    //Datos Proforma
    doc.rect(14, posicionVerticalActual, 170 ,7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(20, posicionVerticalActual, 'ETA:');
    const etaString = this.proforma.eta.toUTCString();
    doc.setFontStyle('normal');
    doc.text(31, posicionVerticalActual, `${etaString.substring(0, etaString.length - 7)}`);
    posicionVerticalActual += 2;
    doc.rect(14, posicionVerticalActual, 170 ,7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(20, posicionVerticalActual, 'PORT:');
    doc.setFontStyle('normal');
    const puertoProforma = this.ports.find((puerto) => puerto.PUE_ID == this.proformaForm.get('port').value);
    doc.text(35, posicionVerticalActual, `${puertoProforma.PUE_NOMBRE}`);
    posicionVerticalActual += 2;
    doc.rect(14, posicionVerticalActual, 170 ,7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(20, posicionVerticalActual, 'TERMINAL:');
    doc.setFontStyle('normal');
    const terminalProforma = this.terminals.find(
      (terminal) => terminal.TER_ID == this.proformaForm.get('terminal').value
    );
    doc.text(44, posicionVerticalActual, `${terminalProforma.TER_NOMBRE}`);
    posicionVerticalActual += 2;
    doc.rect(14, posicionVerticalActual, 170 ,7);
    posicionVerticalActual += 5;
    doc.setFontStyle('bold');
    doc.text(20, posicionVerticalActual, 'LOAD (MTNS):');
    doc.setFontStyle('normal');
    doc.text(50, posicionVerticalActual, `${this.formatearNumero(this.proformaForm.get('load').value)}`);
    posicionVerticalActual += 10;

    // Tabla con los servicios proformados y sus costos asociados
    const serviciosHeaders = ['SERVICE', 'COST'];
    const serviciosFormatoCarta = this.generarServiciosFormatoCarta();
    doc.autoTable(serviciosHeaders, serviciosFormatoCarta, {
      headStyles: { fillColor: [12, 41, 83] },
      theme: 'grid',
      startY: posicionVerticalActual,
      tableWidth: 170
    });

    // Ultimas palabras de la carta, informacion bancaria y firma
    doc.addPage();
    posicionVerticalActual = 20;
    const partesTexto2 = this.datosCartaProforma.texto2.split('\n');
    partesTexto2.forEach((parte) => {
      doc.text(14, posicionVerticalActual, parte, { maxWidth: 180 });

      posicionVerticalActual += 5 * Math.ceil(parte.length / 105);
    });
    posicionVerticalActual += 12;
    const partesDatosBancarios = this.datosCartaProforma.datosBancarios.split('\n');
    partesDatosBancarios.forEach((parte) => {
      doc.text(14, posicionVerticalActual, parte, { maxWidth: 180 });

      posicionVerticalActual += 5 * Math.ceil(parte.length / 105);
    });
    posicionVerticalActual += 12;
    const partesFirma = this.datosCartaProforma.firma.split('\n');
    partesFirma.forEach((parte) => {
      doc.text(14, posicionVerticalActual, parte, { maxWidth: 180 });

      posicionVerticalActual += 5 * Math.ceil(parte.length / 105);
    });
    doc.text(14, posicionVerticalActual, this.datosCartaProforma.direccion, { maxWidth: 180 });

    doc.save(`carta-proforma.pdf`);
    Swal.fire('Éxito', 'Se generó la carta exitosamente', 'success');
    this.cargando = false;
  }

  /**
   * Transforma los servicios a la forma necesaria para imprimir en la carta
   */
  generarServiciosFormatoCarta() {
    let serviciosFormatoCarta = [];

    this.proforma.serviciosProforma.forEach((servicio) => {
      let servicioFormatoCarta = [servicio.tarifa.nombreServicio, `$ ${this.formatearNumero(servicio.valor)}`];
      serviciosFormatoCarta.push(servicioFormatoCarta);
    });

    serviciosFormatoCarta.push(['', '']);
    serviciosFormatoCarta.push(['TOTAL', `$ ${this.valorTotal}`]);

    return serviciosFormatoCarta;
  }

  setVessel(data: string) {
    this.proformaForm.patchValue({
      vessel: data,
    });

    this.onChangeVessel(data);
  }

  public dataCollectionToVessel = [];
  setDataVessel() {
    this.dataCollectionToVessel = [];

    this.vessels.forEach((vessel) => {
      let value = `${vessel.BUQ_NOMBRE}`;
      if (!vessel.BUQ_NOMBRE) return;
      if (this.viaje) {
        value = `${value} - ${this.viaje.nv} - ${this.viaje.puertoNombre} ${this.viaje.terminalNombre}`;
      }

      this.dataCollectionToVessel.push({
        key: vessel.BUQ_ID,
        value,
      });
    });
  }
}
