import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MainService } from '../services/main.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DateAndTimeService } from '../services/date-and-time.service';

@Injectable({
  providedIn: 'root',
})
export class PqrsService {
  /** Indica si hay algo cargandose en el modulo */
  cargando: Subject<boolean> = new Subject<boolean>();
  /** Arreglo con las PQRs en la BD */
  pqrs: any;
  /** Observable con los PQRs */
  pqrsCambiadas: Subject<any> = new Subject<any>();
  /** Datos de las PQRs */
  datosPqrs: any;
  /** Observable con los datos de las PQRs */
  datosPqrsCambiados: Subject<any> = new Subject<any>();

  constructor(private mainService: MainService) {}

  /**
   * Trae todas las PQRs de la base de datos y las asigna al observable
   */
  obtenerPqrs(): void {
    this.cargando.next(true);

    this.mainService.get('api/pqr?activo=true').subscribe((res) => {
      this.pqrs = res;
      this.pqrs.forEach((element) => {
        element.nombresClientes = '';
        element.clientes.forEach((cliente) => (element.nombresClientes += `${cliente.EMP_NOMBRE}, `));
        element.nombresClientes = element.nombresClientes.slice(0, element.nombresClientes.length - 2);

        element.nombreBuque = 'Cargando ...';
        if (element.viaje && element.viaje.vessel) {
          this.mainService.get(`api/buque?BUQ_ID=${element.viaje.vessel.id}`).subscribe((resul) => {
            element.nombreBuque = resul[0].BUQ_NOMBRE;
            this.pqrs = this.pqrs.slice();
            this.pqrsCambiadas.next(this.pqrs);
            this.datosPqrs = {
              presentadas: this.pqrs.length,
              abiertas: this.pqrs.filter((pqr) => pqr.estado == 'ABIERTO').length,
              seguimiento: this.pqrs.filter((pqr) => pqr.estado == 'SEGUIMIENTO').length,
              completadas: this.pqrs.filter((pqr) => pqr.estado == 'COMPLETO').length,
            };
            this.datosPqrsCambiados.next(this.datosPqrs);
          });
        }

        element.nombreNominador = element.viaje.disponentOwner.nombre;
        element.nombrePuerto = element.viaje.port.nombre;
        element.nombreResponsable =
          element.responsable && element.responsable.nombre && element.responsable.apellido
            ? `${element.responsable.nombre} ${element.responsable.apellido}`
            : 'No registrado';
        element.nombreQuienReporta =
          element.responsable && element.responsable.nombre && element.responsable.apellido
            ? `${element.quienReporta.nombre} ${element.quienReporta.apellido}`
            : 'No registrado';
        element.estadoHtml = `<div class="estado ${element.estado ? element.estado.toLowerCase() : ''}"> ${
          element.estado
        } </div>`;
      });

      this.pqrs = _.orderBy(this.pqrs, ['createdAt'], ['desc']);
      this.pqrsCambiadas.next(this.pqrs);
      this.cargando.next(false);
    });
  }
}
