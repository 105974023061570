import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MainService } from '../services/main.service';
import { DateAndTimeService } from '../services/date-and-time.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AnalisisOperativoService {
  /** Indica si hay algo cargandose en el modulo */
  cargando = new Subject<boolean>();
  /** Viajes que no estan en estado facturado y estan activos en la BDv*/
  viajesSinFacturar: any = [];
  /** Viajes sin facturar que aplican al filtro actual */
  viajesFiltrados: any = [];
  /** Observable de los viajes */
  viajesFiltradosCambiados = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de los viajes */
  viajesCargandose = new Subject<boolean>();
  /** Buques en la BD */
  buques: any;
  /** Observable de los buques */
  buquesCambiados = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de los buques */
  buquesCargandose = new Subject<boolean>();
  /** Puertos en la BD */
  puertos: any;
  /** Observable de los puertos */
  puertosCambiados = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de los puertos */
  puertosCargandose = new Subject<boolean>();
  /** Terminales en la BD */
  terminales: any;
  /** Observable de las terminales */
  terminalesCambiadas = new Subject<any[]>();
  /** Empresas en la BD*/
  empresas: any;
  /** Observable de las empresas */
  empresasCambiadas = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de las empresas */
  empresasCargandose = new Subject<boolean>();

  /** Empresas en la BD*/
  commodities: any;
  /** Observable de las empresas */
  commoditiesCambiadas = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de las empresas */
  commoditiesCargandose = new Subject<boolean>();

  /** Empresas en la BD*/
  boardingAgents: any;
  /** Observable de las empresas */
  boardingAgentsCambiadas = new Subject<any[]>();
  /** Observable que indica que se estan cargando los datos de las empresas */
  boardingAgentsCargandose = new Subject<boolean>();

  constructor(private mainService: MainService, private dateAndTimeService: DateAndTimeService) {}

  /**
   * Inicializa los datos traidos de la BD
   */
  inicializarDatos() {
    this.viajesSinFacturar = [];

    this.iniciarCargaDeDatos();

    // Viajes
    this.mainService.get('api/analisis-operativo').subscribe((res) => {
      this.viajesSinFacturar = res;
      this.viajesSinFacturar.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
        element.etaVisual = this.convertirFechaAStringTabla(element.etaVisual);
        element.etbVisual = this.convertirFechaAStringTabla(element.etbVisual);
        element.etdVisual = this.convertirFechaAStringTabla(element.etdVisual);
      });
      this.viajesSinFacturar = _.orderBy(this.viajesSinFacturar, ['createdAt'], ['desc']);
      this.viajesFiltrados = this.viajesSinFacturar;
      this.viajesFiltradosCambiados.next(this.viajesFiltrados);
      this.viajesCargandose.next(false);
    });

    //Buques
    this.mainService.get('api/analisis-operativo/buque').subscribe((resul) => {
      this.buques = JSON.parse(JSON.stringify(resul));
      this.buques = _.orderBy(this.buques, ['nombre'], ['asc']);
      this.buquesCambiados.next(this.buques);
      this.buquesCargandose.next(false);
    });

    // Puertos
    this.mainService.get('api/analisis-operativo/puerto').subscribe((resul) => {
      this.puertos = JSON.parse(JSON.stringify(resul));
      this.puertos = _.orderBy(this.puertos, ['nombre'], ['asc']);
      this.puertosCambiados.next(this.puertos);
      this.puertosCargandose.next(false);
    });

    // Empresas
    this.mainService.get('api/analisis-operativo/disponent').subscribe((resul) => {
      this.empresas = JSON.parse(JSON.stringify(resul));
      this.empresas = _.orderBy(this.empresas, ['nombre'], ['asc']);
      this.empresasCambiadas.next(this.empresas);
      this.empresasCargandose.next(false);
    });

    // Commodities
    this.mainService.get('api/analisis-operativo/commodity').subscribe((resul) => {
      this.commodities = JSON.parse(JSON.stringify(resul));
      this.commodities = _.orderBy(this.commodities, ['nombre'], ['asc']);
      this.commoditiesCambiadas.next(this.commodities);
      this.commoditiesCargandose.next(false);
    });

    // Boarding Agents
    this.mainService.get('api/users/tipo?activo=true&tipo=Boarding Agent').subscribe((resul) => {
      this.boardingAgents = JSON.parse(JSON.stringify(resul));
      this.boardingAgentsCambiadas.next(this.boardingAgents);
      this.boardingAgentsCargandose.next(false);
    });
  }

  /**
   * Activa la carga de todos los datos traidos de la BD para el servicio
   */
  iniciarCargaDeDatos() {
    this.viajesCargandose.next(true);
    this.buquesCargandose.next(true);
    this.puertosCargandose.next(true);
    this.empresasCargandose.next(true);
    this.commoditiesCargandose.next(true);
    this.boardingAgentsCargandose.next(true);
  }

  /**
   * Convierte una fecha a un String para mostrar en la tabla
   * @param fecha Fecha de de la cual se desea el string
   * @return Un string con la fecha en el formato para ver en la tabla
   */
  convertirFechaAStringTabla(fecha: Date): string {
    if (!fecha) return 'No definido';

    const fechaPartes = this.dateAndTimeService.convertDateToDateView(fecha).split('-');
    const { hours, minutes } = this.dateAndTimeService.convertDateToHoursView(fecha);

    return `${fechaPartes[2]}/${fechaPartes[1]}/${fechaPartes[0].slice(-2)} ${hours}:${minutes}`;
  }

  /**
   * Convierte un numero positivo a un string de minimo 2 digitos
   * @param numero Numero del cual se desea obtener el string
   */
  convertirNumeroAStringMinDosDigitos(numero: number): string {
    if (numero < 10) return `0${numero}`;
    else return `${numero}`;
  }

  /**
   * Filtra los viajes no facturados segun el objeto pasado por parametro
   * @param filtro Objeto con los valores por los cuales se va a aplicar el filtro
   */
  filtrarViajes(filtro) {
    this.viajesFiltrados = this.viajesSinFacturar;

    if (filtro.viaje) this.viajesFiltrados = this.viajesFiltrados.filter((viaje) => viaje._id == filtro.viaje);

    if (filtro.buque) this.viajesFiltrados = this.viajesFiltrados.filter((viaje) => viaje.buque == filtro.buque);

    if (filtro.estado)
      this.viajesFiltrados = this.viajesFiltrados.filter((viaje) => viaje.statusExtra == filtro.estado);

    if (filtro.nominador)
      this.viajesFiltrados = this.viajesFiltrados.filter((viaje) => viaje.nominador == filtro.nominador);

    if (filtro.puerto) this.viajesFiltrados = this.viajesFiltrados.filter((viaje) => viaje.puerto == filtro.puerto);

    if (filtro.commodity)
      this.viajesFiltrados = this.viajesFiltrados.filter(
        (viaje) => viaje.commodity && viaje.commodity._id == filtro.commodity
      );

    if (filtro.boardingAgent)
      this.viajesFiltrados = this.viajesFiltrados.filter(
        (viaje) => viaje.boardingAgent && viaje.boardingAgent._id == filtro.boardingAgent
      );

    this.viajesFiltradosCambiados.next(this.viajesFiltrados);
  }
}
