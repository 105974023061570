import { Component, OnInit, ViewChild, ElementRef, TemplateRef, OnDestroy } from '@angular/core';
import { MainService } from '../../../../../services/main.service';
import { ReportesService } from '../../reportes.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormControlDirective } from '@angular/forms';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { VistaPreviaReporeProspectosComponent } from './components/vista-previa-repore-prospectos/vista-previa-repore-prospectos.component';
import * as S3 from 'aws-sdk/clients/s3';
import { S3Service } from '../../../../../services/s3.service';
import { UtilsService } from '../../../../../services/utils.service';

import { TimeService } from '../../../../../../services/time/time.service';
import { AlertService } from '../../../../../../services/alertService/alert-service.service';

@Component({
  selector: 'reporte-prospectos',
  templateUrl: './reporte-prospectos.component.html',
  styleUrls: ['./reporte-prospectos.component.scss'],
})
export class ReporteProspectosComponent implements OnInit, OnDestroy {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Formulario reactivo de los correos */
  public emailGroup: FormGroup;
  /** Formulario reactivo del reporte de prospectos*/
  public formulario: FormGroup;
  /** Archivos adjuntos en el reporte de prospectos*/
  public archivosAdjuntos: any[] = [];
  /** Posibles errores en los datos ingresados por el usuario */
  public erroresDatosIngresados: string[] = ['NINGUNO', 'FECHA_INCOMPLETA', 'MAGNITUD_FECHAS_INCORRECTA'];
  /** Indica que los archivos adjuntos se estan cargando */
  public archivosAdjuntosCargandose: boolean = false;
  /** Arreglo con los correos de los contactos asociados al viaje */
  public emailsContactosViaje: any[] = [];
  /** Arreglo con todas las áreas con sus correos */
  public empresasAreasConCorreos: any[] = [];
  /** Arreglo con todas las áreas de las empresas */
  public areasEmpresas: any[] = [];
  /** Email del puerto asociado */
  public emailPuerto: string = '';
  /** Arreglo con los posibles errores al intentar enviar un correo */
  public erroresEnvioCorreo: string[] = ['NINGUNO', 'SIN_ASUNTO', 'SIN_CONTACTOS'];

  /** Lista de correos con copia */
  public ccs: any[] = [];
  /** Nuevo correo para agregar a la copia */
  public nuevoCC: FormControl = new FormControl('', [Validators.email, Validators.pattern('')]);
  /** Nuevo correo para agregar a la copia */
  public nuevoBCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Lista de correos con copia oculta */
  public bccs: any[] = [];
  /** Indica si se está enviando correo */
  public enviandoCorreo: boolean = false;
  /** Indica si desea enviar correo */
  public enviarCorreo: boolean = false;

  /** Referencia al archivo que se va a adjuntar */
  @ViewChild('archjivoAAdjuntar', { static: true }) archjivoAAdjuntar: ElementRef;
  /** Referencia al modal para guardar cambios antes de ir a vista previa */
  @ViewChild('guardarVistaPrevia', { static: false }) guardarVistaPrevia: TemplateRef<any>;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  public tiposHoras = ['AM', 'PM'];
  public prueba: boolean = false;

  public vistaPrevia: boolean;
  public vistaPreviaDialog: any;

  /** Imágenes del buque */
  public fotoBuque: string;
  public iconoBuque: string;

  /** bucket de s3 */
  private bucket: S3;
  /** URL del documento adjunto */
  private documentURL: string;
  public buqueCorreo: string;

  constructor(
    public router: Router,
    private mainService: MainService,
    private reportesService: ReportesService,
    private dialogService: NbDialogService,
    private _location: Location,
    private s3Service: S3Service,
    private utilsService: UtilsService,
    private timeService: TimeService,
    private alertService: AlertService
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  ngOnInit() {
    this.inicializarFormualrioVacio();
    this.obtenerPermisos();
  }

  ngOnDestroy(): void {
    if(!this.vistaPreviaDialog) return
    this.vistaPreviaDialog.close();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.obtenerViaje();
      }
    });
  }

  public selectedSubject: string;
  public setSubject(subjectSelected?: string) {
    this.formulario.patchValue({
      asuntoReporte: subjectSelected || this.selectedSubject,
    });

    this.selectedSubject = subjectSelected || this.selectedSubject;
  }

  public deaultSubjects: string[] = [];
  public setDefaultSubjects() {
    const nv = this.viaje.nv;
    const vesselName = this.viaje.buqueNombre;
    const portName = this.viaje.puertoNombre;
    const voyageNumber = this.viaje.voyageNumber ? `${this.viaje.voyageNumber + ' -'}` : '';

    this.deaultSubjects.push(`MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - ARRIVAL BERTHING PROSPECTS`);

    this.selectedSubject = this.deaultSubjects[0];
    this.setSubject(this.formulario.value.asuntoReporte);
  }

  async obtenerViaje(): Promise<boolean> {
    this.cargando = true;
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');
    return new Promise(async (resolve, reject) => {
      this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
        if (res.message) {
          Swal.fire({
            title: '¡Error!',
            text: res.message,
            type: 'error',
          });
          return this.router.navigate(['home/dashboard']);
        }
        this.viaje = res;
        this.emailPuerto = this.viaje.puertoCorreo;
        this.obtenerCorreosYEmpresasAsociados();
        this.obtenerTiemposEstimadosEnFormatoVisual();

        const savedSubject = this.formulario.value.asuntoReporte;
        const savedFiles = this.archivosAdjuntos;
        // if (this.viaje.sof.length > 0) {
        //   this.obtenerActividadesSof(this.viaje.sof[0]);
        // }
        await this.inicializarViajeEnFormatoRequerido();
        this.inicializarFormualrioConDatosViaje();
        // this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
        this.archivosAdjuntos = savedFiles;
        this.formulario.patchValue({ asuntoReporte: savedSubject });
        this.cargando = false;

        this.setDefaultSubjects();
        resolve(true);
      });
    });
  }

  /**
   * Inicializa el viaje en el formato requerido para el componente de reporte de prospectos
   */
  inicializarViajeEnFormatoRequerido(): void {
    this.obtenerNombresAsociacionesRelevantesViaje();
    this.fechasEnFormatoFormulario();
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de prospectos, también trae los iconos e imagenes del buque
   */
  obtenerNombresAsociacionesRelevantesViaje(): void {
    // Nombre del buque e imágenes
    if (this.viaje.vessel) {
      this.mainService.get(`api/buque?BUQ_ID=${this.viaje.vessel}`).subscribe((res) => {
        this.buqueCorreo = res.length > 0 ? res[0].BUQ_CORREO : undefined;
        this.viaje.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
        this.iconoBuque = res.length > 0 ? res[0].BUQ_FOTO_ICONO : undefined;
        this.fotoBuque = res.length > 0 ? res[0].BUQ_FOTO_REAL : undefined;
      });
    } else this.viaje.buqueNombre = 'Not registered';
  }

  /**
   * Obtiene en formato visual los tiemos estimados
   */
  obtenerTiemposEstimadosEnFormatoVisual(): void {
    this.viaje.etaVisual =
      this.viaje && this.viaje.eta && !this.viaje.etaSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.eta)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.eta, this.viaje.etaAMPM);
    this.viaje.etbVisual =
      this.viaje && this.viaje.etb && !this.viaje.etbSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etb)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etb, this.viaje.etbAMPM);
    this.viaje.etcVisual =
      this.viaje && this.viaje.etc && !this.viaje.etcSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etc, this.viaje.etcAMPM);
    this.viaje.etdVisual =
      this.viaje && this.viaje.etd && !this.viaje.etdSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etd, this.viaje.etdAMPM);
  }

  /**
   * Trae los correos de los contactos asociados al viaje
   */
  obtenerCorreosYEmpresasAsociados() {
    // this.emailsContactosViaje = [];
    this.mainService.get(`api/empresa`).subscribe((areasEmpresas) => {
      this.areasEmpresas = areasEmpresas;
      this.getContacts();
    });
  }

  /**
   * Ordena los contactos para que la persona los seleccione y luego se puedan mandar por correo
   */
  getContacts() {
    let arrayFinalSendgrid = [];
    for (let contacto of this.viaje.recieversShippersOthers) {
      const bd = this.mainService.get(`api/contacto_empresa/areaEmpresa/${contacto.areaId}`);
      bd.subscribe((contactosEmpresas) => {
        if (contactosEmpresas && contactosEmpresas[0]) {
          const posiblesEmpresas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreEmpresa).flat();
          const areasEmpresasFiltradas = posiblesEmpresas.filter((area, index) => {
            return posiblesEmpresas.indexOf(area) === index;
          });
          const posiblesAreas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreArea).flat();
          const areasAreasFiltradas = posiblesAreas.filter((area, index) => {
            return posiblesAreas.indexOf(area) === index;
          });
          for (let empresa of areasEmpresasFiltradas) {
            for (let area of areasAreasFiltradas) {
              const correos = contactosEmpresas
                .filter((conEmpresa) => {
                  return conEmpresa.nombreEmpresa[0] === empresa && conEmpresa.nombreArea[0] === area;
                })
                .map((contacto) => {
                  return { email: contacto.correo, nombre: contacto.nombre };
                });

              const empresaAreaContactos = {
                empresa,
                area,
                correos,
              };

              arrayFinalSendgrid.push(empresaAreaContactos);
              this.empresasAreasConCorreos = arrayFinalSendgrid;
            }
          }
        }
      });
    }
  }

  /**
   * Pone las fechas del viaje que son relevantes al componente de reporte de prospectos en formato del formulario
   */
  fechasEnFormatoFormulario(): void {
    // ETA
    if (this.viaje.eta) {
      /** ETA en tipo Date */
      let etaDate = new Date(this.viaje.eta);
      if (this.viaje.etaSinHora) {
        this.viaje.etaFecha = this.reportesService.obtenerFechaFormulario(etaDate);
      } else {
        this.viaje.etaFecha = this.reportesService.obtenerFechaFormulario(etaDate);
        this.viaje.etaHora = this.reportesService.obtenerHoraFormulario(etaDate);
      }
    }

    // ETB
    if (this.viaje.etb) {
      /** ETB en tipo Date */
      let etbDate = new Date(this.viaje.etb);
      if (this.viaje.etbSinHora) {
        this.viaje.etbFecha = this.reportesService.obtenerFechaFormulario(etbDate);
      } else {
        this.viaje.etbFecha = this.reportesService.obtenerFechaFormulario(etbDate);
        this.viaje.etbHora = this.reportesService.obtenerHoraFormulario(etbDate);
      }
    }

    // ETC
    if (this.viaje.etc) {
      /** ETC en tipo Date */
      let etcDate = new Date(this.viaje.etc);
      if (this.viaje.etcSinHora) {
        this.viaje.etcFecha = this.reportesService.obtenerFechaFormulario(etcDate);
      } else {
        this.viaje.etcFecha = this.reportesService.obtenerFechaFormulario(etcDate);
        this.viaje.etcHora = this.reportesService.obtenerHoraFormulario(etcDate);
      }
    }

    // ETD
    if (this.viaje.etd) {
      /** ETD en tipo Date */
      let etdDate = new Date(this.viaje.etd);
      if (this.viaje.etdSinHora) {
        this.viaje.etdFecha = this.reportesService.obtenerFechaFormulario(etdDate);
      } else {
        this.viaje.etdFecha = this.reportesService.obtenerFechaFormulario(etdDate);
        this.viaje.etdHora = this.reportesService.obtenerHoraFormulario(etdDate);
      }
    }
  }

  /**
   * Inicializa el formulario con los datos vacios
   * (Esta fucnion tiene como error prevebnir el error de que el formulario se renderice antes de obtener los datos del viaje)
   */
  inicializarFormualrioVacio(): void {
    this.emailGroup = new FormGroup({
      nuevoCC: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        ),
      ]),
      nuevoBCC: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        ),
      ]),
    });
    this.formulario = new FormGroup({
      etaFecha: new FormControl({ value: '', disabled: false }),
      etaHora: new FormControl({ value: '', disabled: false }),
      etaAMPM: new FormControl({ value: undefined, disabled: false }),
      etaSinHora: new FormControl({ value: '', disabled: false }),
      etbFecha: new FormControl({ value: '', disabled: false }),
      etbHora: new FormControl({ value: '', disabled: false }),
      etbAMPM: new FormControl({ value: undefined, disabled: false }),
      etbSinHora: new FormControl({ value: '', disabled: false }),
      etcFecha: new FormControl({ value: '', disabled: false }),
      etcHora: new FormControl({ value: '', disabled: false }),
      etcAMPM: new FormControl({ value: undefined, disabled: false }),
      etcSinHora: new FormControl({ value: '', disabled: false }),
      etdFecha: new FormControl({ value: '', disabled: false }),
      etdHora: new FormControl({ value: '', disabled: false }),
      etdAMPM: new FormControl({ value: undefined, disabled: false }),
      etdSinHora: new FormControl({ value: '', disabled: false }),
      arriveFwd: new FormControl({ value: '', disabled: false }),
      arriveAft: new FormControl({ value: '', disabled: false }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: '', disabled: false }),
    });
  }

  /**
   * Inicializa el formulario con los datos del viaje
   */
  inicializarFormualrioConDatosViaje(): void {
    this.formulario = new FormGroup({
      etaFecha: new FormControl({ value: this.viaje.etaFecha ? this.viaje.etaFecha : '', disabled: false }),
      etaHora: new FormControl({
        value: this.viaje.etaHora ? this.viaje.etaHora : '',
        disabled: false,
      }),
      etaSinHora: new FormControl({
        value: this.viaje.etaSinHora ? this.viaje.etaSinHora : '',
        disabled: false,
      }),
      etaAMPM: new FormControl({
        value: this.viaje.etaAMPM ? this.viaje.etaAMPM : '',
        disabled: false,
      }),
      etbFecha: new FormControl({ value: this.viaje.etbFecha ? this.viaje.etbFecha : '', disabled: false }),
      etbHora: new FormControl({
        value: this.viaje.etbHora ? this.viaje.etbHora : '',
        disabled: false,
      }),
      etbSinHora: new FormControl({
        value: this.viaje.etbSinHora ? this.viaje.etbSinHora : '',
        disabled: false,
      }),
      etbAMPM: new FormControl({
        value: this.viaje.etbAMPM ? this.viaje.etbAMPM : '',
        disabled: false,
      }),
      etcFecha: new FormControl({ value: this.viaje.etcFecha ? this.viaje.etcFecha : '', disabled: false }),
      etcHora: new FormControl({
        value: this.viaje.etcHora ? this.viaje.etcHora : '',
        disabled: false,
      }),
      etcSinHora: new FormControl({
        value: this.viaje.etcSinHora ? this.viaje.etcSinHora : '',
        disabled: false,
      }),
      etcAMPM: new FormControl({
        value: this.viaje.etcAMPM ? this.viaje.etcAMPM : '',
        disabled: false,
      }),
      etdFecha: new FormControl({ value: this.viaje.etdFecha ? this.viaje.etdFecha : '', disabled: false }),
      etdHora: new FormControl({
        value: this.viaje.etdHora ? this.viaje.etdHora : '',
        disabled: false,
      }),
      etdSinHora: new FormControl({
        value: this.viaje.etdSinHora ? this.viaje.etdSinHora : '',
        disabled: false,
      }),
      etdAMPM: new FormControl({
        value: this.viaje.etdAMPM ? this.viaje.etdAMPM : '',
        disabled: false,
      }),
      arriveFwd: new FormControl({
        value: this.viaje.arriveFwd || this.viaje.arriveFwd == 0 ? this.viaje.arriveFwd : '',
        disabled: false,
      }),
      arriveAft: new FormControl({
        value: this.viaje.arriveAft || this.viaje.arriveAft == 0 ? this.viaje.arriveAft : '',
        disabled: false,
      }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: this.viaje.recalada.remark ? this.viaje.remark : '', disabled: false }),
    });
  }

  /**
   * Alista los datos para enviarlos a la BD
   *
   */
  onGuardarReporte({ vistaPrev = false, enviarCorreo = false }) {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.vistaPrevia = vistaPrev;
    this.enviarCorreo = enviarCorreo;
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.cargando = true;
    const errorDatosIngresados = this.identificarErroresDatosIngresados();

    if (errorDatosIngresados !== this.erroresDatosIngresados[0]) {
      this.indicarErrorEnDatosIngresados(errorDatosIngresados);

    } else {
      /** Objeto con los datos de salida para guardar en la BD */
      const datosSalida = this.transformarDatosForm(this.formulario);
      this.enviarDatosABD(datosSalida);
    }
  }

  transformarDatosForm(form) {
    let datosSalida = {
      prospectos: {
        eta: null,
        etaSinHora: null,
        etaAMPM: null,
        etb: null,
        etbSinHora: null,
        etbAMPM: null,
        etc: null,
        etcSinHora: null,
        etcAMPM: null,
        etd: null,
        etdSinHora: null,
        etdAMPM: null,
      },
      adConditions: {
        arriveFwd: null,
        arriveAft: null,
      },
      remark: null,
    };

    // ETA
    if (form.value.etaFecha && form.value.etaHora && !form.value.etaSinHora) {
      datosSalida.prospectos.eta = this.reportesService.obtenerFechaUTC(form.value.etaFecha, form.value.etaHora);
    }
    if (form.value.etaFecha && form.value.etaSinHora && form.value.etaAMPM) {
      datosSalida.prospectos.eta = this.reportesService.obtenerFechaUTCSinHora(form.value.etaFecha);
      datosSalida.prospectos.etaSinHora = form.value.etaSinHora;
      datosSalida.prospectos.etaAMPM = form.value.etaAMPM;
    }

    // ETB
    if (form.value.etbFecha && form.value.etbHora && !form.value.etbSinHora) {
      datosSalida.prospectos.etb = this.reportesService.obtenerFechaUTC(form.value.etbFecha, form.value.etbHora);
    }
    if (form.value.etbFecha && form.value.etbSinHora && form.value.etbAMPM) {
      datosSalida.prospectos.etb = this.reportesService.obtenerFechaUTCSinHora(form.value.etbFecha);
      datosSalida.prospectos.etbSinHora = form.value.etbSinHora;
      datosSalida.prospectos.etbAMPM = form.value.etbAMPM;
    }

    // ETC
    if (form.value.etcFecha && form.value.etcHora && !form.value.etcSinHora) {
      datosSalida.prospectos.etc = this.reportesService.obtenerFechaUTC(form.value.etcFecha, form.value.etcHora);
    }
    if (form.value.etcFecha && form.value.etcSinHora && form.value.etcAMPM) {
      datosSalida.prospectos.etc = this.reportesService.obtenerFechaUTCSinHora(form.value.etcFecha);
      datosSalida.prospectos.etcSinHora = form.value.etcSinHora;
      datosSalida.prospectos.etcAMPM = form.value.etcAMPM;
    }

    // ETD
    if (form.value.etdFecha && form.value.etdHora && !form.value.etdAMPM) {
      datosSalida.prospectos.etd = this.reportesService.obtenerFechaUTC(form.value.etdFecha, form.value.etdHora);
    }
    if (form.value.etdFecha && form.value.etdSinHora && form.value.etdAMPM) {
      datosSalida.prospectos.etd = this.reportesService.obtenerFechaUTCSinHora(form.value.etdFecha);
      datosSalida.prospectos.etdSinHora = form.value.etdSinHora;
      datosSalida.prospectos.etdAMPM = form.value.etdAMPM;
    }

    // Arrive Fwd
    if (form.value.arriveFwd || form.value.arriveFwd == 0) {
      datosSalida.adConditions.arriveFwd = form.value.arriveFwd;
    }

    // Arrive Aft
    if (form.value.arriveAft || form.value.arriveAft == 0) {
      datosSalida.adConditions.arriveAft = form.value.arriveAft;
    }

    // Remark
    if (form.value.remark) {
      datosSalida.remark = form.value.remark;
    }
    return datosSalida;
  }

  /** Cambia de hora precisa a hora estimada y viceversa, borrando los datos de la que se qutó para que no se guarden */
  changeCheckboxStatus(checkbox) {
    switch (checkbox) {
      case 'etaSinHora':
        if (!this.formulario.value.etaSinHora) {
          this.formulario.patchValue({ etaAMPM: undefined });
        } else {
          this.formulario.patchValue({ etaHora: undefined });
        }
        break;
      case 'etbSinHora':
        if (!this.formulario.value.etbSinHora) {
          this.formulario.patchValue({ etbAMPM: undefined });
        } else {
          this.formulario.patchValue({ etbHora: undefined });
        }
        break;
      case 'etcSinHora':
        if (!this.formulario.value.etcSinHora) {
          this.formulario.patchValue({ etcAMPM: undefined });
        } else {
          this.formulario.patchValue({ etcHora: undefined });
        }
        break;
      case 'etdSinHora':
        if (!this.formulario.value.etdSinHora) {
          this.formulario.patchValue({ etdAMPM: undefined });
        } else {
          this.formulario.patchValue({ etdHora: undefined });
        }
        break;
    }
  }

  /**
   * Envia los datos ingresados por el usaurio a la BD
   * @param datosSalida Datos que van a persistir
   */
  enviarDatosABD(datosSalida) {
    this.mainService
      .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, datosSalida)
      .subscribe(async (data) => {
        this.cargando = false;
        await this.obtenerViaje();

        if (!this.enviarCorreo && !this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente');
        }

        if (this.enviarCorreo) {
          this.onEnviarCorreoReporte();
          this.enviarCorreo = false;
          return;
        }

        if (this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente').then((result) => {
            this.vistaPreviaDialog = this.dialogService.open(VistaPreviaReporeProspectosComponent, {
              context: {
                companyName: this.empresasAreasConCorreos.length !== 0 && this.empresasAreasConCorreos[0].empresa,
                asunto: this.formulario.value.asuntoReporte,
                parrafosRemarks: this.formulario.value.remark.split('\n'),
                imagenBuque: this.fotoBuque,
                iconBuque: this.iconoBuque,
                attachments: this.archivosAdjuntos,
                correosCopia: this.ccs.map((cc) => cc.email),
                correosCopiaOculta: this.bccs.map((bcc) => bcc.email),
                anchoredInformation: this.anchoredInformation,
                showAnchoredInformation: this.showAnchoredInformation,
              },
            });
            // .onClose.subscribe((act) => {
            //   this.vistaPrevia = false;
            // });
          });

          return;
        }

        this.vistaPrevia = false;
        this.enviarCorreo = false;

        this.obtenerViaje();
        return;
      });
  }

  /**
   * Identifica si hay errores en los datos ingresados por el usuario
   */
  identificarErroresDatosIngresados(): string {
    if (this.errorFechaIncompletaPresente()) return this.erroresDatosIngresados[1];
    if (this.errorMagnitudFechasIncorrecta()) return this.erroresDatosIngresados[2];

    return this.erroresDatosIngresados[0];
  }

  /**
   * Indica al usuario del error en los datos ingresados
   */
  indicarErrorEnDatosIngresados(error: string): void {
    if (error == this.erroresDatosIngresados[1]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Todas las fechas deben tener tanto fecha como hora',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (error == this.erroresDatosIngresados[2]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Se debe cumplir lo siguiente: (ETA < ETB < ETC < ETD)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    }

    this.cargando = false;
  }

  /**
   * Indica si el error de fecha incompleta esta presente
   */
  errorFechaIncompletaPresente(): boolean {
    if (
      !this.formulario.value.etaFecha &&
      (!this.formulario.value.etaHora || this.formulario.value.etaHora == '') &&
      (this.formulario.value.etaAMPM == '' || this.formulario.value.etaAMPM == undefined)
    ) {
      return true;
    }
    if (
      !this.formulario.value.etaFecha &&
      ((this.formulario.value.etaHora && this.formulario.value.etaHora != '') || this.formulario.value.etaAMPM)
    ) {
      return true;
    }

    if (
      this.formulario.value.etaFecha &&
      (!this.formulario.value.etaHora || this.formulario.value.etaHora == '')
    ) {
      return true;
    }
    // ETB
    if (
      !this.formulario.value.etbFecha &&
      (!this.formulario.value.etbHora || this.formulario.value.etbHora == '') &&
      (this.formulario.value.etbAMPM == '' || this.formulario.value.etbAMPM == undefined)
    ) {
      return true;
    }

    if (
      !this.formulario.value.etbFecha &&
      ((this.formulario.value.etbHora && this.formulario.value.etbHora != '') || this.formulario.value.etbAMPM)
    ) {
      return true;
    }

    if (
      this.formulario.value.etbFecha &&
      (!this.formulario.value.etbHora || this.formulario.value.etbHora == '')
    ) {
      return true;
    }

    // ETC
    if (
      !this.formulario.value.etcFecha &&
      (!this.formulario.value.etcHora || this.formulario.value.etcHora == '') &&
      (this.formulario.value.etcAMPM == '' || this.formulario.value.etcAMPM == undefined)
    ) {
      return true;
    }
    if (
      !this.formulario.value.etcFecha &&
      ((this.formulario.value.etcHora && this.formulario.value.etcHora != '') || this.formulario.value.etcAMPM)
    ) {
      return true;
    }
    if (
      this.formulario.value.etcFecha &&
      (!this.formulario.value.etcHora || this.formulario.value.etcHora == '')
    ) {
      return true;
    }
    // ETD
    if (
      !this.formulario.value.etdFecha &&
      (!this.formulario.value.etdHora || this.formulario.value.etdHora == '') &&
      (this.formulario.value.etdAMPM == '' || this.formulario.value.etdAMPM == undefined)
    ) {
      return true;
    }
    if (
      !this.formulario.value.etdFecha &&
      ((this.formulario.value.etdHora && this.formulario.value.etdHora != '') || this.formulario.value.etdAMPM)
    ) {
      return true;
    }
    if (
      this.formulario.value.etdFecha &&
      (!this.formulario.value.etdHora || this.formulario.value.etdHora == '')
    ) {
      return true;
    }

    return false;
  }

  /**
   * Indica si no se esta cumpliendo la condición: ETA <= ETB <= ETC <= ETD
   */
  errorMagnitudFechasIncorrecta(): boolean {
    let eta = 0;
    let etb = 0;
    let etc = 0;
    let etd = 0;

    // Obtener las fechas en formato Date
    if (this.formulario.value.etaFecha && this.formulario.value.etaHora)
      eta = this.reportesService.obtenerFechaUTC(this.formulario.value.etaFecha, this.formulario.value.etaHora);

    if (this.formulario.value.etbFecha && this.formulario.value.etbHora)
      etb = this.reportesService.obtenerFechaUTC(this.formulario.value.etbFecha, this.formulario.value.etbHora);

    if (this.formulario.value.etcFecha && this.formulario.value.etcHora)
      etc = this.reportesService.obtenerFechaUTC(this.formulario.value.etcFecha, this.formulario.value.etcHora);

    if (this.formulario.value.etdFecha && this.formulario.value.etdHora)
      etd = this.reportesService.obtenerFechaUTC(this.formulario.value.etdFecha, this.formulario.value.etdHora);

    // Comparar fechas para asegurarse que ETA <= ETB <= ETC <= ETD
    if (eta <= etb && etb <= etc && etc <= etd) return false;

    return true;
  }

  /**
   * Activa el modal para guardar cambios antes de ir a la vista previa
   */
  activarModalVistaPrevia() {
    this.getAnchoredInfo();
    if (this.viaje.status === 'CANCELED') return;
    this.vistaPreviaDialog = this.dialogService.open(this.guardarVistaPrevia, { context: '' });
  }

  get f() {
    return this.emailGroup.controls;
  }

  /**
   * Agrega un nuevo correo a la lista de correos con copia o con copia oculta
   */
  agregarExtraCorreo(extra, lista) {
    if (extra.valid) {
      if (
        this.ccs.find((cc) => cc.email.toLowerCase() === extra.value.toLowerCase()) ||
        this.bccs.find((bcc) => bcc.email.toLowerCase() === extra.value.toLowerCase())
      ) {
        Swal.fire({
          title: '¡Error!',
          text: 'No se puede agregar un correo que ya se encuentre en la lista.',
          type: 'error',
        });
        return;
      }
      if (lista === 'cc') {
        this.ccs.push({ email: extra.value });
        this.emailGroup.get('nuevoCC').setValue('');
      } else {
        this.bccs.push({ email: extra.value });
        this.emailGroup.get('nuevoBCC').setValue('');
      }
    } else {
      Swal.fire({
        title: '¡Error!',
        text: 'El correo no tiene el formato adecuado.',
        type: 'error',
      });
    }
  }

  /**
   * Redirigue a pagina con la visa previa del correo
   */
  onIrVistaPreviaCorreo() {
    this.vistaPreviaDialog.close();
    this.vistaPreviaDialog = undefined;
    this.onGuardarReporte({ vistaPrev: true });
  }

  /**
   * Procede a guardar y posteriormente a enviar el correo
   */
  onGuardarInfoEnviarCorreo() {
    this.onGuardarReporte({ enviarCorreo: true });
  }

  /**
   * Envia el correo con el repore
   */

  onEnviarCorreoReporte(): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.cargando = true;

    if (this.encontrarErroresEnvioCorreo() == this.erroresEnvioCorreo[0]) {
      this.enviandoCorreo = true;
      const emails = [];

      for (let destinatario of this.emailsContactosViaje) {
        delete destinatario.nombre;

        const html = this.generarHTMLCorreo(destinatario.empresa);

        const msg = {
          to: destinatario.correos,
          cc: this.ccs,
          bcc: this.bccs,
          html: html,
          attachments: this.archivosAdjuntos,
          fromEmail: this.emailPuerto,
          subject: this.formulario.value.asuntoReporte,
        };

        emails.push(msg);
      }
      console.log(emails)
      this.mainService.post(`api/sendEmail`, { emails, puerto: this.viaje.puertoNombre }).subscribe((result) => {
        this.emailsContactosViaje = [];
        this.addedVesselEmail = false;
        this.enviandoCorreo = false;
        this.alertService.simpleAlertConfirm('Reporte enviado y datos guardados exitosamente');

        const reporteEnviado = {
          tipoReporte: 'PROSPECTOS',
          fechaDeEnvio: Date.now(),
        };

        if (result.success) {
          this.viaje.reportesEnviados
            ? this.viaje.reportesEnviados.push(reporteEnviado)
            : (this.viaje.reportesEnviados = [reporteEnviado]);

          this.mainService
            .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, {
              reporteEnviado,
            })
            .subscribe((res) => {
              this.obtenerViaje();
            });
        } else {
          Swal.fire({
            title: 'Se generó un problema al enviar el correo',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });
          this.cargando = false;
        }
      });
    }
  }

  generarHTMLCorreo(companyName: string): string {
    const spacerItems = '<br><br>';
    const spacerItem = '<br>';
    const spacerHeader = '<br><br><br>';

    for (let archivoAdjunto of this.archivosAdjuntos) {
      delete archivoAdjunto.path;
    }

    let html = `<!DOCTYPE html>
		<html lang="en">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
		</head>
		<body style="color:black !important;">`;

    html += '<table style="border-collapse: collapse;">';

    // header
    if (companyName) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TO:</td><td>${companyName}</td></tr>`;
    }

    if (this.viaje.commodityNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">CARGO:</td><td>${this.viaje.commodityNombre}</td></tr>`;
    }

    if (this.viaje.buqueNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">VESSEL:</td><td>${
        this.viaje.buqueNombre
      } ${this.viaje.voyageNumber? this.viaje.voyageNumber: '' && '- ' + this.viaje.voyageNumber} - ${this.viaje.nv}</td></tr>`;
    }

    if (this.viaje.terminalNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TERMINAL:</td><td>${this.viaje.terminalNombre}</td></tr>`;
    }

    if (this.viaje.puertoNombre) {
      html += `<tr><td style="padding-right: 0.5rem">PORT:</td><td>${this.viaje.puertoNombre}</td></tr>`;
    }

    html += '</table>';
    html += spacerHeader;

    // image

    if (this.iconoBuque && this.iconoBuque != 'NULL') {
      html += `<img src=${this.iconoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    if (this.fotoBuque && this.fotoBuque != 'NULL') {
      html += `<img src=${this.fotoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    // prospects report

    html += '<p style="margin: 0;">Please find below arrival prospect report of mentioned vessel</p>';
    html += spacerItem;

    if (this.viaje.etaVisual || this.viaje.etbVisual || this.viaje.etcVisual || this.viaje.etdVisual) {
      html += '<table style="table-layout:fixed;max-width:400px;"><tbody>';

      if (this.viaje.etaVisual) {
        html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">ETA:</td><td>${
          this.viaje.etaSinHora ? this.viaje.etaVisual || 'Not defined AM/PM' : this.viaje.etaVisual || 'Not defined'
        }</td></tr>`;
      }

      if (this.viaje.etbVisual) {
        html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">ETB:</td><td>${
          this.viaje.etbSinHora ? this.viaje.etbVisual || 'Not defined AM/PM' : this.viaje.etbVisual || 'Not defined'
        }</td></tr>`;
      }

      if (this.viaje.etcVisual) {
        html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">ETC:</td><td>${
          this.viaje.etcSinHora ? this.viaje.etcVisual || 'Not defined AM/PM' : this.viaje.etcVisual || 'Not defined'
        }</td></tr>`;
      }

      if (this.viaje.etdVisual) {
        html += `<tr><td style="padding-right: 0.5rem">ETD:</td><td>${
          this.viaje.etdSinHora ? this.viaje.etdVisual || 'Not defined AM/PM' : this.viaje.etdVisual || 'Not defined'
        }</td></tr>`;
      }

      html += '</tbody></table>';
      html += spacerItems;
    }

    if (this.viaje.arriveFwd || this.viaje.arriveAft) {
      html += '<p style="margin: 0;">DRAFTS</p>';
      html += spacerItem;
      html += '<table style="table-layout:fixed; max-width:400px; color:black;"><tbody>';

      const valueArriveFWD = this.viaje.arriveFwd ? `${this.viaje.arriveFwd} M` : 'Not registered';
      const valueArriveAFT = this.viaje.arriveAft ? `${this.viaje.arriveAft} M` : 'Not registered';

      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">FWD:</td><td >${valueArriveFWD}</td></tr>`;
      html += `<tr><td style="padding-right: 0.5rem">AFT:</td><td >${valueArriveAFT}</td></tr>`;

      html += '</tbody></table>';
      html += spacerItems;
    }

    if (this.showAnchoredInformation && this.anchoredInformation) {
      html += `<p style="margin: 0">ANCHORED</p>`;
      html += spacerItem;
      html += `<p style="margin: 0">${this.anchoredInformation}</p>`;
      html += spacerItems;
    }

    html += '<p style="margin: 0; font-weight: bold;">Summary</p>';
    html += spacerItem;
    html += this.reportesService.obtenerTextoHTML(this.viaje.remark); // pending
    html += spacerItems;

    html += '<p style="margin: 0;">We will keep you posted</p>';
    html += spacerItems;

    html += '<p style="margin: 0;">Best regards,</p>';
    html += spacerItem;

    if (this.viaje.boardingAgentNombre) {
      html += `<p style="margin: 0;">${this.viaje.boardingAgentNombre}</p>`;
      html += `<p style="margin: 0;">BOARDING AGENT</p>`;
    }

    html += `<p style="margin: 0;">${this.viaje.port.direccion}</p>`;
    html += `<p style="margin: 0;">${this.viaje.port.nombre}</p>`;

    html += `<p style="margin: 0;">Mobile: ${
      this.viaje.boardingAgent && this.viaje.boardingAgent.telefono ? this.viaje.boardingAgent.telefono : 'Not defined'
    }</p>`;

    html += `<p style="margin: 0;">
        Email:
        <a href=${this.viaje.port ? 'mailto:' + this.viaje.port.email : 'Not defined'}>
        ${this.viaje.port ? this.viaje.port.email : 'Not defined'};
        </a>
      </p>`;

    html += `<p style="margin: 0;">
      Web Site:
      <a href="www.navescolombia.com">www.navescolombia.com</a>
    </p>`;

    html += `<p style="text-align: justify;">The information transmitted by this email is confidential and intended solely for use by their (s) recipient (s). His play, read or use is prohibited to any person or entity other, without prior written permission. If you received this in error, please notify the sender immediately and delete it from your system. You may not copy, print or distribute this email or its attachments, or use for any purpose nor disclose its contents to anyone. The opinions, conclusions and other information contained in this email, not related to official business NAVES SAS shall be construed as personal and in no way endorsed by the company. Although Naves SAS has done its best to ensure that this message and any attachments are free from viruses and defects that can potentially affect computers or systems that receive, not responsible for the possible transmission of viruses or malicious programs via this channel, and therefore the recipient's responsibility to confirm the existence of such elements when received and open it. Naves Colombia Neither nor any of its divisions or departments are responsible for possible damages or changes arising from the receipt or use of this message.</p>`;

    html += spacerItem;

    html += `<p style="text-align: justify;">This message has been sent by an automated system, please do not respond to this mail and will not be reviewed
    by any staff because it is an automatic process of sending mail.</p>`;

    html += '</tbody></table></body></html>';
    return html;
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia
   */
  eliminarContactoCopia(index: number): void {
    this.ccs.splice(index, 1);
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia oculta
   */
  eliminarContactoCopiaOculta(index: number): void {
    this.bccs.splice(index, 1);
  }

  /**
   * Agrega los emails de un área a la lista de emails a mandar por sendgrid
   */
  addEmails(area, event) {
    if (event.target.checked) {
      this.emailsContactosViaje = this.emailsContactosViaje.includes(area.correos)
        ? this.emailsContactosViaje
        : this.emailsContactosViaje.concat(area);
    } else {
      this.emailsContactosViaje = this.emailsContactosViaje.filter((areaAgregada) => {
        return !area.correos.includes(...areaAgregada.correos);
      });
    }
  }

  /**
   * Añade o elimina de la lista de destinatarios el correo del buque
   */

  public addedVesselEmail = false;
  addBuqueCorreo(event) {
    const contactoBuque = {
      area: undefined,
      empresa: this.viaje.buqueNombre,
      correos: [
        {
          email: this.buqueCorreo,
          nombre: this.viaje.buqueNombre,
        },
      ],
    };
    if (event.target.checked) {
      this.addedVesselEmail = true;
      const existeContacto = this.emailsContactosViaje.find((contactoAgregado) => {
        return contactoAgregado.empresa === this.viaje.buqueNombre;
      });
      if (!existeContacto) {
        this.emailsContactosViaje.push(contactoBuque);
      }
    } else {
      this.addedVesselEmail = false;
      this.emailsContactosViaje = this.emailsContactosViaje.filter((contactoAgregado) => {
        return contactoAgregado.empresa != this.viaje.buqueNombre;
      });
    }
  }

  public anchoredInformation: string = undefined;
  public showAnchoredInformation: boolean = false;
  public includeAnchored(): void {
    this.showAnchoredInformation = !this.showAnchoredInformation;
    this.getAnchoredInfo();
  }

  public getAnchoredInfo() {
    let { etaFecha, etaHora, etbFecha, etbHora, etbAMPM, etaAMPM } = this.formulario.value;
    if (!etaFecha || !etbFecha) return;

    if (etbAMPM === 'AM') {
      etbHora = '00:00';
    } else if (etbAMPM === 'PM') {
      etbHora = '12:00';
    }

    if (etaAMPM === 'AM') {
      etaHora = '00:00';
    } else if (etaAMPM === 'PM') {
      etaHora = '12:00';
    }

    const combineETA = this.timeService.combineDatesAndHours(etaFecha, etaHora);
    const combineETB = this.timeService.combineDatesAndHours(etbFecha, etbHora);
    const dateHours = this.timeService.diffDateInHoursMinutes(combineETB, combineETA);

    dateHours.hours = Math.abs(dateHours.hours);
    dateHours.minutes = Math.abs(dateHours.minutes);

    if (dateHours.hours <= 0 && dateHours.minutes <= 0) {
      this.anchoredInformation = undefined;
      return;
    }

    this.anchoredInformation = this.timeService.formatHoursMinutesToString(dateHours.hours, dateHours.minutes);
  }

  /**
   * Encuentra si hay algun error al intentar enviar un correo
   */
  encontrarErroresEnvioCorreo(): string {
    if (!this.formulario.value.asuntoReporte) {
      Swal.fire({
        title: 'El reporte no tiene asunto',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[1];
    }

    if (this.emailsContactosViaje.length == 0) {
      Swal.fire({
        title: 'El viaje no tiene Receivers, Shippers o Others',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[2];
    }

    return this.erroresEnvioCorreo[0];
  }

  /**
   * Maneja la seleccion de archivos a adjuntar
   */
  onSeleccionarArchivoAAdjuntar(event) {
    const maxSizeInMb = 15;
    this.archivosAdjuntosCargandose = true;
    const archivoSeleccionado = event.target.files[0];
    if (archivoSeleccionado !== undefined) {
      // Obtener la información necesaria
      const fileSizeInMb = archivoSeleccionado.size / (1024 * 1024);

      if (fileSizeInMb > maxSizeInMb) {
        Swal.fire({
          title: 'El archivo excede el tamaño máximo permitido de 15 MB.',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.archivosAdjuntosCargandose = false;
      } else {
        const { params, options } = this.s3Service.getInfoForUpload('adjuntoCorreos', archivoSeleccionado);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if (err) {
            this.s3Service.showErrorUploading();
          } else {
            this.documentURL = data.Location.toString();
            this.reportesService
              .archivoABase64(event.target.files[0])
              .then((result) => {
                const nuevoArchivo = {
                  content: result,
                  filename: event.target.files[0].name,
                  type: 'application/pdf',
                  disposition: 'attachment',
                  path: this.documentURL,
                };
                this.archivosAdjuntos.push(nuevoArchivo);
                this.archivosAdjuntosCargandose = false;
                this.archjivoAAdjuntar.nativeElement.value = '';
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Se generó un problema al adjuntar el archivo',
                  type: 'error',
                  showCancelButton: false,
                  confirmButtonText: 'Continuar',
                });
              });
          }
        });

        Swal.fire('Exito!', 'El archivo se subió exitosamente', 'success');
      }
    } else {
      this.archivosAdjuntosCargandose = false;
    }
  }

  /**
   * Genera un ID
   */
  makeid() {
    return this.utilsService.generateRandomId();
  }

  /**
   * Borra un arhivo de los archivos adjuntos
   * @param indiceArchivo Indice del archivo en los archivos adjuntos
   */
  onBorrarArchivoAdjunto(indiceArchivo: number): void {
    this.archivosAdjuntos.splice(indiceArchivo, 1);
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.router.navigate([`home/reportes/${this.viaje._id}/${this.viaje.recalada._id}`]);
      }
    });
  }
}
