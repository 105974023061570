import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { PqrsService } from '../../pqrs.service';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MainService } from '../../../services/main.service';
import { TimeService } from '../../../../services/time/time.service';

@Component({
  selector: 'mis-pqrs-reportados',
  templateUrl: './mis-pqrs-reportados.component.html',
  styleUrls: ['./mis-pqrs-reportados.component.scss'],
})
export class MisPqrsReportadosComponent implements OnInit, OnDestroy {
  /** Referencia al permiso de edición */
  available: boolean;
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Tipos de modos para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle */
  public modo: String = 'CREAR';
  /** Dato seleccionado por el usuario*/
  public pqr: any;
  /** PQRs en la BD */
  public pqrs: any;
  /** Subscripcion a los pqrs del servicio */
  private pqrsSubscripcion: Subscription;
  /** Indica si se estan cargando las PQRs */
  public pqrsCargandose: boolean;
  /** Subscripcion a los pqrs del servicio */
  private pqrsCargandoseSubscripcion: Subscription;
  /** Configuracion de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      idPqr: {
        title: 'ID',
      },
      estadoHtml: {
        type: 'html',
        title: 'Estado',
      },
      nombreBuque: {
        title: 'Buque',
      },
      nombreNominador: {
        title: 'Nominador',
      },
      nombrePuerto: {
        title: 'Puerto/Oficina',
      },
      tipoSolicitud: {
        title: 'Tipo de solicitud',
      },
      nombreResponsable: {
        title: 'Responsable',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Informacion de l usuario actual */
  public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));

  /** Referencia al detalle*/
  @ViewChild('pqrDetalle', { static: true }) pqrDetalle: ElementRef;

  constructor(
    private pqrsService: PqrsService,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.available = false;
    this.inicializarSubscripciones();
  }

  ngOnDestroy(): void {
    this.detenerSubscripciones();
  }

  /**
   * Inicializa los datos del modulo
   */
  recargarDatos(): void {
    this.pqrs = [];
    this.pqrsService.obtenerPqrs();
  }

  /**
   * Subscribrse a los datos necesarios del servicio
   */
  inicializarSubscripciones(): void {
    this.pqrsCargandoseSubscripcion = this.pqrsService.cargando.subscribe((cargandose) => {
      this.pqrsCargandose = cargandose;
    });
    this.pqrsSubscripcion = this.pqrsService.pqrsCambiadas.subscribe((pqrs) => {
      this.pqrs = pqrs.filter((pqr) => pqr.quienReporta && pqr.quienReporta._id == this.usuarioActual._id);
    });
  }

  /**
   * Detener Subscripciones del servicio
   */
  detenerSubscripciones(): void {
    this.pqrsSubscripcion.unsubscribe();
    this.pqrsCargandoseSubscripcion.unsubscribe();
  }

  /**
   * Inicializar creacion de una PQR
   */
  onCrearPqr(): void {
    // this.pqr = null;
    this.modo = this.modos[0];
    this.abrirPqrDetalleDialog(this.pqrDetalle);
  }

  /**
   * Abre el detalle de una PQR
   * @param dialog Referencia al detalle
   */
  abrirPqrDetalleDialog(dialog): void {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear, Ver o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarPqr(event);
        break;
      case 'editar':
        this.editarPQR(event);
        break;
    }
  }

  /**
   * Borra el PQR de la BD
   * @param event Evento con los datos de la PQR a eliminar
   */
  borrarPqr(event): void {
    if (this.available) {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el PQR?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/pqr/' + event.data._id).subscribe((res) => {
          if (res.exito) {
            Swal.fire('Éxito', 'PQR eliminada exitosamente', 'success');

            this.recargarDatos();
          } else {
            Swal.fire('Error', 'Se generó un error al intentar eliminar la PQR', 'error');
          }
        });
      }
    });
  }

  /**
   * Edita una PQR en la BD
   * @param event Evento con los datos de la PQR a editar
   */
  editarPQR(event) {
    this.pqr = event.data;
    this.modo = this.modos[1];
    this.abrirPqrDetalleDialog(this.pqrDetalle);
  }
}
