enum STATUSEXTRACOLOR {
  ANNOUNCED = '#FFFF00',
  BERTHED = '#9BC2E6',
  COMPLETED = '#00B0F0',
  SHIPPED = '#0070C0',
  LIQUIDADO = '#FF3300',
  'FACTURAS RECIBIDAS' = '#F4B084',
  FACTURADO = '#92D050',
}

export default STATUSEXTRACOLOR;
