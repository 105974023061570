import { Injectable } from '@angular/core';
import { InfoCompletaNuevoViaje, nuevoViaje } from '../../interfaces/requests.interface';
import { MainService } from '../main.service';

@Injectable({
  providedIn: 'root',
})
export class ViajesServiceService {
  constructor(private mainService: MainService) {}

  getAllViajes() {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/nuevo-viaje').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Obtiene TODA la información que va en el módulo de viajes
   * @returns
   */
  getAllInfo(): Promise<InfoCompletaNuevoViaje> {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/nuevo-viaje/info').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getViajeById(id: string): Promise<nuevoViaje> {
    return new Promise((resolve, reject) => {
      this.mainService.get(`api/nuevo-viaje/${id}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          console.log('el error', error);
          reject(error);
        }
      );
    });
  }

  /**
   * Guarda un nuevo viaje
   * @param {nuevoViaje} data La información del viaje
   * @returns
   */
  postViaje(viaje: nuevoViaje) {
    return new Promise((resolve, reject) => {
      this.mainService.post('api/nuevo-viaje', { viaje, recalada: viaje.recaladas[0] }).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postRecalada(viaje: nuevoViaje) {
    return new Promise((resolve, reject) => {
      this.mainService.post(`api/recalada/${viaje._id}`, viaje.recaladas[0]).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getShipsByRecaladas(viajes: nuevoViaje[]) {}
}
