import STATUSEXTRACOLOR from '../constants/colorStatus.const';

export interface SingleSelectIns {
  label: string;
  defaults: {
    value: string;
    color?: string;
    placeHolder?: string;
  };
  settings: {
    placeHolder?: boolean;
    colors?: boolean;
  };
  options: {
    value: string;
    content: string;
    color?: string;
  }[];
}

export interface SingleSelectsIns {
  [key: string]: SingleSelectIns;
}

export const singleSelectsIns: SingleSelectsIns = {
  status: {
    label: 'Status *',
    defaults: {
      value: 'CONFIRMED',
      color: STATUSEXTRACOLOR.FACTURADO,
    },
    settings: {
      colors: true,
    },
    options: [
      {
        value: 'CONFIRMED',
        content: 'CONFIRMED',
        color: STATUSEXTRACOLOR.FACTURADO,
      },
      {
        value: 'CANCELED',
        content: 'CANCELED',
        color: STATUSEXTRACOLOR.LIQUIDADO,
      },
    ],
  },
  statusExtra: {
    label: 'Status',
    defaults: {
      value: 'ANNOUNCED',
      color: STATUSEXTRACOLOR.ANNOUNCED,
    },
    settings: {
      colors: true,
    },
    options: [
      {
        value: 'ANNOUNCED',
        content: 'ANNOUNCED',
        color: STATUSEXTRACOLOR.ANNOUNCED,
      },
      {
        value: 'BERTHED',
        content: 'BERTHED',
        color: STATUSEXTRACOLOR.BERTHED,
      },
      {
        value: 'COMPLETED',
        content: 'COMPLETED',
        color: STATUSEXTRACOLOR.COMPLETED,
      },
      {
        value: 'SHIPPED',
        content: 'SHIPPED',
        color: STATUSEXTRACOLOR.SHIPPED,
      },
      {
        value: 'LIQUIDADO',
        content: 'LIQUIDADO',
        color: STATUSEXTRACOLOR.LIQUIDADO,
      },
      {
        value: 'FACTURAS RECIBIDAS',
        content: 'FACTURAS RECIBIDAS',
        color: STATUSEXTRACOLOR['FACTURAS RECIBIDAS'],
      },
      {
        value: 'FACTURADO',
        content: 'FACTURADO',
        color: STATUSEXTRACOLOR.FACTURADO,
      },
    ],
  },
  areaCompanyType: {
    label: 'Tipo',
    defaults: {
      value: '',
      placeHolder: 'Selecciona el tipo de área',
    },
    settings: {
      placeHolder: true,
    },
    options: [
      {
        value: 'BROKER/AGENT/OTHERS',
        content: 'BROKER/AGENT/OTHERS',
      },
      {
        value: 'CARGO OWNER',
        content: 'CARGO OWNER',
      },
      {
        value: 'CHARTERER',
        content: 'CHARTERER',
      },
      {
        value: 'CLIENTE',
        content: 'CLIENTE',
      },
      {
        value: 'OWNER',
        content: 'OWNER',
      },
      {
        value: 'PROVEEDOR',
        content: 'PROVEEDOR',
      },
      {
        value: 'RECEIVER',
        content: 'RECEIVER',
      },
    ],
  },
  clientCompany: {
    label: 'Cliente',
    defaults: {
      value: '',
      placeHolder: 'Seleccione el cliente',
    },
    settings: {
      placeHolder: true,
    },
    options: [],
  },
  clientProformaSinNomicacion: {
    label: 'Cliente *',
    defaults: {
      value: '',
      placeHolder: 'Seleccione el cliente',
    },
    settings: {
      placeHolder: true,
    },
    options: [],
  },
  proformaReporteType: {
    label: 'Tipo de Reporte',
    defaults: {
      value: 'CloseToReal',
    },
    settings: {},
    options: [
      {
        value: 'CloseToReal',
        content: 'Close to Real',
      },
      {
        value: 'ReporteProforma',
        content: 'Reporte proforma',
      },
    ],
  },
};
