import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { HomeComponent } from './public/home/home.component';
import { UsuariosComponent } from './public/usuarios/usuarios.component';
import { AgentsComponent } from './public/agents/agents.component';
import { ViajesComponent } from './public/viajes/viajes.component';
import { NoticiasComponent } from './public/noticias/noticias.component';
import { NotificacionesComponent } from './public/notificaciones/notificaciones.component';
import { ConfiguracionesComponent } from './public/configuraciones/configuraciones.component';
import { UsuarioVerComponent } from './public/usuarios/usuario-ver/usuario-ver.component';
import { AgentVerComponent } from './public/agents/agents-ver/agent-ver.component';
import { NoticiaVerComponent } from './public/noticias/noticia-ver/noticia-ver.component';
import { NotificacionVerComponent } from './public/notificaciones/notificacion-ver/notificacion-ver.component';
import { BarcoVerComponent } from './public/viajes/components/barco-ver/barco-ver.component';
import { ServiciosComponent } from './public/servicios/servicios.component';
import { BuquesComponent } from './public/buques/buques.component';
import { BuqueVerComponent } from './public/buques/buque-ver/buque-ver.component';
import { BussinesLineComponent } from './public/bussines-line/bussines-line.component';
import { BussinesLineVerComponent } from './public/bussines-line/bussines-line-ver/bussines-line-ver.component';
import { CommodityComponent } from './public/commodity/commodity.component';
import { CommodityVerComponent } from './public/commodity/commodity-ver/commodity-ver.component';
import { StatementComponent } from './public/statement/statement.component';
import { StatementVerComponent } from './public/statement/statement-ver/statement-ver.component';
import { DocumentosComponent } from './public/documentos/documentos.component';
import { KimComponent } from './public/kim/kim.component';
import { KimVerComponent } from './public/kim/kim-ver/kim-ver.component';
import { OperacionComponent } from './public/operacion/operacion.component';
import { OperacionVerComponent } from './public/operacion/operacion-ver/operacion-ver.component';
import { TerminalComponent } from './public/terminal/terminal.component';
import { TerminalVerComponent } from './public/terminal/terminal-ver/terminal-ver.component';
import { PuertoComponent } from './public/puerto/puerto.component';
import { PuertoVerComponent } from './public/puerto/puerto-ver/puerto-ver.component';
import { EmpresaSqlComponent } from './public/empresa-sql/empresa-sql.component';
import { EmpresaSqlVerComponent } from './public/empresa-sql/empresa-sql-ver/empresa-sql-ver.component';
import { HusbandryComponent } from './public/husbandry/husbandry.component';
import { ProductoComponent } from './public/producto/producto.component';
import { MedidaComponent } from './public/medida/medida.component';
import { ProductoViajeComponent } from './public/producto-viaje/producto-viaje.component';
import { ProductoVerComponent } from './public/producto-viaje/producto-ver/producto-ver.component';
import { CategorySofComponent } from './public/category-sof/category-sof.component';
import { TypeRemarksComponent } from './public/type-remarks/type-remarks.component';
import { SofVerComponent } from './public/viajes/components/sof-ver/sof-ver.component';
import { ViajeDetalleComponent } from './public/viajes/components/viaje-detalle/viaje-detalle.component';
import { ParametrosComponent } from './public/parametros/parametros.component';
import { ProformasComponent } from './public/proformas/proformas.component';
import { TarifasComponent } from './public/tarifas/tarifas.component';
import { LiquidacionesComponent } from './public/liquidaciones/liquidaciones.component';
import { AreasEmpresaComponent } from './public/areas-empresa/areas-empresa.component';
import { ContactosComponent } from './public/contactos/contactos.component';
import { AnalisisOperativoComponent } from './public/analisis-operativo/analisis-operativo.component';
import { ListaDeChequeoComponent } from './public/analisis-operativo/componentes/lista-de-chequeo/lista-de-chequeo.component';
import { ReportesComponent } from './public/analisis-operativo/componentes/reportes/reportes.component';
import { ReporteArriboComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-arribo/reporte-arribo.component';
import { ReporteProductividadComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-productividad/reporte-productividad.component';
import { VistaPreviaReporteProductividadComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-productividad/componentes/vista-previa-reporte-productividad/vista-previa-reporte-productividad.component';
import { ReporteProspectosComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-prospectos/reporte-prospectos.component';
import { VistaPreviaReporeProspectosComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-prospectos/components/vista-previa-repore-prospectos/vista-previa-repore-prospectos.component';
import { VistaPreviaReporeArriboComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-arribo/componentes/vista-previa-repore-arribo/vista-previa-repore-arribo.component';
import { ReporteZarpeComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-zarpe/reporte-zarpe.component';
import { VistaPreviaReporteZarpeComponent } from './public/analisis-operativo/componentes/reportes/components/reporte-zarpe/componentes/vista-previa-reporte-zarpe/vista-previa-reporte-zarpe.component';
import { PqrsComponent } from './public/pqrs/pqrs.component';
import { RolesComponent } from './public/roles/roles.component';
import { RecuperarContrasenaComponent } from './public/recuperar-contrasena/recuperar-contrasena.component';
import { ReestablecerComponent } from './public/reestablecer/reestablecer.component';

import { SofFormatComponent } from './pages/sof-format/sof-format.component';
import { CaptianInterviewComponent } from './pages/captian-interview/captian-interview.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'recuperar-contrasena', component: RecuperarContrasenaComponent },
  { path: 'reestablecer/:id', component: ReestablecerComponent },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'usuario-ver/:id', component: UsuarioVerComponent },
      { path: 'viajes', component: ViajesComponent },
      { path: 'viaje/create', component: ViajeDetalleComponent },
      { path: 'viaje/create-recalada/:id', component: ViajeDetalleComponent },
      { path: 'viaje/view-and-edit/:id/:idRecalada', component: ViajeDetalleComponent },
      { path: 'viaje/ver-sof/:id/:idRecalada', component: SofVerComponent },

      { path: 'barco-ver/:id', component: BarcoVerComponent },
      { path: 'tarifas', component: TarifasComponent },
      { path: 'noticias', component: NoticiasComponent },
      { path: 'notificaciones', component: NotificacionesComponent },
      { path: 'notificacion-ver/:id', component: NotificacionVerComponent },
      { path: 'noticia-ver/:id', component: NoticiaVerComponent },
      { path: 'configuracion', component: ConfiguracionesComponent },
      { path: 'servicios', component: ServiciosComponent },
      { path: 'buques', component: BuquesComponent },
      { path: 'buque-ver/:id', component: BuqueVerComponent },
      { path: 'bussines-line', component: BussinesLineComponent },
      { path: 'bussines-line-ver/:id', component: BussinesLineVerComponent },
      { path: 'commodity', component: CommodityComponent },
      { path: 'commodity-ver/:id', component: CommodityVerComponent },
      { path: 'husbandry', component: HusbandryComponent },
      { path: 'statement', component: StatementComponent },
      { path: 'statement-ver/:id', component: StatementVerComponent },
      { path: 'kim', component: KimComponent },
      { path: 'kim-ver/:id', component: KimVerComponent },
      { path: 'operacion', component: OperacionComponent },
      { path: 'operacion-ver/:id', component: OperacionVerComponent },
      { path: 'terminal', component: TerminalComponent },
      { path: 'terminal-ver/:id', component: TerminalVerComponent },
      { path: 'puerto', component: PuertoComponent },
      { path: 'puerto-ver/:id', component: PuertoVerComponent },
      { path: 'documentos', component: DocumentosComponent },
      { path: 'empresa-sql', component: EmpresaSqlComponent },
      { path: 'empresa-sql-ver/:id', component: EmpresaSqlVerComponent },
      { path: 'producto', component: ProductoComponent },
      { path: 'medida', component: MedidaComponent },
      { path: 'producto-viaje', component: ProductoViajeComponent },
      { path: 'producto-ver/:id', component: ProductoVerComponent },

      { path: 'categorias', component: CategorySofComponent },
      { path: 'tipo-comentarios', component: TypeRemarksComponent },
      { path: 'formato-sof', component: SofFormatComponent },

      { path: 'reportes/ver-sof/:id/:idRecalada', component: SofVerComponent },
      { path: 'parametros', component: ParametrosComponent },
      { path: 'proformas', component: ProformasComponent },
      { path: 'liquidaciones', component: LiquidacionesComponent },
      { path: 'areas-empresa', component: AreasEmpresaComponent },
      { path: 'contactos', component: ContactosComponent },

      { path: 'analisis-operativo', component: AnalisisOperativoComponent },
      { path: 'lista-de-chequeo/:idViaje/:idRecalada', component: ListaDeChequeoComponent },

      { path: 'reportes/:idViaje/:idRecalada', component: ReportesComponent },

      { path: 'reporte-arribo/:idViaje/:idRecalada', component: ReporteArriboComponent },
      { path: 'reporte-arribo/:idViaje/:idRecalada/vista-previa', component: VistaPreviaReporeArriboComponent },
      { path: 'reporte-arribo/ver-sof/:id/:idRecalada', component: SofVerComponent },

      { path: 'reporte-productividad/:idViaje/:idRecalada', component: ReporteProductividadComponent },
      {
        path: 'reporte-productividad/:idViaje/:idRecalada/vista-previa',
        component: VistaPreviaReporteProductividadComponent,
      },

      { path: 'reporte-prospectos/:idViaje/:idRecalada', component: ReporteProspectosComponent },
      { path: 'reporte-prospectos/:idViaje/:idRecalada/vista-previa', component: VistaPreviaReporeProspectosComponent },

      { path: 'reporte-zarpe/:idViaje/:idRecalada', component: ReporteZarpeComponent },
      { path: 'reporte-zarpe/:idViaje/:idRecalada/vista-previa', component: VistaPreviaReporteZarpeComponent },
      { path: 'reporte-zarpe/ver-sof/:id/:idRecalada', component: SofVerComponent },

      { path: 'reporte-fin-operaciones/:idViaje/:idRecalada', component: ReporteZarpeComponent },
      { path: 'reporte-fin-operaciones/:idViaje/vista-previa', component: VistaPreviaReporteZarpeComponent },
      { path: 'reporte-fin-operaciones/ver-sof/:id/:idRecalada', component: SofVerComponent },

      { path: 'pqrs', component: PqrsComponent },
      { path: 'roles', component: RolesComponent },

      { path: 'captian-survies', component: CaptianInterviewComponent },
    ],
  },
  {
    path: '**',
    redirectTo: 'home/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
