import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../../../../services/main.service';
import { Router } from '@angular/router';
import { ReportesService } from '../../../../reportes.service';

@Component({
  selector: 'vista-previa-repore-arribo',
  templateUrl: './vista-previa-repore-arribo.component.html',
  styleUrls: ['./vista-previa-repore-arribo.component.scss'],
})
export class VistaPreviaReporeArriboComponent implements OnInit {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  public companyName: string;
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Arreglo con los parrafos del remark*/
  public parrafosRemarks: string[] = [];
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = true;
  /** Indica si se incluyen las tablas o no */
  public incluirTablas: boolean = true;
  /** Imágen que se va a mostrar del buque */
  public imagenBuque: string;
  public iconBuque: string;

  /** Lista de correos con copia y copia oculta */
  public correosCopia: string[];
  public correosCopiaOculta: string[];

  /** Actividades del SOF */
  sofConfirmadas: any[];
  sofEstimadas: any[];

  public asunto: any;
  public attachments: any[];

  public anchoredInformation;

  public showAnchoredInformation;
  public showSOFActivities;

  constructor(private mainService: MainService, public router: Router, private reportesService: ReportesService) {}

  ngOnInit() {
    this.obtenerViaje();
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de prospectos e inicializa el viaje en el formato requerido para
   * el componente de reporte de prospectos
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      this.viaje = res;
      this.obtenerNombresAsociacionesRelevantesViaje();
      // this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
      this.cargando = false;
    });
  }

  includeAnchored() {
    this.showAnchoredInformation = !this.showAnchoredInformation;
  }

  includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de prospectos
   */
  obtenerNombresAsociacionesRelevantesViaje(): void {}
  /**
   * Obtiene las actividades confirmadas y estimadas ordenadas cronologicamente
   */
  obtenerActividadesEstimadasYConfirmadasOrdenadas() {
    if (Object.keys(this.viaje.portLog).length > 0) {
      let activdadesOrdenadas = [];
      for (const property in this.viaje.portLog) {
        if (this.viaje.portLog[property].fecha) {
          let actividad = {
            nombre: this.viaje.portLog[property].nombre,
            fecha: this.viaje.portLog[property].fecha,
            status: this.viaje.portLog[property].status,
            fechaVisual: '',
          };

          activdadesOrdenadas.push(actividad);
        }
      }

      activdadesOrdenadas.forEach((actividad) => {
        actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
      });

      activdadesOrdenadas.sort(function (a, b) {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

      this.arregloOrdenadoActividadesConfirmadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'CONFIRMED'
      );
      this.arregloOrdenadoActividadesEstimadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'ESTIMATED'
      );
    } else {
      this.arregloOrdenadoActividadesConfirmadas = [];
      this.arregloOrdenadoActividadesEstimadas = [];
    }
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;
  }

  /**
   * Cambia el estado de la inclusión de tablas
   */
  onCambioIncluirTablas() {
    this.incluirTablas = !this.incluirTablas;
  }
}
