import { Injectable } from '@angular/core';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.fonts = {
  Arial: {
    normal: `${window.location.origin}/assets/fonts/arial/arial.ttf`,
    bold: `${window.location.origin}/assets/fonts/arial/arialbd.ttf`,
    italics: `${window.location.origin}/assets/fonts/arial/arialbi.ttf`,
    bolditalics: `${window.location.origin}/assets/fonts/arial/ariali.ttf`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class PDFService {
  constructor() {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }

  public organizeData() {}

  public buildPDF(contentOfPDF: any, fileName) {
    const pdfConfig: any = contentOfPDF;
    pdfMake.createPdf(pdfConfig).download(`${fileName}.pdf`);
  }
}
