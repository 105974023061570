import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

interface dataItem {
  key: string;
  value: string;
}

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit {
  @Output() emitResult = new EventEmitter<string>();
  @Output() emitComponent = new EventEmitter<this>();

  @Input() dataCollection: dataItem[] = [];
  @Input() defaultOptionKey: string;
  @Input() placeHolder: string;
  @Input() disabledInput: boolean = false;

  public dataToFind: string;
  public filteredDataCollection: dataItem[] = [];
  public focusElement = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataCollection && this.focusElement) {
      this.showAllOptions();
    }

    if (changes.dataCollection && this.defaultOptionKey) {
      const defaultOption = this.dataCollection.find((data) => data.key == this.defaultOptionKey);
      if (!defaultOption) return;
      this.dataToFind = defaultOption.value.trim();
    }
  }

  public filterData() {
    this.dataToFind = this.dataToFind.trim();

    if (!this.dataToFind) {
      this.filteredDataCollection = [];
      this.showAllOptions();
      this.emitResult.emit(undefined);
      return;
    }

    this.filteredDataCollection = this.dataCollection.filter((data) =>
      data.value.toLowerCase().includes(this.dataToFind.toLowerCase())
    );
    this.emitResult.emit(undefined);
  }

  public selectOption(option: string, key: string) {
    this.dataToFind = option.trim();
    this.filteredDataCollection = [];

    const selectedOption = this.dataCollection.find((data) => data.key === key);
    this.emitResult.emit(selectedOption.key);
    this.emitComponent.emit(this);
    this.hideOptions = true;
  }

  public showAllOptions() {
    this.focusElement = true;
    if (this.dataToFind) return;
    this.filteredDataCollection = this.dataCollection;
  }

  private hideOptions = true;

  public hideOptionsBlur() {
    this.focusElement = false;
    if (!this.hideOptions) return;
    this.filteredDataCollection = [];
  }

  public blurOptions() {
    this.hideOptions = true;
  }

  public hoverOptions() {
    this.hideOptions = false;
  }

  public clearData(){
    this.dataToFind = '';
    this.emitResult.emit(undefined);
  }
}
