import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AnalisisOperativoService } from '../../analisis-operativo.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../../../../services/alertService/alert-service.service';
import STATUSEXTRACOLOR from '../../../../constants/colorStatus.const';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'tabla-viajes-analisis-operativo',
  templateUrl: './tabla-viajes-analisis-operativo.component.html',
  styleUrls: ['./tabla-viajes-analisis-operativo.component.scss'],
})
export class TablaViajesAnalisisOperativoComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialogRef: ElementRef;
  private dialog: any;

  public observationCheckList = [];

  public observationsListInstructions = {
    observationsList: [],
  };

  /** Viajes sin facturar aplicables al filtro actual */
  public viajesFiltrados: any = [];
  sortedColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  /** Subscripcion a los viajes filtrados del servicio */
  public viajesFiltradosSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de los viajes */
  public viajesCargandose = true;
  /** Subscripcion al indicativo de carga de buques del sevicio*/
  public viajesCargandoseSubscripcion: Subscription;
  /** */
  public viajesCargandoseSubject: Subject<boolean> = new Subject<boolean>();
  p: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;

  // Colors for status
  public STATUSEXTRACOLOR = STATUSEXTRACOLOR;

  constructor(
    private analisisOperativoService: AnalisisOperativoService,
    public router: Router,
    private alertService: AlertService,
    private diagoService: NbDialogService
  ) {}

  ngOnInit() {
    this.inicializarSubscripciones();
  }

  ngOnDestroy(): void {
    this.detenerSubscripciones();
  }

  sortData(column: string) {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }

    this.viajesFiltrados.sort((a, b) => {
      let valueA = this.getValue(a, column);
      let valueB = this.getValue(b, column);

      // Eliminar espacios en blanco al inicio y al final del valor
      if (typeof valueA === 'string') {
        valueA = valueA.trim();
      }
      if (typeof valueB === 'string') {
        valueB = valueB.trim();
      }

      // Manejar el caso de "No definido"
      if (valueA === 'No definido') {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (valueB === 'No definido') {
        return this.sortDirection === 'asc' ? 1 : -1;
      }

      // Convertir las fechas a objetos Date
      if (typeof valueA === 'string' && (column.includes('eta') || column.includes('etb') || column.includes('etd'))) {
        const [dayA, monthA, yearA, hourA, minuteA] = valueA.split(/[/ :]/);
        valueA = new Date(`20${yearA}-${monthA}-${dayA}T${hourA}:${minuteA}`);
        
        const [dayB, monthB, yearB, hourB, minuteB] = valueB.split(/[/ :]/);
        valueB = new Date(`20${yearB}-${monthB}-${dayB}T${hourB}:${minuteB}`);
      }

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }

      return this.sortDirection === 'asc' ? comparison : -comparison;
    });
  }



  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(2000 + year, month - 1, day);
  }

  getValue(item: any, column: string): any {
    switch (column) {
      case 'nv':
        return item.nv;
      case 'buque':
        return item.nombreBuque;
      case 'puerto':
        return item.codigoPuerto;
      case 'terminal':
        return item.abreviaturaTerminal;
      case 'commodity':
        return item.commodity && item.commodity.nombre ? item.commodity.nombre : '';
      case 'eta':
        return item.etaVisual;
      case 'etb':
        return item.etbVisual;
      case 'etd':
        return item.etdVisual;
      case 'disponent':
        return item.nombreDisponentOwner;
      case 'reportes':
        return item.reportes;
      case 'estado':
        return item.statusExtra;
      case 'listaChequeo':
        return this.listaDeChequeoCompleta(item) ? 'Completo' : 'Pendiente';
      case 'boardingAgent':
        const nombre = item.boardingAgent ? item.boardingAgent.nombre : '';
        const apellido = item.boardingAgent ? item.boardingAgent.apellido : '';
        return `${nombre} ${apellido}`;
      case 'reportesEnviados':
        return item.reportesEnviadosHoy.length;
      default:
        return '';
    }
  }

  getSortClass(column: string): string {
    if (this.sortedColumn === column) {
      return this.sortDirection === 'asc' ? 'sorted-asc' : 'sorted-desc';
    }
    return '';
  }

  onItemsPerPageChange(event: any): void {
    this.itemsPerPage = +event.target.value;
    this.p = 1; // Reset to first page
  }

  getRangeText(): string {
    const start = (this.p - 1) * this.itemsPerPage + 1;
    const end = Math.min(this.p * this.itemsPerPage, this.totalItems);
    return `${start} a ${end} de ${this.totalItems} datos`;
  }


  /**
   * Subscribrse a los datos necesarios del servicio
   */
  inicializarSubscripciones(): void {
    this.viajesCargandoseSubscripcion = this.analisisOperativoService.viajesCargandose.subscribe((cargandose) => {
      this.viajesCargandose = cargandose;
      this.viajesCargandoseSubject.next(cargandose);
    });
    this.viajesFiltradosSubscripcion = this.analisisOperativoService.viajesFiltradosCambiados.subscribe((viajes) => {
      this.viajesFiltrados = viajes.sort((a, b) => b.nv - a.nv);
      this.totalItems = this.viajesFiltrados.length;
    });
  }

  /**
   * Detener Subscripciones del servicio
   */
  detenerSubscripciones(): void {
    this.viajesFiltradosSubscripcion.unsubscribe();
    this.viajesCargandoseSubscripcion.unsubscribe();
  }

  /**
   * Redirigue al usuario a la pagina del detalle del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAViaje(idViaje: string, idRecalada: string) {
    this.router.navigate([`home/viaje/view-and-edit/${idViaje}/${idRecalada}`]);
  }

  /**
   * Redirigue al usuario a la pagina del los reportes del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAListaDeChequeo(idViaje: string, idRecalada: string) {
    const viaje = this.viajesFiltrados.find((viaje) => viaje._id === idViaje);

    if (!viaje) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');
    if (!viaje.boardingAgent) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');

    this.router.navigate([`home/lista-de-chequeo/${idViaje}/${idRecalada}`]);
  }
  /**
   * Redirigue al usuario a la pagina del detalle del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAReportes(idViaje: string, idRecalada: string) {
    const viaje = this.viajesFiltrados.find((viaje) => viaje._id === idViaje);

    if (!viaje) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');
    if (!viaje.boardingAgent) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');

    this.router.navigate([`home/reportes/${idViaje}/${idRecalada}`]);
  }

  /**
   * Verifica si la lista de chequeo del viaje esta completa
   * @viaje Viaje del cual se quiere saber la completitud de la lista de chequeo
   */
  listaDeChequeoCompleta(viaje): boolean {
    return (
      viaje.listaDeChequeo.instruccionesNominacion &&
      viaje.listaDeChequeo.situacionRestriccionPuerto &&
      viaje.listaDeChequeo.caladoPuerto &&
      viaje.listaDeChequeo.otrasRestricciones &&
      viaje.listaDeChequeo.isps &&
      viaje.listaDeChequeo.caladoArriboZarpe &&
      viaje.listaDeChequeo.secuenciaCargueDescargue &&
      viaje.listaDeChequeo.distribucionCargaEnTierra &&
      viaje.listaDeChequeo.tiposProductosYRitmos &&
      viaje.listaDeChequeo.prospectosYProgramacion &&
      viaje.listaDeChequeo.almacenamientoYTransporte &&
      viaje.listaDeChequeo.oblsODraftBls &&
      viaje.listaDeChequeo.shipperDeclaration &&
      viaje.listaDeChequeo.msds &&
      viaje.listaDeChequeo.matesReceipt &&
      viaje.listaDeChequeo.cargoManifest &&
      viaje.listaDeChequeo.dian &&
      viaje.listaDeChequeo.capitania &&
      viaje.listaDeChequeo.ica &&
      viaje.listaDeChequeo.sanidad &&
      viaje.listaDeChequeo.migracion &&
      viaje.listaDeChequeo.muellaje &&
      viaje.listaDeChequeo.poilotos &&
      viaje.listaDeChequeo.remolacadores &&
      viaje.listaDeChequeo.estibadores &&
      viaje.listaDeChequeo.serviciosNave &&
      viaje.listaDeChequeo.productividadRealVsProgramada &&
      viaje.listaDeChequeo.sofYLaytime &&
      viaje.listaDeChequeo.cuellosDeBotella &&
      viaje.listaDeChequeo.pda &&
      viaje.listaDeChequeo.ordenesDeServicios &&
      viaje.listaDeChequeo.closeToReal &&
      viaje.listaDeChequeo.fda
    );
  }

  public openObservationDialog() {
    this.dialog = this.diagoService.open(this.dialogRef as any);
  }

  public selectObservationsCheckList(observatiosList: any): void {
    if (observatiosList.length === 0) {
      this.alertService.simpleAlertWarning('No tienes observaciones');
      return;
    }

    this.observationsListInstructions.observationsList = observatiosList;

    this.observationCheckList = observatiosList;
    this.openObservationDialog();
    console.log(observatiosList);
  }

  public closeObservationDialog(): void {
    this.dialog.close();
  }
}
