import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import * as _ from 'lodash';
import { debounceTime, filter } from 'rxjs/operators';
import * as moment from 'moment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TIME_FORM_MAX_SIZE_BREAK_POINT } from '../../constants/pqrs.const';

@Component({
  selector: 'pqr-detalle',
  templateUrl: './pqr-detalle.component.html',
  styleUrls: ['./pqr-detalle.component.scss'],
})
export class PqrDetalleComponent implements OnInit, OnDestroy {
  @Input() available: boolean;
  /** Referencia al dialogo del detalle */
  @Input() dialogRef: any;
  /** Modo de la accion del detalle ('CREAR' o 'EDITAR') */
  @Input() modo: string;
  /** Datos de la PQR seleccionada */
  @Input() pqr: any;
  /** Evento que indica que se realizo algun cambio en la tabla de PQRs de la BD */
  @Output() cambioEnPqr: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('descripcionCaso', { static: false }) descripcionCaso!: ElementRef;
  @ViewChild('respuestaCaso', { static: false }) respuestaCaso!: ElementRef;
  @ViewChild('seguimientoCierre', { static: false }) seguimientoCierre!: ElementRef;

  public responsibleField = true;

  /** Formulario reactivo con los datos de la PQR*/
  public formulario: FormGroup;
  /** Poibles modos de acciones para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR'];
  /** Indica que le modulo se esta cargando */
  public cargando: boolean = false;
  /** Arreglo con los clientes asociados a la PQR */
  public clientes: any;
  /** Informacion de l usuario actual */
  public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));
  /** ID de la PQR actual */
  public idPqr: number;
  /** Viajes en la BD */
  public viajes: any[] = [];
  /** Buques filtrados segun el viaje escogido */
  public viajesFiltrados: any;
  /** Indica si los viajes se estan cargando */
  public viajesCargandose: boolean;
  /** Buques en la BD */
  public buques: any[] = [];
  /** Indica si los buques se estan cargando */
  public buquesCargandose: boolean;
  /** Indica si los viajes y los buques se estan cargando */
  public buquesOViajesCargandose: boolean;
  /** Empresas en la BD */
  public empresas: any[] = [];
  /** Indica si las empresas se estan cargando */
  public empresasCargandose: boolean;
  /** Puertos en la BD */
  public puertos: any[] = [];
  /** Indica si loss puertos se estan cargando */
  public puertosCargandose: boolean;
  /** Usuarios en la BD */
  public usuarios: any[] = [];
  /** Indica si los usuarios se estan cargando */
  public usuariosCargandose: boolean;

  constructor(
    private mainService: MainService,
    public dateAndTimeService: DateAndTimeService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    // Cargar datos de BD
    this.onCargarViajes();
    this.onCargarBuques();
    this.onCargarEmpresas();
    this.onCargarPuertos();
    this.onCargarUsuarios();

    this.clientes = this.pqr && this.pqr.clientes ? this.pqr.clientes : [];
    this.modo == this.modos[0] ? this.obtenerIdPqr() : this.fechasEnFormatoFomulario();

    if (this.modos[0] == this.modo) {
      this.clientes = [];
    }

    this.inicializarFormulario();

    if (this.modo == this.modos[0]) {
      this.pqr = null;
    }

    this.formulario.controls['fechaSeguimientoCierreFecha'].valueChanges
      .pipe(
        filter(() => this.formulario.controls['fechaSeguimientoCierreFecha'].dirty),
        debounceTime(500)
      )
      .subscribe(() => this.onActualizarFechaSeguimientoCierre());

    this.watchForScreenJumps();
  }

  /**Variable to watch the screen size */
  isSmallScreen = false;

  /**
   * Method to watch the breakpoint for render properly the time in date form field
   */
  watchForScreenJumps() {
    this.breakpointObserver.observe(`(max-width: ${TIME_FORM_MAX_SIZE_BREAK_POINT}px)`).subscribe((result) => {
      this.isSmallScreen = result.matches;
    });
  }

  ngOnDestroy(): void {
    this.pqr = null;
  }

  /**
   * Trae los datos de los viajes de la BD
   */
  onCargarViajes(): void {
    this.viajesCargandose = true;
    this.buquesOViajesCargandose = this.viajesCargandose || this.buquesCargandose;

    this.mainService.get('api/nuevo-viaje').subscribe((res) => {
      this.viajes = res;
      this.setDataViajes();
      this.viajes = _.orderBy(this.viajes, [Number('nv')]);
      this.viajesFiltrados =
        this.formulario && this.formulario.value.buque
          ? this.viajes.filter((viaje) => viaje.vessel == this.formulario.value.buque)
          : this.viajes;

      this.viajesCargandose = false;
      this.buquesOViajesCargandose = this.viajesCargandose || this.buquesCargandose;

      this.onCambioViaje();
    });
  }

  /**
   * Trae los datos de los buques de la BD
   */
  onCargarBuques(): void {
    this.buquesCargandose = true;
    this.buquesOViajesCargandose = this.viajesCargandose || this.buquesCargandose;

    this.mainService.get('api/buque').subscribe((res) => {
      this.buques = res;
      this.buques = _.orderBy(this.buques, ['BUQ_NOMBRE']);

      this.buquesCargandose = false;
      this.buquesOViajesCargandose = this.viajesCargandose || this.buquesCargandose;
    });
  }

  /**
   * Reacciona al evento de cambio de buques en el formulario filtrando los viajes de ese buque y
   * inicializando el cambpo del nv en el formulario
   */
  onCambioBuques(): void {
    this.viajesFiltrados =
      this.formulario && this.formulario.value.buque
        ? this.viajes.filter((viaje) => viaje.vessel == this.formulario.value.buque)
        : this.viajes;

    this.formulario.controls['viaje'].setValue('');
  }

  /**
   * Reacciona al evento de cambio de viajes cambiando los campos del formulario pertinentes a este viaje
   */
  nominadorName = '';

  onCambioViaje(): void {
    const viajeSeleccionado = this.viajes.find((viaje) => viaje._id == this.formulario.value.viaje);
    const nominadorDelViaje = this.empresas.find((empresa) => empresa.EMP_ID == viajeSeleccionado.disponentOwner.id);
    this.nominadorName = nominadorDelViaje.EMP_NOMBRE;
    this.formulario.controls['nominador'].setValue(nominadorDelViaje._id ? nominadorDelViaje._id : '');
  }

  /**
   * Trae los datos de las empresas de la BD
   */
  onCargarEmpresas(): void {
    this.empresasCargandose = true;

    this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
      this.empresas = res;
      this.setDataClientes();

      this.empresasCargandose = false;
      this.onCambioViaje();
    });
  }

  /**
   * Trae los datos de los puertos de la BD
   */
  onCargarPuertos(): void {
    this.puertosCargandose = true;

    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.puertos = res;
      this.puertos = this.puertos.filter((puerto) => puerto.PUE_ID != 0);
      this.puertos = _.orderBy(this.puertos, ['PUE_NOMBRE']);

      this.puertosCargandose = false;
    });
  }

  /**
   * Trae los usuarios de la BD
   */
  onCargarUsuarios(): void {
    this.usuariosCargandose = true;

    this.mainService.get('api/user?estado=Habilitado').subscribe((res) => {
      this.usuarios = res;
      this.usuarios = _.orderBy(this.usuarios, ['nombre']);

      this.usuariosCargandose = false;
    });
  }

  /**
   * Obiene el numero consecutivo en el que se encuentra actualmente los IDs de las PQRs
   */
  obtenerIdPqr(): void {
    this.mainService.get('api/global_variable/idPqr').subscribe((res) => {
      // Cuando la variable global no ha sido creada llega un mensaje
      if (res.message) {
        this.idPqr = 1; //Primer valor del idPqr
        const idPqrGlobalVariable = {
          key: 'idPqr',
          value: this.idPqr,
        };
        this.mainService.post('api/global_variable', idPqrGlobalVariable).subscribe((result) => {});
      } else {
        this.idPqr = res.value;
      }

      if (this.formulario) this.formulario.controls['idPqr'].setValue(this.idPqr);
    });
  }

  /**
   * Obtiene las fechas en el formato del formulario
   */
  fechasEnFormatoFomulario(): void {
    // Fecha del Evento
    if (this.pqr && this.pqr.fechaEvento) {
      this.pqr.fechaEventoFecha = this.dateAndTimeService.convertirFechaAFormatoFormulario(this.pqr.fechaEvento);
      this.pqr.fechaEventoHora = this.dateAndTimeService.convertirHoraAFormatoFormulario(this.pqr.fechaEvento);
      this.pqr.fechaEventoMinuto = this.dateAndTimeService.convertirMinutoAFormatoFormulario(this.pqr.fechaEvento);
    }

    // Fecha del seguimiento/cierre
    if (this.pqr && this.pqr.fechaSeguimientoCierre) {
      this.pqr.fechaSeguimientoCierreFecha = this.dateAndTimeService.convertirFechaAFormatoFormulario(
        this.pqr.fechaSeguimientoCierre
      );
      this.pqr.fechaSeguimientoCierreHora = this.dateAndTimeService.convertirHoraAFormatoFormulario(
        this.pqr.fechaSeguimientoCierre
      );
      this.pqr.fechaSeguimientoCierreMinuto = this.dateAndTimeService.convertirMinutoAFormatoFormulario(
        this.pqr.fechaSeguimientoCierre
      );
    }
  }

  changeValue(event, key: string) {
    this.formulario.patchValue({
      [key]: event.target.value,
    });
  }

  /**
   * Inicializa el formualrio del detalle
   */
  inicializarFormulario(): void {
    if (this.modo === this.modos[1]) {
      if (this.usuarioActual._id == this.pqr.quienReporta._id) {
        this.responsibleField = false;
      } else if (this.usuarioActual._id == this.pqr.responsable._id) {
        this.responsibleField = false;
        this.available = true;
      }
    } else {
      this.pqr = null;
      this.responsibleField = false;
    }

    this.formulario = new FormGroup({
      idPqr: new FormControl(
        { value: this.idPqr ? this.idPqr : this.pqr && this.pqr.idPqr ? this.pqr.idPqr : '', disabled: true },
        Validators.required
      ),
      viaje: new FormControl(
        {
          value: this.pqr && this.pqr.viaje && this.pqr.viaje._id ? `${this.pqr.viaje._id}` : '',
          disabled: this.available,
        },
        Validators.required
      ),
      nominador: new FormControl(
        {
          value: this.pqr && this.pqr.nominador && this.pqr.nominador._id ? this.pqr.nominador._id : '',
          disabled: this.available,
        },
        Validators.required
      ),
      cliente: new FormControl({
        value: '',
        disabled: this.available,
      }),
      fechaEventoFecha: new FormControl({
        value: this.pqr && this.pqr.fechaEventoFecha ? this.pqr.fechaEventoFecha : '',
        disabled: this.available,
      }),
      fechaEventoHora: new FormControl({
        value: this.pqr && this.pqr.fechaEventoHora ? this.pqr.fechaEventoHora : '',
        disabled: this.available,
      }),
      fechaEventoMinuto: new FormControl({
        value: this.pqr && this.pqr.fechaEventoMinuto ? this.pqr.fechaEventoMinuto : '',
        disabled: this.available,
      }),
      tipoSolicitud: new FormControl(
        {
          value: this.pqr && this.pqr.tipoSolicitud ? this.pqr.tipoSolicitud : '',
          disabled: this.available,
        },
        Validators.required
      ),
      area: new FormControl({
        value: this.pqr && this.pqr.area ? this.pqr.area : '',
        disabled: this.available,
      }),
      quienReporta: new FormControl(
        {
          value:
            this.pqr && this.pqr.quienReporta && this.pqr.quienReporta._id
              ? this.pqr.quienReporta._id
              : this.usuarioActual._id,
          disabled: true,
        },
        Validators.required
      ),
      responsable: new FormControl(
        {
          value: this.pqr && this.pqr.responsable && this.pqr.responsable._id ? this.pqr.responsable._id : '',
          disabled: this.available,
        },
        Validators.required
      ),
      descripcionCaso: new FormControl(
        {
          value: this.pqr && this.pqr.descripcionCaso ? this.pqr.descripcionCaso : '',
          disabled: this.available,
        },
        Validators.required
      ),
      respuestaCaso: new FormControl({
        value: this.pqr && this.pqr.respuestaCaso ? this.pqr.respuestaCaso : '',
        disabled: this.responsibleField,
      }),
      seguimientoCierre: new FormControl({
        value: this.pqr && this.pqr.seguimientoCierre ? this.pqr.seguimientoCierre : '',
        disabled: this.available,
      }),
      fechaSeguimientoCierreFecha: new FormControl({
        value: this.pqr && this.pqr.fechaSeguimientoCierreFecha ? this.pqr.fechaSeguimientoCierreFecha : '',
        disabled: this.available,
      }),
      fechaSeguimientoCierreHora: new FormControl({
        value: this.pqr && this.pqr.fechaSeguimientoCierreHora ? this.pqr.fechaSeguimientoCierreHora : '',
        disabled: this.available,
      }),
      fechaSeguimientoCierreMinuto: new FormControl({
        value: this.pqr && this.pqr.fechaSeguimientoCierreMinuto ? this.pqr.fechaSeguimientoCierreMinuto : '',
        disabled: this.available,
      }),
      estado: new FormControl(
        {
          value: this.pqr && this.pqr.estado ? this.pqr.estado : 'ABIERTO',
          disabled: this.available,
        },
        Validators.required
      ),
    });

    this.descripcionCaso.nativeElement.value = this.pqr && this.pqr.descripcionCaso ? this.pqr.descripcionCaso : '';
    this.respuestaCaso.nativeElement.value = this.pqr && this.pqr.respuestaCaso ? this.pqr.respuestaCaso : '';
    this.seguimientoCierre.nativeElement.value =
      this.pqr && this.pqr.seguimientoCierre ? this.pqr.seguimientoCierre : '';

    this.changeDateCierre();
  }

  /**
   * Maneja el evento de mandar los datos del formualrio
   */
  onEnviarFormulario() {
    if (!this.formulario.valid) {
      Swal.fire({
        title: `No se puede ${this.modo.toLocaleLowerCase()} la PQR`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      const datosDeSalida: any = this.obtenerDatosDeSalida();
      if (this.modo == this.modos[0]) {
        this.mainService.post('api/pqr', datosDeSalida).subscribe((result) => {
          if (result) {
            this.cambioEnPqr.emit(result);
            this.mainService.put('api/global_variable/idPqr', {}).subscribe((result) => {});
            this.mainService.post('api/sendEmail', this.generarCorreoPQR(result)).subscribe((result) => {});
            Swal.fire('Éxito', 'Se reportó la PQR exitosamente', 'success').then((result) => {
              if (result) {
                this.cerrarDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo reportar la PQR, verifique la información', 'warning');
          }
        });
      } else {
        this.mainService.put(`api/pqr/${this.pqr._id}`, datosDeSalida).subscribe((result) => {
          if (result) {
            this.cambioEnPqr.emit(result);
            this.mainService.post('api/sendEmail', this.generarCorreoPQR(result)).subscribe((result) => {});
            Swal.fire('Éxito', 'Se actualizó la PQR exitosamente', 'success').then((result) => {
              if (result.value) {
                this.cerrarDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo actualizar la PQR, verifique la información', 'warning');
          }
        });
      }
    }
  }

  /**
   * Genera el contenido del correo de la PQR
   * @param datosPqr Datos de la PQR del correo
   * @returns Objeto con el contenido de los correos de una PQR
   */
  generarCorreoPQR(datosPqr: any): any {
    const responsable: any =
      datosPqr && datosPqr.responsable
        ? this.usuarios.find((usuario) => usuario._id == datosPqr.responsable._id)
        : undefined;
    const quienReporta: any =
      datosPqr && datosPqr.quienReporta
        ? this.usuarios.find((usuario) => usuario._id == datosPqr.quienReporta._id)
        : undefined;
    const viaje: any =
      datosPqr && datosPqr.viaje ? this.viajes.find((viaje) => viaje._id == datosPqr.viaje._id) : undefined;
    const buque: any = viaje && viaje.vessel ? this.buques.find((buque) => buque.BUQ_ID == viaje.vessel.id) : undefined;
    const nominador: any =
      datosPqr && datosPqr.nominador
        ? this.empresas.find((empresa) => empresa._id == datosPqr.nominador._id)
        : undefined;
    const puerto: any =
      datosPqr && datosPqr.puerto ? this.puertos.find((puerto) => puerto._id == datosPqr.port._id) : undefined;

    const fechaEventoVisual: string =
      datosPqr && datosPqr.fechaEvento
        ? `${this.dateAndTimeService.convertirFechaAFormatoFormulario(datosPqr.fechaEvento)} ` +
          `${this.dateAndTimeService.convertirHoraAFormatoFormulario(datosPqr.fechaEvento)}:` +
          `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(datosPqr.fechaEvento)}`
        : undefined;
    const fechaSeguimientoVisual: string =
      datosPqr && datosPqr.fechaSeguimiento
        ? `${this.dateAndTimeService.convertirFechaAFormatoFormulario(datosPqr.fechaSeguimiento)} ` +
          `${this.dateAndTimeService.convertirHoraAFormatoFormulario(datosPqr.fechaSeguimiento)}:` +
          `${this.dateAndTimeService.convertirMinutoAFormatoFormulario(datosPqr.fechaSeguimiento)}`
        : undefined;

    const clientesVisual: string =
      datosPqr.clientes && datosPqr.clientes.length > 0 ? this.obtenerClientesVisual(datosPqr.clientes) : undefined;

    const html: string =
      '<table>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Estado:</th>' +
      `<td valign= top align=left>${datosPqr && datosPqr.estado ? datosPqr.estado : 'No definido'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Tipo solicitud: </th>' +
      `<td valign= top align=left>${datosPqr && datosPqr.tipoSolicitud ? datosPqr.tipoSolicitud : 'No definida'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Responsable:</th>' +
      `<td valign= top align=left>${
        responsable && responsable.nombre && responsable.apellido
          ? `${responsable.nombre} ${responsable.apellido}`
          : 'No definido'
      }</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Quien reporta:</th>' +
      `<td valign= top align=left>${
        quienReporta && quienReporta.nombre && quienReporta.apellido
          ? `${quienReporta.nombre} ${quienReporta.apellido}`
          : 'No definido'
      }</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Fecha del evento:</th>' +
      `<td valign= top align=left>${fechaEventoVisual ? fechaEventoVisual : 'No definida'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Area:</th>' +
      `<td valign= top align=left>${datosPqr && datosPqr.area ? datosPqr.area : 'No definida'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Buque:</th>' +
      `<td valign= top align=left>${buque && buque.BUQ_NOMBRE ? buque.BUQ_NOMBRE : 'No definido'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">NV:</th>' +
      `<td valign= top align=left>${viaje && viaje.nv ? viaje.nv : 'No definido'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Nominador</th>' +
      `<td valign= top align=left>${nominador && nominador.EMP_NOMBRE ? nominador.EMP_NOMBRE : 'No definido'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Puerto:</th>' +
      `<td valign= top align=left>${puerto && puerto.PUE_NOMBRE ? puerto.PUE_NOMBRE : 'No definido'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Clientes:</th>' +
      `<td valign= top align=left>${clientesVisual ? clientesVisual : 'No definidos'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Descripción del caso:</th>' +
      `<td valign= top align=left>${
        datosPqr && datosPqr.descripcionCaso ? this.obtenerTextoHTML(datosPqr.descripcionCaso) : 'No definida'
      }</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Respuesta del caso:</th>' +
      `<td valign= top align=left>${
        datosPqr && datosPqr.respuestaCaso ? this.obtenerTextoHTML(datosPqr.respuestaCaso) : 'No definida'
      }</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Fecha seguimiento/cierre:</th>' +
      `<td valign= top align=left>${fechaSeguimientoVisual ? fechaSeguimientoVisual : 'No definida'}</td>` +
      '</tr>' +
      '<tr>' +
      '<th valign= top align=left style ="padding-right: 10px;">Seguimiento/Cierre del caso:</th>' +
      `<td valign= top align=left>${
        datosPqr && datosPqr.seguimientoCierre ? this.obtenerTextoHTML(datosPqr.seguimientoCierre) : 'No definida'
      }</td>` +
      '</tr>' +
      '</table>';

    const subject: string =
      this.modo == this.modos[0]
        ? `Ha sido asignado una PQR con id: ${datosPqr && datosPqr.idPqr}`
        : `Se ha actualizado la PQR con id: ${datosPqr && datosPqr.idPqr}`;

    const to: string = this.usuarioActual._id == datosPqr.quienReporta._id ? responsable.email : quienReporta.email;

    return {
      to: to,
      subject: subject,
      html: html,
    };
  }

  /**
   * Coge un texto largo y lo convierte a HTML
   * @param texto Texto del cual se quiere obtener el HTML
   * @return El texto del parametro en formato HTML
   */
  obtenerTextoHTML(texto: string): string {
    if (!texto) return '';
    const textoPorParrafos = texto.split('\n');

    let textoHtml = '';
    textoPorParrafos.forEach((parrafo) => {
      textoHtml += '<p>' + (parrafo ? parrafo : '&nbsp;') + '</p>';
    });

    return textoHtml;
  }

  /**
   * Coge los datos del modulo y los pone en el formato JSON como deben ser guardados en la BD
   * @returns Datos en el formato que lo recibe la BD
   */
  obtenerDatosDeSalida(): any {
    let idResponsable;
    if (this.pqr) {
      idResponsable = this.pqr.responsable._id;
    } else {
      idResponsable = this.formulario.value.responsable;
    }

    if (this.usuarioActual._id == idResponsable) {
      const data = {
        respuestaCaso: this.formulario.value.respuestaCaso ? this.formulario.value.respuestaCaso : null,
      };

      if (data.respuestaCaso !== null && data.respuestaCaso !== '') {
        data['estado'] = 'SEGUIMIENTO';
      }

      return data;
    }

    let clientesDeSalida = [];
    this.clientes.forEach((cliente) => clientesDeSalida.push(cliente._id));

    const datosDeSalida = {
      idPqr: this.idPqr ? this.idPqr : this.pqr.idPqr ? this.pqr.idPqr : null,
      viaje: this.formulario.value.viaje ? this.formulario.value.viaje : null,
      nominador: this.formulario.value.nominador ? this.formulario.value.nominador : null,
      puerto: this.formulario.value.puerto ? this.formulario.value.puerto : null,
      fechaEvento: this.formulario.value.fechaEventoFecha
        ? this.dateAndTimeService.convertirFechaFormatoFormularioAFormatoBD(
            this.formulario.value.fechaEventoFecha,
            this.formulario.value.fechaEventoHora ? this.formulario.value.fechaEventoHora : '00',
            this.formulario.value.fechaEventoMinuto ? this.formulario.value.fechaEventoMinuto : '00'
          )
        : null,
      clientes: clientesDeSalida,
      tipoSolicitud: this.formulario.value.tipoSolicitud ? this.formulario.value.tipoSolicitud : null,
      area: this.formulario.value.area ? this.formulario.value.area : null,
      quienReporta: this.usuarioActual._id ? this.usuarioActual._id : null,
      responsable: this.formulario.value.responsable ? this.formulario.value.responsable : null,
      descripcionCaso: this.formulario.value.descripcionCaso ? this.formulario.value.descripcionCaso : null,
      respuestaCaso: this.formulario.value.respuestaCaso ? this.formulario.value.respuestaCaso : null,
      seguimientoCierre: this.formulario.value.seguimientoCierre ? this.formulario.value.seguimientoCierre : null,
      fechaSeguimientoCierre: this.formulario.value.fechaSeguimientoCierreFecha
        ? this.dateAndTimeService.convertirFechaFormatoFormularioAFormatoBD(
            this.formulario.value.fechaSeguimientoCierreFecha,
            this.formulario.value.fechaSeguimientoCierreHora ? this.formulario.value.fechaSeguimientoCierreHora : '00',
            this.formulario.value.fechaSeguimientoCierreMinuto
              ? this.formulario.value.fechaSeguimientoCierreMinuto
              : '00'
          )
        : null,
      estado: this.formulario.value.estado ? this.formulario.value.estado : null,
    };

    if (datosDeSalida.respuestaCaso !== null && datosDeSalida.respuestaCaso !== '') {
      datosDeSalida['estado'] = 'SEGUIMIENTO';
    }

    if (
      datosDeSalida.estado === 'COMPLETO' ||
      (datosDeSalida.seguimientoCierre !== null && datosDeSalida.seguimientoCierre !== '')
    ) {
      datosDeSalida['estado'] = 'COMPLETO';
    }

    return datosDeSalida;
  }

  /**
   * Cierra la ventana de dialogo del detalle
   */
  cerrarDialog() {
    this.pqr = null;
    this.dialogRef.close();
  }

  changeDateCierre() {
    this.formulario.get('estado').valueChanges.subscribe((value) => {
      if (value !== 'COMPLETO') return;
      this.setDateClose();
    });

    this.formulario.get('seguimientoCierre').valueChanges.subscribe((value) => {
      if (value === '' || value === null) return;
      this.setDateClose();
    });
  }

  setDateClose() {
    const date = moment(new Date());
    let hour = date.hour() as any;
    let minute = date.minute() as any;

    if (hour >= 0 && hour <= 9) {
      hour = `0${hour}`;
    }

    if (minute >= 0 && minute <= 9) {
      minute = `0${minute}`;
    }

    this.formulario.patchValue({
      fechaSeguimientoCierreFecha: date.format('YYYY-MM-DD'),
      fechaSeguimientoCierreHora: hour,
      fechaSeguimientoCierreMinuto: minute,
    });
  }

  /**
   * Borra un cliente de la lista de clientes
   * @param indiceCliente Indice del archivo en los archivos adjuntos
   */
  onBorrarCliente(indiceCliente: number): void {
    if (this.available) {
      Swal.fire('Error', 'No tiene permisos de escritura', 'warning');
    } else {
      this.clientes.splice(indiceCliente, 1);
    }
  }

  /**
   * Agrega un cliente a la lista de clientes
   */
  onAgregarCliente(): void {
    if (this.formulario.value.cliente) {
      const clienteAAgregar = this.empresas.find((empresa) => empresa._id == this.formulario.value.cliente);
      this.clientes.push(clienteAAgregar);
    }
  }

  /**
   * Obtiene la lista de clientes por sus nombres
   * @param clientes IDs de los clientes
   * @return Un string con la lista de los nombres de los clientes separada por commas
   */
  obtenerClientesVisual(idsClientes: any): string {
    let clientesVisual = '';

    idsClientes.forEach((cliente) => {
      const clienteFound = this.empresas.find((empresa) => empresa._id == cliente._id);
      clientesVisual += clienteFound.EMP_NOMBRE + ', ';
    });

    return clientesVisual.slice(0, clientesVisual.length - 2);
  }

  /**
   * Actualiza la fecha del seguimiento/ cierre a la fecha actual
   */
  onActualizarFechaSeguimientoCierre() {
    const ahora: string = new Date(Date.now()).toISOString();
    this.formulario.controls['fechaSeguimientoCierreFecha'].setValue(
      this.dateAndTimeService.convertirFechaAFormatoFormulario(ahora)
    );
    this.formulario.controls['fechaSeguimientoCierreHora'].setValue(
      this.dateAndTimeService.convertirHoraAFormatoFormulario(ahora)
    );
    this.formulario.controls['fechaSeguimientoCierreMinuto'].setValue(
      this.dateAndTimeService.convertirMinutoAFormatoFormulario(ahora)
    );
  }

  setNVForPQR(data: string) {
    this.formulario.patchValue({
      viaje: data,
    });
    this.onCambioViaje();
  }

  public dataCollectionToFindNV = [];
  setDataViajes() {
    this.dataCollectionToFindNV = [];
    this.viajes.forEach((viaje) => {
      this.dataCollectionToFindNV.push({
        key: viaje._id,
        value: `${viaje.nv} - ${viaje.vessel.nombre} - ${viaje.voyageNumber || ''} - ${viaje.port.nombre}`,
      });
    });
  }

  // clients

  setClient(data: string) {
    this.formulario.patchValue({
      cliente: data,
    });
  }

  public dataCollectionToClientes = [];
  setDataClientes() {
    this.dataCollectionToClientes = [];
    this.empresas.forEach((company) => {
      this.dataCollectionToClientes.push({
        key: company._id,
        value: company.EMP_NOMBRE,
      });
    });
  }
}
