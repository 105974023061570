import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../services/main.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss']
})
export class ParametrosComponent implements OnInit {
	/** Nombres de los parametros del sistema */
	parametros: string[] = ['SMLVM', 'SMLVD', 'TRM', 'UVT'];

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
	public permisosUsuario: any;

	constructor(
    private router: Router,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.obtenerPermisos();
  }
  /**
	 * Obtiene los permisos del módulo de roles y bloquea o deja pasar
	 */
	obtenerPermisos(){
		this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe(res => {
			this.permisosUsuario = res;
			if(this.permisosUsuario.parametrosSistema === 'NINGUNO'){
				Swal.fire({
					title: 'No se tiene permisos de acceso al módulo',
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Continuar'
				});
				this.router.navigate(['home/dashboard']);
			}
		})
	}

}
