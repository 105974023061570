import { Injectable } from '@angular/core';
import SofFormatOptions from '../../interfaces/enums/SofFormatOptions.enum';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormatSofService {
  constructor() {}

  private storeBuilder = {
    [SofFormatOptions.HEADER]: this.buildHeader,
    [SofFormatOptions.SUBHEADER]: this.buildGeneralInfo,
    [SofFormatOptions.ARRIVALDEPARTURE]: this.buildArriveDeparture,
    [SofFormatOptions.DEPARTURE]: this.buildDeparture,
    [SofFormatOptions.ARRIVAL]: this.buildArrive,
    [SofFormatOptions.PRIORITYREMARKS]: this.buildTablePriorityRemarks,
    [SofFormatOptions.ALLREMARKS]: this.buildTableAllRemarks,
    [SofFormatOptions.PORTLOG]: this.buildAllPortLog,
    [SofFormatOptions.ZARPEPORTLOG]: this.buildZarpePortLog,
    [SofFormatOptions.ARRIBOPORTLOG]: this.buildArriboPortLog,
    [SofFormatOptions.TOTALSTOPPEDOPERATIONS]: this.buildTotalHoursByGeneralType,
    [SofFormatOptions.TOTALSTOPPEDHOURS]: this.buildTableStoppedHours,
    [SofFormatOptions.MASTERREMARK]: this.buildMasterRemark,
    [SofFormatOptions.AGENCYREMARK]: this.buildAgencyRemark,
    [SofFormatOptions.TERMINALREMARK]: this.buildTerminalRemark,
    [SofFormatOptions.FOOTER]: this.buildFooter,
  };

  private buildOrder = [
    [SofFormatOptions.HEADER, SofFormatOptions.SUBHEADER],
    [
      SofFormatOptions.ARRIVALDEPARTURE,
      SofFormatOptions.ALLREMARKS,
      SofFormatOptions.ZARPEPORTLOG,
      SofFormatOptions.PRIORITYREMARKS,
      SofFormatOptions.TOTALSTOPPEDHOURS,
    ],
    [SofFormatOptions.FOOTER],
  ];

  private settingsPage = {
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Arial',
    },
  };

  public generateContentForPDF(dataToSet) {
    this.settingsPage.pageMargins = [20, 20, 20, 20];

    const headerPDF = {
      margin: [20, 20, 20, 0],
      columns: [[]],
    };
    const contentPDF = [];
    const footerPDF = {
      margin: [20, 20, 20, 0],
      columns: [[]],
    };

    // header
    this.buildOrder[0].forEach((builderName, index) => {
      if (!this.storeBuilder[builderName]) return;
      headerPDF.columns[0].push(this.storeBuilder[builderName].bind(this)(dataToSet));

      if (builderName === SofFormatOptions.HEADER) this.setSettingHeader(60);
      if (builderName === SofFormatOptions.SUBHEADER) this.setSettingHeader(80);

      if (this.buildOrder[0].length - 1 !== index) headerPDF.columns[0].push('\n');
    });

    // content
    this.buildOrder[1].forEach((builderName, index) => {
      if (!this.storeBuilder[builderName]) return;

      contentPDF.push(this.storeBuilder[builderName].bind(this)(dataToSet));
      if (this.buildOrder[1].length - 1 !== index) contentPDF.push('\n', '\n');
    });

    // footer
    if (this.storeBuilder[this.buildOrder[2] as any]) {
      this.setSettingFooter(30);
      footerPDF.columns[0].push(this.storeBuilder[this.buildOrder[2] as any].bind(this)(dataToSet));
    }

    return {
      ...this.settingsPage,
      header: (currentPage, pageCount) => {
        const clonedHeader = _.cloneDeep(headerPDF);
        clonedHeader.columns[0].unshift({ text: `${currentPage} / ${pageCount}`, fontSize: 8, alignment: 'right' });
        return clonedHeader;
      },
      content: contentPDF,
      footer: footerPDF,
      images: {
        navesLogo: 'https://naves-recursos.s3.amazonaws.com/archivosBuque/fadaca87-16bd-4599-897b-0058512a1ff8',
      },
    };
  }

  setSettingHeader(topMargin: number) {
    this.settingsPage.pageMargins[1] += topMargin;
  }

  setSettingFooter(bottomMargin: number) {
    this.settingsPage.pageMargins[3] += bottomMargin;
  }

  private buildHeader({ ship }) {
    return {
      style: 'tableExample',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { image: 'navesLogo', rowSpan: 2, width: 130 },
            { text: 'STATEMENT OF FACTS', rowSpan: 2, fontSize: 18, alignment: 'center' },
          ],
          ['', ''],
        ],
      },
    };
  }

  private buildGeneralInfo({ ship }) {
    return {
      style: 'tableExample',
      table: {
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [
            { text: 'Vessel', fontSize: 11, bold: true },
            { text: ship.vessel, fontSize: 9 },
            { text: 'Terminal', fontSize: 11, bold: true },
            { text: ship.terminal, fontSize: 9 },
          ],
          [
            { text: 'Voyage', fontSize: 11, bold: true },
            { text: ship.voyageNumber, fontSize: 9 },
            { text: 'Commodity', fontSize: 11, bold: true },
            { text: ship.commodity, fontSize: 9 },
          ],
          [
            { text: 'Port', fontSize: 11, bold: true },
            { text: ship.port, fontSize: 9 },
            { text: 'Flag', fontSize: 11, bold: true },
            { text: ship.vesselFlag, fontSize: 9 },
          ],
        ],
      },
    };
  }

  private buildArriveDeparture({ arriveDeparture }) {
    return {
      alignment: 'justify',
      columnGap: 20,
      columns: [this.generateArrive(arriveDeparture), this.generateDeparture(arriveDeparture)],
    };
  }

  private buildArrive({ arriveDeparture }) {
    return this.generateArrive(arriveDeparture);
  }

  private buildDeparture({ arriveDeparture }) {
    return this.generateDeparture(arriveDeparture);
  }

  private generateArrive(arriveConditions) {
    return {
      table: {
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [{ text: 'ARRIVAL CONDITIONS', fontSize: 11, bold: true, colSpan: 4, alignment: 'center' }, '', '', ''],
          [
            { text: 'BUNKER', fontSize: 9, colSpan: 2, alignment: 'center' },
            '',
            { text: 'DRAFT', fontSize: 9, colSpan: 2, alignment: 'center' },
            '',
          ],
          [
            { text: 'FO', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveBunkerFo, fontSize: 9, alignment: 'left' },
            { text: 'FWD', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveDraftFwd, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'DO', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveBunkerDo, fontSize: 9, alignment: 'left' },
            { text: 'AFT', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveDraftAft, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'FW', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveBunkerFw, fontSize: 9, alignment: 'left' },
            { text: 'LO', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.arriveDraftLo, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'TUGBOATS IN', fontSize: 9, alignment: 'left' },
            { text: arriveConditions.tugBoatsIn, fontSize: 9, alignment: 'left' },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
          ],
        ],
      },
    };
  }

  private generateDeparture(departureConditions) {
    return {
      table: {
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [{ text: 'SAILING CONDITIONS', fontSize: 11, bold: true, colSpan: 4, alignment: 'center' }, '', '', ''],
          [
            { text: 'BUNKER', fontSize: 9, colSpan: 2, alignment: 'center' },
            '',
            { text: 'DRAFT', fontSize: 9, colSpan: 2, alignment: 'center' },
            '',
          ],
          [
            { text: 'FO', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingBunkerFo, fontSize: 9, alignment: 'left' },
            { text: 'FWD', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingDraftFwd, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'DO', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingBunkerDo, fontSize: 9, alignment: 'left' },
            { text: 'AFT', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingDraftAft, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'FW', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingBunkerFw, fontSize: 9, alignment: 'left' },
            { text: 'LO', fontSize: 9, alignment: 'left' },
            { text: departureConditions.sailingDraftLo, fontSize: 9, alignment: 'left' },
          ],
          [
            { text: 'TUGBOATS OUT', fontSize: 9, alignment: 'left' },
            { text: departureConditions.tugBoatsOut, fontSize: 9, alignment: 'left' },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
          ],
        ],
      },
    };
  }

  private buildTableAllRemarks({ allRemarksPDF }) {
    return this.generatorForTableRemarks(allRemarksPDF, 'All Remarks');
  }

  private buildTablePriorityRemarks({ priorityRemarksPDF }) {
    return this.generatorForTableRemarks(priorityRemarksPDF, 'Priority Remarks');
  }

  private generatorForTableRemarks(remarks, title) {
    const table = {
      alignment: 'justify',
      columns: [
        [
          {
            text: title,
          },
          '\n',
          {
            style: 'tableExample',
            table: {
              widths: [50, 60, 'auto', 'auto', '*'/* , 100, 100 */],
              body: [
                [
                  { text: 'Date', fontSize: 11, bold: true },
                  { text: 'Hours Type', fontSize: 11, bold: true },
                  { text: 'From', fontSize: 11, bold: true },
                  { text: 'To', fontSize: 11, bold: true },
                  { text: 'Description', fontSize: 11, bold: true },
                  /* { text: 'Type', fontSize: 11, bold: true },
                  { text: 'Category', fontSize: 11, bold: true }, */
                ],
              ],
            },
          },
        ],
      ],
    };
    remarks.forEach((remark) => {
      if (remark.reportDay) {
        const fieldTextHours = remark.hours === undefined ? 'NII' : `${remark.hours}HRS ${remark.minutes}MNS`;
        const text = remark.collectionRangeHour == '' || remark.collectionRangeHour == null || remark.collectionRangeHour == undefined ? `${remark.collectionRangeHour} (${fieldTextHours})` : `${remark.collectionRangeHour.slice(0, -2)}`;

        (table.columns[0][2] as any).table.body.push([
          { text: 'Summary report of rain', fontSize: 9, alignment: 'center', colSpan: 5 },
          '',
          '',
          '',
          '',
          /* { text: `${text}`, fontSize: 9, alignment: 'center', colSpan: 2 },
          '', */
        ]);
        return;
      }

      (table.columns[0][2] as any).table.body.push([
        { text: remark.dateView, fontSize: 9, alignment: 'left' },
        { text: remark.hoursType, fontSize: 9, alignment: 'left' },
        { text: remark.from, fontSize: 9, alignment: 'left' },
        { text: remark.to, fontSize: 9, alignment: 'left' },
        { text: remark.description, fontSize: 9, alignment: 'left' },
        /* { text: remark.typeCopy, fontSize: 9, alignment: 'left' },
        { text: remark.categoryCopy, fontSize: 9, alignment: 'left' }, */
      ]);
    });

    return table;
  }

  private buildTableStoppedHours({ stoppedHoursInfo }) {
    stoppedHoursInfo.totalHoursByType = Object.values(stoppedHoursInfo.totalHoursByType).reverse();
    const table = {
      alignment: 'justify',
      columns: [
        [
          {
            style: 'tableExample',
            table: {
              widths: ['*', 120],
              body: [
                [
                  { text: 'Stopped Hours', fontSize: 11, bold: true },
                  { text: 'Time', fontSize: 11, bold: true },
                ],
              ],
            },
          },
        ],
      ],
    };

    if (stoppedHoursInfo.totalHoursByType.length === 1) return table;

    stoppedHoursInfo.totalHoursByType.forEach((timeInfo) => {
      (table.columns[0][0] as any).table.body.push([
        { text: timeInfo.type, fontSize: 9, alignment: 'left' },
        { text: `${timeInfo.hours}HRS ${timeInfo.minutes}MNS`, fontSize: 9, alignment: 'left' },
      ]);
    });

    return table;
  }

  private buildTotalHoursByGeneralType({ stoppedHoursInfo }) {
    const totalGeneralType = stoppedHoursInfo.totalGeneralType;

    const table = {
      alignment: 'justify',
      columns: [
        [
          {
            style: 'tableExample',
            table: {
              widths: ['*', 120],
              body: [
                [
                  { text: 'Stopped Operations', fontSize: 11, bold: true },
                  { text: 'Time', fontSize: 11, bold: true },
                ],
              ],
            },
          },
        ],
      ],
    };

    Object.keys(totalGeneralType).forEach((timeInfoKey: any) => {
      const timeInfo = totalGeneralType[timeInfoKey] as any;

      (table.columns[0][0] as any).table.body.push([
        { text: timeInfo.textForGeneralType, fontSize: 9, alignment: 'left' },
        { text: `${timeInfo.hours}HRS ${timeInfo.minutes}MNS`, fontSize: 9, alignment: 'left' },
      ]);
    });

    return table;
  }

  private buildAllPortLog({ portLogAll }) {
    return this.generatePortLog(portLogAll, 'PortLog');
  }

  private buildArriboPortLog({ portLogArribo }) {
    return this.generatePortLog(portLogArribo, 'Arrival Details');
  }

  private buildZarpePortLog({ portLogZarpe }) {
    return this.generatePortLog(portLogZarpe, 'Departure Details');
  }

  private generatePortLog(portLog, title) {
    const table = {
      alignment: 'justify',
      columns: [
        [
          {
            text: title,
          },
          '\n',
          {
            style: 'tableExample',
            table: {
              widths: ['*', 120],
              body: [
                [
                  { text: 'Activity', fontSize: 11, bold: true },
                  { text: 'Date', fontSize: 11, bold: true },
                ],
              ],
            },
          },
        ],
      ],
    };

    if (portLog.length === 0) return table;

    portLog.forEach((infoPortlog) => {
      (table.columns[0][2] as any).table.body.push([
        { text: infoPortlog.nombre, fontSize: 9, alignment: 'left' },
        { text: infoPortlog.fecha, fontSize: 9, alignment: 'left' },
      ]);
    });

    return table;
  }

  private buildMasterRemark({ customsRemarks }) {
    return this.generateSectionImage(customsRemarks.master, "Master's Remark");
  }

  private buildAgencyRemark({ customsRemarks }) {
    return this.generateSectionImage(customsRemarks.agency, "Agency's Remark");
  }

  private buildTerminalRemark({ customsRemarks }) {
    return this.generateSectionImage(customsRemarks.terminal, "Terminal's Remark");
  }

  private generateSectionImage(image, title) {
    let content: any = { image, width: 550 }
    if (!image) content = { text: "" }
    return [{ text: title }, '\n', content];
  }

  private buildFooter() {
    return {
      columnGap: 10,
      columns: [
        {
          style: 'tableExample',
          table: {
            widths: ['*'],
            body: [
              [{ text: 'VESSEL', fontSize: 11, bold: true, alignment: 'center', border: [false, true, false, false] }],
            ],
          },
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*'],
            body: [
              [{ text: 'AGENCY', fontSize: 11, bold: true, alignment: 'center', border: [false, true, false, false] }],
            ],
          },
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: 'TERMINAL/RECIEVERS',
                  fontSize: 11,
                  bold: true,
                  alignment: 'center',
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
      ],
    };
  }

  public setBuildOrder(buildOrder) {
    this.buildOrder = buildOrder;
  }
}
