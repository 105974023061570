import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'statement-of-facts-cargar-viaje',
  templateUrl: './statement-of-facts-cargar-viaje.component.html',
  styleUrls: ['./statement-of-facts-cargar-viaje.component.scss'],
})
export class StatementOfFactsCargarViajeComponent implements OnInit {
  @Input() available: boolean;
  /** Viaje de la proforma */
  public viaje: any = {};
  /** Indica si el modo actual es el de crear */
  public modoCrear: boolean = true;
  /** Datos del sof */
  public idSof: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;

  constructor(private mainService: MainService, public router: Router) {}

  ngOnInit() {
    const urlSeparada = this.router.url.split('/');
    this.inicializarDatos(urlSeparada[4]);
  }

  /**
   * Inicializa los datos de la BD
   * @param idViaje ID del viaje de la proforma
   */
  inicializarDatos(idViaje) {
    this.cargando = true;

    this.idSof = undefined;

    this.mainService.get(`api/viaje/${idViaje}`).subscribe((res) => {
      this.viaje = res;

      this.modoCrear = !(this.viaje.sof && this.viaje.sof.length > 0);
      if (!this.modoCrear) {
        this.idSof = this.viaje.sof[0];
      }

      this.cargando = false;
    });
  }

  /**
   * Crea el sof asociado al viaje
   */
  onCrearSof(): void {
    let nuevoSof = {
      voyage: this.viaje.voyageNumber,
      terminal: this.viaje.terminal,
      nv: this.viaje.nv,
      via_id: this.viaje._id,
      history: [],
    };

    this.mainService.post('api/sof', nuevoSof).subscribe(
      (res) => {
        this.idSof = res._id;
        this.modoCrear = false;
        this.mainService.put(`api/nuevo-viaje/${this.viaje._id}`, { sof: [this.idSof] }).subscribe(
          (res) => {
            alert('Se creo y asocio el SOF correctamente');
            Swal.fire('Éxito', 'Se creó el viaje exitosamente', 'success');
          },
          (err) => {
            Swal.fire('Error', 'No se pudo asociar el SOF al viaje', 'error');
          }
        );
      },
      (err) => {
        Swal.fire('Error', 'No se pudo crear el SOF', 'error');
      }
    );
  }
}
