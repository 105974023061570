import { Component, OnInit } from '@angular/core';

import { Instruction } from '../../../../../shared/nb-card-module/nb-card-module.component';

@Component({
  selector: 'captian-interview-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  public instructionNbCard: Instruction = {
    title: 'Listado de preguntas',
    textButton: 'Crear Pregunta',
  };

  constructor() {}

  ngOnInit() {}
}
