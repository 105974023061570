import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'captian-interview-survy',
  templateUrl: './survy.component.html',
  styleUrls: ['./survy.component.scss'],
})
export class SurvyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
