import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../../../../services/main.service';
import { Router } from '@angular/router';
import { ReportesService } from '../../../../reportes.service';
import * as moment from 'moment';
@Component({
  selector: 'vista-previa-reporte-productividad',
  templateUrl: './vista-previa-reporte-productividad.component.html',
  styleUrls: ['./vista-previa-reporte-productividad.component.scss'],
})
export class VistaPreviaReporteProductividadComponent implements OnInit {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  public companyName: string;
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Arreglo con los parrafos del remark*/
  public parrafosRemarks: string[] = [];
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = false;
  /** Productos del viaje */
  public productosViaje: any;
  /** Imágen del buque */
  public imagenBuque: string;
  public iconBuque: string;

  /** Productividad total */
  public productividadTotal: number = 0;

  /** Lista de correos con copia y copia oculta */
  public correosCopia: string[];
  public correosCopiaOculta: string[];

  public showIncludeProductivityDetails: boolean;
  public showIncludeStoppedHours: boolean;
  public showSOFActivities: boolean;
  public stoppedHoursReport;

  public calculatedProductivityDetials = {};

  asunto: string;
  attachments: any[];

  sofConfirmadas: any[];
  sofEstimadas: any[];

  constructor(private mainService: MainService, public router: Router, private reportesService: ReportesService) {}

  ngOnInit() {
    this.obtenerViaje();
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de prospectos e inicializa el viaje en el formato requerido para
   * el componente de reporte de prospectos
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
      try {
        this.viaje = res;

        this.viaje.commenceOpsVisual =
          this.viaje &&
          this.viaje.portLog &&
          this.viaje.portLog.commenceOPS &&
          this.viaje.portLog.commenceOPS.fecha &&
          this.viaje.portLog.commenceOPS.status == 'CONFIRMED'
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.portLog.commenceOPS.fecha)
            : 'Not registered';
        this.viaje.etcVisual =
          this.viaje && this.viaje.etc
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
            : 'Not registered';
        this.viaje.etdVisual =
          this.viaje && this.viaje.etd
            ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
            : 'Not registered';

        await this.onCargarProductos();
        this.obtenerProductosViaje();

        this.parrafosRemarks = this.viaje.remark ? this.viaje.remark.split('\n') : [];
        this.cargando = false;
      } catch (err) {
        console.error(err);
      }
    });
  }

  public includeProductivityDetails() {
    this.showIncludeProductivityDetails = !this.showIncludeProductivityDetails;
  }

  public includeStoppedHours() {
    this.showIncludeStoppedHours = !this.showIncludeStoppedHours;
  }

  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  public showVessel = false;
  public includeVesselGraphic() {
    this.showVessel = !this.showVessel;
  }

  /**
   * Revisa si es de tipo objeto
   * @param posible El posible objeto
   * @returns {boolean}
   */
  isObject(posible) {
    return typeof posible === 'object';
  }

  public productsByHolds = {
    H1: {
      hold: 'H1',
      products: [],
    },
    H2: {
      hold: 'H2',
      products: [],
    },
    H3: {
      hold: 'H3',
      products: [],
    },
    H4: {
      hold: 'H4',
      products: [],
    },
    H5: {
      hold: 'H5',
      products: [],
    },
    H6: {
      hold: 'H6',
      products: [],
    },
  };
  public productsForView = Object.values(this.productsByHolds).reverse();

  public products = [];
  async onCargarProductos() {
    const products = await this.mainService.get(`api/producto/commodity/${this.viaje.commodity.id}`).toPromise();
    this.products = products;
  }

  /**
   * Obtiene los productos del viaje
   */
  obtenerProductosViaje(): void {
    this.mainService.get(`api/producto_completo/recalada/${this.viaje.recalada._id}`).subscribe((res) => {
      this.productosViaje = res;

      this.productosViaje.forEach((product) => {
        if (!this.productsByHolds[product.HOLD_NUMBER]) return;
        this.productoAFromatoVisual(product);
        this.productsByHolds[product.HOLD_NUMBER].products.push(product);
      });
    });
  }

  /**
   * Coge un producto y lo pasa al formato para visualizar en la tabla
   */
  productoAFromatoVisual(producto: any): void {
    producto.createdAt = moment(producto.createdAt).format('DD-MM-YYYY HH:mm');
    const productividadParcial = moment(producto.PRV__TIME_TO).diff(moment(producto.PRV__TIME_FROM), 'hours');
    this.productividadTotal += productividadParcial;

    producto.nombreProducto = 'Cargando ...';
    this.mainService.get(`api/producto?PRD_ID=${producto.PRD_ID}`).subscribe((res) => {
      const proudctoAsociado = res.filter((element) => element.PRD_ID == producto.PRD_ID);
      producto.nombreProducto =
        proudctoAsociado && proudctoAsociado[0] && proudctoAsociado[0].PRD_NOMBRE
          ? proudctoAsociado[0].PRD_NOMBRE
          : 'Producto no encontrado';
      this.productosViaje = this.productosViaje.slice();
    });

    producto.acumuladoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_ACUMULADO);
    producto.productividadVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_PROD_12H);
    producto.planificadoVisual = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(producto.PRV_CANTIDAD);
    producto.robVisual = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      producto.PRV_ROB
    );

    producto.nombreMedida = 'Cargando ...';
    this.mainService.get(`api/medida?MED_ID=${producto.MED_ID}`).subscribe((res) => {
      producto.nombreMedida =
        res && res[0] && res[0].MED_NOMBRE
          ? res.filter((element) => element.MED_ID == producto.MED_ID)[0].MED_NOMBRE
          : 'Medida no encontrada';
      this.productosViaje = this.productosViaje.slice();
    });

    producto.holdInfo = `H${producto.HOLD_NUMBER}`;
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;

    const allActivites = [...this.arregloOrdenadoActividadesConfirmadas, ...this.arregloOrdenadoActividadesEstimadas];

    if (this.incluirActividades) {
      allActivites.push(...this.sofConfirmadas, ...this.sofEstimadas);
    }

    const { confirmedActivities, estimatedActivites } = this.reportesService.combinePortLogsAndSOFActivities(
      allActivites,
      this.incluirActividades
    );

    this.arregloOrdenadoActividadesConfirmadas = [...confirmedActivities];
    this.arregloOrdenadoActividadesEstimadas = [...estimatedActivites];
  }
}
